import React from 'react'
import RestaurantCardContent from './RestaurantCardContent'

interface IBusinessCardProps {
	business: any
	view?:boolean
}

const ProductCard = ({view,business}: IBusinessCardProps) => {
	return (
				<RestaurantCardContent view={view} business={business} />
	)
}

export default ProductCard
