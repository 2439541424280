import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { Heading, Spacer, Text, Divider, Flexed } from '../../styled/shared'
import { FaFacebookF, FaTwitter, FaSnapchatGhost, FaYoutube, FaInstagram } from "react-icons/fa";
import { palette } from '../../styled/colors'
import { useSelector } from 'react-redux'
import useWindowSize from '../../hooks/useWindowSize';
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlinePhone, MdOutlineMailOutline } from "react-icons/md";
import { Link } from 'react-router-dom';

interface IProps {
    isDarkTheme: boolean,
}

const BusinessFooter = ({ businessFullDetails }: any) => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
    const _windowSize = useWindowSize();
    return (
        <Section isDarkTheme={_isDarkTheme}>
            <Wrapper>
                <Row justifyContent='between'>
                    <Col>
                        <SubSection>
                            <Content>
                                {businessFullDetails?.photo && <Image src={process.env.REACT_APP_IMAGE_URL + businessFullDetails?.photo} />
                                }
                            </Content>
                            {(businessFullDetails?.youTube != "" || businessFullDetails?.facebook != "" || businessFullDetails?.twitter != "" || businessFullDetails?.snapChat != "" || businessFullDetails?.instagram != "") &&  <Spacer height={0.7} />}
                            <Icon>
                                {businessFullDetails?.youTube && <ArrowAvatar href={businessFullDetails?.youTube} target='_blank'>
                                    <FaYoutube color={`${_isDarkTheme ? palette.silver : palette.gray}`} />
                                </ArrowAvatar>}
                                {businessFullDetails?.facebook && <ArrowAvatar href={businessFullDetails?.facebook} target='_blank'>
                                    <FaFacebookF color={`${_isDarkTheme ? palette.silver : palette.gray}`} />
                                </ArrowAvatar>}
                                {businessFullDetails?.twitter && <ArrowAvatar href={businessFullDetails?.twitter} target='_blank'>
                                    <FaTwitter color={`${_isDarkTheme ? palette.silver : palette.gray}`} />
                                </ArrowAvatar>}
                                {businessFullDetails?.snapChat && <ArrowAvatar href={businessFullDetails?.snapChat} target='_blank'>
                                    <FaSnapchatGhost color={`${_isDarkTheme ? palette.silver : palette.gray}`} />
                                </ArrowAvatar>}
                                {businessFullDetails?.instagram && <ArrowAvatar href={businessFullDetails?.instagram} target='_blank'>
                                    <FaInstagram color={`${_isDarkTheme ? palette.silver : palette.gray}`} />
                                </ArrowAvatar>}
                            </Icon>
                        </SubSection>
                    </Col>
                    {(businessFullDetails?.displayPhoneNo || businessFullDetails?.displayEmail) && <Col>
                        <SubSection>
                            <Text type='medium' margin='0.5rem 0rem 0.5rem 0rem' fontWeight={600} isDarkTheme={_isDarkTheme} >Contact us</Text>
                        </SubSection>
                        <Flexed direction='row' gap={1} justify='center' flexWrap='wrap'>
                            {businessFullDetails?.displayPhoneNo && <CustomText pointer direction='row' gap={0.5} justify='center' align='center' onClick={() => {
                                const telLink = `tel:${businessFullDetails?.phone}`
                                window.location.href = telLink
                            }}>
                                <MdOutlinePhone color={`${_isDarkTheme ? palette.silver : palette.gray}`} />
                                <Text isDarkTheme={_isDarkTheme}>+{businessFullDetails?.phone}</Text>
                            </CustomText>}
                            {businessFullDetails?.displayEmail && <CustomText pointer direction='row' gap={0.5} justify='center' align='center' onClick={() => {
                                const mailtoLink = `mailto:${businessFullDetails?.email}`
                                window.location.href = mailtoLink
                            }}>
                                <MdOutlineMailOutline color={`${_isDarkTheme ? palette.silver : palette.gray}`} />
                                <Text isDarkTheme={_isDarkTheme}>{businessFullDetails?.email}</Text>
                            </CustomText>}
                        </Flexed>
                    </Col>}
                </Row>
            </Wrapper>
        </Section>
    )
}

const Section = styled.div<IProps>`
    background: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
    border-top: 0.063rem solid ${({ isDarkTheme }) => (isDarkTheme ? palette.light_silver : palette.silver)};
	padding: 2rem 0;
`

const Wrapper = styled(Container)`
`

const ArrowAvatar = styled.a`
	vertical-align: middle;
	width: 2.188rem;
	height: 2.188rem;
	border-radius: 50%;
	border: 0.063rem solid ${palette.silver};
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0.5rem;
    cursor:pointer;
	&:not(:last-child) {
		margin-right: 0.625rem;
	}
    &:not(:last-child) {
		margin-right: 0.625rem;
	}
	&:hover {
		border: 0.063rem solid ${palette.red};
		background:  ${palette.red};
	}
	&:hover svg {
		color: white !important;
	}
`
const SubSection = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`

const Image = styled.img`
	height: 6rem;
    aspect-ratio: 3/1.9;
    object-fit: cover;
`
const Content = styled.div``

const Icon = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const CustomText = styled(Flexed) <any>`
   &:hover div{
    color: ${palette.red_hover_text} !important;
   }
   &:hover svg{
    color: ${palette.red_hover_text} !important;
   }
`;


export default BusinessFooter
