import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { Flexed, Heading, Spacer, Text, Loading } from '../../styled/shared'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useSelector } from 'react-redux'
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import { createSearchParams, useNavigate } from 'react-router-dom'
import Button from '../../components/common/Button'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import axios from 'axios'
import InputField from '../../components/common/InputField'
import { api } from '../../components/api/callAxios'
import { toast } from 'react-toastify'
import { verifyJSON } from '../../constants/commonFunctions'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'


const PlaceOrder = () => {
    const MySwal = withReactContent(Swal)
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);
    const _loginBusinessDetails: any = useSelector<any>((state: any) => state.auth.loginBusinessDetails)
    const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
    const _checkOutDetail: any = useSelector<any>((state: any) => state.auth.checkOutDetail)
    const _loginUserStatus: any = localStorage.getItem('userStatus')
    const [paymentMethod, setPaymentMethod] = useState('cashOnDelivery')
    const [instructions, setInstructions] = useState('')
    const [isLoading, setIsLoading] = useState<any>(false)
    const [address, setAddress] = useState('');

    let _navigate = useNavigate();
    const [map, setMap] = useState(null)
    const [clat, setCLat] = useState<any>('')
    const [clng, setCLng] = useState<any>('')


    useEffect(() => {
        if (_loginUserStatus === 'owner') {
            let _loginBusinessLocationJson = _loginBusinessDetails?.location;
            let _loginBusinessLocationObject = verifyJSON(_loginBusinessLocationJson)
            ? JSON.parse(_loginBusinessLocationJson)
            : _loginBusinessLocationJson;
            var _loginBusinessFullAddress = _loginBusinessLocationObject?.address;
            setAddress(_loginBusinessFullAddress)
            var _loginBusinessAddress = _loginBusinessFullAddress?.split('#');
            if(_loginUserAddress[1]){
            var _loginBusinessCoords = _loginBusinessAddress[1]?.split(' ');
            getAddress(parseFloat(_loginBusinessCoords[1]), parseFloat(_loginBusinessCoords[2]));
            }
        }
        else if (_loginUserStatus === 'customer') {
            let _loginUserLocationJson = _loginUserDetails?.address;
            let _loginUserLocationObject = verifyJSON(_loginUserLocationJson)
                ? JSON.parse(_loginUserLocationJson)
                : _loginUserLocationJson;
            var _loginUserFullAddress = _loginUserLocationObject?.address;
            setAddress(_loginUserFullAddress)
            var _loginUserAddress = _loginUserFullAddress?.split('#');
            if(_loginUserAddress[1]){
            var _loginUserCoords = _loginUserAddress[1]?.split(' ');
            getAddress(parseFloat(_loginUserCoords[1]), parseFloat(_loginUserCoords[2]));
            }
        }
    }, [_loginUserStatus])

    const getAddress = async (lat: any, lng: any) => {
        await getCurrentLatLng(lat, lng);
    };

    const containerStyle = {
        width: '100%',
        height: '330px'
    };

    const center = {
        lat: clat ? clat : 38.892708,
        lng: clng ? clng : -94.6426741,
        zoom: 15
    };



    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAiSEHvjCeth6Gx8YDltGHUhzBl5ongDRk"
    })

    const getCurrentLatLng = async (lat: any, lng: any) => {
        navigator.geolocation.getCurrentPosition(
            async position => {
                setCLat(lat);
                setCLng(lng);
                await getCurrentAddress(lat, lng);
            },
            function (error) {
                // eslint-disable-next-line eqeqeq
                if (error.code == error.PERMISSION_DENIED) {
                } else {
                }
            },
            { timeout: 5000, enableHighAccuracy: true }
        );
    };

    const getCurrentAddress = async (lat: any, lng: any) => {
        setCLat(lat)
        setCLng(lng)
        await axios
            .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(lat)},${parseFloat(lng)}&sensor=true&key=${process.env.REACT_APP_MAP_API_KEY}`)
            .then((response) => {
            })
            .catch((error) => {
            })
    }

    const placeOrder = () => {
        if (_checkOutDetail?.id) {
            setIsLoading(true)
            api.put(`/check-out/${_checkOutDetail?.id}`, {
                paymentMethod: paymentMethod,
                instructions: instructions,
            }).then(() => {
                setIsLoading(false)
                placeOrderPopup()

            }).catch((e) => {
                if (e?.response) {
                    toast.error(e?.response?.data?.message)
                    setIsLoading(false)
                }
            })
        }
        else {
            toast.error('Please place your order first.');
        }

    }

    const placeOrderPopup = () => {
        MySwal.fire({
            title: <p>Your order has been placed successfully</p>,
            icon: 'success',
            confirmButtonText: 'Thank You'
        }).then((result) => {
            if (result.isConfirmed) {
                _navigate('/history')
            }
        });
    }


    return (
        <>
            <Container>
                <Spacer height={2} />
                <Row>
                    <Col lg={6}>
                        <Heading isDarkTheme={_isDarkTheme} isCentered margin='0rem 0rem 2rem 0rem'>Checkout List</Heading>

                        <BillingContent isDarkTheme={_isDarkTheme}>
                            <Heading fontWeight={700} isDarkTheme={_isDarkTheme} margin='1.1rem 0rem 0.5rem 0rem'>
                                Payment Method
                            </Heading>
                            <Flexed justify='space-between' gap={0.5} pointer>
                                <Flexed direction="row" align="center" gap={0.5}>
                                    <input id='cashOnDelivery' name='paymentMethod' type="radio" value="cashOnDelivery" checked={paymentMethod === "cashOnDelivery"} onClick={() => { setPaymentMethod('cashOnDelivery') }} />
                                    <Label htmlFor='cashOnDelivery' isDarkTheme={_isDarkTheme}>
                                        Cash on Delivery
                                    </Label>
                                </Flexed>

                                <Flexed direction="row" align="center" gap={0.5}>
                                    <input id='debitOrCreditCard' name='paymentMethod' type="radio" value="debitOrCreditCard" checked={paymentMethod === "debitOrCreditCard"} onClick={() => { setPaymentMethod('debitOrCreditCard') }} />
                                    <Label htmlFor='debitOrCreditCard' isDarkTheme={_isDarkTheme}>
                                        Debit / Credit card
                                    </Label>
                                </Flexed>
                            </Flexed>
                            {paymentMethod == 'debitOrCreditCard' && <>
                                <Spacer height={1} />
                                <InputField label="Crad No" />
                                <Spacer height={1} />
                                <InputField label="CCV" />
                                <Spacer height={1} />
                                <InputField label="Expiray" />
                            </>
                            }
                            <Spacer height={1} />
                            <InputField label="Instructions for rider" type='textarea' value={instructions} handleChange={(e: any) => {
                                if (e?.length <= 250) {
                                    setInstructions(e)
                                }
                            }} />
                            <Flexed direction='row' justify='flex-end'>
                                <Text isDarkTheme={_isDarkTheme} type='xsmall'>{instructions?.length}/250</Text>
                            </Flexed>

                            <Flexed direction="row" gap={2} margin='1rem 0rem 0rem 0rem'>
                                <Button medium label="Edit Order" width="100%" ifClicked={() => {
                                    _navigate({
                                        pathname: '/business-details',
                                        search: createSearchParams({
                                            id: _loginUserStatus === 'owner' ? _loginBusinessDetails?.id : _checkOutDetail?.businessId
                                        }).toString()
                                    })
                                }} />
                                <Button medium label="Place Order" width="100%" ifClicked={placeOrder} />
                            </Flexed>
                        </BillingContent>
                    </Col>
                    <Col lg={6}>
                        <Heading isDarkTheme={_isDarkTheme} isCentered margin='0rem 0rem 2rem 0rem'>Delivery Location</Heading>

                        {isLoaded ? (
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                zoom={center.zoom}
                                center={center}
                                onLoad={(map: any) => {
                                    setMap(map)
                                }}
                                options={{
                                    mapTypeControl: false,
                                    fullscreenControl: false,
                                    streetViewControl: false,
                                }}
                            >
                                <MarkerF
                                    position={{ lat: parseFloat(clat), lng: parseFloat(clng) }}
                                    icon={{
                                        // url: `/images/icons/marker.svg`,
                                        scaledSize: new window.google.maps.Size(40, 40),
                                        // @ts-ignore
                                        shape: { coords: [17, 17, 18], type: 'circle' },
                                        optimized: false,
                                    }}
                                />
                            </GoogleMap>
                        ) : <Text isDarkTheme={_isDarkTheme}>Loading</Text>}
                        <Flexed direction="row" margin='1rem 0rem 0rem 0rem' gap={1} >
                            <Text type="normal" isDarkTheme={_isDarkTheme}>
                                Address:
                            </Text>
                            <Text type="normal" isDarkTheme={_isDarkTheme}>
                                {address}
                            </Text>
                        </Flexed>
                    </Col>
                </Row>
                <Spacer height={2} />
            </Container>
            {isLoading && <Loading ><div className="dots-loading"></div></Loading>}
        </>
    );
}

const BillingContent = styled.div<any>`
	background: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem 1rem;
	border-radius: 0.39rem;
`

const Label = styled.label<any>`
	margin: 0;
	cursor: pointer;
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
`

export default PlaceOrder
