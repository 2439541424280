import * as types from '../actions/types'

const initialState = {
	isDarkTheme: false,
	loginUserDetails: {},
	loginBusinessDetails: {},
	checkOutDetail: null,
	search: null,
	isImageSearch: false,
	saveSearchImage: ''
}

export default function authReducer(state = initialState, action: any) {
	switch (action.type) {
		case types.SWITCH_THEME: {
			return {
				...state,
				isDarkTheme: action.value
			}
		}
		case types.LOGIN_USER_DETAILS:
			return {
				...state,
				loginUserDetails: action.value
			}
		case types.LOGIN_BUSINESS_DETAILS:
			return {
				...state,
				loginBusinessDetails: action.value
			}
		case types.CHECK_OUT_DETAIL:
			return {
				...state,
				checkOutDetail: action.value
			}
		case types.SEARCH:
			return {
				...state,
				search: action.value
			}
		case types.IMAGE_SEARCH:
			return {
				...state,
				isImageSearch: action.value
			}
		case types.SAVE_SEARCH_IMAGE:
			return {
				...state,
				saveSearchImage: action.value
			}
		default:
			return state
	}
}
