import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { Text, Spacer, Flexed, Loading } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { BsXLg } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { media } from 'styled-bootstrap-grid'
import axios from 'axios'
import InputField from '../common/InputField'
import { api } from '../api/callAxios'
import Button from '../common/Button'
import { Rating } from 'react-simple-star-rating'
import { toast } from 'react-toastify'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

interface ICategoriesModalProps {
    open: boolean
    onCloseModal: any
    orderFullDetails:any
    reCallGetOrderDetails:any
}

interface IProps {
    isDarkTheme: boolean
}

const AddReviewsModal = ({ open, onCloseModal,orderFullDetails,reCallGetOrderDetails }: ICategoriesModalProps) => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
    const [rating, setRating] = useState(0)
    const [review, setReview] = useState('');
    const [isLoading, setIsLoading] = useState<any>(false)

    const handleRating = (rate: number) => {
        setRating(rate)
    }

    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value: number, index: number) => console.log(value, index)

    const AddReview = () => {
        setIsLoading(true)
        api.post('/rating/create', {
            ratingPoints: rating,
            orderId: orderFullDetails?.orderId,
            businessId: orderFullDetails?.businessId,
            customerId: orderFullDetails?.customerId,
            reviews: review
        })
            .then((res) => {
                if(res?.data.status){
                    toast.success('Your feedback is greatly appreciated, Thank you.')
                    reCallGetOrderDetails();
                }
                setIsLoading(false)
                onCloseModal()
            }).catch(function (error: any) {
                console.log(error)
                setIsLoading(false)
            })
    }
    return (
        <>
            <Modal
                open={open}
                center
                onClose={() => {
                    onCloseModal()
                }}
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'blockModal'
                }}
                showCloseIcon={false}
                // @ts-ignore
                styles={{ zIndex: '2' }}>
                <ModalWrapper isDarkTheme={_isDarkTheme}>
                    <Head direction="row" align="center" justify="space-between" gap={1}>
                        <Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
                            Add your Review
                        </Text>
                        <CrossIcon
                            isDarkTheme={_isDarkTheme}
                            onClick={() => {
                                onCloseModal()
                            }}
                        />
                    </Head>
                    <Body>
                        <Spacer height={0.5} />
                        <Rating
                        allowFraction={true}
                            onClick={handleRating}
                            onPointerEnter={onPointerEnter}
                            onPointerLeave={onPointerLeave}
                            onPointerMove={onPointerMove}
                        /* Available Props */
                        />
                        <Spacer height={1} />

                        <InputField type='textarea' placeholder='Type here' label='Review' value={review} handleChange={(e: any) => {
                            if (e?.length <= 200) {
                                setReview(e)
                            }
                        }} />
                        <Flexed direction='row' justify='flex-end'>
                            <Text isDarkTheme={_isDarkTheme} type='xsmall'>{review?.length}/200</Text>
                        </Flexed>

                        <Spacer height={1.5} />

                        <Flexed direction='row' align='center' justify='space-between' gap={1}>
                            <Button small width='100%' type='red' label='Save' ifClicked={AddReview} />
                        </Flexed>
                    </Body>
                </ModalWrapper>
            </Modal>
            {isLoading && <Loading ><div className="dots-loading"></div></Loading>}
        </>
    )
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
    padding: 1rem;
`

const Head = styled(Flexed)`
	padding: 1rem;
    ${media.sm`
    width: 400px;
	`}
    ${media.md`
    width: 400px;
	`}
`

const Body = styled.div`
padding: 0rem 1rem 1rem;
`

const CrossIcon = styled(BsXLg) <IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

export default AddReviewsModal
