import styled from "styled-components"
import {Modal} from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import {Text, Spacer, Flexed} from "../../styled/shared"
import {palette} from "../../styled/colors"
import {BsXLg} from "react-icons/bs"
import {useSelector} from "react-redux"
import Button from "../common/Button"
import {media} from "styled-bootstrap-grid"

interface ICategoriesModalProps {
	open: boolean
	onCloseModal: any
	deleteAction: any
}

interface IProps {
	isDarkTheme: boolean
}

const DeleteAccountModal = ({open, onCloseModal, deleteAction}: ICategoriesModalProps) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	return (
		<>
			<Modal
				open={open}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: "customOverlay",
					modal: "blockModal",
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{zIndex: "2"}}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" align="center" justify="space-between">
						<Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
							Delete Account?
						</Text>
						<CrossIcon
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						<Spacer height={0.5} />
						<Text type="normal" fontWeight={500} isCentered isDarkTheme={_isDarkTheme}>
							Are you sure you want to delete your account.
						</Text>
						<Spacer height={2} />
						<Flexed direction="row" align="center" justify="space-between" gap={1}>
							<Cancel
								isDarkTheme={_isDarkTheme}
								onClick={() => {
									onCloseModal()
								}}>
								Cancel
							</Cancel>
							<Button
								label="Delete"
								type="red"
								small
								width="100%"
								ifClicked={() => {
									deleteAction()
								}}
							/>
						</Flexed>
					</Body>
				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem;
`

const Head = styled(Flexed)`
	padding: 1rem;
	${media.sm`
    width: 400px;
	`}
	${media.md`
    width: 400px;
	`}
`

const Body = styled.div`
	padding: 0rem 1rem 1rem;
`

const CrossIcon = styled(BsXLg)<IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

const Cancel = styled.div<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.dark_gray : palette.black)};
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	border-radius: 0.3rem;
	padding: 0.4rem 0.8rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	justify-content: center;
	font-size: 0.75rem;
	font-weight: bold;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.white)};
	cursor: pointer;
	width: 100%;
	text-transform: uppercase;
	&:hover {
		background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.dark_gray)};
		border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.black)};
	}
`

export default DeleteAccountModal
