import React, {useState} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Heading, Spacer, Text, Divider, Flexed} from '../../styled/shared'
import {FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube} from 'react-icons/fa'
import {palette} from '../../styled/colors'
import {useSelector} from 'react-redux'
import useWindowSize from '../../hooks/useWindowSize'
import {CommonEnum} from '../../utils'
import {TbWorld} from 'react-icons/tb'
import useRouter from './UserRouterHook'

interface IProps {
	isDarkTheme: boolean
}

const Footer = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _windowSize = useWindowSize()
	const router = useRouter()

	return (
		<Section isDarkTheme={_isDarkTheme}>
			<Wrapper>
				<Row justifyContent="center">
					<CustomColMain lg={2.4} xs={12} sm={12} md={12}>
						<SubSection>
							<Content>
								<Image src={CommonEnum.logo} />
							</Content>
							<Spacer height={1} />
							<Icon>
								<ArrowAvatar>
									<a href={CommonEnum.linkedinUrl} target="_black">
										<FaLinkedinIn color={`${_isDarkTheme ? palette.silver : palette.gray}`} />
									</a>
								</ArrowAvatar>

								<ArrowAvatar>
									<a href={CommonEnum.fbUrl} target="_black">
										<FaFacebookF color={`${_isDarkTheme ? palette.silver : palette.gray}`} />
									</a>
								</ArrowAvatar>

								<ArrowAvatar>
									<a href={CommonEnum.websiteUrl} target="_black">
										<TbWorld color={`${_isDarkTheme ? palette.silver : palette.gray}`} />
									</a>
								</ArrowAvatar>
							</Icon>
						</SubSection>
					</CustomColMain>
				</Row>
			</Wrapper>
			<Flexed direction="row" gap="1" flexWrap="wrap" justify={'center'}>
				<FooterList>
					<NewHeading type="small" href="/contact-us" onClick={() => router.navigate('/contact-us')} isDarkTheme={_isDarkTheme}>
						Contact us
					</NewHeading>
				</FooterList>
			</Flexed>
			<Spacer height={'1'} />
			<Wrapper>
				<Row>
					<Col>
						<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
							© 2024 DeliveryApp. All rights reserved.
						</Text>
					</Col>
				</Row>
			</Wrapper>
		</Section>
	)
}

const Section = styled.div<IProps>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	border-top: 0.063rem solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	padding: 1.5rem 0 1.5rem 0;
`

const Wrapper = styled(Container)``

const NewHeading = styled(Text)`
	text-transform: capitalize;
	cursor: pointer;
	&:hover {
		color: ${palette.blue};
	}
`

const ArrowAvatar = styled.div`
	vertical-align: middle;
	width: 2.188rem;
	height: 2.188rem;
	border-radius: 50%;
	border: 0.063rem solid ${palette.silver};
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0.5rem;
	cursor: pointer;
	&:not(:last-child) {
		margin-right: 0.625rem;
	}
	& a {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&:hover {
		border: 0.063rem solid ${palette.red};
		background: ${palette.red};
	}
	&:hover a > svg {
		color: white !important;
	}
`
const SubSection = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`

const CustomColMain = styled(Col)`
	margin-bottom: 0.5rem;
`

const Image = styled.img`
	height: 6rem;
`
const Content = styled.div``

const Icon = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const FooterList = styled.li`
	list-style: none;
	margin-top: 1rem;
`

export default Footer
