import React, {useEffect, useState, useRef} from 'react'
import {Route, Routes} from 'react-router-dom'
import styled from 'styled-components'
import Header from './components/common/Header'
import Home from './pages/Home'
import BusinessLogin from './pages/businessAuth/Login'
import BusinessForgotPassword from './pages/businessAuth/ForgotPassword'
import BusinessSignUp from './pages/businessAuth/SignUp'
import CustomerLogin from './pages/customerAuth/Login'
import CustomerForgotPassword from './pages/customerAuth/ForgotPassword'
import CustomerSignUp from './pages/customerAuth/SignUp'
import CustomerProfile from './pages/customerAuth/CustomerProfile'
import RestaurantDetails from './pages/business/RestaurantDetails'
import PlaceOrder from './pages/checkoutProcess/PlaceOrder'
import History from './pages/checkoutProcess/History'
import OrderDetails from './components/business/OrderDetails'
import {useLocation} from 'react-router-dom'
import Footer from './components/common/Footer'
import {useSelector} from 'react-redux'
import {palette} from './styled/colors'
import Business from './pages/business/Business'
import {Loading} from './styled/shared'
import CustomerSettings from './pages/customerAuth/CustomerSettings'
import BusinessSettings from './pages/business/Settings'
import ContactUs from './components/common/Contactus'
import AdBanner from './components/common/adBanner'
import FrontPage from './pages/FrontPage'

interface IProps {
	active: boolean
	isDarkTheme: boolean
}

const App = () => {
	const {pathname} = useLocation()
	const _useRef = useRef<any>()
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [searchLocation, setSearchLocation] = useState('')
	const [searchKeyword, setSearchKeyword] = useState('')
	const [sideFilters, setSideFilters] = useState({sort: [], category: []})
	const [isLoading, setIsLoading] = useState<any>(false)
	const [sideBarRefresh, setSideBarRefresh] = useState<any>(true)
	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(15)
	const [pageNoSearch, setPageNoSearch] = useState(1)
	const [copyAdsSearchResults, setCopyAdsSearchResults] = useState([])

	useEffect(() => {
		if (_isDarkTheme) {
			document.body.style.backgroundColor = '#141414'
		} else {
			document.body.style.background = 'linear-gradient(to bottom, #e8edf1, #ffffff)'
		}
	}, [_isDarkTheme])

	const appSearch = (keyword: any, location: any) => {
		if (_useRef?.current) {
			_useRef.current.mainAppSearch(keyword, location, sideFilters, 1)
		}
	}

	const onChangeBusinessType = () => {
		setSearchKeyword('')
		setSearchLocation('')
		setSideBarRefresh(false)
		setTimeout(() => {
			setSideBarRefresh(true)
		}, 2)
	}

	const clearFilter = () => {
		setSearchKeyword('')
		setSearchLocation('')
		setSideBarRefresh(false)
		setTimeout(() => {
			setSideBarRefresh(true)
		}, 2)
		if (_useRef?.current) {
			_useRef.current.mainAppSearch('', '', {sort: [], category: []}, 1)
		}
	}

	return (
		<div>
			<Header sideBarRefresh={sideBarRefresh} clearFilter={clearFilter} searchKeyword={searchKeyword} searchLocation={searchLocation} setSearchLocation={setSearchLocation} setSearchKeyword={setSearchKeyword} onChangeBusinessType={onChangeBusinessType} appSearch={(keyword: any, location: any) => appSearch(keyword, location)} />
			<Main active={pathname !== '/'} isDarkTheme={_isDarkTheme}>
				<Routes>
					<Route path="/" element={<FrontPage />}></Route>
					<Route path="/demo" element={<Home />}></Route>
					<Route path="/business-login" element={<BusinessLogin />}></Route>
					<Route path="/business-signup" element={<BusinessSignUp />}></Route>
					<Route path="/business-forgotpassword" element={<BusinessForgotPassword />}></Route>
					<Route path="/customer-login" element={<CustomerLogin />}></Route>
					<Route path="/customer-signup" element={<CustomerSignUp />}></Route>
					<Route path="/customer-forgotpassword" element={<CustomerForgotPassword />}></Route>
					<Route path="/customer-profile" element={<CustomerProfile />}></Route>
					<Route path="/business" element={<Business sideBarRefresh={sideBarRefresh} ref={_useRef} searchKeyword={searchKeyword} searchLocation={searchLocation} sideFilters={sideFilters} setSideFilters={setSideFilters} page={page} setPage={setPage} pageSize={pageSize} pageNoSearch={pageNoSearch} setPageNoSearch={setPageNoSearch} copyAdsSearchResults={copyAdsSearchResults} setCopyAdsSearchResults={setCopyAdsSearchResults} clearFilter={clearFilter} />}></Route>
					<Route path="/business-details" element={<RestaurantDetails />}></Route>
					<Route path="/place-order" element={<PlaceOrder />}></Route>
					<Route path="/history" element={<History />}></Route>
					<Route path="/customer-settings" element={<CustomerSettings />}></Route>
					<Route path="/business-settings" element={<BusinessSettings />}></Route>
					<Route path="/order-details" element={<OrderDetails />}></Route>
					<Route path="/contact-us" element={<ContactUs />}></Route>
				</Routes>
			</Main>
			<AdBanner />
			{pathname === '/' && <Footer />}
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</div>
	)
}

const Main = styled.div<IProps>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_black : 'transparent')};
`

export default App
