import axios from 'axios'
import { toast } from 'react-toastify'
export const api = axios.create({
	baseURL: process.env.REACT_APP_API,
	headers: {
		'Content-Type': 'application/json'
	}
})

api.interceptors.request.use(
	(request: any) => {
		let token = localStorage.getItem('authorization')
		if (token) {
			request.headers.common.Authorization = `Bearer ${token}`
		}
		return request
	},
	(error) => {
		return Promise.reject(error)
	}
)

api.interceptors.response.use(
	(response) => {
		return response
	},
	function (error) {
		return Promise.reject(error)
	}
)

export const doGetOwnerProfile = async () => {
	let response: any = []
	await api.post('/owner/view_owner')
		.then((res) => {
			if (res?.data.status) {
				response = res?.data?.data
			}
		})
		.catch((e) => {
			console.error(e)
			toast.error(e?.response?.data?.message)
		})
	return response
}

export const doGetCustomerProfile = async () => {
	let response: any = []
	await api.post('/customers/view_user')
		.then((res) => {
			if (res?.data) {
				response = res?.data
			}
		})
		.catch((e) => {
			console.error(e)
			toast.error(e?.response?.data?.message)
		})
	return response
}
