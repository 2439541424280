import React, { useEffect, useState } from 'react'
import { palette } from '../../styled/colors'
import { BsXLg } from 'react-icons/bs'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Heading, Divider, Flexed, Text, Spacer } from '../../styled/shared'
import { FaAngleDown } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { api } from '../api/callAxios'
import { useSearchParams } from 'react-router-dom'

interface FilterMenuProps {
	heading: string
	data: any
	selected: any
	setSelected: any
	onChangeCB: any
	clearFilter:any
}

interface IProps {
	dropOpen?: boolean
	isDarkTheme?: boolean
}

interface SidebarProps {
	onClose?: () => void
	setSideFilters: any
	clearFilter?:any
	filterBusinessIds:any
	sideBarRefresh:any
}

const FilterMenu = ({ clearFilter, heading, data, selected, setSelected, onChangeCB }: FilterMenuProps) => {
	const [dropDownOpen, setDropDownOpen] = useState(true)
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [sort, setSort] = useState('')

	const handleOnSelect = (item: any, filterType: string) => {
		if (filterType == 'sort') {
			setSelected(item?.title)
			onChangeCB(item?.title, filterType)
			// } else if (selected.findIndex((x: number) => x !== item?.id) == -1) {
		} else if (selected?.find((x: number) => x == item?.title) == undefined) {
			let _selectedItems = selected
			_selectedItems?.push(item?.title)
			setSelected(_selectedItems)
			onChangeCB(_selectedItems, filterType)
		} else {
			let _selectedItems = selected?.filter((x: number) => x != item?.title)
			setSelected(_selectedItems)
			onChangeCB(_selectedItems, filterType)
		}
	}

	return (
		<div>
			<Menu
				onClick={() => {
					setDropDownOpen(!dropDownOpen)
				}}>
				<Drop direction="row" align="center" justify="space-between">
					<Heading fontWeight={700} isDarkTheme={_isDarkTheme}>
						{heading}
					</Heading>
					<Arrow dropOpen={dropDownOpen} isDarkTheme={_isDarkTheme} />
				</Drop>
			</Menu>
			<Spacer height={0.5} />
			 <DropWrapper gap={0.5} dropOpen={dropDownOpen}>
				{heading == 'Sort' ? (<>
					<Text
							type="normal"
							pointer
							fontWeight={600}
							color='red'
							onClick={() => {
									clearFilter()
							}}>
							All Business
						</Text>
					<DropList direction="row" align="center" gap={0.5}>
						<input id='topRated' name='sort' type="radio" checked={'topRated' == sort} onClick={() => { setSort('topRated'); setSelected('topRated'); onChangeCB('topRated', 'sort') }} />
						<Label htmlFor='topRated' isDarkTheme={_isDarkTheme}>
							Top Rated
							 <span>({data?.topRated})</span>
						</Label>
					</DropList>
					<DropList direction="row" align="center" gap={0.5}>
						<input id='freeDelivery' name='sort' type="radio" checked={'freeDelivery' == sort} onClick={() => { setSort('freeDelivery'); setSelected('freeDelivery'); onChangeCB('freeDelivery', 'sort') }} />
						<Label htmlFor='freeDelivery' isDarkTheme={_isDarkTheme}>
							Free Delivery 
							<span>({data?.freeDelivery})</span>
						</Label>
					</DropList>
				</>
				// ) : heading == 'Rating' ? (
				// 	data?.length > 0 ? (
				// 		data?.map((i: any, key: number) => {
				// 			return (
				// 				<DropList direction="row" align="center" gap={0.5} key={'hygiene' + key}>
				// 					<input id={'hygiene' + key} name={i?.id} type="checkbox" checked={selected?.find((x: any) => x == i?.id)} onClick={() => handleOnSelect(i, 'hygiene')} />
				// 					<Label htmlFor={'hygiene' + key} isDarkTheme={_isDarkTheme}>
				// 						{i?.title} <span>({i?.business})</span>
				// 					</Label>
				// 				</DropList>
				// 			)
				// 		})
				// 	) : (
				// 		<Label isDarkTheme={_isDarkTheme}>No hygiene rating filters available</Label>
				// 	)
					) : heading == 'Categories' && data?.length > 0 ? (
						data?.map((i: any, key: number) => {
							return (
								<DropList direction="row" align="center" gap={0.5} key={'categorie' + key}>
									<input id={'categorie' + key} name={i?.id} type="checkbox" checked={selected?.find((x: any) => x == i?.id)} onClick={() => handleOnSelect(i, 'categories')} />
									<Label htmlFor={'categorie' + key} isDarkTheme={_isDarkTheme}>
										{i?.title}
										 <span>({i?.businessCount})</span>
									</Label>
								</DropList>
							)
						})
					) : (
						<Label isDarkTheme={_isDarkTheme}>No category filters available</Label>
					)}
				{/* ) : heading == 'Categories' && data?.length > 0 ? (
					data?.slice(0, categoryPreviewLimit)?.map((i: any, key: number) => {
						return (
							<DropList direction="row" align="center" gap={0.5} key={'categories' + key}>
								<input
									id={'categories' + key}
									name={i?.title}
									type="checkbox"
									checked={i?.title == selected}
									onClick={() => {
										handleOnSelect(i, 'categories')
									}}
								/>
								<Label htmlFor={'categories' + key} isDarkTheme={_isDarkTheme}>
									{i?.title} <span>({i?.business})</span>
								</Label>
							</DropList>
						)
					})
				) : (
					<Label isDarkTheme={_isDarkTheme}>No category filters available</Label>
				)} */}
			</DropWrapper>
		</div>
	)
}

const Sidebar = ({sideBarRefresh,clearFilter,filterBusinessIds, onClose, setSideFilters }: SidebarProps) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [searchParams] = useSearchParams()
	let _id: any = searchParams.get('id')
	const [filtersList, setFiltersList] = useState<any>([])
	const [selectedSortFilter, setSelectedSortFilter] = useState<number>(0)
	const [selectedCategoryFilter, setSelectedCategoryFilter] = useState<any>([])
	
	

	useEffect(() => {
		fetchAllFilters(_id)
	}, [filterBusinessIds,_id])

	const fetchAllFilters = async (_id:any) => {
		await api
			.post(`/business-type-category/category_count`,{
				businessId:filterBusinessIds,
				businessTypeId:_id
			})
			.then(async (response) => {
				setFiltersList(response?.data)
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	const handleFiltersChange = async (term: any, filterType: string) => {
		let apiParams = { sort: selectedSortFilter, category: selectedCategoryFilter }
		filterType === 'sort'
			? (apiParams = { ...apiParams, sort: term })
			: filterType == 'categories' && (apiParams = { ...apiParams, category: term })
			setSideFilters(apiParams)
	}
	return (
		<SideNavBar>
			<SideBarContent isDarkTheme={_isDarkTheme}>
				{sideBarRefresh &&<span>
					<FilterClose direction="row" margin="0rem 0rem 2rem 0rem" justify="flex-end">
						<CloseIcon onClick={onClose} isDarkTheme={_isDarkTheme} />
					</FilterClose>
					<FilterMenu clearFilter={()=>{setSideFilters({sort: [], category: []}); clearFilter()}} heading="Sort" data={filtersList?.sort} selected={selectedSortFilter} setSelected={setSelectedSortFilter} onChangeCB={handleFiltersChange} />

					<Divider margin="1.5rem 0rem" isDarkTheme={_isDarkTheme} />

					{/* <FilterMenu heading="Rating" data={rating} selected={selectedHygieneFilter} setSelected={setSelectedHygieneFilter} onChangeCB={handleFiltersChange} />

					<Divider margin="1.5rem 0rem" isDarkTheme={_isDarkTheme} /> */}

					<FilterMenu clearFilter={clearFilter} heading="Categories" data={filtersList?.categories} selected={selectedCategoryFilter} setSelected={setSelectedCategoryFilter} onChangeCB={handleFiltersChange} />

					<Spacer height="1" />
				</span>}
			</SideBarContent>
		</SideNavBar>
	)
}

const SideNavBar = styled.div<any>`
	width: 17rem;
	position: absolute;
	transition: width 70ms ease-in-out 0s;
	z-index: 3;
	${media.lg`position: relative;`}
	@media screen and (min-width: 575px) and (max-width: 992px) {
		z-index: 4;
		top: 8rem;
	}
	@media screen and (min-width: 0) and (max-width: 574px) {
		z-index: 4;
		top: 12rem;
	}
`

const FilterClose = styled(Flexed)`
	display: flex;
	${media.lg`display: none;`}
`

const CloseIcon = styled(BsXLg) <IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

const SideBarContent = styled.div<any>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 17rem;
	padding: 0.5rem 0.938rem 0.5rem 0.938rem;
	position: fixed;
	z-index: 4;
	overflow-x: hidden;
	overflow-y: auto;
	height: calc(100vh - 128px);
	transition: width 70ms ease-in-out 0s;
	background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.light_black}` : `linear-gradient(rgb(236 240 243), rgb(255, 255, 255))`)};
	${media.lg`background: transparent;
	padding: 0.5rem 0.938rem 0.5rem 0;
	`};
	
	/* width */
	::-webkit-scrollbar {
		width: 3px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	}
`

const Menu = styled.div<any>``

const Drop = styled(Flexed)`
	cursor: pointer;
`

const DropWrapper = styled(Flexed) <IProps>`
	display: ${({ dropOpen }) => (dropOpen ? `flex` : `none`)};
	padding: 0rem 1rem 0rem 1rem;
`

const DropList = styled(Flexed) <IProps>`
	cursor: pointer;
	margin: ${({ margin }) => `${margin}`};
`

const Arrow = styled(FaAngleDown) <IProps>`
	transition: transform 0.2s;
	transform: ${({ dropOpen }) => (dropOpen ? `rotate(-180deg)` : `rotate(0deg)`)};
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.silver : palette.gray)};
`

const Label = styled.label<any>`
	margin: 0;
	cursor: pointer;
	font-size: 14px;
	color: ${({ isDarkTheme, active }) => (active ? (isDarkTheme ? palette.white : palette.red) : isDarkTheme ? palette.light_gray : palette.gray)};
	font-weight: ${({ active }) => (active ? '500' : '')};
	& span {
		color: ${({ isDarkTheme }) => (isDarkTheme ? palette.light_gray : palette.gray)};
		font-size: 14px;
	}
	&:hover {
		color: ${({ isDarkTheme }) => (isDarkTheme ? palette.white : palette.red)};
	}
	&:hover span {
		color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : palette.red)};
	}
`

export default Sidebar
