import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Col, Container, Row, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from '../../styled/colors'

const appFeatures = [
	{
		title: 'Login and Customer Registration',
		shortDescription: 'Securely log in and register for the application.',
		description: `The login and registration functionality ensures secure access to the application, allowing users to create accounts and log in securely. This feature provides a foundational step for personalized user experiences.`,
		image: 'login-registration-image.png'
	},
	{
		title: 'Forgot Password',
		shortDescription: 'Recover your account by resetting your password.',
		description: `The Forgot Password feature allows users to recover their accounts by initiating a password reset. Users receive instructions on how to reset their passwords securely, enhancing account accessibility.`,
		image: 'forgot-password-image.png'
	},
	{
		title: 'Business Registration',
		shortDescription: 'Modify appointment details from the customer perspective.',
		description: `The Edit Appointment Drawer for Customers provides a user-friendly interface for customers to modify appointment details. This feature enhances customer control over their scheduled appointments.`,
		image: 'edit-appointment-drawer-customer-image.png'
	},
	{
		title: 'Filters',
		shortDescription: 'Initiate the scheduling of a new appointment as a customer.',
		description: `The New Appointment Drawer for Customers enables users to initiate the scheduling of a new appointment seamlessly. This feature streamlines the appointment booking process from the customer's perspective.`,
		image: 'new-appointment-drawer-customer-image.png'
	},
	{
		title: 'Business Categories',
		shortDescription: 'Access and manage administrative functionalities.',
		description: `The Admin Dashboard provides administrators with a centralized hub to access and manage various administrative functionalities. From user management to system configuration, this feature ensures efficient administration of the application.`,
		image: 'admin-dashboard-image.png'
	},
	{
		title: 'Business Menus',
		shortDescription: 'Tailor the calendar view based on individual preferences.',
		description: `Tailor the calendar view according to your preferences with the customizable calendar feature. This allows users to adapt the calendar layout and settings to meet specific business needs, enhancing user flexibility.`,
		image: 'customizable-calendar-image.png'
	},
	{
		title: 'Add-Ons',
		shortDescription: 'Stay informed with real-time notifications.',
		description: `Receive real-time notifications to stay informed about important updates and events. The notifications feature ensures that users are promptly alerted to changes, appointments, or any other relevant information.`,
		image: 'notifications-image.png'
	},
	{
		title: 'Discounts',
		shortDescription: 'Enable customers to book services online.',
		description: `Facilitate convenient online booking for customers with the online booking feature. This empowers customers to schedule appointments, enhancing accessibility and streamlining the booking process for both clients and businesses.`,
		image: 'online-booking-image.png'
	},
	{
		title: 'Cart & Checkout',
		shortDescription: 'Enable customers to book services online.',
		description: `Facilitate convenient online booking for customers with the online booking feature. This empowers customers to schedule appointments, enhancing accessibility and streamlining the booking process for both clients and businesses.`,
		image: 'online-booking-image.png'
	},
	{
		title: 'Order Tracking',
		shortDescription: 'Tailor the calendar view based on individual preferences.',
		description: `Tailor the calendar view according to your preferences with the customizable calendar feature. This allows users to adapt the calendar layout and settings to meet specific business needs, enhancing user flexibility.`,
		image: 'customizable-calendar-image.png'
	},
	{
		title: 'Notifications',
		shortDescription: 'Stay informed with real-time notifications.',
		description: `Receive real-time notifications to stay informed about important updates and events. The notifications feature ensures that users are promptly alerted to changes, appointments, or any other relevant information.`,
		image: 'notifications-image.png'
	},
	{
		title: 'Image based Search',
		shortDescription: 'Enable customers to book services online.',
		description: `Facilitate convenient online booking for customers with the online booking feature. This empowers customers to schedule appointments, enhancing accessibility and streamlining the booking process for both clients and businesses.`,
		image: 'online-booking-image.png'
	},
	{
		title: 'Voice based Search',
		shortDescription: 'Enable customers to book services online.',
		description: `Facilitate convenient online booking for customers with the online booking feature. This empowers customers to schedule appointments, enhancing accessibility and streamlining the booking process for both clients and businesses.`,
		image: 'online-booking-image.png'
	},
	{
		title: 'Search by Title',
		shortDescription: 'Enable customers to book services online.',
		description: `Facilitate convenient online booking for customers with the online booking feature. This empowers customers to schedule appointments, enhancing accessibility and streamlining the booking process for both clients and businesses.`,
		image: 'online-booking-image.png'
	},
	{
		title: 'Search by Title',
		shortDescription: 'Enable customers to book services online.',
		description: `Facilitate convenient online booking for customers with the online booking feature. This empowers customers to schedule appointments, enhancing accessibility and streamlining the booking process for both clients and businesses.`,
		image: 'online-booking-image.png'
	},
	{
		title: 'Business to Busienss Order',
		shortDescription: 'Enable customers to book services online.',
		description: `Facilitate convenient online booking for customers with the online booking feature. This empowers customers to schedule appointments, enhancing accessibility and streamlining the booking process for both clients and businesses.`,
		image: 'online-booking-image.png'
	}
]

const SecondStep = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const [openSections, setOpenSections] = useState([0])

	const toggleSection = (index: any) => {
		const isOpen = openSections.includes(index)
		if (isOpen) {
			setOpenSections(openSections.filter((i) => i !== index))
		} else {
			setOpenSections([...openSections, index])
		}
	}

	return (
		<Container>
			<Row>
				<Col>
					<AccordionContainer>
						<AccordionSection>
							<Heading1 isDarkTheme={_isDarkTheme}>Implemented Features</Heading1>
						</AccordionSection>
						<Flexed>
							<Content>
								{appFeatures?.slice(0, 4)?.map((re, index) => (
									<AccordionContent key={index}>
										<AccordionTrigger className="">
											<GreenBackground onClick={() => toggleSection(index)}>{openSections.includes(index) ? '+' : '-'}</GreenBackground>
											<AccordianHeading>{re.title}</AccordianHeading>
											<AccordianSubHeading> {re.shortDescription}</AccordianSubHeading>
											<AccordianContent isOpen={openSections.includes(index)}>
												<Description>{re.description}</Description>
												{/* <StyledLink href="https://ebookingsystem.com/features/salon-appointment-book/" target="">
													Appointment Calendar
													<StyledSvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
														<line x1="5" y1="12" x2="19" y2="12" />
														<polyline points="12 5 19 12 12 19" />
													</StyledSvg>
												</StyledLink> */}
											</AccordianContent>
										</AccordionTrigger>
									</AccordionContent>
								))}
							</Content>
							<Image alt="" src="/images/cover_img_1.png" data-loaded="true" />
						</Flexed>
						<Spacer height={1.5} />
						<FlexedSecond>
							<Image alt="" src="/images/cover_img_2.png" data-loaded="true" />
							<Content>
								{appFeatures?.slice(4, 8)?.map((re, index) => (
									<AccordionContent key={index}>
										<AccordionTrigger className="">
											<GreenBackground onClick={() => toggleSection(index)}>{openSections.includes(index) ? '+' : '-'}</GreenBackground>
											<AccordianHeading>{re.title}</AccordianHeading>
											<AccordianSubHeading> {re.shortDescription}</AccordianSubHeading>
											<AccordianContent isOpen={openSections.includes(index)}>
												<Description>{re.description}</Description>
												{/* <StyledLink href="https://ebookingsystem.com/features/salon-appointment-book/" target="">
													Appointment Calendar
													<StyledSvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
														<line x1="5" y1="12" x2="19" y2="12" />
														<polyline points="12 5 19 12 12 19" />
													</StyledSvg>
												</StyledLink> */}
											</AccordianContent>
										</AccordionTrigger>
									</AccordionContent>
								))}
							</Content>
						</FlexedSecond>
						<Spacer height={1.5} />
						<Flexed>
							<div>
								{appFeatures?.slice(8, 12)?.map((re, index) => (
									<AccordionContent key={index}>
										<AccordionTrigger className="">
											<GreenBackground onClick={() => toggleSection(index)}>{openSections.includes(index) ? '+' : '-'}</GreenBackground>
											<AccordianHeading>{re.title}</AccordianHeading>
											<AccordianSubHeading> {re.shortDescription}</AccordianSubHeading>
											<AccordianContent isOpen={openSections.includes(index)}>
												<Description>{re.description}</Description>
												{/* <StyledLink href="https://ebookingsystem.com/features/salon-appointment-book/" target="">
													Appointment Calendar
													<StyledSvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
														<line x1="5" y1="12" x2="19" y2="12" />
														<polyline points="12 5 19 12 12 19" />
													</StyledSvg>
												</StyledLink> */}
											</AccordianContent>
										</AccordionTrigger>
									</AccordionContent>
								))}
							</div>
							<Image alt="" src="/images/cover_img_3.png" data-loaded="true" />
						</Flexed>
					</AccordionContainer>
				</Col>
			</Row>
		</Container>
	)
}

const AccordionContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: space-around;
	position: relative;
`

const Heading1 = styled.h1<any>`
	font-size: 1.5rem;
	font-weight: bold;
	text-align: center;
	margin-top: 5rem;
	${media.md`font-size: 2.9rem;`}
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.white : '')};
`

const AccordionSection = styled.div`
	width: 100%;
	text-align: center;
	position: relative;
	margin-bottom: 2rem;
`

const Content = styled.div`
	width: 100%;
	${media.lg`
        width: auto
    `};
`

const Flexed = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column-reverse;
	gap: 2rem;
	${media.lg`
    flex-direction: row;
    gap:0rem;`}
`

const FlexedSecond = styled(Flexed)`
	flex-direction: column;
	${media.lg`
    flex-direction: row;`}
`

const Spacer = styled.h2<any>`
	height: ${({height}) => height}rem;
`

const AccordionContent = styled.div`
	margin-bottom: 6px;
	// background-color: white;
	width: 100%;
	/* border-top: 2px solid; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`

const AccordionTrigger = styled.div`
	cursor: pointer;
	padding-bottom: 2px;
	margin-bottom: 1rem;
	padding-left: 5px;
	padding-right: 12px;
	position: relative;
	line-height: 1.5;
	background: #ffffff;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	border-radius: 4.50296px;
	padding: 1rem;
	width: 100%;
	${media.lg`width: 27rem;`};
`

const GreenBackground = styled.span`
	position: absolute;
	background-color: ${palette.red};
	color: white;
	width: 2rem;
	height: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 0;
	top: 0;
	font-size: 1.3rem;
	font-weight: 500;
`

const StyledLink = styled.a`
	display: inline-block;
	font-size: 1rem;
	font-weight: 500;
`

const StyledSvg = styled.svg`
	fill: none;
	stroke: currentColor;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	width: 1.1rem;
`

const AccordianContent = styled.div<any>`
	font-size: small;
	display: flex;
	flex-direction: column;
	display: ${({isOpen}) => (isOpen ? 'flex' : 'none')};
`

const AccordianHeading = styled.div`
	font-size: 1.3rem;
	letter-spacing: 0;
	font-weight: 500;
	margin-bottom: 0.2rem;
`

const AccordianSubHeading = styled.div`
	font-size: 1.05rem;
	letter-spacing: 0;
	color: #272727;
`

const Description = styled.div`
	font-size: 0.938rem;
	margin: 1rem 0rem;
`

const Image = styled.img`
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
	width: 100%;
	object-fit: cover;
	aspect-ratio: 1.8;
	${media.lg`
    width:50%;
    `};
`

export default SecondStep
