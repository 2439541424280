import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { Flexed, Heading, Text, Spacer, Loading } from '../../styled/shared'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { useSelector } from 'react-redux'
import { FaAngleDown } from 'react-icons/fa'
import Button from '../common/Button'
import {
    GoogleMap,
    useJsApiLoader,
    MarkerF,
    DirectionsService,
    DirectionsRenderer,
    Circle
} from '@react-google-maps/api';
import { useSearchParams, useNavigate } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import { api } from '../api/callAxios'
import { verifyJSON } from '../../constants/commonFunctions'
import { AddOnsImage } from './CartItemList'
import AddReviewsModal from '../modals/AddReviewsModal'
import Pusher from 'pusher-js';
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const OrderDetails = () => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);
    const _loginBusinessDetails: any = useSelector<any>((state: any) => state.auth.loginBusinessDetails)
    const [businessFullDetails, setBusinessFullDetails] = useState<any>('')
    const [map, setMap] = useState(null)
    const [searchParams] = useSearchParams()
    const _id: any = searchParams.get('id')
    const [orderFullDetails, setOrderFullDetails] = useState<any>()
    const [pickUpLocation, setPickUpLocation] = useState<any>('')
    const [deliveryLocation, setDeliveryLocation] = useState<any>('')
    const [arrival, setArrival] = useState<any>({ lat: 0, lng: 0 })
    const [destination, setDestination] = useState<any>({ lat: 0, lng: 0 })
    const [isLoading, setIsLoading] = useState<any>(false)
    const [openReviewModal, setOpenReviewModal] = useState(false)
    const [clat, setCLat] = useState<any>('')
    const [clng, setCLng] = useState<any>('')

    const [Timer, setTimer] = useState('');

    useEffect(() => {
        getOrderDetails(_id)
    }, [_id])

    useEffect(() => {
        if (_loginBusinessDetails?.id) {
            fetchBusinessDetails(_loginBusinessDetails?.id)
        }
    }, [_loginBusinessDetails])

    const getOrderDetails = async (orderId: string) => {
        setIsLoading(true)
        await api
            .post(`/check-out/orders_detail/${orderId}`)
            .then(async (response: any) => {
                setOrderFullDetails(response?.data)
                setIsLoading(false)
                getPickUpLocation(response?.data?.businessIdFk?.location)
                getDeliveryLocation(response?.data?.location)
            })
            .catch(function (error: any) {
                console.log(error)
                setIsLoading(false)
            })
    }

    const fetchBusinessDetails = async (businessId: string) => {
        setIsLoading(true)
        await api
            .get(`business/${businessId}`)
            .then(async (response: any) => {
                setBusinessFullDetails(response?.data)
                setIsLoading(false)
            })
            .catch(function (error: any) {
                console.log(error)
                setIsLoading(false)
            })
    }

    const getPickUpLocation = (location: any) => {
        let addressObject = verifyJSON(location)
            ? JSON.parse(location)
            : location;
        var fullAddress = addressObject?.address;
        var address = fullAddress?.split('#');
        setPickUpLocation(address)
        if (address[1]) {
            var coords = address[1]?.split(' ');
            setArrival({ lat: parseFloat(coords[1]), lng: parseFloat(coords[2]) });
            // getAddress(parseFloat(coords[1]), parseFloat(coords[2]));
            setCLat(parseFloat(coords[1]))
            setCLng(parseFloat(coords[2]))
        }
    }

    const getDeliveryLocation = (location: any) => {
        let addressObject = verifyJSON(location)
            ? JSON.parse(location)
            : location;
        var fullAddress = addressObject?.address;
        var address = fullAddress?.split('#');
        setDeliveryLocation(address)
        if (address[1]) {
            var coords = address[1]?.split(' ');
            setDestination({ lat: parseFloat(coords[1]), lng: parseFloat(coords[2]) });
            // getAddress(parseFloat(coords[1]), parseFloat(coords[2]));
        }
    }

    const containerStyle = {
        width: '100%',
        height: '330px'
    };

    const center = {
        lat: clat ? clat : 38.892708,
        lng: clng ? clng : -94.6426741,
        zoom: 12
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAiSEHvjCeth6Gx8YDltGHUhzBl5ongDRk"
    })

    const UpdateStatus = async (orderId: string, status: string, processStartTime: boolean) => {
        setIsLoading(true)
        await api
            .put(`/check-out/update_status/${orderId}`, {
                status: status,
                processStartTime: processStartTime
            })
            .then(async (response: any) => {
                setIsLoading(false)
                getOrderDetails(_id)
            })
            .catch(function (error: any) {
                console.log(error)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (orderFullDetails?.processStartTime) {
            let _minLimit = parseInt(orderFullDetails?.commonBusinessSettings?.avgDeliveryTime.split(' ')[0]) + parseInt(orderFullDetails?.commonBusinessSettings?.threshold?.split(' ')[0])
            // Set the start date and time using Moment.js (21-12-2023 13:52 PM)
            const startDate = moment(orderFullDetails.processStartTime);

            // Calculate the end date and time (45 minutes after the start time)
            const endDate = startDate.clone().add(_minLimit, 'minutes');

            const interval = setInterval(() => {
                const now = moment();
                const diff = moment.duration(endDate.diff(now));

                if (diff.asSeconds() <= 0) {
                    clearInterval(interval);
                    // Your action when the timer ends can be placed here
                    //   alert('Timer khatam ho gaya!');
                    setTimer('Time out');
                } else {
                    const hours = Math.floor(diff.asHours());
                    const minutes = diff.minutes().toString().padStart(2, '0');
                    const seconds = diff.seconds().toString().padStart(2, '0');
                    if (hours > 0) {
                        setTimer(`${hours}:${minutes}:${seconds} min`);
                    } else {
                        setTimer(`${minutes}:${seconds} min`);
                    }
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [orderFullDetails?.processStartTime]);

    useEffect(() => {
        const pusher = new Pusher('34802ec02966c3d07993', {
            cluster: 'ap2',
        });
        const channel = pusher.subscribe('my-channel');
        channel.bind('my-event', function (data: any) {
            const _id: any = searchParams.get('id')
            getOrderDetails(_id)
        });
        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    }, []);

    return (
        <>
            <MainWrapper>
                <Spacer height={2} />
                <Flexed direction='row' align='center' gap={1} justify='space-between' flexWrap='wrap' >
                    <Flexed direction='row' align='center' gap={2} flexWrap='wrap' >
                        <Flexed direction='row' align='center' gap={1} >
                            <Heading level={3} fontWeight={800} isDarkTheme={_isDarkTheme}> Order no # </Heading>
                            <Heading level={3} fontWeight={800} color='red' isDarkTheme={_isDarkTheme}> {orderFullDetails?.orderId} </Heading>
                        </Flexed>
                    </Flexed>
                    {orderFullDetails?.processStartTime && <Flexed direction='row'>
                        <Text type='normal' fontWeight={800} isDarkTheme={_isDarkTheme}>Deliver  {Timer !== 'Time out' && `in -`}&nbsp;</Text>
                        <Text type='normal' fontWeight='bold' color='red' isDarkTheme={_isDarkTheme}>{Timer}</Text>
                    </Flexed>}
                </Flexed>
                <Spacer height={0.5} />
                <Row>
                    <Col lg={6}>
                        <Text type='normal' fontWeight={800} isDarkTheme={_isDarkTheme}>Payment method </Text>
                        <Text type='normal' isDarkTheme={_isDarkTheme}>{orderFullDetails?.paymentMethod === "cashOnDelivery" ? "Cash On Delivery" : 'Debit/Credit Card'} </Text>
                        <Spacer height={0.5} />
                    </Col>
                    <Col lg={6}>
                        <Flexed gap={1} direction='row' flexWrap='wrap' align='center' justify='space-between'>
                            {orderFullDetails?.createdAt && <Text type='normal' fontWeight={600} isDarkTheme={_isDarkTheme}> {moment(orderFullDetails?.createdAt).format('DD-MM-YYYY HH:mm A')} </Text>}

                            <Flexed gap={1} direction='row' align='center'>
                                {businessFullDetails?.id === _loginBusinessDetails?.id && businessFullDetails?.id != undefined && _loginBusinessDetails?.id != undefined && orderFullDetails?.businessOrderId != businessFullDetails?.id ?
                                    <MenuList type="normal" isDarkTheme={_isDarkTheme}>
                                        Status {(orderFullDetails?.status != 'completed' && orderFullDetails?.status != 'canceled') && <Arrow />} :
                                        {(orderFullDetails?.status != 'completed' && orderFullDetails?.status != 'canceled') && <DropContent isDarkTheme={_isDarkTheme}>
                                            {(orderFullDetails?.status != 'pending' && orderFullDetails?.status != 'processing') && <DropMenu isDarkTheme={_isDarkTheme} onClick={() => { UpdateStatus(orderFullDetails?.orderId, 'PENDING', false) }}>
                                                Pending
                                            </DropMenu>}
                                            {(orderFullDetails?.status != 'processing' && orderFullDetails?.status != 'completed') && <DropMenu isDarkTheme={_isDarkTheme} onClick={() => { UpdateStatus(orderFullDetails?.orderId, 'PROCESSING', true) }}>
                                                Processing
                                            </DropMenu>}
                                            <DropMenu isDarkTheme={_isDarkTheme} onClick={() => { UpdateStatus(orderFullDetails?.orderId, 'COMPLETED', false) }}>
                                                Complete
                                            </DropMenu>
                                            <DropMenu isDarkTheme={_isDarkTheme} onClick={() => { UpdateStatus(orderFullDetails?.orderId, 'CANCELED', false) }}>
                                                Cancelled
                                            </DropMenu>
                                        </DropContent>}
                                    </MenuList>
                                    :
                                    // null
                                    (orderFullDetails?.status === 'completed' && orderFullDetails?.isReviewed === false) && <Review order pointer fontWeight={500} color='blue' onClick={() => { setOpenReviewModal(true) }}>Review</Review>
                                }
                                <Button label={orderFullDetails?.status} type={orderFullDetails?.status} small />
                            </Flexed>
                        </Flexed>
                    </Col>
                </Row>
                <Spacer height={0.5} />
                <Row>
                    <Col lg={6}>
                        <Flexed direction='row' gap={1} align='center'>
                            <div>
                                <Label type='small' isDarkTheme={_isDarkTheme} fontWeight={700}>Pickup Point: </Label>
                            </div>
                            <Flexed direction='row' gap={0.5} align='center' justify='center'>
                                {orderFullDetails?.businessIdFk?.photo && <ResturentImage src={process.env.REACT_APP_IMAGE_URL + orderFullDetails?.businessIdFk?.photo} />}
                                <div>
                                    <Name type='small' isDarkTheme={_isDarkTheme} fontWeight={700}>{orderFullDetails?.businessIdFk.name} </Name>
                                </div>
                            </Flexed>
                        </Flexed>
                        <div>
                            <CustomText type='small' pointer isDarkTheme={_isDarkTheme}
                            onClick={() => {
                                const address = encodeURIComponent(pickUpLocation[0]);
                                const mapLink = `https://www.google.com/maps?q=${address}`;
                                window.open(mapLink, '_blank');
                            }}
                            >
                                {pickUpLocation[0]}
                            </CustomText>
                            <CustomText type='small' pointer
                             onClick={() => {
                                const mapLink = `https://www.google.com/maps?q=${pickUpLocation[1]}`;
                                window.open(mapLink, '_blank');
                            }}
                             >{pickUpLocation[1]}</CustomText>
                        </div>
                        <Spacer height={0.5} />
                    </Col>
                    <Col lg={6}>
                        <Flexed direction='row' gap={1} align='center'>
                            <div>
                                <Label type='small' isDarkTheme={_isDarkTheme} fontWeight={700}>Delivered at: </Label>
                            </div>
                            <Flexed direction='row' gap={0.5} align='center' justify='center'>
                                {!orderFullDetails?.nonRegisterCustomer ? orderFullDetails?.customerIdFkey?.photo && <ResturentImage src={process.env.REACT_APP_IMAGE_URL + orderFullDetails?.customerIdFkey?.photo} /> : <DefaultImage>{orderFullDetails?.nonRegisterCustomer[0]}</DefaultImage>}
                                {orderFullDetails?.businessOrderImage && <ResturentImage src={process.env.REACT_APP_IMAGE_URL + orderFullDetails?.businessOrderImage} />}
                                <div>
                                    <Name type='small' isDarkTheme={_isDarkTheme} fontWeight={700}>{orderFullDetails?.nonRegisterCustomer ? orderFullDetails?.nonRegisterCustomer : orderFullDetails?.businessOrderName ? orderFullDetails?.businessOrderName : orderFullDetails?.customerIdFkey?.fullname} </Name>
                                </div>
                            </Flexed>
                        </Flexed>
                        <div>
                            <CustomText type='small'
                                onClick={() => {
                                    const address = encodeURIComponent(deliveryLocation[0]);
                                    const mapLink = `https://www.google.com/maps?q=${address}`;
                                    window.open(mapLink, '_blank');
                                }}
                                pointer isDarkTheme={_isDarkTheme}>
                                {deliveryLocation[0]}
                            </CustomText>
                            <CustomText type='small'
                                onClick={() => {
                                    const mapLink = `https://www.google.com/maps?q=${deliveryLocation[1]}`;
                                    window.open(mapLink, '_blank');
                                }}
                                pointer>
                                {deliveryLocation[1]}
                            </CustomText>
                        </div>
                        <Spacer height={0.5} />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <DashDivider margin='0rem 0rem 0.5rem 0rem' isDarkTheme={_isDarkTheme} />
                    </Col>
                </Row>

                <Row>
                    <Col lg={6}>
                        <Text type='normal' fontWeight={800} isDarkTheme={_isDarkTheme} isCentered>Purchased Items </Text>
                        {orderFullDetails?.soldItems.map((items: any) => {
                            return (
                                <Wrapper isDarkTheme={_isDarkTheme}>
                                    <Cover>
                                        <Image src={process.env.REACT_APP_IMAGE_URL + (verifyJSON(items?.photo) ? JSON.parse(items?.photo)?.length > 1 ? JSON.parse(items?.photo)[0] : JSON.parse(items?.photo) : items?.photo)} />
                                    </Cover>
                                    <Content>
                                        <div>
                                            <Heading level={6} fontWeight={700} isDarkTheme={_isDarkTheme}>
                                                {items?.title}
                                            </Heading>
                                            <Text type='normal' isDarkTheme={_isDarkTheme}>
                                                {'x' + items?.quantity}
                                            </Text>
                                            <Flexed gap={0.6} direction='row' flexWrap='wrap' margin='0.4rem 0rem 0rem 0rem'>
                                                {items?.soldItemsAddOns?.map((addOns: any) => {
                                                    let addOnsphoto = verifyJSON(addOns?.photo)
                                                        ? JSON.parse(addOns?.photo)
                                                        : addOns?.photo;
                                                    return (
                                                        <Flexed gap={0.1} direction='row' align='center'>
                                                            <AddOnsImage src={process.env.REACT_APP_IMAGE_URL + addOnsphoto[0]} />
                                                            <Text type='xsmall' fontWeight='500' isDarkTheme={_isDarkTheme}>
                                                                x{addOns?.quantity}
                                                            </Text>
                                                        </Flexed>)
                                                })}
                                            </Flexed>
                                        </div>
                                        <Flexed gap={0.5}>
                                            <Text type='normal' isDarkTheme={_isDarkTheme}>
                                                {items?.total}
                                            </Text>
                                        </Flexed>
                                    </Content>
                                </Wrapper>
                            )
                        })}
                        <BillingContent isDarkTheme={_isDarkTheme}>
                            <Flexed direction="row" justify="space-between">
                                <Text type="normal" isDarkTheme={_isDarkTheme}>
                                    Total
                                </Text>
                                <Text type="normal" isDarkTheme={_isDarkTheme}>
                                    ${orderFullDetails?.totalPrice}
                                </Text>
                            </Flexed>
                            <Flexed direction="row" justify="space-between">
                                <Text type="normal" isDarkTheme={_isDarkTheme}>
                                    Delivery Charges
                                </Text>
                                <Text type="normal" isDarkTheme={_isDarkTheme}>
                                    ${orderFullDetails?.deliveryCharges}
                                </Text>
                            </Flexed>
                            <Flexed direction="row" justify="space-between" margin='0rem 0rem 1rem 0rem'>
                                <Text type='medium' fontWeight={800} isDarkTheme={_isDarkTheme}>
                                    Grand Total
                                </Text>
                                <Text type='medium' fontWeight={800} isDarkTheme={_isDarkTheme}>
                                    ${orderFullDetails?.GrandTotal}
                                </Text>
                            </Flexed>
                            {orderFullDetails?.instructions && <Flexed>
                                <Text type='medium' fontWeight={800} color='red'>
                                    Instructions:
                                </Text>
                                <Text type='small' lineHeight={1.563} isDarkTheme={_isDarkTheme}>
                                    {orderFullDetails?.instructions}
                                </Text>
                            </Flexed>}
                        </BillingContent>
                    </Col>
                    <Col lg={6}>
                        <Text type='normal' fontWeight={800} isDarkTheme={_isDarkTheme} isCentered>Tracking </Text>
                        <Spacer height={1} />
                        {(isLoaded && (arrival?.lat != 0) && (destination?.lat != 0)) ? (
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                zoom={center.zoom}
                                center={arrival}
                                onLoad={(map: any) => {
                                    setMap(map)
                                }}
                                options={{
                                    mapTypeControl: false,
                                    fullscreenControl: false,
                                    streetViewControl: false,
                                }}
                            >
                                <MarkerF
                                    position={arrival}
                                    // icon={{
                                    //     // url: `/images/icons/marker.svg`,
                                    //     scaledSize: new window.google.maps.Size(40, 40),
                                    //     // @ts-ignore
                                    //     shape: { coords: [17, 17, 18], type: 'circle'},
                                    //     optimized: false,
                                    // }}
                                    label={{
                                        text: 'P',
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                />
                                <MarkerF
                                    position={destination}
                                    // icon={{
                                    //     // url: `/images/icons/marker.svg`,
                                    //     scaledSize: new window.google.maps.Size(40, 40),
                                    //     // @ts-ignore
                                    //     shape: { coords: [17, 17, 18], type: 'circle' },
                                    //     optimized: false,
                                    // }}
                                    label={{
                                        text: 'D',
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                />
                                <DirectionsService
                                    options={{
                                        origin: arrival,
                                        destination: destination,
                                        travelMode: google.maps.TravelMode.DRIVING,
                                    }}
                                    callback={(response: any) => {
                                        if (response !== null) {
                                            if (response.status === 'OK') {
                                                return <DirectionsRenderer directions={response} />;
                                            } else {
                                                console.error(
                                                    `Directions request failed with status: ${response.status}`
                                                );
                                            }
                                        }
                                    }}
                                />
                                <Circle
                                    center={{ lat: arrival.lat, lng: arrival.lng }}
                                    radius={parseFloat(orderFullDetails?.businessIdFk?.deliveryRadius) * 1609.34} // Convert miles to meters (1 mile = 1609.34 meters)
                                    options={{
                                        fillColor: '#FF0000', // Replace with your preferred color
                                        fillOpacity: 0.2, // Adjust the opacity as needed
                                        strokeColor: '#FF0000', // Replace with your preferred color
                                        strokeOpacity: 1,
                                        strokeWeight: 1,
                                    }}
                                />
                            </GoogleMap>
                        ) : <Text isDarkTheme={_isDarkTheme}>Loading</Text>}
                    </Col>
                </Row>
                <Spacer height={2} />
            </MainWrapper>
            {isLoading && <Loading ><div className="dots-loading"></div></Loading>}
            {openReviewModal && <AddReviewsModal orderFullDetails={orderFullDetails} open={openReviewModal} onCloseModal={() => { setOpenReviewModal(false) }} reCallGetOrderDetails={() => getOrderDetails(_id)} />}
        </>
    );
}

const MainWrapper = styled(Container)`
    min-height: calc(100vh - 70px);
`

const DashDivider = styled.div<any>`
    border-bottom: 1px dashed ${({ isDarkTheme }) => (isDarkTheme ? palette.light_silver : palette.silver)};
	width: 100%;
	margin: ${({ margin }) => `${margin}`};
`;

const Wrapper = styled.div<any>`
    display: flex;
    gap: 1rem;
	width: 100%;
    overflow:hidden;
    padding:1rem;
    cursor:pointer;
    border-radius: 0.39rem;
    transition: all ease 0.25s;
    position:relative;
    border-bottom: 1px dashed ${({ isDarkTheme }) => (isDarkTheme ? palette.light_silver : palette.silver)};
`;

const Cover = styled.div`
`;

const Image = styled.img`
	width: 3rem;
    height: 3rem;
	object-fit: cover;
    border-radius: 0.39rem;
`;

const ResturentImage = styled.img`
	width: 2rem;
    height: 2rem;
	object-fit: cover;
    border-radius: 5rem;
`;

const DefaultImage = styled.div`
	width: 2rem;
    height: 2rem;
    border-radius: 5rem;
    display: flex;
    align-items:center;
    justify-content:center;
    background: ${palette.red};
    color: ${palette.white};
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
`;

const Content = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-between;
	width: 100%;
`;

const BillingContent = styled.div<any>`
	background: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem 1rem;
	border-radius: 0.39rem;
`

const Label = styled(Text) <any>`
	width: 6.2rem;
`

const Name = styled(Text) <any>`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`

const DropContent = styled.div<any>`
	display: none;
	margin-top: 0.3rem;
	position: absolute;
	background-color: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
	min-width: max-content;
	right: ${({ right }) => (right ? `${right}rem` : 'auto')};
	top: ${({ top }) => (top ? `${top}rem` : 'auto')};
	box-shadow: ${palette.shadow};
	z-index: 5;
	border-radius: 0.5rem;
	overflow: hidden;
`

const DropMenu = styled.div<any>`
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-size: 0.875rem;
	padding: 0.425rem 1rem 0.425rem 0.8rem;
	text-decoration: none;
	display: block;
	&:hover {
		background-color: ${({ isDarkTheme }) => (isDarkTheme ? palette.light_black : palette.silver)};
		color: ${palette.blue};
	}
`

const Arrow = styled(FaAngleDown) <any>`
	transition: transform 0.2s;
	transform: rotate(0deg);
`

const MenuList = styled(Text) <any>`
	position: relative;
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	letter-spacing: 0.05em;
	font-weight: 600;
	cursor: pointer;
	&:after {
		content: '';
		display: block;
		margin: 0 auto;
		width: 70%;
		margin-top: 0.35rem;
	}
	& ${Arrow} {
		color: ${({ isDarkTheme }) => (isDarkTheme ? palette.silver : palette.gray)};
	}

	&:hover ${Arrow} {
		transform: rotate(-180deg);
	}
	&:hover ${DropContent} {
		display: block;
	}
`

const Review = styled(Text)`
    text-decoration: underline;
    text-underline-offset: 3px;
`;

const CustomText = styled(Text) <any>`
   &:hover{
    color: ${palette.red_hover_text};
   }
`;

export default OrderDetails
