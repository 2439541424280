import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import DropDown from './DropDown'
import InputField from './InputField'
import { Text } from '../../styled/shared'

const VideoInputDrop = ({ label, value, handleChange, videoType, setVideoType,onFocus,onBlur,error,required }: any) => {
	// eslint-disable-next-line
	const [type, setType] = useState(videoType)
	const [active, setActive] = useState('input')
	// eslint-disable-next-line
	const [videoOption, setVideoOption] = useState([
        { value: 'None', label: 'None' },
		{ value: 'Youtube', label: 'Youtube' },
		{ value: 'Vimeo', label: 'Vimeo' },
		// { value: 'Video', label: 'Video' }
	])
	const selectedFairOptionValue = async (val: any) => {
		setType(val)
		setVideoType(val)
	}

	return (
		<Wrapper>
			<Row alignItems="end">
				<CurrencyInput active={active === 'input'} onClick={() => { setActive('input') }} lg={9} md={7} sm={7} xs={7} padding={'0rem 0rem 0rem 0.938rem'}>
					<InputField type="text" label={label} value={value} handleChange={handleChange} placeholder="Add the video URL and specify its correct platform." onFocus={onFocus} onBlur={onBlur} />
				</CurrencyInput>

				<CurrencyDrop active={active === 'drop'} onClick={() => { setActive('drop') }} lg={3} md={5} sm={5} xs={5} padding={'0rem 0.938rem 0rem 0rem'}>
					<DropDown firstSelected={type} name="collection" hasChanged={(value: any) => selectedFairOptionValue(value)} options={videoOption} />
				</CurrencyDrop>
				<Col>
				{required && error && (
					<Text fontSize={0.7} type="small" color="danger">
						{error}
					</Text>
				)}
				</Col>
			</Row>
		</Wrapper>
	)
}
const Wrapper = styled(Container)`
	padding: 0;
`
const CurrencyInput = styled(Col) <any>`
	padding: ${({ padding }) => padding};
	z-index: ${({ active }) => (active ? `2` : `0`)};
	& div > input {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
`
const CurrencyDrop = styled(Col) <any>`
	padding: ${({ padding }) => padding};
	z-index: ${({ active }) => (active ? `2` : `0`)};
	& button {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		margin-left: -0.1rem;
		border-left: 0;
	}
`

export default VideoInputDrop
