import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { Divider, Flexed, Heading, Text } from '../../styled/shared'
import { useSelector } from 'react-redux'
import Button from '../common/Button'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { verifyJSON } from '../../constants/commonFunctions'
import { media } from 'styled-bootstrap-grid'
import moment from 'moment'
import { api } from '../api/callAxios'
import CancelOrderModal from '../modals/CancelOrderModal'

const OrderHistory = ({setIsLoading, history,getOrderHistory }: any) => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);
    let _navigate = useNavigate();
    const _loginUserStatus: any = localStorage.getItem('userStatus')
    let _historyLocationJson = history?.location;
    let _historyLocationObject = verifyJSON(_historyLocationJson) ? JSON.parse(_historyLocationJson) : _historyLocationJson;
    var _historyFullAddress = _historyLocationObject?.address;
    var _historyAddress = _historyFullAddress?.split('#');

    const [Timer, setTimer] = useState('');
    const [openCancelModal, setOpenCancelModal] = useState(false);

    useEffect(() => {
        if (history?.processStartTime) {
            let _minLimit = parseInt(history?.commonBusinessSettings?.avgDeliveryTime.split(' ')[0]) + parseInt(history?.commonBusinessSettings?.threshold?.split(' ')[0])
            // Set the start date and time using Moment.js (21-12-2023 13:52 PM)
            const startDate = moment(history.processStartTime);

            // Calculate the end date and time (45 minutes after the start time)
            const endDate = startDate.clone().add(_minLimit, 'minutes');

            const interval = setInterval(() => {
                const now = moment();
                const diff = moment.duration(endDate.diff(now));

                if (diff.asSeconds() <= 0) {
                    clearInterval(interval);
                    // Your action when the timer ends can be placed here
                    //   alert('Timer khatam ho gaya!');
                    setTimer('Time out');
                } else {
                    const hours = Math.floor(diff.asHours());
                    const minutes = diff.minutes().toString().padStart(2, '0');
                    const seconds = diff.seconds().toString().padStart(2, '0');
                    if (hours > 0) {
                        setTimer(`${hours}:${minutes}:${seconds} min`);
                      } else {
                        setTimer(`${minutes}:${seconds} min`);
                      }
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [history?.processStartTime]);

    const UpdateStatus = async (orderId: string) => {
        setIsLoading(true)
        await api
            .put(`/check-out/update_status/${orderId}`, {
                status: 'CANCELED',
                canceledByCustomer: _loginUserStatus === 'owner' ? false : true
            })
            .then(async (response: any) => {
                setIsLoading(false);
                setOpenCancelModal(false)
                getOrderHistory();
            })
            .catch(function (error: any) {
                console.log(error)
                setIsLoading(false)
            })
    }

    return (
        <>
            <Wrapper isDarkTheme={_isDarkTheme}>
                <Flexed direction="row" gap={0.5} align="center">
                    <OrderNoCover gap={0.2}>
                        <Text type='small' fontWeight={600} isDarkTheme={_isDarkTheme}>
                            Order no
                        </Text>
                        <Text type='small' isDarkTheme={_isDarkTheme}>
                            {history?.orderId}
                        </Text>
                    </OrderNoCover>

                    <AddressCover gap={0.2}>
                        <Text type='small' fontWeight={600} isDarkTheme={_isDarkTheme}>
                            Address
                        </Text>
                        <Address type='small' isDarkTheme={_isDarkTheme}>
                            {_historyAddress[0]}
                        </Address>
                    </AddressCover>

                    <PriceCover gap={0.2}>
                        <Text type='small' fontWeight={600} isDarkTheme={_isDarkTheme}>
                            {history?.paymentMethod === "cashOnDelivery" ? "Cash On Delivery" : 'Debit/Credit Card'}
                        </Text>
                        <Text type='small' isDarkTheme={_isDarkTheme}>
                            ${history?.GrandTotal}
                        </Text>
                    </PriceCover>

                    <DateCover gap={0.2}>
                        <Text type='small' fontWeight={600} isDarkTheme={_isDarkTheme}>
                            Date
                        </Text>
                        <Text type='small' isDarkTheme={_isDarkTheme}>
                            {moment(history?.createdAt).format('DD-MM-YYYY HH:mm A')}
                        </Text>
                    </DateCover>

                    <Status>
                        <Button label={history?.status} type={history?.status} small />
                    </Status>

                    <Action direction='row' align="center" justify='flex-end'>
                        <Button label='View' type='red' small ifClicked={() => {
                            _navigate({
                                pathname: '/order-details',
                                search: createSearchParams({
                                    id: history?.orderId
                                }).toString()
                            })
                        }} />
                    </Action>
                </Flexed>
                {(history?.processStartTime && Timer || history?.status === 'pending') &&
                    <>
                        <Divider isDarkTheme={_isDarkTheme} margin="0.5rem 0rem" />
                        <Flexed direction='row' justify='flex-end' gap={2}>
                        {history?.status === 'pending' && <Cancel type='small' pointer fontWeight='bold' color='red' isDarkTheme={_isDarkTheme} onClick={()=>{ setOpenCancelModal(true)}}>Cancel Order</Cancel>}
                        {history?.processStartTime && Timer && <Flexed direction='row' justify='flex-end' >
                            <Text type='small' fontWeight='bold' isDarkTheme={_isDarkTheme}>Deliver {Timer !== 'Time out' && `in`} :</Text>
                            <Text type='small' fontWeight='bold' color='red' isDarkTheme={_isDarkTheme} style={{minWidth: '5rem',textAlign:'end'}}>{Timer}</Text>
                        </Flexed>}
                        </Flexed>
                    </>
                    }
            </Wrapper>
            {openCancelModal && <CancelOrderModal open={openCancelModal} onCloseModal={()=>{ setOpenCancelModal(false)}} cancelOrder={()=> UpdateStatus(history?.orderId)}/>}
        </>
    );
}

const Wrapper = styled.div<any>`
    margin: 0 0 0.5rem 0;
    width: 60rem;
    background: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
    // overflow: hidden;
    box-shadow: ${palette.shadow};
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 0.39rem;
    transition: all ease 0.25s;
    position: relative;
    &:hover {
        box-shadow: ${palette.shadowHover};
    }
    ${media.lg`
    width: 100%;
    `}
`;

const OrderNoCover = styled(Flexed)`
    width: 12%; 
`;

const AddressCover = styled(Flexed)`
    width: 20%; 
`;

const PriceCover = styled(Flexed)`
    width: 22%; 
`;

const DateCover = styled(Flexed)`
    width: 20%; 
`;

const Status = styled(Flexed)`
    width: 12.5%;
    & button {
		height: 1.5rem;
		padding: 0.2rem 1rem;
		border-radius: 1rem;
		font-size:0.75rem;
	}
	${media.xs`& button {
		font-size:0.5rem;
		min-width: 100% !important;
		padding: 0.2rem 0.4rem;
	}`}
	${media.sm`& button {
		font-size:0.5rem;
		min-width: 100% !important;
		padding: 0.2rem 0.4rem;
	}`}
	${media.md`& button {
		font-size:0.75rem;
		min-width: 6rem !important;
	}`}
`;

const Action = styled(Flexed)`
	width: 12.5%;
    & button {
		height: 1.5rem;
		padding: 0.2rem 1rem;
		border-radius: 1rem;
		font-size:0.75rem;
	}
	${media.xs`& button {
		font-size:0.5rem;
		min-width: 100% !important;
		padding: 0.2rem 0.4rem;
	}`}
	${media.sm`& button {
		font-size:0.5rem;
		min-width: 100% !important;
		padding: 0.2rem 0.4rem;
	}`}
	${media.md`& button {
		font-size:0.75rem;
		min-width: 6rem !important;
	}`}
`

const Address = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
`;

const Cancel = styled(Text)`
    text-decoration: underline;
    text-underline-offset: 3px;
`;

export default OrderHistory
