import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {palette} from '../../styled/colors'
import {DisplayItemBaner, Flexed, Heading, Spacer, Tag, Text} from '../../styled/shared'
import {AiFillStar, AiOutlineTag, AiOutlineInfoCircle} from 'react-icons/ai'
import {useSelector} from 'react-redux'
import {getDistanceFromLatLonInMiles, getRatingText, verifyJSON, weekday} from '../../constants/commonFunctions'
import RestaurantInfoModal from '../modals/RestaurantInfoModal'
import {FiEdit} from 'react-icons/fi'
import moment from 'moment'
import CheckBusinessTime from '../common/CheckBusinessTime'

interface IRestaurantDetailCardProps {
	business: any
	onEdit: any
	isStickyHeader: any
}

const RestaurantDetailCard = ({isStickyHeader, business, onEdit}: IRestaurantDetailCardProps) => {
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _loginBusinessDetails: any = useSelector<any>((state: any) => state.auth.loginBusinessDetails)
	const _day = new Date().getDay()
	const _loginUserStatus: any = localStorage.getItem('userStatus')
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const [distanceInMiles, setDistanceInMiles]: any = useState('')
	const [openItemsModal, setOpenItemsModal] = useState(false)
	useEffect(() => {
		doGetDistanceFromLatLonInMiles()
	}, [business])

	const doGetDistanceFromLatLonInMiles = async () => {
		if (business?.location) {
			let locationJson = business?.location
			let locationObject = verifyJSON(locationJson) ? JSON.parse(locationJson) : locationJson
			var fullAddress = locationObject?.address
			var address = fullAddress?.split('#')
			if (address?.length && address[1]) {
				var businessCoords = address[1]?.split(' ')
			}
		}
		if (_loginUserStatus === 'owner') {
			if (_loginBusinessDetails?.location) {
				let _loginBusinessLocationJson = _loginBusinessDetails?.location
				let _loginBusinessLocationObject = verifyJSON(_loginBusinessLocationJson) ? JSON.parse(_loginBusinessLocationJson) : _loginBusinessLocationJson
				var _loginBusinessFullAddress = _loginBusinessLocationObject?.address
				var _loginBusinessAddress = _loginBusinessFullAddress?.split('#')
				if (_loginBusinessAddress[1]) {
					var _loginBusinessCoords = _loginBusinessAddress[1]?.split(' ')
					if (parseFloat(businessCoords[1]) && parseFloat(businessCoords[2]) && _loginBusinessCoords[1] && _loginBusinessCoords[2]) {
						const res = await getDistanceFromLatLonInMiles(parseFloat(businessCoords[1]), parseFloat(businessCoords[2]), _loginBusinessCoords[1], _loginBusinessCoords[2])
						setDistanceInMiles(res)
					}
				}
			}
		} else if (_loginUserStatus === 'customer') {
			if (_loginUserDetails?.address) {
				let _loginUserLocationJson = _loginUserDetails?.address
				let _loginUserLocationObject = verifyJSON(_loginUserLocationJson) ? JSON.parse(_loginUserLocationJson) : _loginUserLocationJson
				var _loginUserFullAddress = _loginUserLocationObject?.address
				var _loginUserAddress = _loginUserFullAddress?.split('#')
				if (_loginUserAddress[1]) {
					var _loginUserCoords = _loginUserAddress[1]?.split(' ')
					if (parseFloat(businessCoords[1]) && parseFloat(businessCoords[2]) && _loginUserCoords[1] && _loginUserCoords[2]) {
						const res = await getDistanceFromLatLonInMiles(parseFloat(businessCoords[1]), parseFloat(businessCoords[2]), _loginUserCoords[1], _loginUserCoords[2])
						setDistanceInMiles(res)
					}
				}
			}
		}
	}
	return (
		<>
			<Wrapper isStickyHeader={isStickyHeader} isDarkTheme={_isDarkTheme}>
				<Cover>{business?.photo && <Image isStickyHeader={isStickyHeader} src={process.env.REACT_APP_IMAGE_URL + business?.photo} />}</Cover>
				<Content isStickyHeader={isStickyHeader}>
					<div>
						<Heading level={isStickyHeader ? 4 : 2} fontWeight={700} isDarkTheme={_isDarkTheme}>
							{business?.name ? business?.name : '-'}
						</Heading>

						<Spacer height={0.3} />

						<Flexed direction="row" align="center" gap={0.5}>
							{business?.businessTypeCategory?.length > 0 ? (
								business?.businessTypeCategory?.map((item: any, key: number) => {
									return (
										<Text type={isStickyHeader ? 'small' : 'medium'} isDarkTheme={_isDarkTheme} key={key}>
											{item?.name}
										</Text>
									)
								})
							) : (
								<Text type={isStickyHeader ? 'small' : 'medium'} isDarkTheme={_isDarkTheme}>
									No categories found
								</Text>
							)}
						</Flexed>

						<Spacer height={0.3} />

						{business?.businessSettings?.rating && (
							<Flexed direction="row" align="center" gap={0.2}>
								<AiFillStar fill={`${palette.golden}`} />
								<Text type={isStickyHeader ? 'small' : 'medium'} isDarkTheme={_isDarkTheme}>
									{business?.businessSettings?.rating?.toFixed(1) + ' ' + getRatingText(business?.businessSettings?.rating)}
								</Text>
							</Flexed>
						)}

						<Spacer height={0.3} />
						{business?.businessSettings?.inHouseDelivery && (
							<>
								<Flexed direction="row" align="center" gap={1}>
									<Text type={isStickyHeader ? 'small' : 'medium'} isDarkTheme={_isDarkTheme}>
										Average delivery time{' '}
										{business?.businessSettings?.avgDeliveryTime
											? `(${business?.businessSettings?.avgDeliveryTime.split(' ')[0]} - ${
													parseInt(business?.businessSettings?.avgDeliveryTime.split(' ')[0]) + parseInt(business?.businessSettings?.threshold.split(' ')[0])
											  } min)`
											: '99 - 99 min'}
									</Text>
								</Flexed>
								<Spacer height={0.3} />
							</>
						)}

						<Flexed direction="row" align="center" gap={1} flexWrap="wrap">
							{_loginUserStatus && (
								<Text type={isStickyHeader ? 'small' : 'medium'} isDarkTheme={_isDarkTheme}>
									{distanceInMiles ? `${distanceInMiles?.toFixed(2)} miles away` : '0 miles away'}
								</Text>
							)}

							{business?.name && (
								<Text type={isStickyHeader ? 'small' : 'medium'} isDarkTheme={_isDarkTheme}>
									{'$' + business?.businessSettings?.minOrderAmount + ' minimum order'}
								</Text>
							)}

							{isStickyHeader && (
								<Text type="small" isDarkTheme={_isDarkTheme} color="popular">
									Deliver within {business?.deliveryRadius} miles
								</Text>
							)}
						</Flexed>

						<Spacer height={0.3} />
					</div>
					<div>
						{/* <Flexed direction="row" justify="space-between" align="center" gap={1}>
						{business?.workingHours[weekday[_day]?.toLowerCase() + 'On'] ?
							<Text type={isStickyHeader ? 'small' : "medium"} isDarkTheme={_isDarkTheme}>
								{'Today Opens at ' + `${business?.workingHours[weekday[_day]?.toLowerCase() + 'From'] > '12:00' ? (business?.workingHours[weekday[_day]?.toLowerCase() + 'From'] + ' ' + 'pm') : (business?.workingHours[weekday[_day]?.toLowerCase() + 'From'] + ' ' + 'am')}` + ', Closes at ' + `${business?.workingHours[weekday[_day]?.toLowerCase() + 'To'] > '12:00' ? (business?.workingHours[weekday[_day]?.toLowerCase() + 'To'] + ' ' + 'pm') : (business?.workingHours[weekday[_day]?.toLowerCase() + 'To'] + ' ' + 'am')} `}
							</Text>
							:
							<Text type={isStickyHeader ? 'small' : "medium"} fontWeight={500} color='danger'>Today the business is closed.</Text>
						}
					</Flexed> */}
						{business?.name && <CheckBusinessTime business={business} isStickyHeader={isStickyHeader} />}
						<Spacer height={0.3} />
						{business?.name && (
							<>
								{' '}
								{business?.businessSettings?.inHouseDelivery ? (
									<>
										{business?.businessSettings?.freeDelivery && business?.businessSettings?.inHouseDelivery ? (
											<>
												<Spacer height={0.7} />
												<Tag color="green" bgColor="green_light">
													Free home delivery{' '}
												</Tag>
												<Spacer height={1} />
											</>
										) : (
											<Text type={isStickyHeader ? 'small' : 'medium'} margin="0rem 0rem 1rem 0rem" color="danger">
												${business?.businessSettings?.deliveryCharges} delivery charges
											</Text>
										)}
									</>
								) : (
									<>
										<Spacer height={0.7} />
										<Tag color="red" bgColor="red_light">
											No home delivery{' '}
										</Tag>
										<Spacer height={1} />
									</>
								)}
							</>
						)}

						{business?.id === _loginBusinessDetails?.id && (
							<EditRestaurantLink direction="row" align="center" onClick={onEdit}>
								<EditRestaurant lineHeight={1.3} type={isStickyHeader ? 'small' : 'normal'} color="red" fontWeight={500} isDarkTheme={_isDarkTheme}>
									Edit Business
								</EditRestaurant>
								<FiEdit color={`${palette.red}`} />
							</EditRestaurantLink>
						)}
					</div>
					{/* <OfferLabel>
					<AiOutlineTag fill={`${palette.white}`} />
					<Text type="normal" fontWeight={700} color="white">
						{business?.businessSettings?.discount + '% - ' + business?.businessSettings?.discountPolicy}
					</Text>
					-
					<Text type="normal" color="white">
						{business?.businessSettings?.tcPolicy ? business?.businessSettings?.tcPolicy : 'For orders over $99. T&Cs apply'}
					</Text>
				</OfferLabel> */}
				</Content>
				<Content hide={isStickyHeader}>
					<Flexed pointer onClick={() => setOpenItemsModal(true)}>
						<Flexed direction="row" justify="space-between" align="center">
							<CustomText type="normal" color="red" fontWeight={500} isDarkTheme={_isDarkTheme}>
								Business Info
							</CustomText>
							<InfoIcon isDarkTheme={_isDarkTheme} />
						</Flexed>
						<Text type="small" isDarkTheme={_isDarkTheme}>
							Contact, Map and offical site
						</Text>
					</Flexed>
					{business?.businessSettings?.inHouseDelivery && (
						<Flexed gap={0.5} margin="2rem 0rem 0rem 0rem">
							<Text type="normal" isDarkTheme={_isDarkTheme}>
								{business?.businessSettings?.avgDeliveryTime
									? `Deliver in ${business?.businessSettings?.avgDeliveryTime.split(' ')[0]} - ${
											parseInt(business?.businessSettings?.avgDeliveryTime.split(' ')[0]) + parseInt(business?.businessSettings?.threshold.split(' ')[0])
									  } min`
									: 'Deliver in 99 min'}
							</Text>
							<Text type="normal" isDarkTheme={_isDarkTheme} color="popular">
								Deliver within {business?.deliveryRadius} miles
							</Text>
						</Flexed>
					)}
					<Spacer height={0.5} />
					{/* <Flexed direction="row" justify="space-between" align="center" gap={1}>
					{business?.workingHours[weekday[_day]?.toLowerCase() + 'On'] ?
						<div>
							<Text type='normal' isDarkTheme={_isDarkTheme}>
								{'Opens at ' + `${business?.workingHours[weekday[_day]?.toLowerCase() + 'From'] > '12:00' ? (business?.workingHours[weekday[_day]?.toLowerCase() + 'From'] + ' ' + 'pm') : (business?.workingHours[weekday[_day]?.toLowerCase() + 'From'] + ' ' + 'am')}`}
							</Text>
							<Text type='normal' isDarkTheme={_isDarkTheme}>
								{'Closes at ' + `${business?.workingHours[weekday[_day]?.toLowerCase() + 'To'] > '12:00' ? (business?.workingHours[weekday[_day]?.toLowerCase() + 'To'] + ' ' + 'pm') : (business?.workingHours[weekday[_day]?.toLowerCase() + 'To'] + ' ' + 'am')} `}
							</Text>
						</div>
						:
						<Text type='small' fontWeight={500} color='danger'>Today the restaurant is closed.</Text>
					}
				</Flexed> */}
				</Content>
			</Wrapper>
			{openItemsModal && <RestaurantInfoModal open={openItemsModal} business={business} onCloseModal={() => setOpenItemsModal(false)} />}
		</>
	)
}

const Wrapper = styled.div<any>`
	display: ${({isStickyHeader}) => (isStickyHeader ? 'flex' : 'grid')};
	gap: 1rem;
	margin: ${({isStickyHeader}) => (isStickyHeader ? '0' : '0 0 2rem')};
	width: 100%;
	overflow: hidden;
	@media screen and (min-width: 992px) and (max-width: 9999px) {
		grid-template-columns: 1.62fr 3fr 1fr;
	}
`

const Cover = styled.div``

const Image = styled.img<any>`
	height: ${({isStickyHeader}) => (isStickyHeader ? '7.5rem' : '16.25rem')};
	width: ${({isStickyHeader}) => (isStickyHeader ? '13rem' : '100%')};
	object-fit: cover;
	border-radius: 0.39rem;
`

const Content = styled.div<any>`
	padding: ${({isStickyHeader}) => (isStickyHeader ? '0rem 0.5rem' : '0.5rem')};
	display: ${({isStickyHeader, hide}) => (hide ? 'none' : isStickyHeader ? 'flex' : '')};
	flex-wrap: wrap;
	justify-content: space-between;
	gap: ${({isStickyHeader}) => (isStickyHeader ? '2rem' : '0rem')};
	width: ${({isStickyHeader}) => (isStickyHeader ? '100%' : 'auto')};
`

const OfferLabel = styled.div`
	padding: 0.6rem 1rem;
	gap: 0.5rem;
	display: flex;
	align-items: center;
	width: fit-content;
	background: ${palette.danger};
	color: ${palette.white};
	border-radius: 0.39rem;
	font-size: 1.5rem;
`

const InfoIcon = styled(AiOutlineInfoCircle)<any>`
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
`

export const EditRestaurantLink = styled(Flexed)<any>`
	gap: 0.6rem;
	cursor: pointer;
	width: fit-content;
	border-bottom: 1.2px solid ${palette.red};
`

export const EditRestaurant = styled(Text)<any>`
	&:hover {
		color: ${palette.red_hover_text};
	}
`

const CustomText = styled(Text)<any>`
	&:hover {
		color: ${palette.red_hover_text};
	}
`

export default RestaurantDetailCard
