import React, { useState, useRef, useEffect } from 'react';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from '../../styled/colors';
import { Text } from '../../styled/shared';
import { useSelector } from 'react-redux'

interface AutocompleteSearchProps {
  suggestionsList: any[];
  label?: string;
  placeHolder?: string;
  required?: boolean;
  nonRegisterClientSelected: any;
}

interface IProps {
  active: boolean;
}

const AutocompleteSearch: React.FC<AutocompleteSearchProps> = ({
  suggestionsList,
  label,
  placeHolder,
  required,
  nonRegisterClientSelected
}) => {
  const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);
  const [isDataCenter, setIsDataCenter] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [activeSuggestion, setActiveSuggestion] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [listRef, setListRef] = useState<HTMLUListElement | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        if (activeSuggestion === null) {
          setActiveSuggestion(0);
        } else if (activeSuggestion < suggestions.length - 1) {
          setActiveSuggestion(activeSuggestion + 1);
          if (listRef && listRef.children[activeSuggestion + 1]) {
            const nextSuggestion = listRef.children[
              activeSuggestion + 1
            ] as HTMLLIElement;
            const topOffset = nextSuggestion.offsetTop - listRef.offsetTop;
            const bottomOffset = topOffset + nextSuggestion.offsetHeight;
            if (bottomOffset > listRef.offsetHeight) {
              listRef.scrollTo(0, bottomOffset - listRef.offsetHeight);
            } else if (topOffset < 0) {
              listRef.scrollTo(0, topOffset);
            }
          }
        }
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        if (activeSuggestion !== null && activeSuggestion > 0) {
          setActiveSuggestion(activeSuggestion - 1);
          if (listRef && listRef.children[activeSuggestion - 1]) {
            const prevSuggestion = listRef.children[
              activeSuggestion - 1
            ] as HTMLLIElement;
            const topOffset = prevSuggestion.offsetTop - listRef.offsetTop;
            const bottomOffset = topOffset + prevSuggestion.offsetHeight;
            if (bottomOffset > listRef.offsetHeight) {
              listRef.scrollTo(0, bottomOffset - listRef.offsetHeight);
            } else if (topOffset < 0) {
              listRef.scrollTo(0, topOffset);
            }
          }
        } else if (activeSuggestion === 0) {
          setActiveSuggestion(null);
        }
      } else if (event.key === 'Enter') {
        if (activeSuggestion !== null) {
          setSearchTerm(suggestions[activeSuggestion] ?? '');
          setShowSuggestions(false);
          setActiveSuggestion(null);
        }
      } else if (event.key === 'Escape') {
        setShowSuggestions(false);
        setActiveSuggestion(null);
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeSuggestion, suggestions, listRef]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    if (searchTerm) {
      const filteredSuggestions = suggestionsList.filter((suggestion) =>
        suggestion?.phone.startsWith(searchTerm),
      );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
      setActiveSuggestion(null);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
      setActiveSuggestion(null);
    }
  };

  const handleSuggestionClick = (suggestion: any) => {
    setSearchTerm(suggestion?.phone);
    nonRegisterClientSelected(suggestion)
    setShowSuggestions(false);
    setActiveSuggestion(null);
  };

  return (
    <SearchContainer>
      {label && (
        <Text type="body" color="white" margin="0rem 0rem 0.25rem 0rem">
          {label} {required ? <Mandatory>*</Mandatory> : ''}
        </Text>
      )}
      <SearchInput
      isDarkTheme={_isDarkTheme}
        type="text"
        placeholder={placeHolder}
        value={searchTerm}
        onChange={(e:any)=>{handleInputChange(e)}}
        ref={inputRef}
      />
      {showSuggestions && (
        <SuggestionList ref={(ref:any) => setListRef(ref)} isDarkTheme={_isDarkTheme}>
          {suggestions.map((suggestion, index) => (
            <SuggestionItem
            isDarkTheme={_isDarkTheme}
              key={suggestion}
              active={activeSuggestion === index}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion?.phone}
            </SuggestionItem>
          ))}
          {suggestions.length === 0 && (
            <NotFoundItem isDarkTheme={_isDarkTheme}>
              No phone number found.
            </NotFoundItem>
          )}
        </SuggestionList>
      )}
    </SearchContainer>
  );
};

const Mandatory = styled.span`
  color: ${palette.danger};
`;

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SearchInput = styled.input<any>`
  font-family: 'Roboto';
  line-height: 2rem;
  font-weight: 400;
  font-size: 0.875rem;
  width: 100%;
  height: 40px;
  padding: 0.5rem 0.8rem;
  border-radius: 0.375rem;
  color: ${palette.cadet_blue};
  border: 1px solid ${({isDarkTheme }) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
  background: ${({isDarkTheme }) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
  outline: none;
  &::placeholder {
    color: ${palette.cadet_blue};
    /* opacity: 0.5; Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${palette.cadet_blue};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
  }
`;

const SuggestionList = styled.ul<any>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid ${({isDarkTheme }) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
  background: ${({isDarkTheme }) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
  border-top: none;
  border-radius: 0 0 4px 4px;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  padding: 0;
  margin: 0;
  list-style-type: none;
  box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 15%);
  z-index: 999;
`;

const SuggestionItem = styled.li<any>`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${(props) => (props.active ? palette.red : 'transparent')};
  color: ${({isDarkTheme})=>isDarkTheme ? palette.light_gray : palette.dark_gray};
  &:hover {
    background-color: ${palette.red};
    color: ${palette.white};
  }
`;

const NotFoundItem = styled.li<any>`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({isDarkTheme})=>isDarkTheme ? palette.light_gray : palette.dark_gray};
`;



export default AutocompleteSearch;
