import { useEffect, useState, ChangeEvent } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { Spacer, Flexed, Text } from '../../styled/shared';
import { palette } from '../../styled/colors'
import { BsXLg } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import InputField from '../common/InputField'
import Button from '../common/Button'
import { MdOutlineCloudUpload } from 'react-icons/md';
import { api } from '../../components/api/callAxios'
import { toast } from 'react-toastify';
import { BsCheckSquareFill } from 'react-icons/bs'
import { ImCheckboxUnchecked } from 'react-icons/im'
import InputWithDrop from '../common/InputWithDrop';
import { verifyJSON } from '../../constants/commonFunctions';
import DropDown from '../common/DropDown';
import VideoInputDrop from '../common/VideoInputDrop';
import useWindowSize from '../../hooks/useWindowSize';

interface IProps {
    isDarkTheme: boolean
}

const AddRestaurantMenuModal = ({ modalStatus, open, onCloseModal, businessDetails, setIsLoading, menuLabelId, fetchBusinessDetails, editMenuData }: any) => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
    const _windowSize = useWindowSize();
    const [itemId, setItemId] = useState('');
    const [videoId, setVideoId] = useState('');
    const [videoType, setVideoType] = useState('');
    const [itemName, setItemName] = useState('');
    const [price, setPrice] = useState('');
    const [newImage, setNewImage] = useState<string[]>([]);
    const [tagLine, setTagLine] = useState({ label: '', value: '' });
    const [discount, setDiscount] = useState<any>(0);
    const [discountType, setDiscountType] = useState('percent');
    const [description, setDescription] = useState('');
    const [tax, setTax] = useState<any>(0);
    const [displayItem, setDisplayItem] = useState(true);

    const [itemNameError, setItemNameError] = useState('')
    const [priceError, setPriceError] = useState('')
    const [discountError, setDiscountPriceError] = useState('')
    const [imageError, setImageError] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [tagOption, setTagOption] = useState([
        { value: '', label: 'None' },
        { value: 'Popular', label: 'Popular' },
        { value: 'Vegan', label: 'Vegan' },
        { value: 'Recommended', label: 'Recommended' },
        { value: 'Hot', label: 'Hot' },
    ])
    useEffect(() => {
        if (open) {
            setNewImage([])
            setItemId(editMenuData?.id ? editMenuData?.id : '')
            setVideoId(editMenuData?.videoId ? editMenuData?.videoId : '')
            setVideoType(editMenuData?.videoType ? editMenuData?.videoType : '')
            setItemName(editMenuData?.title ? editMenuData?.title : '')
            setPrice(editMenuData?.price ? editMenuData?.price : '')
            if (editMenuData?.photo?.length > 0) {
                let photos: any = verifyJSON(editMenuData?.photo) ? JSON.parse(editMenuData?.photo) : editMenuData?.photo;
                photos.map((value: any) => {
                    setNewImage((pre): any => {
                        return ([...pre, value])
                    })
                })
            }
            else {
                setNewImage([])
            }
            setTagLine({ label: editMenuData?.tagLine ? editMenuData?.tagLine : '', value: editMenuData?.tagLine ? editMenuData?.tagLine : '' })
            setDiscount(editMenuData?.discount ? editMenuData?.discount : 0)
            setDiscountType((editMenuData?.discountType ? editMenuData?.discountType : 'percent'))
            setTax(editMenuData?.tax ? editMenuData?.tax : 0)
            setDescription(editMenuData?.description ? editMenuData?.description : '')
            if (editMenuData?.displayItem != undefined) {
                setDisplayItem(editMenuData?.displayItem)
            }
            else {
                setDisplayItem(true)
            }
            setItemNameError('')
            setPriceError('')
            setImageError('')
            setDescriptionError('')
        }
    }, [open])

    const addRestaurantMenu = () => {
        let _apiPath = '';
        let _obj;
        if (modalStatus === 'menu') {
            _apiPath = '/business-menu';
            _obj = {
                menuLabelsId: menuLabelId,
            };
        }
        else {
            _apiPath = '/addOns-menu';
            _obj = {
                addOnsLabelsId: menuLabelId
            };
        }
        if (formValidation()) {
            setIsLoading(true)
            api.post(_apiPath, {
                ..._obj,
                title: itemName,
                price: price,
                photo: newImage,
                tagLine: tagLine?.value,
                discount: discount,
                tax: tax,
                description: description,
                displayItem: displayItem,
                discountType: discountType,
                videoId: videoId,
                videoType: videoType
            }).then((res) => {
                if (res.data) {
                    setIsLoading(false)
                    toast.success(modalStatus === 'menu' ? 'Menu item added successfully' : 'Add-on item added successfully')
                    setNewImage([])
                    fetchBusinessDetails()
                    onCloseModal()
                }

            }).catch((e) => {
                if (e?.response) {
                    toast.error(e?.response?.data?.message)
                    setIsLoading(false)
                }
            })
        }
        else{
            toast.error('Please fill in the required fields');
        }
    }
    const updateRestaurantMenu = () => {
        let _apiPath = modalStatus === 'menu' ? '/business-menu' : '/addOns-menu';
        if (formValidation()) {
            setIsLoading(true)
            api.put(`${_apiPath}/${itemId}`, {
                title: itemName,
                price: price,
                newPhoto: newImage,
                tagLine: tagLine?.value,
                discount: discount,
                tax: tax,
                description: description,
                displayItem: displayItem,
                discountType: discountType,
                videoId: videoId,
                videoType: videoType
            }).then((res) => {
                if (res.data) {
                    setIsLoading(false)
                    toast.success(modalStatus === 'menu' ? 'Menu item updated successfully' : 'Add-on item updated successfully')
                    setNewImage([])
                    fetchBusinessDetails()
                    onCloseModal()
                }

            }).catch((e) => {
                if (e?.response) {
                    toast.error(e?.response?.data?.message)
                    setIsLoading(false)
                }
            })
        }
        else{
            toast.error('Please fill in the required fields');
        }
    }

    const formValidation = () => {
        let isValid = true
        if (itemName === '') {
            setItemNameError('item name is required')
            isValid = false
        }
        if (price === '' || parseFloat(price) == 0) {
            setPriceError('price is required')
            isValid = false
        }
        if (description === '') {
            setDescriptionError('description is required')
            isValid = false
        }
        if (newImage?.length === 0) {
            setImageError('image is required')
            isValid = false
        }
        if (discountType === 'flat' && parseFloat(discount) > parseFloat(price) && discount?.trim() != '' && parseFloat(discount) != 0) {
            setDiscountPriceError('The flat discount should be lower than the price.')
            isValid = false
        }

        return isValid
    }


    const handleCapture = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            // Calculate the number of additional images that can be uploaded without exceeding 7
            const remainingImages = Math.max(4 - newImage.length, 0);
            const selectedFiles = Array.from(files).slice(0, remainingImages);

            const newImagePromises: Promise<string>[] = selectedFiles.map((file) => {
                return new Promise<string>((resolve) => {
                    const reader = new FileReader();

                    reader.readAsDataURL(file);

                    reader.onload = () => {
                        if (reader.readyState === 2) {
                            resolve(reader.result as string);
                        }
                    };
                });
            });

            Promise.all(newImagePromises)
                .then((results) => {
                    // Add the new images to the existing ones
                    setNewImage((prevImages) => [...prevImages, ...results]);
                    setImageError('')
                })
                .catch((error) => {
                    console.error('Error reading images:', error);
                });
        }
    };

    const deleteSelectImage = (index: any) => {
        const deleteImage = newImage.filter((value, ind) => {
            return ind !== index
        })
        setNewImage(deleteImage)
    }
    return (
        <>
            <Modal
                open={open}
                center
                onClose={() => {
                    onCloseModal()
                }}
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'restaurantMenuModal'
                }}
                showCloseIcon={false}
                // @ts-ignore
                styles={{ zIndex: '2' }}>
                <ModalWrapper isDarkTheme={_isDarkTheme}>
                    <Head direction="row" align="center" justify="flex-end">
                        <CrossIcon
                            isDarkTheme={_isDarkTheme}
                            onClick={() => {
                                onCloseModal()
                            }}
                        />
                    </Head>
                    <Body>
                        <Flexed direction="row" justify="center" flexWrap='wrap' margin="4rem 1rem 0rem 1rem" gap={0.5}>
                            {newImage?.map((value: any, index: any) => {
                                return (
                                    <ImgWrapper key={index}>
                                        <ShowImage src={value?.startsWith('data:') ? value : process.env.REACT_APP_IMAGE_URL + value} />
                                        <DeleteImage onClick={() => { deleteSelectImage(index) }} isDarkTheme={_isDarkTheme} />
                                    </ImgWrapper>
                                )
                            })}
                            {newImage?.length !== 4 && (
                                <InputWrapper>
                                    <Upload>
                                        <MdOutlineCloudUpload color={_isDarkTheme ? palette.light_gray : palette.gray} fontSize={24} />
                                        <Text type='xsmall' isDarkTheme={_isDarkTheme}>Upload Image</Text>
                                        <FileInput
                                            multiple
                                            id="faceImage"
                                            accept="image/*"
                                            type="file"
                                            onChange={(e) => {
                                                handleCapture(e)
                                            }}
                                        />
                                    </Upload>
                                </InputWrapper>
                            )}
                        </Flexed>
                        <Text fontSize={0.7} isCentered type="small" color="danger" margin='0rem 0rem 1rem 0rem'>
                            {imageError}
                        </Text>
                        <Wrapper>
                            <Row>
                                <Col lg={12}>
                                    <VideoInputDrop label={`Video id`} value={videoId} videoType={videoType} setVideoType={setVideoType}
                                        handleChange={(e: any) => {
                                            setVideoId(e)
                                        }} />
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={6}>
                                    <InputField error={itemNameError} label={modalStatus === 'menu' ? 'Menu Item Name' : 'Add-on Item Name'} required value={itemName} handleChange={(e: any) => {
                                        setItemNameError('');
                                        if (e == '') {
                                            setItemNameError('item name is required')
                                        }
                                        if (e?.length <= 50) {
                                            setItemName(e)
                                        }
                                    }} />
                                    <Flexed direction='row' justify='flex-end'>
                                        <Text isDarkTheme={_isDarkTheme} type='xsmall'>{itemName?.length}/50</Text>
                                    </Flexed>
                                </Col>
                                <Col lg={6}>
                                    <DropDown label="TagLine" firstSelected={tagLine.label} options={tagOption} hasChanged={(value: any) => setTagLine({ label: value, value: value })} dropDownListHeight='150px' />
                                    <Spacer height={_windowSize?.width > 1200 ? 0.5 : 1} />
                                </Col>

                                <Col lg={6}>
                                    <InputField error={priceError} label="Price" value={price} required
                                        onFocus={() => {
                                            if (parseFloat(price) == 0) {
                                                setPrice('')
                                            }
                                        }}
                                        handleChange={(e: any) => {
                                            setPriceError('');
                                            if (e == '') {
                                                setPriceError('price is required')
                                            }
                                            if (discountType === 'flat' && e != "" && parseFloat(e) > parseFloat(discount)) {
                                                setDiscountPriceError('')
                                            }
                                            setPrice(e)

                                        }}
                                        allowOnlyNumbersAndDecimal={true}
                                    />
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={6}>
                                    <InputWithDrop error={discountError} required={parseFloat(discount) != 0} label={`Discount (${discountType === 'percent' ? '%' : 'flat'})`} value={discount} setValue={setDiscount} discountType={discountType} setDiscountType={setDiscountType}
                                        onFocus={() => {
                                            if (parseFloat(discount) == 0) {
                                                setDiscount('')
                                                setDiscountPriceError('')
                                            }
                                        }}
                                        onBlur={() => {
                                            if (discount?.trim() == '') {
                                                setDiscount(0)
                                                setDiscountPriceError('')
                                            }
                                        }}
                                        handleChange={(e: any) => {
                                            setDiscountPriceError('')
                                            if (discountType === 'percent' && parseFloat(e) <= 99 && e != "") {
                                                setDiscount(e)
                                            }
                                            else if (discountType === 'flat' && e != "" && parseFloat(e) < parseFloat(price)) {
                                                setDiscount(e)
                                            }
                                            else if (e == "") {
                                                setDiscount(e)
                                            }
                                        }}
                                        allowOnlyNumbersAndDecimal={true} />
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={6}>
                                    <InputField label="Tax (%)" placeholder='tax will be in percentage' value={tax}
                                        onFocus={() => {
                                            if (parseFloat(tax) == 0) {
                                                setTax('')
                                            }
                                        }}
                                        onBlur={() => {
                                            if (tax?.trim() == '') {
                                                setTax(0)
                                            }
                                        }}
                                        handleChange={(e: any) => {
                                            setTax(e)
                                        }}
                                        allowOnlyNumbersAndDecimal={true}
                                    />
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={6}>
                                    <Flexed margin="2.7rem 0rem 0rem 0rem" direction="row" align="flex-start" onClick={() => setDisplayItem(!displayItem)}>
                                        <CheckIconWrapper>{displayItem ? <Checked /> : <UnChecked isDarkTheme={_isDarkTheme} />}</CheckIconWrapper>
                                        <Text type="normal" lineHeight={1} fontWeight={500} isDarkTheme={_isDarkTheme}>{modalStatus === 'menu' ? 'Display Menu item' : 'Display Add-on item'}</Text>
                                    </Flexed>
                                </Col>
                                <Col lg={12}>
                                    <InputField error={descriptionError} type='textarea' required placeholder='Type here' label={modalStatus === 'menu' ? 'About Menu Item' : 'About Add-on Item'} value={description} handleChange={(e: any) => {
                                        setDescriptionError('');
                                        if (e == '') {
                                            setDescriptionError('description is required')
                                        }
                                        if (e?.length <= 150) {
                                            setDescription(e)
                                        }
                                    }} />
                                    <Flexed direction='row' justify='flex-end'>
                                        <Text isDarkTheme={_isDarkTheme} type='xsmall'>{description?.length}/150</Text>
                                    </Flexed>
                                    <Spacer height={1} />
                                </Col>
                            </Row>
                        </Wrapper>

                    </Body>
                    <Footer isDarkTheme={_isDarkTheme}>
                        <Button
                            type='red'
                            label={editMenuData?.id ? 'Edit' : 'Add'}
                            width="100%"
                            ifClicked={() => { editMenuData?.id ? updateRestaurantMenu() : addRestaurantMenu() }}
                        />
                    </Footer>
                </ModalWrapper>
            </Modal>
        </>
    )
}

const ModalWrapper = styled.div<IProps>`
	position: relative;
	background-color: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
`

const Head = styled(Flexed)`
	padding: 1rem;
	position: absolute;
	width: 100%;
`

const Body = styled.div`
	max-height: 32rem;
	overflow-y: scroll;
	overflow-x: hidden;
`

const Wrapper = styled(Container)`
`;

const CrossIcon = styled(BsXLg) <IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
	background: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
	width: 2.8rem;
	height: 2.8rem;
	border-radius: 2rem;
	padding: 0.8rem;
`

const DeleteImage = styled(BsXLg) <IProps>`
    cursor: pointer;
    z-index: 1;
    padding: 0.2rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? palette.white : palette.white)};
    font-size: 1.3rem;
    background: ${({ isDarkTheme }) => (isDarkTheme ? palette.danger : palette.danger)};
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 2rem;
    padding: 0.3rem;
    position: absolute;
    top: 0.25rem;
    right:0.25rem;
`

const Footer = styled.div<any>`
	background: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
	border-top: 0.063rem solid ${({ isDarkTheme }) => (isDarkTheme ? palette.light_silver : palette.silver)};
	padding: 1rem;
`

const CheckIconWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	cursor: pointer;
	height: 1.25rem;
	/* border-radius: 1rem; */
	width: 1.25rem;
`

const Checked = styled(BsCheckSquareFill)`
	color: ${palette.red};
`
const UnChecked = styled(ImCheckboxUnchecked) <any>`
    color: ${({ isDarkTheme }) => isDarkTheme ? palette.light_silver : palette.silver};
`

const InputWrapper = styled.div`
	position: relative;
	width: 120px;
    height: 120px;
	margin-bottom: 0.5rem;
`

const Upload = styled.label`
	padding: 1rem;
	width: 120px;
    height: 120px;
	border: 0.063rem dashed ${palette.light_gray};
	border-radius: 0.1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.7rem;
	flex-direction: column;
	cursor: pointer;
`

const FileInput = styled.input`
	display: none !important;
`

const ImgWrapper = styled.div`
	position: relative;
	width: 120px;
    height: 120px;
	margin-bottom: 0.5rem;
`

const ShowImage = styled.img`
    width: 120px;
    height: 120px;
	object-fit: cover;
	border: 0.063rem dashed ${palette.light_gray};
`

export default AddRestaurantMenuModal
