import React from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'

const Button = ({id, ifClicked, disabled, textTransformation, label, icon, type, stretchMobile, stretchWeb, hasShadow, hasBorder, medium, small, margin, width, tooltip, color, fontWeight }:any) => {

	return (
		<Btn
		id={id}
			type={type}
			color={color}
			hasLabel={label}
			disabled={disabled}
			onClick={ifClicked}
			medium={medium}
			small={small}
			hasBorder={hasBorder}
			margin={margin}
			width={width}
			stretchMobile={stretchMobile}
			stretchWeb={stretchWeb}
			textTransformation={textTransformation}
			fontWeight={fontWeight}
			hasShadow={hasShadow}>
			{icon && <Icon color={color} hasLabel={label} src={`/images/icons/${icon}`} />}
			{label}
		</Btn>
	)
}

const handleColorType = (type:any) => {
	switch (type) {
		case 'clear':
			return palette.dark_gray
		default:
			return palette.white
	}
}

const handleBackgroundType = (type:any, index:any) => {
	switch (type) {
		case 'clear':
			return palette.white
		case 'completed':
			return palette.blue
		case 'success':
			return palette.success
		case 'pending':
			return palette.golden
		case 'processing':
			return palette.green
		case 'danger':
			return palette.danger
		case 'red':
			return palette.red
		case 'submit':
			return palette.red
		case 'cancel':
			return palette.dark_gray
		case 'canceled':
			return palette.dark_gray
		default:
			return palette.blue
	}
}

const handleBorderColor = (type:any) => {
	switch (type) {
		case 'clear':
			return palette.white
		case 'dark_grey_border':
			return palette.silver
		default:
			return palette.blue
	}
}

const handleHoverBackgroundType = (type:any) => {
	switch (type) {
		case 'clear':
			return palette.white
		case 'completed':
			return palette.blue
		case 'cancel':
			return palette.dark_gray
		case 'red':
			return palette.red_hover
		case 'danger':
			return palette.red_hover
		case 'submit':
			return palette.red_hover
		case 'pending':
			return palette.golden
		case 'processing':
			return palette.green
		case 'canceled':
			return palette.dark_gray
		default:
			return palette.blue
	}
}
// Button Size
//             fontSize              		Hight
// Large	14px || 0.875rem	.		50px || 3.125rem
// Medium	14px || 0.875rem	.		40px || 2.5rem
// Small	12px || 0.75rem		.		32px || 2rem

const Btn = styled.button<any>`
	opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
	text-transform: ${({ textTransformation }) => (textTransformation === 'capitalize' ? 'capitalize' : 'uppercase')};
	display: inline-block;
	border: ${({ hasBorder, type }) => (hasBorder ? `0.063rem solid ${handleBorderColor(type)}` : 'none')};
	border-radius: 0.25rem;
	padding: 0.65rem 1rem;
	font-size: ${({ small }) => (small ? '0.75rem' : '0.875rem')};
	height: ${({ small, medium }) => (small ? '2rem' : medium ? '2.5rem' : '3.125rem')};
	color: ${({ hasBorder, type }) => (hasBorder ? palette[type] : handleColorType(type))};
	background: ${({ type, hasBorder }) => (hasBorder ? `transparent` : `linear-gradient(180deg,${handleBackgroundType(type, 1)} 0%, ${handleBackgroundType(type, 2)} 100%)`)};
	margin: ${({ margin }) => `${margin}`};
	font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '600')};
	opacity: ${({ type }) => (type === 'disable' ? '50%' : '')};
	cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
	min-width: 6rem;
	width: ${({ width }) => (width ? width : 'min-content')};
	line-height: 32px;
	white-space: nowrap;
	align-items: center;
	position: relative;
	display: flex;
	justify-content: center;
	transition: all ease 0.25s;
	font-family: 'Montserrat', sans-serif;
	&:hover {
		background: ${({ type, disabled }) => (disabled ? '' : `linear-gradient(180deg,${handleHoverBackgroundType(type)} 40%, ${handleHoverBackgroundType(type)} 100%)`)};
	}
	// &:hover > div {
	// 	visibility: visible !important;
	// }
`

const Icon = styled.img<any>`
	margin-right: ${({ hasLabel }) => (hasLabel ? ` 0.5rem` : 'none')};
`

Btn.defaultProps = {
	type: 'primary',
	ifClicked: () => null
}

export default Button
