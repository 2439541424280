import React, { useState, useEffect } from 'react'
import { Flexed, Heading, Spacer, Loading, Text } from '../../styled/shared'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { useSelector } from 'react-redux'
import OrderHistory from '../../components/business/OrderHistory'
import { api } from '../../components/api/callAxios'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Button from '../../components/common/Button'
import InputField from '../../components/common/InputField'
import { palette } from '../../styled/colors'
import Pusher from 'pusher-js';

const History = () => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);
    const [isLoading, setIsLoading] = useState<any>(false)
    const [isBusinessToBusiness, setIsBusinessToBusiness] = useState<any>(false)
    const [search, setSearch] = useState<any>('')
    const [history, setHistory] = useState<any>([])
    const [copyHistory, setCopyHistory] = useState([])
    const [historyFilter, setHistoryFilter] = useState<any>([])
    const _loginUserStatus: any = localStorage.getItem('userStatus')
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(15)
    const [loadMore, setLoadMore] = useState(false)
    const [filterStatus, setFilterStatus] = useState<any>('all')

    useEffect(() => {
        const pusher = new Pusher('34802ec02966c3d07993', {
            cluster: 'ap2',
        });
        const channel = pusher.subscribe('my-channel');
        channel.bind('my-event', function (data: any) {
            getOrderHistory(page, false, isBusinessToBusiness)
        });
        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    }, []);

    useEffect(() => {
        getOrderHistory(page, false, isBusinessToBusiness)
    }, [])

    const getOrderHistory = (page: any, isCallAfterCanceled: any, isBusinessToBusiness: any) => {
        setIsLoading(true)
        let apiPath = ''
        if (_loginUserStatus === 'owner') {
            apiPath = '/check-out/view_business_orders'
        }
        else {
            apiPath = '/check-out/view_orders'
        }
        api.post(apiPath, {
            page: page,
            pageSize: pageSize,
            isCallAfterCanceled: isCallAfterCanceled,
            isBusinessToBusiness: isBusinessToBusiness
        })
            .then((res) => {
                let _data = res?.data?.data
                let _totalRec = res?.data?.ordersCount
                if (page > 1) {
                    if (!isCallAfterCanceled) {
                        _data = history.concat(_data)
                    }
                }
                setHistory(_data)
                setCopyHistory(_data)
                setHistoryFilter(_data)
                setFilterStatus('all')
                let _remainingRec = _totalRec - (page * pageSize)
                setLoadMore(_remainingRec > 0)
                setIsLoading(false)
            }).catch((e) => {
                if (e?.response) {
                    toast.error(e?.response?.data?.message)
                    setIsLoading(false)
                }
            })
    }

    const historySearch = async (id: any) => {
        setIsLoading(true)
        let apiPath = ''
        if (_loginUserStatus === 'owner') {
            apiPath = '/check-out/search_business_orders'
        }
        else {
            apiPath = '/check-out/search_customer_orders'
        }
        await api
            .post(apiPath, {
                orderId: id,
                isBusinessToBusiness: isBusinessToBusiness
            })
            .then(async (response) => {
                if (id.trim().length > 0) {
                    setHistory(response?.data)
                    setHistoryFilter(response?.data)
                    setFilterStatus('all')
                } else {
                    setHistory(copyHistory)
                    setHistoryFilter(copyHistory)
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                console.log(error)
                setIsLoading(false)
            })
    }

    const loadMoreData = () => {
        setIsLoading(true)
        setPage(page + 1)
        getOrderHistory(page + 1, false, isBusinessToBusiness)
    }

    const reset = () => {
        setHistory([]);
        setCopyHistory([]);
        setHistoryFilter([]);
        setFilterStatus('all');
        setPage(1);
        setSearch('');
    }
    return (
        <>
            <MainWrapper>
                <Spacer height={1} />
                <Row>
                    <Col>
                        <Heading level={3} fontWeight={800} margin={'0rem 0rem 1.5rem 0rem'} isDarkTheme={_isDarkTheme}>Order History</Heading>
                        {_loginUserStatus === 'owner' &&
                            <>
                                <Flexed direction='row' gap={1} align='center' flexWrap='wrap'>
                                    <Button fontWeight={700} mudium label='My Customers Orders' type={!isBusinessToBusiness ? 'red' : 'cancel'} ifClicked={() => { reset(); setIsBusinessToBusiness(false); getOrderHistory(1, false, false);}} />
                                    <Button fontWeight={700} mudium label='My Business Orders' type={isBusinessToBusiness ? 'red' : 'cancel'} ifClicked={() => {reset(); setIsBusinessToBusiness(true); getOrderHistory(1, false, true);}} />
                                </Flexed>
                                <Spacer height={1.5} />
                            </>
                        }
                        <SearchWrapper>
                            <InputField type='search' value={search} placeholder='Search by order no' handleChange={(e: any) => { historySearch(e); setSearch(e); }} />
                        </SearchWrapper>
                        <Spacer height={1.5} />
                        <Flexed direction='row' flexWrap='wrap' gap={1} margin='0rem 0rem 2rem 0rem'>
                            <FilterButtons active={filterStatus === 'all'} isDarkTheme={_isDarkTheme} onClick={() => { setFilterStatus('all'); setHistoryFilter(history) }}>
                                All Orders ({history?.length})
                            </FilterButtons>
                            <FilterButtons active={filterStatus === 'completed'} isDarkTheme={_isDarkTheme} onClick={() => { setFilterStatus('completed'); setHistoryFilter(history.filter((history: any) => (history?.status === 'completed'))) }}>
                                Completed ({history?.filter((history: any) => (history?.status === 'completed'))?.length})
                            </FilterButtons>
                            <FilterButtons active={filterStatus === 'processing'} isDarkTheme={_isDarkTheme} onClick={() => { setFilterStatus('processing'); setHistoryFilter(history.filter((history: any) => (history?.status === 'processing'))) }}>
                                Processing ({history?.filter((history: any) => (history?.status === 'processing'))?.length})
                            </FilterButtons>
                            <FilterButtons active={filterStatus === 'pending'} isDarkTheme={_isDarkTheme} onClick={() => { setFilterStatus('pending'); setHistoryFilter(history.filter((history: any) => (history?.status === 'pending'))) }}>
                                Pending ({history?.filter((history: any) => (history?.status === 'pending'))?.length})
                            </FilterButtons>
                            <FilterButtons active={filterStatus === 'canceled'} isDarkTheme={_isDarkTheme} onClick={() => { setFilterStatus('canceled'); setHistoryFilter(history.filter((history: any) => (history?.status === 'canceled'))) }}>
                                Cancelled ({history?.filter((history: any) => (history?.status === 'canceled'))?.length})
                            </FilterButtons>
                        </Flexed>
                    </Col>
                    <List>
                        {historyFilter?.length > 0 && (
                            <>
                                {historyFilter?.map((history: any) => {
                                    return (
                                        <Col> <OrderHistory setIsLoading={setIsLoading} history={history} getOrderHistory={() => getOrderHistory(page, true, isBusinessToBusiness)} /> </Col>
                                    )
                                })}
                            </>
                        )}
                    </List>
                    {(history?.length > 0 && loadMore && filterStatus === 'all' && search.trim().length === 0) && (
                        <Col>
                            <Flexed direction='row' justify='center' margin='1rem 0rem'>
                                <Button medium label='Load More' type='red' ifClicked={() => loadMoreData()} />
                            </Flexed>
                        </Col>
                    )}
                    {historyFilter?.length === 0 && <Col>
                        <Spacer height={5} />
                        {!isLoading ? <Text type='small' isCentered isDarkTheme={_isDarkTheme}>Sorry, we couldn't find your {filterStatus} order history.</Text> : <Text type='small' isCentered isDarkTheme={_isDarkTheme}>The order history is loading...</Text>}
                    </Col>
                    }
                </Row>
                <Spacer height={2} />
            </MainWrapper>
            {isLoading && <Loading ><div className="dots-loading"></div></Loading>}
        </>
    );
}

const MainWrapper = styled(Container)`
    min-height: calc(100vh - 70px);
`

const List = styled.div`
    width: 100%;
	overflow: auto;
    ${media.lg`
	overflow: visible;
	`}
`
const SearchWrapper = styled.div`
	width: 18rem;
`;

const FilterButtons = styled.div<any>`
	font-weight: 600;
	border: 1px solid ${({ active, isDarkTheme }) => (active ? palette.red : isDarkTheme ? palette.light_silver : palette.silver)};
	color: ${({ active, isDarkTheme }) => (active ? palette.white : isDarkTheme ? palette.white : palette.dark_gray)};
	background-color: ${({ active }) => (active ? palette.red : '')};
	padding: 0.3rem 1rem;
	border-radius: 2rem;
	cursor: pointer;
	font-size: 14px;
    &:hover{
		background-color: ${palette.red};
		color: ${palette.white};
		border: 1px solid ${palette.red};
	}
`;

export default History
