import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { Heading, Text, Flexed, Divider, DletelTag, Spacer } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { BsXLg } from 'react-icons/bs'
import { FaPlus, FaMinus } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import InputField from '../common/InputField'
import Button from '../common/Button'
import Slider from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { verifyJSON } from '../../constants/commonFunctions'
import { Link } from 'react-router-dom'
import AddOnsList from '../business/AddOnsList'
import YouTubeVideo from '../common/YoutubeVideo'
import VimeoVideo from '../common/VimeoVideo'
import HTML5Video from '../common/HTML5Video'

interface IProps {
	isDarkTheme: boolean
}

const SelectProductItemsModal = ({ modalStatus, open, onCloseModal, addToCart, item, addOnsMenuList }: any) => {
	console.log('item', item)
	let photo = ''
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _loginUserStatus: any = localStorage.getItem('userStatus')
	const [total, setTotal] = useState<any>(0)
	const [images, setImages] = useState<any>([])
	const [quantity, setQuantity] = useState(item?.quantity ? item?.quantity : 1)
	const [orderPass, setOrderPass] = useState(false)
	const [addedAddOnsList, setAddedAddOnsList] = useState<any>(item?.soldItemsAddOns ? item?.soldItemsAddOns : [])
	if (item?.photo) {
		photo = verifyJSON(item?.photo) ? JSON.parse(item?.photo) : item?.photo;
	}

	useEffect(() => {
		let _selectedAddOnsGrossTotalPrice = 0
		let _total = 0
		addedAddOnsList?.map((addOns: any) => {
			_selectedAddOnsGrossTotalPrice = _selectedAddOnsGrossTotalPrice + parseFloat(addOns?.grossTotalPrice)
		})
		_total = item?.totalPrice * quantity
		_total = _total + _selectedAddOnsGrossTotalPrice
		setTotal(_total ? _total.toFixed(2) : total)

		setImages(verifyJSON(item?.photo) ? JSON.parse(item?.photo) : item?.photo);
	}, [item, quantity, addedAddOnsList])

	const addAddOnsItemQty = (data: any, qty: any) => {
		let _obj = {
			...data, quantity: qty,
			grossTotalPrice: data?.totalPrice * qty
		}
		let check = addedAddOnsList?.some((e: any) => e.title === data?.title)
		if (check) {
			let arr: any = []
			addedAddOnsList?.map((item: any) => {
				if (item?.title === data?.title && item?.id === data?.id) {
					if (qty > 0) {
						// item.quantity = qty;
						// item.grossTotalPrice = item?.totalPrice * qty;
						// arr.push({item})
						arr.push({
							...item, quantity: qty,
							grossTotalPrice: item?.totalPrice * qty
						})
					}
					else {
						let objectNameToDelete = data?.title;
						arr = arr.filter((item: any) => item.title !== objectNameToDelete);
					}
				} else {
					arr.push(item)
				}
			});
			setAddedAddOnsList(arr)
		} else {
			setAddedAddOnsList((prev: any) => [...prev, _obj])
		}
	}

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
			slidesToSlide: 1
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
			slidesToSlide: 1
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
			slidesToSlide: 1
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			slidesToSlide: 1
		}
	}

	return (
		<>
			<Modal
				open={open}
				center
				onClose={() => {
					onCloseModal()
				}}
				classNames={{
					overlay: 'customOverlay',
					modal: 'selectItemModal'
				}}
				showCloseIcon={false}
				// @ts-ignore
				styles={{ zIndex: '2' }}>
				<ModalWrapper isDarkTheme={_isDarkTheme}>
					<Head direction="row" align="center" justify="flex-end">
						<CrossIcon
							isDarkTheme={_isDarkTheme}
							onClick={() => {
								onCloseModal()
							}}
						/>
					</Head>
					<Body>
						{/* {item?.videoType === 'Video' && <HTML5Video videoId={item?.videoId}/>} */}
						<div id='selectMenuModalSlider'>
							{/* <Flexed direction='row' justify='center' > */}
							{/* {images?.length ? */}
								{item?.videoId &&  item?.videoType != '' && item?.videoType != 'None'  ? <Slider responsive={responsive}>
									<div>
										{item?.videoType === 'Youtube' && item?.videoId != '' && <YouTubeVideo videoUrl={item?.videoId} />}
										{item?.videoType === 'Vimeo' && item?.videoId != '' && <VimeoVideo videoUrl={item?.videoId} />}
									</div>
									{images?.map((img: any, key: number) => {
										return (
											<Img
												src={process.env.REACT_APP_IMAGE_URL + img} />
										)
									})}
								</Slider>
								:
								<Slider responsive={responsive}>
									{images?.map((img: any, key: number) => {
										return (
											<Img
												src={process.env.REACT_APP_IMAGE_URL + img} />
										)
									})}
								</Slider>
								}

								{/* :
								<Img src={photo[0]?.startsWith('data:') ? photo[0] : process.env.REACT_APP_IMAGE_URL + photo[0]} />
							} */}
							{/* </Flexed> */}
						</div>
						<Content>
							<Heading isDarkTheme={_isDarkTheme} level={3} fontWeight={800} margin="0rem 0rem 1rem 0rem">
								{item?.title}
							</Heading>

							{item?.tagLine && <Text type="medium" fontWeight={500} margin="0rem 0rem 0.5rem 0rem" color={item?.tagLine === 'Vegan' ? 'vegan' : item?.tagLine === 'Popular' ? 'popular' : item?.tagLine === 'Recommended' ? 'recommended' : 'hot'}>
								{item?.tagLine}
							</Text>}

							<Text type="normal" isDarkTheme={_isDarkTheme}>
								{item?.description}
							</Text>

							<Flexed direction='row' gap={0.5} flexWrap='wrap' margin='0.5rem 0rem 0rem 0rem'>
								{/* <Text fontSize={1.02} type='normal' fontWeight={500} color={(item?.discount && item?.discount != 0) ? 'danger' : ''} isDarkTheme={_isDarkTheme}>
								{'$' + (item?.totalPrice)}
							</Text> */}
								{(item?.discount && item?.discount != 0) &&
									<DletelTag isDarkTheme={_isDarkTheme}>
										<Text type="normal" fontWeight={500} isDarkTheme={_isDarkTheme}>
											{'$' + (item?.price)}
										</Text>
									</DletelTag>
								}
								<Text type="normal" fontWeight={400} color='danger'>
									{(item?.discount && item?.discount != 0) ? `${(item?.discountType === 'percent' ? '' : '$')}${item?.discount + (item?.discountType === 'percent' ? '%' : '-flat') + ' ' + `off`}` : ''}
								</Text>
							</Flexed>
							{(item?.tax && item?.tax != 0) ?
								<Text type="xsmall" id='s' fontWeight={500} isDarkTheme={_isDarkTheme}>
									{item?.tax + '% tax included in price'}
								</Text>
								:
								null
							}

							{_loginUserStatus !== null &&
								<Flexed direction="row" align="canter" gap={2} margin="0.5rem 0rem 1rem 0rem">
									<Heading isCentered color='red' level={4} fontWeight={600}>
										${item?.totalPrice}
									</Heading>
									<Flexed direction="row" align="center" gap={1}>
										<IconCover
											color={`${quantity > 1 ? 'red_light' : 'red_light'}`}
											onClick={() => {
												if (quantity > 1) {
													setQuantity(quantity - 1)
												}
											}}>
											<Minus isDarkTheme={_isDarkTheme} />
										</IconCover>
										<Heading level={4} fontWeight={500} isDarkTheme={_isDarkTheme}>
											{quantity}
										</Heading>
										<IconCover
											color="green_light"
											onClick={() => {
												setQuantity(quantity + 1)
											}}>
											<Add isDarkTheme={_isDarkTheme} />
										</IconCover>
									</Flexed>
								</Flexed>
							}
							{(addOnsMenuList?.length > 0 && modalStatus === 'menu') &&
								<>
									<Divider isDarkTheme={_isDarkTheme} margin='1rem 0rem' />
									<Flexed direction='row' align='center' gap={0.5} margin='0rem 0rem 0.2rem 0rem'>
										<Heading level={5} fontWeight={700} isDarkTheme={_isDarkTheme} >
											Add Ons
										</Heading>
										<Text type='small' isDarkTheme={_isDarkTheme} >
											(Optional)
										</Text>
									</Flexed>
								</>
							}
							{(addOnsMenuList?.length > 0 && modalStatus === 'menu') &&
								addOnsMenuList?.map((addOnsItems: any, key: number) => {
									return (
										<div key={`addOnsItems${key}`}>
											<AddOnsList item={addOnsItems} selectedAddOnsFromCartItemList={item?.soldItemsAddOns} addAddOnsItemQty={(item: any, qty: any) => { addAddOnsItemQty(item, qty) }} />
										</div>
									)
								})}

							{/* <AddOnsList item={addOnsItems} removeItemCb={removeFromCart} addQty={(id: any, qty: any) => { addQty(id, qty) }} /> */}

						</Content>
					</Body>
					{_loginUserStatus !== null ?
						<Footer isDarkTheme={_isDarkTheme}>
							<Button
								type='red'
								disabled={orderPass}
								label={`Add for $ ${total}`}
								width="100%"
								id="add-to-cart"
								ifClicked={(e: any) => {
									setOrderPass(true)
									addToCart(e, { item: item, total: total, quantity: quantity, addedAddOnsList })
									setQuantity(1)
								}}
							/>
						</Footer>
						:
						<>
							<Text margin='1rem 1rem 0rem 1rem' isCentered isDarkTheme={_isDarkTheme} type="normal">Please <SignIn to='/customer-login'>sign in</SignIn> before adding items to the cart.</Text>
							<Spacer height={2} />
						</>
					}

				</ModalWrapper>
			</Modal>
		</>
	)
}

const ModalWrapper = styled.div<IProps>`
	position: relative;
	background-color: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
`

const Head = styled(Flexed)`
	padding: 1rem;
	position: absolute;
	width: 100%;
`

const Body = styled.div`
	max-height: 32rem;
	overflow-y: scroll;
	overflow-x: hidden;
`

const Content = styled.div`
	padding: 1rem;
`

const CrossIcon = styled(BsXLg) <IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
	background: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
	width: 2.8rem;
	height: 2.8rem;
	border-radius: 2rem;
	padding: 0.8rem;
`

const Img = styled.img`
	max-width: 100%;
	height: 19.688rem;
	object-fit: cover;
`

const DropList = styled(Flexed) <IProps>`
	cursor: pointer;
`

const Label = styled.label<any>`
	margin: 0;
	cursor: pointer;
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
`

const Footer = styled.div<any>`
	background: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
	border-top: 0.063rem solid ${({ isDarkTheme }) => (isDarkTheme ? palette.light_silver : palette.silver)};
	padding: 1rem;
`

const IconCover = styled.div<any>`
	cursor: pointer;
	background-color: ${({ isDarkTheme, color }) => (color ? palette[color] : isDarkTheme ? palette.silver : palette.gray)};
	width: 1.7rem;
	height: 1.7rem;
	border-radius: 0.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Minus = styled(FaMinus) <any>`
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.red : palette.red)};
	font-size: 14px;
`

const Add = styled(FaPlus) <any>`
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.green : palette.green)};
	font-size: 14px;
`

const SignIn = styled(Link) <any>`
	color: ${palette.red};
	cursor:pointer;
	&:hover{
		color: ${palette.red_hover_text} ;
	}
	text-decoration: underline;
    text-underline-offset: 4px;
`

export default SelectProductItemsModal
