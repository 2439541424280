import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Heading, Spacer, Text } from '../styled/shared'
import { palette } from '../styled/colors'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { AiOutlineRight } from "react-icons/ai";
import { useSelector } from 'react-redux'
import useWindowSize from '../hooks/useWindowSize'
import { api } from './api/callAxios'
import { toast } from 'react-toastify'
import { useNavigate, createSearchParams } from "react-router-dom"

interface IProps {
    isDarkTheme: boolean,
}

const Category = () => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);
    let _navigate = useNavigate()
    const _windowSize = useWindowSize();
    const [offersMenu, setOffersMenu] = useState([]);

    useEffect(() => {
        getBusinessTypes()
    }, []);

    const getBusinessTypes = () => {
        api.get(`/business-type`)
            .then((res) => {
                if (res.data) {
                    let storeType: any = []
                    res?.data?.businesTypes?.map((e: any) => {
                        storeType.push({ title: e?.title, id: e.id })
                    })
                    setOffersMenu(storeType)
                }
            })
            .catch((e) => {
                if (e?.response) {
                    toast.error(e?.response?.data?.message)
                }
            })
    }

    const getToBusiness = (id: any) => {
        _navigate({
            pathname: "/business",
            search: createSearchParams({
                id: id,
            }).toString(),
        })
    }

    const getToBusinessIcon = (name: any) => {
        switch (name) {
            case 'Food':
                return '/images/food.svg'
            case 'Medicine':
                return '/images/grocery.svg'
            case 'Transport':
                return '/images/logistic.webp'
            case 'E-commerce':
                return '/images/courier.svg'
            case 'Pickup and Delivery':
                return '/images/pickup-deliery.webp'
            case 'Logistics':
                return '/images/logistic.webp'
            default:
                return '/images/food.svg'
        }
    }

    const getToBusinessInfo = (name: any) => {
        switch (name) {
            case 'Food':
                return 'Our Plate form automatically allocates food deliveries with optimized routes which enable quick planning for every driver.'
            case 'Medicine':
                return 'Improve operational efficiency with auto-mated delivery processes, seamless integration, customer profile, inventory management, and more.'
            case 'Transport':
                return 'To manage multiple deliveries in a day, Our Plate form optimizes your delivery operations keeping your profit margins and operation scale in mind.'
            case 'E-commerce':
                return 'Automate Order Management, Delivery Dispatch, Real-time Delivery Tracking & Marketing Campaigns to scale your business.'
            case 'Pickup and Delivery':
                return 'Enhance pick-up and delivery business with route optimization, real-time tracking, task automation, and more'
            case 'Logistics':
                return 'Our Plate form manages all complex logistics operations simultaneously and speeds up the business flow to bring in more revenue.'
            default:
                return 'Our Plate form automatically allocates food deliveries with optimized routes which enable quick planning for every driver.'
        }
    }

    return (
        <Container>
            <Spacer height="5" />
            {offersMenu?.length > 0 &&
                <>
                    <Row justifyContent='center'>
                        <Heading level={_windowSize?.width < 575 ? 3 : 3} isDarkTheme={_isDarkTheme} fontWeight={700} isCentered>Enterprise Solution for Every Business</Heading>
                    </Row>
                    <Spacer height={2} />
                    <Row>
                        {offersMenu?.map((val: any, index: any) => {
                            return (
                                <Col key={index + 'cetagories'} xl={2.2} lg={4} md={4} sm={6} xs={12}>
                                    <Card isDarkTheme={_isDarkTheme} onClick={() => { getToBusiness(val?.id) }} >
                                        <Logo src={getToBusinessIcon(val?.title)} />
                                        <Title isDarkTheme={_isDarkTheme} type='normal' lineHeight="1.8" margin="0.5rem 0rem 0rem 0rem" isCentered fontWeight={700}>
                                            {val?.title}
                                        </Title>
                                        <Description isDarkTheme={_isDarkTheme} type='small' lineHeight="1.3" margin="0.5rem 0rem 0rem 0rem" isCentered>
                                            {getToBusinessInfo(val?.title)}
                                        </Description>
                                    </Card>
                                </Col>
                            )
                        })}
                        {/* <LoadMore xl={1} lg={4} md={4} sm={6} xs={12}>
                            <ArrowWrapper><ArrowRight /></ArrowWrapper>
                            <Text isDarkTheme={_isDarkTheme} type='normal' lineHeight="1.8" margin="0.5rem 0rem 0rem 0rem" fontWeight={700}>
                                View All
                            </Text>
                        </LoadMore> */}
                    </Row>
                    <Spacer height="5" />
                </>
            }
        </Container>

    )
}

const Logo = styled.img`
    object-fit: cover;
    height:5.875rem;
    opacity:1;
    transition: all ease 1s;
`;

const Title = styled(Text)`
    position:absolute;
    top:8rem;
    left:0;
    right:0;
    margin:auto;
    transition: all ease 0.5s;
`;

const Description = styled(Text)`
    // display:none;
    top:14rem;
    position:absolute;
    padding:0rem 1rem;
    left:0;
    right:0;
    margin:auto;
    transition: all ease 0.5s;
`;

const Card = styled.div<IProps>`
    position:relative;
    overflow:hidden;
    text-align:center;
    padding:1rem;
    border-radius: 1rem;
    box-shadow: ${palette.shadow};
    min-height: 13rem;
    cursor:pointer;
    margin-bottom: 1.875rem;
    background: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
    ${media.xs` min-height: 12rem;`}
    &:hover{
        box-shadow: ${palette.shadowHover};
    }
    &:hover ${Logo} {
        opacity:0;
        transition: all ease 0.2s;
    }

    &:hover ${Title} {
        top:1rem;
        transition: all ease 0.5s;
    }

    &:hover ${Description}{
        top:3.2rem;
        transition: all ease 0.5s;
    }
`;

const LoadMore = styled(Col)`
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    &:hover div,  &:hover label{
            opacity:0.7;
    }
`;

const ArrowWrapper = styled.label`
	width:3rem;
	height: 3rem;
	background:${palette.black};
	cursor: pointer;
	display: flex;
	justify-content:center;
	align-items:center;
	margin:0;
	border-radius: 3rem;
    &:hover{
        opacity:0.7;
    }
`

const ArrowRight = styled(AiOutlineRight)`
	cursor: pointer;
	font-size:1.5rem;
	color:${palette.white};
`

export default Category
