import React, { useState, useEffect } from "react"
import { Flexed, Spacer, Loading, Text } from "../../styled/shared"
import { Container, Row, Col, media } from "styled-bootstrap-grid"
import styled from "styled-components"
import { api } from "../../components/api/callAxios"
import { useSelector, useDispatch } from "react-redux"
import { palette } from "../../styled/colors"
import DeleteAccountModal from "../../components/modals/DeleteAccountModal"
import { loginBusinessDetailsAction, loginUserDetailsAction } from "../../actions/authActions"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import BusinessClaimModal from "../../components/modals/BusinessClaimModal"
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import '@react-pdf-viewer/core/lib/styles/index.css'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import axios from 'axios'
import InputField from "../../components/common/InputField"
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const Settings = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _loginBusinessDetails: any = useSelector<any>((state: any) => state.auth.loginBusinessDetails);
	const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
	let _dispatch = useDispatch()
	const _navigate = useNavigate()
	const [isLoading, setIsLoading] = useState<any>(false)
	const [deleteAccount, setDeleteAccount] = useState(false)
	const [openClaimModal, setOpenClaimModal] = useState(false)
	const [numPages, setNumPages] = useState<any>(null);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [error, setError] = useState<string | null>(null);
	const [pdfFiles, setPdfFiles] = useState<string[]>([]);
	const [lightBoxOpen, setLightBoxOpen] = useState(false)
	const [photoSelected, setPhotoSelected] = useState<any>('')
	const [youTubeLink, setYouTubeLink] = useState<any>('')
	const [faceBookLink, setFaceBookLink] = useState<any>('')
	const [twitterLink, setTwitterLink] = useState<any>('')
	const [snapChatLink, setSnapChatLink] = useState<any>('')
	const [instagramLink, setInstagramLink] = useState<any>('')
	const [showEmail, setShowEmail] = useState<any>(false)
	const [showPhoneNo, setShowPhoneNo] = useState<any>(false)

	const [youTubeLinkError, setYouTubeLinkError] = useState<any>('')
	const [faceBookLinkError, setFaceBookLinkError] = useState<any>('')
	const [twitterLinkError, setTwitterLinkError] = useState<any>('')
	const [snapChatLinkError, setSnapChatLinkError] = useState<any>('')
	const [instagramLinkError, setInstagramLinkError] = useState<any>('')


	useEffect(() => {
		if (Object.keys(_loginBusinessDetails).length != 0) {
			setPdfFiles(_loginBusinessDetails?.govCertificate)
			setYouTubeLink(_loginBusinessDetails?.youTube)
			setFaceBookLink(_loginBusinessDetails?.facebook)
			setTwitterLink(_loginBusinessDetails?.twitter)
			setSnapChatLink(_loginBusinessDetails?.snapChat)
			setInstagramLink(_loginBusinessDetails?.instagram)
			setShowEmail(_loginBusinessDetails?.displayEmail)
			setShowPhoneNo(_loginBusinessDetails?.displayPhoneNo)
		}
	}, [_loginBusinessDetails])


	const doDeleteAccount = () => {
		setIsLoading(true)
		api.delete(`/business/${_loginBusinessDetails?.id}`)
			.then((res) => {
				setIsLoading(false)
				setDeleteAccount(false)
				localStorage.removeItem("authorization")
				localStorage.removeItem("userStatus")
				_dispatch(loginUserDetailsAction({}))
				_dispatch(loginBusinessDetailsAction({}))
				_navigate("/")
				toast.success("Account deleted successfully.")
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const footerEdit = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.put(`/business/add_social_links/${_loginBusinessDetails?.id}`, {
				youTube: youTubeLink,
				facebook: faceBookLink,
				twitter: twitterLink,
				snapChat: snapChatLink,
				instagram: instagramLink,
				displayPhoneNo: showPhoneNo,
				displayEmail: showEmail,
			})
				.then((res) => {
					if (res?.data) {
						_dispatch(loginBusinessDetailsAction(res?.data))
						toast.success('Footer setting updated successfully.')
					}
					setIsLoading(false)
				}).catch(function (error: any) {
					console.log(error)
					setIsLoading(false)
				})
		}
		else {
			toast.error('Please enter a valid URL; otherwise, empty the input field.');
		}
	}

	const formValidation = () => {
		let isValid = true
		if (youTubeLinkError === true && youTubeLink != '') {
			isValid = false
		}
		if (faceBookLinkError === true && faceBookLink != '') {
			isValid = false
		}
		if (twitterLinkError === true && twitterLink != '') {
			isValid = false
		}
		if (snapChatLinkError === true && snapChatLink != '') {
			isValid = false
		}
		if (instagramLinkError === true && instagramLink != '') {
			isValid = false
		}
		return isValid
	}

	function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
		setNumPages(numPages);
	}

	return (
		<>
			<MainWrapper>
				<Spacer height={1} />
				<Row justifyContent="center">
					<Col xl={7} lg={10}>
						<Wrapper>
							<Container fluid>
								<Row>
									<Col>
										<AccountWrapper>
											<Text type="medium" margin="0rem 0rem 1rem 0rem" fontWeight={700} isDarkTheme={_isDarkTheme}>
												Claim your business account
											</Text>
										</AccountWrapper>

										{_loginBusinessDetails?.IdCardFrontImage && _loginBusinessDetails?.IdCardBackImage && <Flexed direction='row' flexWrap='wrap' gap={0.5}>
											<IdCardImg src={process.env.REACT_APP_IMAGE_URL + _loginBusinessDetails?.IdCardFrontImage} alt='idCardFront' onClick={() => { setLightBoxOpen(true); setPhotoSelected(_loginBusinessDetails?.IdCardFrontImage) }} />
											<IdCardImg src={process.env.REACT_APP_IMAGE_URL + _loginBusinessDetails?.IdCardBackImage} alt='idCardBack' onClick={() => { setLightBoxOpen(true); setPhotoSelected(_loginBusinessDetails?.IdCardBackImage) }} />
										</Flexed>}
										{error && <p>{error}</p>}
										<Flexed margin='0.5rem 0rem' direction='row' flexWrap='wrap' gap={0.5}>
											{pdfFiles?.map((file: any, index: any) => (
												<div>
													<PDFWrapper key={index}>
														<Document
															file={process.env.REACT_APP_IMAGE_URL + file}
															onLoadSuccess={onDocumentLoadSuccess}
															onClick={() => {
																var url = process.env.REACT_APP_IMAGE_URL + file; var newTab = window.open(url, '_blank');
																if (newTab) {
																	newTab.focus();
																}
															}}
														>
															<Page pageNumber={pageNumber} width={160} height={80} />
														</Document>
													</PDFWrapper>
												</div>
											))}
										</Flexed>
										<Button onClick={() => setOpenClaimModal(true)}>
											<ButtonText>{_loginBusinessDetails?.govCertificate?.length > 0 && _loginBusinessDetails?.IdCardFrontImage && _loginBusinessDetails?.IdCardBackImage ? 'Edit Claim' : 'Claim'}</ButtonText>
										</Button>
									</Col>
								</Row>
							</Container>
						</Wrapper>
						<Spacer height={1} />
						<Wrapper>
							<Container fluid>
								<Row>
									<Col>
										<AccountWrapper>
											<Text type="medium" margin="0rem 0rem 1rem 0rem" fontWeight={700} isDarkTheme={_isDarkTheme}>
												Delete Your business account
											</Text>
										</AccountWrapper>

										<Text type="normal" margin="0rem 0rem 1rem 0rem" fontWeight={500} isDarkTheme={_isDarkTheme}>
											Once you delete a business account, it cannot be recovered.
										</Text>
										<Button onClick={() => setDeleteAccount(true)}>
											<ButtonText>Delete account</ButtonText>
										</Button>
									</Col>
								</Row>
							</Container>
						</Wrapper>
						<Spacer height={1} />
						<Wrapper>
							<Container fluid>
								<Row>
									<Col>
										<AccountWrapper>
											<Text type="medium" margin="0rem 0rem 1rem 0rem" fontWeight={700} isDarkTheme={_isDarkTheme}>
												Edit your business footer
											</Text>
										</AccountWrapper></Col>
								</Row>
								<Row>
									<Col lg={6}>
										<InputField label="YouTube" placeholder='Enter URL'
											onBlur={() => {
												if (urlRegex.test(youTubeLink)) {
													setYouTubeLinkError(false)
												}
												else {
													setYouTubeLinkError(true)
												}
											}}
											value={youTubeLink} handleChange={(e: any) => {
												setYouTubeLink(e?.trim())
												setYouTubeLinkError(false)
											}}
										/>
										{youTubeLinkError && youTubeLink != '' && <Text fontSize={0.7} type="small" color="danger">
											Invalid URL
										</Text>}
										<Spacer height={1} />
									</Col>
									<Col lg={6}>
										<InputField label="FaceBook" placeholder='Enter URL'
											onBlur={() => {
												if (urlRegex.test(faceBookLink)) {
													setFaceBookLinkError(false)
												}
												else {
													setFaceBookLinkError(true)
												}
											}}
											value={faceBookLink} handleChange={(e: any) => {
												setFaceBookLink(e?.trim())
												setFaceBookLinkError(false)
											}} />
										{faceBookLinkError && faceBookLink != '' && <Text fontSize={0.7} type="small" color="danger">
											Invalid URL
										</Text>}
										<Spacer height={1} />
									</Col>
									<Col lg={6}>
										<InputField label="Twitter" placeholder='Enter URL'
											onBlur={() => {
												if (urlRegex.test(twitterLink)) {
													setTwitterLinkError(false)
												}
												else {
													setTwitterLinkError(true)
												}
											}}
											value={twitterLink} handleChange={(e: any) => {
												setTwitterLink(e?.trim())
												setTwitterLinkError(false)
											}} />
										{twitterLinkError && twitterLink != '' && <Text fontSize={0.7} type="small" color="danger">
											Invalid URL
										</Text>}
										<Spacer height={1} />
									</Col>
									<Col lg={6}>
										<InputField label="Snap Chat" placeholder='Enter URL'
											onBlur={() => {
												if (urlRegex.test(snapChatLink)) {
													setSnapChatLinkError(false)
												}
												else {
													setSnapChatLinkError(true)
												}
											}}
											value={snapChatLink} handleChange={(e: any) => {
												setSnapChatLink(e?.trim())
												setSnapChatLinkError(false)
											}} />
										{snapChatLinkError && snapChatLink != '' && <Text fontSize={0.7} type="small" color="danger">
											Invalid URL
										</Text>}
										<Spacer height={1} />
									</Col>
									<Col lg={6}>
										<InputField label="Instagram" placeholder='Enter URL'
											onBlur={() => {
												if (urlRegex.test(instagramLink)) {
													setInstagramLinkError(false)
												}
												else {
													setInstagramLinkError(true)
												}
											}}
											value={instagramLink} handleChange={(e: any) => {
												setInstagramLink(e?.trim())
												setInstagramLinkError(false)
											}} />
										{instagramLinkError && instagramLink != '' && <Text fontSize={0.7} type="small" color="danger">
											Invalid URL
										</Text>}
										<Spacer height={1} />
									</Col>
								</Row>
								<Row>
									<Col lg={6}>
										<Flexed margin='0rem 0rem 0.5rem 0rem' direction='row' justify='space-between' gap={0.5} align='center'>
											<div>
												<Label type="small" fontWeight={500} isDarkTheme={_isDarkTheme}>Display Phone no</Label>
												<CustomText type='small' isDarkTheme={_isDarkTheme}>+{_loginBusinessDetails?.phone}</CustomText>
											</div>
											<div>
												<Switch
													onClick={() => {
														setShowEmail(!showEmail)
													}}
													toggle={showEmail}
													disabled={false}>
													<Dot toggle={showEmail} />
												</Switch>
											</div>
										</Flexed>
									</Col>
									<Col lg={6}>
										<Flexed margin='0rem 0rem 0.5rem 0rem' direction='row' justify='space-between' gap={0.5} align='center'>
											<div>
												<Label type="small" fontWeight={500} isDarkTheme={_isDarkTheme}>Display Email</Label>
												<CustomText type='small' isDarkTheme={_isDarkTheme}>{_loginBusinessDetails?.email}</CustomText>
											</div>
											<div>
												<Switch
													onClick={() => {
														setShowPhoneNo(!showPhoneNo)
													}}
													toggle={showPhoneNo}
													disabled={false}>
													<Dot toggle={showPhoneNo} />
												</Switch>
											</div>
										</Flexed>
									</Col>
								</Row>
								<Row>
									<Col>
										<Spacer height={1} />
										<Button onClick={() => footerEdit()}>
											<ButtonText>Save</ButtonText>
										</Button>
									</Col>
								</Row>
							</Container>
						</Wrapper>
					</Col>
				</Row>
				<Spacer height={2} />
			</MainWrapper>
			{deleteAccount && <DeleteAccountModal open={deleteAccount} onCloseModal={() => setDeleteAccount(false)} deleteAction={() => doDeleteAccount()} />}
			{openClaimModal && <BusinessClaimModal open={openClaimModal} onCloseModal={() => setOpenClaimModal(false)} />}

			{lightBoxOpen && (
				<Lightbox
					mainSrc={process.env.REACT_APP_IMAGE_URL + photoSelected}
					onCloseRequest={() => setLightBoxOpen(false)}
				/>
			)}

			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const MainWrapper = styled(Container)`
	min-height: calc(100vh - 70px);
`

const Wrapper = styled.div`
	padding: 15px;
	border: 1px solid #ccc;
	border-radius: 8px;
	margin: 0px;
`

const AccountWrapper = styled.div`
	margin-bottom: 15px;
	border-bottom: 1px solid #ccc;
`

const Button = styled.button<any>`
	background-color: ${palette.red};
	padding: 10px 15px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
`

const ButtonText = styled.div<any>`
	font-size: 16px;
	color: ${palette.white};
	font-weight: 500;
	min-width: 6rem;
`

const IdCardImg = styled.img<any>`
	width: 10rem;
	aspect-ratio: 3/1.9;
	object-fit: cover;
	border-radius: 0.3rem;
	cursor:pointer;
	border: 0.063rem dashed ${palette.light_gray};
`


const PDFWrapper = styled.div`
	position: relative;
	width: 110px;
    height: 140px;
	margin-bottom: 0.5rem;
    border: 0.063rem dashed ${palette.light_gray};
    border-radius: 0.1rem;
    overflow: hidden;
	cursor: pointer;
    ${media.sm`
    width: 140px;
    height: 160px;
	`}
    ${media.md`
    width: 160px;
    height: 180px;
	`}
`

const Switch = styled.div<any>`
	display: flex;
	align-items: center;
	justify-content: ${({ toggle }) => (toggle ? 'flex-end' : 'flex-start')};
	background-color: ${({ toggle }) => (toggle ? palette.red : palette.gray)};
	width: 40px;
	height: 26px;
	border-radius: 1rem;
	padding: 0.5rem 0.1rem;
	transition: justify-content 2s, transform 2s;
	border: 0.063rem solid ${({ toggle }) => (toggle ? palette.red : palette.gray)};
	cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
	opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`

const Dot = styled.div<any>`
	width: 20px;
	height: 20px;
	border-radius: 100%;
	background-color: ${palette.white};
    cursor: pointer;
`

const Label = styled(Text) <any>`
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`
const CustomText = styled(Text)`
	word-break: break-all;
`;

export default Settings
