import React, {useEffect, useState} from "react"
import styled from "styled-components"
import {palette} from "../../styled/colors"
import {Flexed, Heading, Loading, Spacer, Text} from "../../styled/shared"
import {Container, Row, Col, media} from "styled-bootstrap-grid"
import {useSelector} from "react-redux"
import Button from "../../components/common/Button"
import LogInGoogle from "../../components/common/LogInGoogle"
import {useNavigate} from "react-router-dom"
import {useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import * as Yup from "yup"
import {api} from "../../components/api/callAxios"
import {toast} from "react-toastify"
import InputFieldAuth from "../../components/common/InputFieldAuth"
import InputPhoneNo from "../../components/common/InputPhoneNo"
import InputAddress from "../../components/common/InputAddress"
import {GoogleMap, useJsApiLoader, MarkerF} from "@react-google-maps/api"
import {MdOutlineCloudUpload} from "react-icons/md"
import axios from "axios"
import CustomerOTPVerification from "./CustomerOTPVerification"
import useWindowSize from "../../hooks/useWindowSize"

interface IProps {
	isDarkTheme?: boolean
}

const SignUp = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	let _navigate = useNavigate()
	const _windowSize = useWindowSize()
	const [map, setMap] = useState(null)
	const [clat, setCLat] = useState<any>("")
	const [clng, setCLng] = useState<any>("")
	const [location, setLocation] = useState("")
	const [image, setImage] = useState("")
	const [imageError, setImageError] = useState("")
	const [isLoading, setIsLoading] = useState(false)
	const [phone, setPhone] = useState("")
	const [showOtpInput, setShowOtpInput] = useState(false)
	const [dataForSignIn, setDataForSignIn] = useState("")

	useEffect(() => {
		localStorage.clear()
		getCurrentLatLng()
	}, [])

	const validationSchema = Yup.object().shape({
		gender: Yup.string().required("This field is required"),
		firstname: Yup.string().required("This field is required"),
		lastname: Yup.string().required("This field is required"),
		email: Yup.string().email().required("This field is required"),
		phone: Yup.string().min(10, "Phone must be a valid phone").required("This field is required"),
		address: Yup.string().required("This field is required"),
		password: Yup.string().min(8, "Password must be at least 8 characters").required("This field is required"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref("password"), null], "Passwords must match")
			.required("This field is required"),
	})

	const {handleSubmit, setValue, getValues, formState, trigger, register} = useForm<any>({
		resolver: yupResolver(validationSchema),
	})
	const {errors} = formState

	async function onChange({name, value}: {name: string; value: any}) {
		if (name === "email") {
			setValue(name as never, value?.toLowerCase() as never)
			await trigger(name as never)
		} else if (name === "phone") {
			setValue(name as never, value as never)
			setPhone(value)
			await trigger(name as never)
		} else {
			setValue(name as never, value as never)
			await trigger(name as never)
		}
	}

	useEffect(() => {
		setTimeout(() => {
			setValue("firstname", "")
			setValue("lastname", "")
			setValue("email", "")
			setValue("phone", "")
			setValue("address", "")
			setValue("password", "")
			setValue("gender", "")
		})
	}, [setValue])

	//submit signup
	const onSubmit = (data: any) => {
		setDataForSignIn(data)
		if (formValidation()) {
			setIsLoading(true)
			api.post(`/auth/register`, {...data, photo: image, address: {address: data?.address}})
				.then((res) => {
					if (res.data) {
						setIsLoading(false)
						toast.success("Customer registered successfully.")
						setShowOtpInput(true)
					}
				})
				.catch((e) => {
					if (e?.response) {
						setIsLoading(false)
						toast.error(e?.response?.data?.message)
					}
				})
		} else {
			toast.error("Image is required")
		}
	}

	const containerStyle = {
		width: "100%",
		height: "14.74rem",
	}

	const center = {
		lat: clat ? clat : 38.892708,
		lng: clng ? clng : -94.6426741,
		zoom: 15,
	}

	const {isLoaded} = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyAiSEHvjCeth6Gx8YDltGHUhzBl5ongDRk",
	})

	const getCurrentLatLng = async () => {
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				setCLat(position.coords.latitude)
				setCLng(position.coords.longitude)
				await getCurrentAddress(position.coords.latitude, position.coords.longitude)
			},
			function (error) {
				// eslint-disable-next-line eqeqeq
				if (error.code == error.PERMISSION_DENIED) {
				} else {
				}
			},
			{timeout: 5000, enableHighAccuracy: true}
		)
	}

	//Function to get Exact Address from above taken Latitude and longitude
	const getCurrentAddress = async (lat: any, lng: any) => {
		await axios
			.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(lat)},${parseFloat(lng)}&sensor=true&key=${process.env.REACT_APP_MAP_API_KEY}`)
			.then((response) => {
				setLocation(response.data.results[0].formatted_address)
			})
			.catch((error) => {
				setLocation("")
			})
	}
	const formValidation = () => {
		let isValid = true
		if (image === "") {
			setImageError("image is required")
			isValid = false
		}
		return isValid
	}

	const handleCapture = ({target}: any) => {
		const reader: any = new FileReader()
		reader.readAsDataURL(target.files[0])
		reader.onload = () => {
			if (reader.readyState === 2) {
				setImageError("")
				setImage(reader?.result)
			}
		}
	}

	return (
		<>
			<MainWrapper fluid>
				{!showOtpInput ? (
					<Row justifyContent="center">
						<Col>
							<Spacer height={2} />
							<Heading level={_windowSize?.width < 600 ? 3 : 2} isCentered isDarkTheme={_isDarkTheme}>
								Customer Sign Up
							</Heading>
							<Spacer height={2} />
						</Col>
						<Col xxl={5} xl={6} lg={6} md={10} lgOrder={1} smOrder={2} xsOrder={2}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Container fluid>
									<Row>
										{/* <Col>
                                    <LogInGoogle />
                                    <Spacer height={1} />
                                </Col>

                                <Col>
                                    <Border isDarkTheme={_isDarkTheme}>
                                        <CustomText fontSize="1" lineHeight={2} isDarkTheme={_isDarkTheme}>
                                            Or SignUp With
                                        </CustomText>
                                    </Border>
                                </Col>
                                <Col>
                                    <Spacer height={2} />
                                </Col> */}
										<Col sm={6} lgOrder={1} smOrder={2} xsOrder={2}>
											<InputFieldAuth label="First Name" type="text" name={"firstname"} placeholder="Enter" required register={register} error={errors.firstname as any} />
											<Spacer height={1} />
										</Col>

										<Col sm={6} lgOrder={2} smOrder={3} xsOrder={3}>
											<InputFieldAuth label="Last Name" type="text" placeholder="Enter" name={"lastname"} required register={register} error={errors.lastname as any} />
											<Spacer height={1} />
										</Col>

										<Col sm={6} lgOrder={3} smOrder={4} xsOrder={4}>
											<InputFieldAuth label="Email" type="email" placeholder="Enter" name={"email"} required register={register} error={errors.email as any} />
											<Spacer height={1} />
										</Col>

										<Col sm={6} lgOrder={4} smOrder={5} xsOrder={5}>
											<InputPhoneNo label="Phone No" placeholder="Enter" required name={"phone"} handleChange={onChange} value={getValues("phone")} error={errors.phone as any} />
											<Spacer height={1} />
										</Col>

										<Col sm={6} lgOrder={5} smOrder={6} xsOrder={6}>
											<InputFieldAuth label="Password" type="password" placeholder="Password" name={"password"} required register={register} error={errors.password as any} />
											<Spacer height={1} />
										</Col>

										<Col sm={6} lgOrder={6} smOrder={7} xsOrder={7}>
											<InputFieldAuth label="Confirm Password" type="password" placeholder="Password" name={"confirmPassword"} required register={register} error={errors.confirmPassword as any} />
											<Spacer height={1} />
										</Col>

										<Col lgOrder={7} smOrder={1} xsOrder={1}>
											<InputAddress
												label="Address"
												required
												address={getValues("address")}
												setCLat={setCLat}
												setCLng={setCLng}
												isEdit={false}
												setAddress={(e: any) => onChange({name: "address", value: e})}
												error={errors.address?.message as any}
											/>
											<Spacer height={1} />
										</Col>

										<Col lgOrder={8} smOrder={8} xsOrder={8}>
											<Flexed direction="row" gap={2}>
												<Text type="normal" fontWeight={500} isDarkTheme={_isDarkTheme}>
													Gender:
												</Text>
												<Flexed direction="row" gap={2}>
													<Flexed direction="row" align="center" gap={0.5}>
														<input name="gender" type="radio" onChange={(e) => onChange({name: "gender", value: "male"})} />
														<Label htmlFor="gender" isDarkTheme={_isDarkTheme}>
															Male
														</Label>
													</Flexed>
													<Flexed direction="row" align="center" gap={0.5}>
														<input name="gender" type="radio" onChange={(e) => onChange({name: "gender", value: "female"})} />
														<Label htmlFor="gender" isDarkTheme={_isDarkTheme}>
															Female
														</Label>
													</Flexed>
												</Flexed>
											</Flexed>
											{errors?.gender && (
												<Text fontSize={0.7} type="small" color="danger">
													{errors?.gender?.message}
												</Text>
											)}
											<Spacer height={2} />
										</Col>
										<Col lgOrder={9} smOrder={9} xsOrder={9}>
											<Button label="Sign Up" width="100%" type="submit" ifClicked={() => formValidation()} />

											<Spacer height={1} />

											<Flexed direction={_windowSize?.width < 400 ? "column" : "row"} justify="center" gap={0.5} align="center">
												<Text textTransform="capitalize" type={_windowSize?.width < 600 ? "small" : "normal"} isDarkTheme={_isDarkTheme}>
													Already have an account?
												</Text>
												<Heading
													level={_windowSize?.width < 600 ? 6 : 5}
													isDarkTheme={_isDarkTheme}
													pointer
													onClick={() => {
														_navigate("/customer-login")
													}}>
													Customer Sign In
												</Heading>
											</Flexed>
										</Col>
									</Row>
								</Container>
							</form>
							<Spacer height={2} />
						</Col>
						<Col xxl={4} xl={4} lg={5} md={10} lgOrder={2} smOrder={1} xsOrder={1}>
							<Container fluid>
								<Row>
									<Col lg={6} md={6} sm={6}>
										<Flexed direction="row" justify="space-between" align="center" margin="0rem 0rem 0.19rem 0rem" style={{width: "17rem"}}>
											<Text type="normal" fontWeight="500" isDarkTheme={_isDarkTheme}>
												Profile Image <Mandatory>*</Mandatory>
											</Text>
											<Text fontSize={0.7} type="small" color="danger">
												{imageError}
											</Text>
										</Flexed>
										<Flexed direction="row">
											<ProfileImgCover htmlFor="faceImage">
												<ProfileImg isDarkTheme={_isDarkTheme} url={image} />
												{(image === "" || image === null || image === undefined) && (
													<UploadImageText isDarkTheme={_isDarkTheme}>
														<MdOutlineCloudUpload color={_isDarkTheme ? palette.light_gray : palette.gray} fontSize={24} />
														Upload Image
													</UploadImageText>
												)}
											</ProfileImgCover>
										</Flexed>
										<Flexed direction="row" justify="center">
											<input accept="image/*" style={{display: "none"}} id="faceImage" type="file" onChange={handleCapture} />
										</Flexed>
										<Spacer height={1} />
									</Col>
									<Col lg={12} md={6} sm={6}>
										<Flexed>
											<Text type="normal" fontWeight="500" isDarkTheme={_isDarkTheme} margin="0rem 0rem 0.19rem 0rem">
												Location <Mandatory>*</Mandatory>
											</Text>
											{isLoaded ? (
												<GoogleMap
													mapContainerStyle={containerStyle}
													zoom={center.zoom}
													center={center}
													onLoad={(map: any) => {
														setMap(map)
													}}
													options={{
														mapTypeControl: false,
														fullscreenControl: false,
														streetViewControl: false,
													}}>
													<MarkerF
														position={{lat: parseFloat(clat), lng: parseFloat(clng)}}
														icon={{
															// url: `/images/icons/marker.svg`,
															scaledSize: new window.google.maps.Size(40, 40),
															// @ts-ignore
															shape: {coords: [17, 17, 18], type: "circle"},
															optimized: false,
														}}
													/>
												</GoogleMap>
											) : (
												<Text isDarkTheme={_isDarkTheme}>Loading</Text>
											)}
										</Flexed>
										<Spacer height={1} />
									</Col>
								</Row>
							</Container>
						</Col>
					</Row>
				) : (
					<CustomerOTPVerification phone={phone} dataForSignIn={dataForSignIn} setIsLoading={setIsLoading} />
				)}
			</MainWrapper>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const MainWrapper = styled(Container)`
	min-height: calc(100vh - 70px);
`

const Border = styled.div<IProps>`
	display: block;
	width: 100%;
	text-align: center;
	border-bottom: 0.063rem solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
`

const CustomText = styled(Text)`
	text-align: center;
	position: relative;
	top: 0.938rem;
	padding: 0 0.938rem;
	display: inline-block;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.light_black : palette.white)};
	color: ${palette.opacity.black};
	text-transform: capitalize;
`

const Label = styled.label<any>`
	margin: 0;
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-weight: 500;
`

const ProfileImgCover = styled.label<any>`
	width: 100%;
	position: relative;
	cursor: pointer;
	margin-bottom: 0;
	width: 17rem;
`

const ProfileImg = styled.div<any>`
	background: ${({url, defaultImage, isDarkTheme}) => (url ? `url(${url})` : defaultImage ? "" : isDarkTheme ? palette.light_silver : palette.silver)};
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	width: 17rem;
	height: 14.74rem;
	border-radius: 0.2rem;
	cursor: pointer;
`

const UploadImageText = styled(Text)<any>`
	position: absolute;
	text-align: center;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	gap: 0.2rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.gray)};
`

const Mandatory = styled.span`
	color: ${palette.danger};
`

export default SignUp
