import React from 'react';
import styled from 'styled-components'
import useWindowSize from '../../hooks/useWindowSize';

interface VimeoProps {
  videoUrl: string;
}

const extractVimeoId = (url: string): string | undefined => {
  const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?))/);
  return match?.[2];
};

const VimeoVideo: React.FC<VimeoProps> = ({ videoUrl }) => {
  const videoId = extractVimeoId(videoUrl);
  const _windowSize = useWindowSize()
  return (
    <Wrapper>
      <iframe
        src={`https://player.vimeo.com/video/${videoId}`}
        // control={0}
        width={_windowSize?.width > 620 ? '560' : _windowSize?.width > 520 ? '480' : _windowSize?.width > 434 ? '400' : _windowSize?.width > 400 ? '368' : _windowSize?.width > 355 ? '320' : '280'}
        height="315"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
    @media screen and (max-width: 619px) {
      background : #040505;
    }
`;

export default VimeoVideo;
