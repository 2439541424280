import React, {useEffect, useRef, useState} from 'react'
import {MdMic, MdMicNone} from 'react-icons/md'
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition'
import {palette} from '../../styled/colors'
import {useDispatch} from 'react-redux'
import {handleHomeBanerSearch} from '../../actions/authActions'
import useRouter from './UserRouterHook'
import {useSelector} from 'react-redux'

const VoiceRecognition = ({iconsColor, setText}: any) => {
	const {transcript, resetTranscript} = useSpeechRecognition()
	const [isRecording, setIsRecording] = useState(false)
	const _dispatch = useDispatch()
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const router = useRouter()

	const startRecording = () => {
		resetTranscript()
		SpeechRecognition.startListening()
		setIsRecording(true)
	}

	const stopRecording = () => {
		SpeechRecognition.stopListening()
		setIsRecording(false)
	}

	useEffect(() => {
		if (transcript && isRecording == false) {
			console.log('=====transcript', transcript)
			if (router.pathname == '/') {
				_dispatch(handleHomeBanerSearch(transcript))
				router.navigate('/post')
			} else {
				setText && setText(transcript)
			}
		}
	}, [transcript, isRecording])

	return (
		<div className="voice-recorder-container">
			{isRecording ? (
				<div className="recording-loader" onClick={stopRecording}>
					<MdMicNone className="mic-icon" style={{color: iconsColor ? palette.white : _isDarkTheme ? palette.white : ''}} />
					<div className="loader" />
				</div>
			) : (
				<>
					<MdMic className="mic-icon" style={{color: iconsColor ? palette.white : _isDarkTheme ? palette.white : ''}} onClick={startRecording} />
					<div className="loader-hide" />
				</>
			)}
		</div>
	)
}

export default VoiceRecognition
