import {GoogleGenerativeAI} from '@google/generative-ai'
import {toast} from 'react-toastify'
const genAI = new GoogleGenerativeAI(process.env.REACT_APP_AI_API_KEY ? process.env.REACT_APP_AI_API_KEY : 'AIzaSyA_1opl_8mbmqBU_pDWk74TvXgtq0EvX-s')

export const doGenerateAiResponse = async (_text: any, images?: any) => {
	try {
		if (_text) {
			const model = genAI.getGenerativeModel({model: images?.length || images ? 'gemini-pro-vision' : 'gemini-pro'})

			const result = await model.generateContent(images?.length ? [_text, ...images] : images ? [_text, images] : _text)
			const response = await result.response
			const text = response.text()
			return text
		}
	} catch (error: any) {
		if (error?.message) {
			if (error?.message == '[GoogleGenerativeAI Error]: Candidate was blocked due to RECITATION') {
				toast.error('Please try searching again by choosing a different image.')
			} else {
				toast.error(error?.message)
			}
		}
		console.log('error', error?.message)
	}
}

export const extractPriceFromResponse = (text: any) => {
	const priceRegex = /\$\d+(?:\.\d{1,2})?/g
	const priceKeywords = ['price', 'Price', 'cost', 'charge', 'fee', 'value', 'recommend', 'pkr', 'PKR', 'Rs.']
	for (const keyword of priceKeywords) {
		const index = text.indexOf(keyword)
		if (index !== -1) {
			const potentialPrice = text.substring(index + keyword.length).trim()
			const priceMatch = potentialPrice.match(priceRegex)
			if (priceMatch) {
				return priceMatch[0]
			}
		}
	}
	return null
}
