import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { Flexed, Heading, Loading, Spacer, Text } from '../../styled/shared'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useSelector, useDispatch } from 'react-redux'
import InputFieldAuth from '../../components/common/InputFieldAuth'
import Button from '../../components/common/Button'
import LogInGoogle from '../../components/common/LogInGoogle'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { api } from '../../components/api/callAxios'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { loginUserDetailsAction, loginBusinessDetailsAction } from '../../actions/authActions'
import BusinessOTPVerification from './BusinessOTPVerification'
import useWindowSize from '../../hooks/useWindowSize'

interface IProps {
    isDarkTheme?: boolean,
}

const LogIn = () => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);
    let _navigate = useNavigate();
    const _windowSize = useWindowSize()
    const _dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);
    const [showOtpInput, setShowOtpInput] = useState(false)
    const [phone, setPhone] = useState('')
    const [dataForSignIn, setDataForSignIn] = useState('')

    useEffect(() => {
        localStorage.removeItem('authorization')
        localStorage.removeItem('userStatus')
        _dispatch(loginUserDetailsAction({}))
        _dispatch(loginBusinessDetailsAction({}))
    }, [])

    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required('This field is required'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('This field is required'),
    });

    const { handleSubmit, setValue, getValues, formState, trigger,register } = useForm<any>({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const onSubmit = (data: any) => {
        setIsLoading(true)
        setDataForSignIn(data)
        api.post(`/owner/login`, data).then((res) => {
            if (res?.data?.status) {
                setIsLoading(false)
                _dispatch(loginUserDetailsAction(res?.data?.data?.user))
                _dispatch(loginBusinessDetailsAction(res?.data?.data?.user?.businessId))
                localStorage.setItem('authorization', res?.data?.data?.accessToken)
                localStorage.setItem('userStatus', res?.data?.data?.user_login)
                _navigate({
                    pathname: '/business-details',
                    search: createSearchParams({
                        id: res?.data?.data?.user?.businessId?.id
                    }).toString()
                })
            }
            else {
                    toast.error(res?.data?.messsage)
                    setPhone(res?.data?.data?.phone)
                    setShowOtpInput(true)
                    setIsLoading(false)
                }

        }).catch((e) => {
            if (e?.response) {
                toast.error(e?.response?.data?.message)
                setIsLoading(false)
            }
        })
    }

    return (
        <>
        <MainWrapper fluid>
        {!showOtpInput ?
            <Row justifyContent='center'>
                <Col xl={4} lg={6} md={8} sm={8} xs={11}>
                    <Spacer height={2} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Flexed justify="center">
                            <Heading level={_windowSize?.width < 600 ? 3 : 2} isCentered isDarkTheme={_isDarkTheme}>
                                Business Sign In
                            </Heading>

                            <Spacer height={2} />

                            {/* <LogInGoogle />

                            <Spacer height={1} />

                            <Border isDarkTheme={_isDarkTheme}>
                                <CustomText fontSize="1" lineHeight={2} isDarkTheme={_isDarkTheme}>
                                    Or Login With
                                </CustomText>
                            </Border> 

                            <Spacer height={2} /> */}
                            <Column lg={12}>
                                <InputFieldAuth label="Business Email" type="email" placeholder="Enter" name='email' register={register} required
                                    error={errors.email as any} />
                            </Column>
                            <Spacer height={1} />

                            <Column lg={12}>
                                <InputFieldAuth label="Password" type="password" placeholder="Password" name='password' register={register} required error={errors.password as any} />
                            </Column>
                            <Spacer height={1} />

                            <Column lg={12}>
                                <Flexed direction="row" justify="space-between" align="center">
                                    <Text textTransf="capitalize" type={_windowSize?.width < 600 ? 'small' : 'normal'} lineHeight={2} isDarkTheme={_isDarkTheme} pointer onClick={() => { _navigate('/business-forgotpassword') }}>
                                        Forgot password?
                                    </Text>
                                </Flexed>
                            </Column>
                            <Spacer height={1.5} />

                            <Column lg={12}>
                                <Button label="Sign In" width="100%" type='submit' />

                                <Spacer height={1} />

                                <Flexed direction={_windowSize?.width < 400 ? 'column' : 'row'} justify="center" gap={0.5} align="center">
                                    <Text textTransform='capitalize' type={_windowSize?.width < 600 ? 'small' : 'normal'} isDarkTheme={_isDarkTheme}>
                                        If you don’t have account?
                                    </Text>
                                    <Heading level={_windowSize?.width < 600 ? 6 : 5} isDarkTheme={_isDarkTheme} pointer onClick={() => { _navigate('/business-signup') }}>
                                        Business Sign Up
                                    </Heading>
                                </Flexed>
                            </Column>
                        </Flexed>
                    </form>
                    <Spacer height={2} />
                </Col>
                </Row>
                :
                <BusinessOTPVerification phone={phone} dataForSignIn={dataForSignIn} setIsLoading={setIsLoading} />
            }
        </MainWrapper >
        {isLoading && <Loading ><div className="dots-loading"></div></Loading>}
    </>
    )
}
const MainWrapper = styled(Container)`
	min-height: calc(100vh - 70px);
`

const Border = styled.div<IProps>`
	display: block;
	width: 100%;
	text-align: center;
	border-bottom: 0.063rem solid ${({ isDarkTheme }) => (isDarkTheme ? palette.light_silver : palette.silver)};
`

const CustomText = styled(Text)`
	text-align: center;
	position: relative;
	top: 0.938rem;
	padding: 0 0.938rem;
	display: inline-block;
	background: ${({ isDarkTheme }) => (isDarkTheme ? palette.light_black : palette.white)};
	color: ${palette.opacity.black};
	text-transform: capitalize;
`

const Column = styled(Col)`
	padding: 0rem;
`

export default LogIn
