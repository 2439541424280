import React, { useState } from 'react'
import styled from 'styled-components'
import { Flexed, Heading, Loading, Spacer, Text } from '../../styled/shared'
import { Row, Col } from 'styled-bootstrap-grid'
import Button from '../../components/common/Button'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import { loginUserDetailsAction } from '../../actions/authActions'
import VerificationInput from 'react-verification-input'
import { api } from '../../components/api/callAxios'
import useWindowSize from '../../hooks/useWindowSize'

const BusinessOTPVerification = ({ phone, dataForSignIn, setIsLoading }: any) => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);
    let _navigate = useNavigate();
    const _windowSize = useWindowSize()
    const _dispatch = useDispatch()
    const [otpCode, setOtpCode] = useState('')
    const [otpCodeError, setOtpCodeError] = useState('')

    const checkVerification = async () => {
        setIsLoading(true)
        await api
            .post(`/customers/verify_account`, {
                phone: phone,
                otp: parseInt(otpCode)
            })
            .then((res: any) => {
                if (res?.data?.status) {
                    toast.success(res?.data?.message)
                    api.post(`/auth/login`, { email: dataForSignIn?.email, password: dataForSignIn?.password, role: 'customer' }).then((res) => {
                        if (res?.data?.status) {
                            setIsLoading(false)
                            _dispatch(loginUserDetailsAction(res?.data?.data?.user))
                            localStorage.setItem('authorization', res?.data?.data?.accessToken)
                            localStorage.setItem('userStatus', res?.data?.data?.user_login)
                            // toast.success('Login successfully')
                            _navigate('/')
                        }
                    }).catch((e) => {
                        if (e?.response) {
                            toast.error(e?.response?.data?.message)
                            setIsLoading(false)
                        }
                    })
                }
                else {
                    setOtpCodeError(res?.data?.message)
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                toast.error(error.message)
                console.error(error)
            })
    }

    const resendOTP = async () => {
        setIsLoading(true)
        await api.post(`customers/resend_otp`, {
            phone: phone
        })
            .then((res: any) => {
                if (res?.data?.status) {
                    setOtpCode('')
                    toast.success(res?.data?.message)
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }

    return (
        <Row justifyContent='center'>
            <Col xxl={3} xl={5} lg={5} md={6}>
                <Spacer height={2} />
                <Heading level={_windowSize?.width < 600 ? 3 : 2} isCentered isDarkTheme={_isDarkTheme}>
                    Verification Code
                </Heading>
                <Spacer height={2} />
                <Text type="normal" isCentered fontWeight={500} color="dark_black">
                    Please enter the validation code that was sent to your email.
                </Text>
                <Spacer height={2} />
                <Flexed direction="row" justify="center">
                    <VerificationInput
                        autoFocus={true}
                        validChars="0-9"
                        classNames={{
                            character: 'character'
                        }}
                        onChange={(e: any) => {
                            setOtpCodeError('')
                            setOtpCode(e)
                        }}

                        value={otpCode}
                    />
                </Flexed>
                <div>
                    <Spacer height={0.5} />
                    <Text isCentered fontSize={0.7} type="small" color="danger">
                        {otpCodeError}
                    </Text>
                    <Spacer height={1} />
                </div>

                <Flexed direction="row" justify="center">
                    <Button
                        textTransformation
                        width="100%"
                        type="red"
                        disabled={otpCode.length < 6}
                        label="Verify Now"
                        ifClicked={() => {
                            checkVerification()
                        }}
                    />
                </Flexed>
                <ResendCode
                    pointer
                    type="normal"
                    margin="0.625rem 0rem 0rem 0rem"
                    textDecoration="underline"
                    fontWeight={700}
                    color="red"
                    onClick={() => {
                        resendOTP()
                    }}
                >
                    Resend Code
                </ResendCode>
            </Col>
        </Row>
    )
}

const ResendCode = styled(Text)`
	text-align: end;
`

export default BusinessOTPVerification
