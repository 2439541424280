import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { Flexed, Heading, Text } from '../../styled/shared'
import { useSelector } from 'react-redux'
import { BsXLg } from 'react-icons/bs'
import { verifyJSON } from '../../constants/commonFunctions'

const CartItemList = ({ item, removeItemCb,openCartModalHandler }: any) => {
	let photo = verifyJSON(item?.photo)
		? JSON.parse(item?.photo)
		: item?.photo;
	const [quantity, setQuantity] = useState(1)
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)

	useEffect(() => {
		setQuantity(item?.quantity)
	}, [item])
	return (
		<>
			<Wrapper isDarkTheme={_isDarkTheme}>
				<Cover onClick={openCartModalHandler}>
					<Image src={photo[0]?.startsWith('data:') ? photo[0] : process.env.REACT_APP_IMAGE_URL + photo[0]} />
				</Cover>
				<Content onClick={openCartModalHandler}>
					<div>
						<Name level={6} fontWeight={700} isDarkTheme={_isDarkTheme}>
							{item?.title}
						</Name>
						<Text type="normal" isDarkTheme={_isDarkTheme}>x{quantity}</Text>
						<Flexed gap={0.6} direction='row' flexWrap='wrap' margin='0.4rem 0rem 0rem 0rem'>
						{item?.soldItemsAddOns?.map((addOns: any) => {
							let addOnsphoto = verifyJSON(addOns?.photo)
							? JSON.parse(addOns?.photo)
							: addOns?.photo;
							return (
							<Flexed gap={0.1} direction='row' align='center'>
								<AddOnsImage src={process.env.REACT_APP_IMAGE_URL + addOnsphoto[0]} />
								<Text type='xsmall' fontWeight='500' isDarkTheme={_isDarkTheme}>
									x{addOns?.quantity}
								</Text>
							</Flexed>)
						})}
						</Flexed>
					</div>
					<Flexed gap={1} direction='row'>
						<Price gap={0.5} direction='row' justify='flex-end'>
							<Text type="normal" isDarkTheme={_isDarkTheme}>
								{'$' + parseFloat(item?.total)?.toFixed(2)}
							</Text>
						</Price>
					</Flexed>
				</Content>
				<DeleteItems>
						<CrossIcon isDarkTheme={_isDarkTheme} onClick={() => removeItemCb(item?.id,item?.title)} />
				</DeleteItems>
			</Wrapper>
		</>
	)
}

const Wrapper = styled.div<any>`
	display: flex;
	gap: 1rem;
	margin: 0 0 0.5rem 0;
	width: 100%;
	background: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
	overflow: hidden;
	box-shadow: ${palette.shadow};
	padding: 1rem;
	cursor: pointer;
	border-radius: 0.39rem;
	transition: all ease 0.25s;
	position: relative;
	&:hover {
		box-shadow: ${palette.shadowHover};
	}
`

const Cover = styled.div``

const Image = styled.img`
	width: 3rem;
	height: 3rem;
	object-fit: cover;
	border-radius: 0.39rem;
`

export const AddOnsImage = styled.img`
	width: 1.3rem;
	height: 1.3rem;
	object-fit: cover;
	border-radius: 0.39rem;
`

const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`

const DeleteItems = styled.div`
	background: ${palette.danger};
	width: 1rem;
	height: 1rem;
	border-radius: 2rem;
	position: absolute;
	right: 0.5rem;
	top: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
`

const CrossIcon = styled(BsXLg) <any>`
	font-size: 0.5rem;
	font-weight: bold;
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.silver : palette.white)};
`

const Price = styled(Flexed)`
	min-width:5rem;
`;

const Name = styled(Heading)`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
`;

export default CartItemList
