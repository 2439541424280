import React, {useState, useEffect} from "react"
import {Spacer, Flexed, Text, Loading} from "../../styled/shared"
import {Container, Row, Col} from "styled-bootstrap-grid"
import {palette} from "../../styled/colors"
import styled from "styled-components"
import InputField from "../../components/common/InputField"
import Button from "../../components/common/Button"
import {MdOutlineCloudUpload} from "react-icons/md"
import {useSelector, useDispatch} from "react-redux"
import {api} from "../../components/api/callAxios"
import {toast} from "react-toastify"
import InputAddress from "../../components/common/InputAddress"
import {useNavigate} from "react-router-dom"
import {GoogleMap, useJsApiLoader, MarkerF} from "@react-google-maps/api"
import axios from "axios"
import {loginUserDetailsAction} from "../../actions/authActions"
import {verifyJSON} from "../../constants/commonFunctions"
import InputPhoneNo from "../../components/common/InputPhoneNo"
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.headers.post["Accept"] = "application/json"
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*"

const CustomerProfile = () => {
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	let _dispatch = useDispatch()
	const _navigate = useNavigate()
	const [userId, setUserId] = useState("")
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [email, setEmail] = useState("")
	const [image, setImage] = useState("")
	const [newImage, setNewImage] = useState("")
	const [phone, setPhone] = useState("")
	const [address, setAddress] = useState("")
	const [gender, setGender] = useState("")
	const [currentPassword, setCurrentPassword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [confirmPassword, setConfirmPassword] = useState("")
	const [isCurrentPassword, setIsCurrentPassword] = useState(false)
	const [showAddressInput, setShowAddressInput] = useState(false)

	const [isLoading, setIsLoading] = useState(false)

	const [map, setMap] = useState(null)
	const [clat, setCLat] = useState<any>("")
	const [clng, setCLng] = useState<any>("")

	const [firstNameError, setFirstNameError] = useState("")
	const [lastNameError, setLastNameError] = useState("")
	const [imageError, setImageError] = useState("")
	const [emailError, setEmailError] = useState("")
	const [phoneError, setPhoneError] = useState("")
	const [addressError, setAddressError] = useState("")
	const [newPasswordError, setNewPasswordError] = useState("")
	const [confirmPasswordError, setConfirmPasswordError] = useState("")

	useEffect(() => {
		setUserId(_loginUserDetails?.id)
		setFirstName(_loginUserDetails?.firstname)
		setLastName(_loginUserDetails?.lastname)
		setImage(_loginUserDetails?.photo ? process.env.REACT_APP_IMAGE_URL + _loginUserDetails?.photo : "")
		setEmail(_loginUserDetails?.email)
		setPhone(_loginUserDetails?.phone)
		setGender(_loginUserDetails?.gender)
		if (_loginUserDetails?.address) {
			let addressJson = _loginUserDetails?.address
			let addressObject = verifyJSON(addressJson) ? JSON.parse(addressJson) : addressJson
			setAddress(addressObject?.address)
			var fullAddress = addressObject?.address
			var address = fullAddress?.split("#")
			if (address[1]) {
				var coords = address[1]?.split(" ")
				getAddress(parseFloat(coords[1]), parseFloat(coords[2]))
			}
		}
		setShowAddressInput(true)
	}, [_loginUserDetails])

	const handleCapture = ({target}: any) => {
		const reader: any = new FileReader()
		reader.readAsDataURL(target.files[0])
		reader.onload = () => {
			if (reader.readyState === 2) {
				setImage(reader?.result)
				setNewImage(reader?.result)
			}
		}
	}

	const editUserDetails = () => {
		if (formValidation()) {
			setIsLoading(true)
			api.put(`/customers/${userId}`, {
				firstname: firstName,
				lastname: lastName,
				photo: newImage ? newImage : _loginUserDetails?.photo,
				email: email,
				phone: phone,
				address: {address: address},
				gender: gender,
			})
				.then(async (res) => {
					if (res.data) {
						setIsLoading(false)
						if (isCurrentPassword) {
							await UpdatePassword()
						} else {
							toast.success("User updated successfully.")
						}
						_dispatch(loginUserDetailsAction(res.data.data))
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const formValidation = () => {
		let isValid = true
		if (firstName === "") {
			setFirstNameError("first Name is required")
			isValid = false
		}
		if (lastName === "") {
			setLastNameError("last Name is required")
			isValid = false
		}
		if (email === "") {
			setEmailError("email is required")
			isValid = false
		}
		if (phone === "") {
			setPhoneError("phone number is required")
			isValid = false
		}
		if (address === "") {
			setAddressError("address is required")
			isValid = false
		}
		if (image === "") {
			setImageError("image is required")
			isValid = false
		}
		if (isCurrentPassword) {
			if (newPassword === "") {
				setNewPasswordError("Password must be at least 8 characters.")
				isValid = false
			}
			if (confirmPassword === "") {
				setConfirmPasswordError("Password must match")
				isValid = false
			}
			if (newPassword?.trim().length < 7) {
				setNewPasswordError("Password must be at least 8 characters.")
				isValid = false
			}
			if (newPassword != confirmPassword) {
				setConfirmPasswordError("Password must match")
				isValid = false
			} else {
				setConfirmPasswordError("")
			}
		}
		return isValid
	}

	const containerStyle = {
		width: "100%",
		height: "14.74rem",
	}

	const center = {
		lat: clat ? clat : 38.892708,
		lng: clng ? clng : -94.6426741,
		zoom: 15,
	}

	const {isLoaded} = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyAiSEHvjCeth6Gx8YDltGHUhzBl5ongDRk",
	})

	const getAddress = async (lat: any, lng: any) => {
		await getCurrentLatLng(lat, lng)
	}
	const getCurrentLatLng = async (lat: any, lng: any) => {
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				setCLat(lat)
				setCLng(lng)
				await getCurrentAddress(lat, lng)
			},
			function (error) {
				// eslint-disable-next-line eqeqeq
				if (error.code == error.PERMISSION_DENIED) {
				} else {
				}
			},
			{timeout: 5000, enableHighAccuracy: true}
		)
	}

	const getCurrentAddress = async (lat: any, lng: any) => {
		setCLat(lat)
		setCLng(lng)
		await axios
			.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(lat)},${parseFloat(lng)}&sensor=true&key=${process.env.REACT_APP_MAP_API_KEY}`)
			.then((response) => {})
			.catch((error) => {})
	}

	const matchCurrentPassword = () => {
		if (currentPassword.trim()?.length > 0) {
			setIsLoading(true)
			api.put(`/customers/match/password/${userId}`, {
				password: currentPassword,
			})
				.then((res) => {
					if (res?.data) {
						setIsCurrentPassword(true)
						toast.success("Your current password has been matched successfully.")
					} else {
						setIsCurrentPassword(false)
						toast.error("Your current password does not match.")
					}
					setIsLoading(false)
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}

	const UpdatePassword = () => {
		if (currentPassword.trim()?.length > 0) {
			setIsLoading(true)
			api.put(`/customers/update_customer_password/${userId}`, {
				password: newPassword,
			})
				.then((res) => {
					setIsLoading(false)
					if (res?.data) {
						toast.success("User updated successfully.")
						setCurrentPassword("")
						setNewPassword("")
						setConfirmPassword("")
						setIsCurrentPassword(false)
					}
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		}
	}
	return (
		<>
			<Container>
				<Spacer height={2} />
				<Row justifyContent="center">
					<Col lg={7} md={10} sm={12}>
						<Wrapper>
							<Row>
								<Col lg={6} md={6} sm={6}>
									<Flexed direction="row" justify="space-between" margin="0rem 0rem 0.19rem 0rem">
										<Text type="normal" fontWeight="500" isDarkTheme={_isDarkTheme}>
											Profile image <Mandatory>*</Mandatory>
										</Text>
										<Text fontSize={0.7} type="small" color="danger">
											{imageError}
										</Text>
									</Flexed>
									<Flexed direction="row">
										<ProfileImgCover htmlFor="faceImage">
											<ProfileImg isDarkTheme={_isDarkTheme} url={image} />
											{(image === "" || image === null || image === undefined) && (
												<UploadImageText isDarkTheme={_isDarkTheme}>
													<MdOutlineCloudUpload color={_isDarkTheme ? palette.light_gray : palette.gray} fontSize={24} />
													Upload Image
												</UploadImageText>
											)}
										</ProfileImgCover>
									</Flexed>
									<Flexed direction="row" justify="center">
										<input accept="image/*" style={{display: "none"}} id="faceImage" type="file" onChange={handleCapture} />
									</Flexed>
									<Spacer height={1} />
								</Col>
								<Col lg={6} md={6} sm={6}>
									<Text type="normal" fontWeight="500" isDarkTheme={_isDarkTheme} margin="0rem 0rem 0.19rem 0rem">
										location <Mandatory>*</Mandatory>
									</Text>
									{isLoaded ? (
										<GoogleMap
											mapContainerStyle={containerStyle}
											zoom={center.zoom}
											center={center}
											onLoad={(map: any) => {
												setMap(map)
											}}
											options={{
												mapTypeControl: false,
												fullscreenControl: false,
												streetViewControl: false,
											}}>
											<MarkerF
												position={{
													lat: parseFloat(clat),
													lng: parseFloat(clng),
												}}
												icon={{
													// url: `/images/icons/marker.svg`,
													scaledSize: new window.google.maps.Size(40, 40),
													// @ts-ignore
													shape: {coords: [17, 17, 18], type: "circle"},
													optimized: false,
												}}
											/>
										</GoogleMap>
									) : (
										<Text isDarkTheme={_isDarkTheme}>Loading</Text>
									)}
									<Spacer height={1} />
								</Col>
								{showAddressInput && <Col lg={12}>
									<InputAddress
										label="Address"
										required
										address={address}
										setCLat={setCLat}
										setCLng={setCLng}
										isEdit={true}
										setAddress={(address: any) => {
											setAddressError("")
											if (address == "") {
												setAddressError("address is required")
											}
											setAddress(address)
										}}
										error={addressError}
									/>
									<Spacer height={1} />
								</Col>}
								<Col lg={6} md={6}>
									<InputField
										error={firstNameError}
										label="First Name"
										required
										value={firstName}
										handleChange={(e: any) => {
											setFirstNameError("")
											if (e == "") {
												setFirstNameError("name is required")
											}
											setFirstName(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6} md={6}>
									<InputField
										error={lastNameError}
										label="Last Name"
										value={lastName}
										required
										handleChange={(e: any) => {
											setLastNameError("")
											if (e == "") {
												setLastNameError("last name is required")
											}
											setLastName(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6} md={6}>
									<InputField
										disabled
										error={emailError}
										label="Email"
										value={email}
										required
										handleChange={(e: any) => {
											const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
											setEmailError("")
											if (e === "") {
												setEmailError("email is required")
											} else if (!regex.test(e.value)) {
												setEmailError("invalid email")
											}
											setEmail(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6} md={6}>
									<InputPhoneNo
										error={phoneError}
										label="Phone"
										required
										value={phone}
										handleChange={(e: any) => {
											setPhoneError("")
											if (e?.value == "") {
												setPhoneError("phone number is required")
											}
											setPhone(e?.value)
										}}
									/>

									<Spacer height={1} />
								</Col>
								<Col lg={6} md={6}>
									<InputField
										disabled={isCurrentPassword}
										type="password"
										required={isCurrentPassword}
										label="Current Password"
										placeholder="Current Password"
										value={currentPassword}
										handleChange={(e: any) => {
											setCurrentPassword(e)
										}}
										onBlur={() => matchCurrentPassword()}
									/>
									<Spacer height={1} />
								</Col>

								<Col lg={6} md={6}>
									<InputField
										disabled={!isCurrentPassword}
										type="password"
										required={isCurrentPassword}
										label="New Password"
										placeholder="New Password"
										error={newPasswordError}
										value={newPassword}
										handleChange={(e: any) => {
											setNewPasswordError("")
											if (newPassword == "") {
												setNewPasswordError("Password must be at least 8 characters.")
											}
											if (newPassword?.trim().length < 7) {
												setNewPasswordError("Password must be at least 8 characters.")
											}
											setNewPassword(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col lg={6} md={6}>
									<InputField
										disabled={!isCurrentPassword}
										type="password"
										required={isCurrentPassword}
										error={confirmPasswordError}
										label="Confirm Password"
										placeholder="Confirm Password"
										value={confirmPassword}
										handleChange={(e: any) => {
											if (e?.length < 7) {
												setConfirmPasswordError("password must be at least 8 characters.")
											} else if (e !== newPassword) {
												setConfirmPasswordError("password must match")
											} else {
												setConfirmPasswordError("")
											}
											setConfirmPassword(e)
										}}
									/>
									<Spacer height={1} />
								</Col>
								<Col>
									<Flexed direction="row" gap={2}>
										<Text type="normal" fontWeight={500} isDarkTheme={_isDarkTheme}>
											Gender:
										</Text>
										<Flexed direction="row" gap={2}>
											<Flexed direction="row" align="center" gap={0.5}>
												<input name="male" type="radio" checked={gender === "male"} onChange={() => setGender("male")} />
												<Label htmlFor="gender" isDarkTheme={_isDarkTheme}>
													Male
												</Label>
											</Flexed>
											<Flexed direction="row" align="center" gap={0.5}>
												<input name="female" type="radio" checked={gender === "female"} onChange={() => setGender("female")} />
												<Label htmlFor="gender" isDarkTheme={_isDarkTheme}>
													Female
												</Label>
											</Flexed>
										</Flexed>
									</Flexed>
								</Col>

								{/* <Col lg={12}>
                                <Spacer height={0.5} />
                                {isLoaded ? (
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        zoom={center.zoom}
                                        center={center}
                                        onLoad={(map: any) => {
                                            setMap(map);
                                        }}
                                        options={{
                                            mapTypeControl: false,
                                            fullscreenControl: false,
                                            streetViewControl: false,
                                        }}>
                                        <MarkerF
                                            position={{
                                                lat: parseFloat(clat),
                                                lng: parseFloat(clng),
                                            }}
                                            icon={{
                                                // url: `/images/icons/marker.svg`,
                                                scaledSize: new window.google.maps.Size(40, 40),
                                                // @ts-ignore
                                                shape: { coords: [17, 17, 18], type: 'circle' },
                                                optimized: false,
                                            }}
                                        />
                                    </GoogleMap>
                                ) : (
                                    <Text isDarkTheme={_isDarkTheme}>Loading</Text>
                                )}
                            </Col> */}
							</Row>
						</Wrapper>
					</Col>

					<Col lg={7} md={10} order={3}>
						<Wrapper>
							<Row>
								<Col>
									<Spacer height={1.5} />
									<Flexed direction="row" justify="center" gap={1}>
										<Button
											medium
											width="100%"
											label="Cancel"
											type="cancel"
											ifClicked={() => {
												_navigate("/")
											}}
										/>
										<Button medium width="100%" type="red" label="Save" ifClicked={editUserDetails} />
									</Flexed>
									<Spacer height={1} />
								</Col>
							</Row>
						</Wrapper>
					</Col>
				</Row>
			</Container>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const Wrapper = styled(Container)`
	padding: 0;
`

const ProfileImgCover = styled.label<any>`
	position: relative;
	cursor: pointer;
`

const ProfileImg = styled.div<any>`
	background: ${({url, defaultImage}) => (url ? `url(${url})` : defaultImage ? "" : palette.silver)};
	background-repeat: no-repeat;
	background-size: cover;
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
	width: 15rem;
	height: 15rem;
	border-radius: 0.2rem;
	cursor: pointer;
`

const UploadImageText = styled(Text)<any>`
	position: absolute;
	text-align: center;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	gap: 0.2rem;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: auto;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.gray)};
`

const UploadImg = styled.div<any>`
	border-radius: 0.25rem;
	padding: 0.65rem 1rem;
	font-size: ${({small}) => (small ? "0.75rem" : "0.875rem")};
	height: ${({small, medium}) => (small ? "2rem" : medium ? "2.5rem" : "3.125rem")};
	min-width: 6rem;
	background: ${palette.blue};
	cursor: ${({disabled}) => (disabled ? "no-drop" : "pointer")};
	text-transform: uppercase;
	color: ${palette.white};
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
`

const Label = styled.label<any>`
	margin: 0;
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-weight: 500;
`

const Mandatory = styled.span`
	color: ${palette.danger};
`

export default CustomerProfile
