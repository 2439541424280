import React, {useState, useEffect} from "react"
import {Flexed, Heading, Spacer, Loading, Text} from "../../styled/shared"
import {Container, Row, Col, media} from "styled-bootstrap-grid"
import styled from "styled-components"
import {api} from "../../components/api/callAxios"
import {useSelector, useDispatch} from "react-redux"
import {palette} from "../../styled/colors"
import DeleteAccountModal from "../../components/modals/DeleteAccountModal"
import {loginBusinessDetailsAction, loginUserDetailsAction} from "../../actions/authActions"
import {useNavigate} from "react-router-dom"
import {toast} from "react-toastify"

const CustomerSettings = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	let _dispatch = useDispatch()
	const _navigate = useNavigate()
	const [isLoading, setIsLoading] = useState<any>(false)
	const [search, setSearch] = useState<any>("")
	const [deleteAccount, setDeleteAccount] = useState(false)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)

	const doDeleteAccount = () => {
		setIsLoading(true)
		api.delete(`/check-out/${_loginUserDetails?.id}`)
			.then((res) => {
				setIsLoading(false)
				setDeleteAccount(false)
				localStorage.removeItem("authorization")
				localStorage.removeItem("userStatus")
				_dispatch(loginUserDetailsAction({}))
				_dispatch(loginBusinessDetailsAction({}))
				_navigate("/")
				toast.success("Account deleted successfully.")
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	return (
		<>
			<MainWrapper>
				<Spacer height={1} />
				<Row justifyContent="center">
					<Col xl={7} lg={10}>
						<DeleteAccountContainer>
							<DeleteAccountWrapper>
								<Text type="medium" margin="0rem 0rem 1rem 0rem" fontWeight={700} isDarkTheme={_isDarkTheme}>
									Delete this account
								</Text>
							</DeleteAccountWrapper>
							<Text type="normal" margin="0rem 0rem 1rem 0rem" fontWeight={500} isDarkTheme={_isDarkTheme}>
								Are you sure you want to delete your account?
							</Text>
							<DeleteButton onClick={() => setDeleteAccount(true)}>
								<DeleteButtonText>Yes, delete my account</DeleteButtonText>
							</DeleteButton>

							<Spacer height={1} />

							{/* <MoreInfoText type="normal" pointer isDarkTheme={_isDarkTheme}>
								See more info
							</MoreInfoText> */}
						</DeleteAccountContainer>
					</Col>
				</Row>
				<Spacer height={2} />
			</MainWrapper>
			{deleteAccount && <DeleteAccountModal open={deleteAccount} onCloseModal={() => setDeleteAccount(false)} deleteAction={() => doDeleteAccount()} />}

			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const MainWrapper = styled(Container)`
	min-height: calc(100vh - 70px);
`

const DeleteAccountContainer = styled.div`
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 8px;
	margin: 0px;
`

const DeleteAccountWrapper = styled.div`
	margin-bottom: 15px;
	border-bottom: 1px solid #ccc;
`

const DeleteButton = styled.button<any>`
	background-color: ${palette.danger};
	padding: 10px 15px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
`

const DeleteButtonText = styled.span<any>`
	font-size: 16px;
	color: ${palette.white};
	font-weight: 500;
`

export default CustomerSettings
