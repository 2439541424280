import React, { useState } from 'react';
import { Spacer, Flexed, Loading, Text } from '../../styled/shared';
import { Container, Row, Col,media } from 'styled-bootstrap-grid';
import { palette } from '../../styled/colors';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import EditRestaurantInfo from './EditRestaurantInfo';
import EditRestaurantTimings from './EditRestaurantTimings';
import { IoIosArrowRoundBack } from 'react-icons/io'
import EditRestaurantSettings from './EditRestaurantSettings';

const EditRestaurantDetails = ({ activeTab, setActiveEditTab, editRestaurant, onClose }: any) => {
    const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
    const [isLoading, setIsLoading] = useState(false);
    return (<>
        <MainWrapper>
            <Spacer height={0.5} />
            <Row justifyContent='center'>
                <Col xl={7} lg={10}>
                    <GoBack pointer direction='row' align='center' onClick={onClose}>
                        <IoIosArrowRoundBack fontSize='1.8rem' />
                        <Text type='normal' fontWeight={500}>Back</Text>
                    </GoBack></Col>
                <Col xl={7} lg={10}>
                    <TabsWrapper justify='center' gap={0.5} >
                        <Tabs active={activeTab === 'editRestaurant'} onClick={() => setActiveEditTab('editRestaurant')} isDarkTheme={_isDarkTheme}>Edit Business info</Tabs>
                        <Tabs active={activeTab === 'editRestaurantTimings'} onClick={() => setActiveEditTab('editRestaurantTimings')} isDarkTheme={_isDarkTheme}>Edit Business Times</Tabs>
                        <Tabs active={activeTab === 'editRestaurantSettings'} onClick={() => setActiveEditTab('editRestaurantSettings')} isDarkTheme={_isDarkTheme}>Edit Business Settings</Tabs>
                    </TabsWrapper>
                </Col>
            </Row>
            <Spacer height={2} />
            {activeTab === 'editRestaurant' &&
                <EditRestaurantInfo setIsLoading={setIsLoading} editRestaurant={editRestaurant} onClose={onClose} />
            }
            {activeTab === 'editRestaurantTimings' &&
                <EditRestaurantTimings setIsLoading={setIsLoading} editRestaurant={editRestaurant} onClose={onClose} />
            }
            {activeTab === 'editRestaurantSettings' &&
                <EditRestaurantSettings setIsLoading={setIsLoading} editRestaurant={editRestaurant} onClose={onClose} />
            }
        </MainWrapper>
        {isLoading && <Loading ><div className="dots-loading"></div></Loading>}
    </>
    );
};

const MainWrapper = styled(Container)`
	min-height: calc(100vh - 70px);
`

const Tabs = styled.div<any>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    min-width: 6rem;
    width: 100%;
    border: 0.07rem solid
        ${({ active }) => (active ? palette.red : palette.silver)};
    color: ${({ active }) => (active ? palette.white : palette.dark_gray)};
    background: ${({ active }) => (active ? palette.red : palette.white)};
    cursor: pointer;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    font-weight: 600;
`;

const GoBack = styled(Flexed)`
	width:fit-content;
    color: ${palette.red} ;
    &:hover{
        color: ${palette.red_hover};
    }
    & div{
        color: ${palette.red} ;
    }
    & div:hover{
        color: ${palette.red_hover} ;
    }
`;

const TabsWrapper = styled(Flexed)`
${media.md`flex-direction:row;`}
`

export default EditRestaurantDetails;
