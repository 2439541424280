import React, { useState, useEffect, ChangeEvent } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { Text, Spacer, Flexed, Loading } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { BsXLg } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { media } from 'styled-bootstrap-grid'
import axios from 'axios'
import InputField from '../common/InputField'
import { api } from '../api/callAxios'
import Button from '../common/Button'
import { Rating } from 'react-simple-star-rating'
import { Document, Page, pdfjs } from 'react-pdf';
import { toast } from 'react-toastify'
import { MdOutlineCloudUpload } from 'react-icons/md';
import { useDispatch } from 'react-redux'
import { loginBusinessDetailsAction } from '../../actions/authActions'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

interface ICategoriesModalProps {
    open: boolean
    onCloseModal: any
}

interface IProps {
    isDarkTheme: boolean
}

const BusinessClaimModal = ({ open, onCloseModal }: ICategoriesModalProps) => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);
    const _loginBusinessDetails: any = useSelector<any>((state: any) => state.auth.loginBusinessDetails)
    let _dispatch = useDispatch()
    const [idCardFrontImg, setIdCardFrontImg] = useState('');
    const [idCardBackImg, setIdCardBackImg] = useState('');
    const [pdfFiles, setPdfFiles] = useState<string[]>([]);
    const [numPages, setNumPages] = useState(0);
    const [description, setDescription] = useState<any>('')
    const [isLoading, setIsLoading] = useState<any>(false)

    const [idCardFrontImgError, setIdCardFrontImgError] = useState('');
    const [idCardBackImgError, setIdCardBackImgError] = useState('');
    const [pdfFileError, setPdfFileError] = useState('')

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    useEffect(() => {
        if (Object.keys(_loginBusinessDetails).length != 0 && _loginBusinessDetails?.govCertificate?.length > 0 && _loginBusinessDetails?.IdCardFrontImage && _loginBusinessDetails?.IdCardBackImage) {
            setIdCardFrontImg(_loginBusinessDetails?.IdCardFrontImage)
            setIdCardBackImg(_loginBusinessDetails?.IdCardBackImage)
            setPdfFiles(_loginBusinessDetails?.govCertificate)
            setDescription(_loginBusinessDetails?.claimDescription)
        }
    }, [_loginBusinessDetails])

    const handleIdCardFrontImage = ({ target }: any) => {
        const reader: any = new FileReader()
        reader.readAsDataURL(target.files[0])
        reader.onload = () => {
            if (reader.readyState === 2) {
                setIdCardFrontImgError('')
                setIdCardFrontImg(reader?.result)
            }
        }
    }

    const handleIdCardBackImage = ({ target }: any) => {
        const reader: any = new FileReader()
        reader.readAsDataURL(target.files[0])
        reader.onload = () => {
            if (reader.readyState === 2) {
                setIdCardBackImgError('')
                setIdCardBackImg(reader?.result)
            }
        }
    }

    // const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    // const files = event.target.files;
    // if (files) {
    //     const filesArray = Array.from(files);
    //     const validFiles = filesArray.filter(
    //         (file) => file.type === 'application/pdf'
    //         // && file.size <= 10 * 1024 * 1024 // Max size: 10MB
    //     );

    //     if (validFiles.length + pdfFiles.length <= 2) {
    //         setPdfFileError('')
    //         setPdfFiles([...pdfFiles, ...validFiles.slice(0, 2 - pdfFiles.length)]);
    //     } else {
    //         toast.warning('You can upload a maximum of 2 PDF files.');
    //     }
    // }
    // };
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const filesArray = Array.from(files).filter(
                (file) => file.type === 'application/pdf' && file.size <= 10 * 1024 * 1024 // Max size: 10MB
            );

            const promises: Promise<string>[] = [];
            filesArray.slice(0, 2 - pdfFiles.length).forEach((file) => {
                promises.push(
                    new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.onload = () => {
                            const base64String = reader.result?.toString();
                            if (base64String) {
                                resolve(base64String);
                            } else {
                                resolve('');
                            }
                        };
                        reader.readAsDataURL(file);
                    })
                );
            });
            Promise.all(promises)
                .then((base64Strings) => {
                    setPdfFileError('')
                    setPdfFiles((prevFiles) => [...prevFiles, ...base64Strings.filter((base64) => !!base64)]);
                })
                .catch((error) => {
                    setPdfFileError(error)
                    console.error('Error reading files:', error);
                });
        }
    };


    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };

    const deleteSelectPDFFile = (index: any) => {
        const _deleteFile = pdfFiles?.filter((value, ind) => {
            return ind !== index
        })
        setPdfFiles(_deleteFile)
    }

    const sendClaimRequest = () => {
        if (formValidation()) {
            setIsLoading(true)
            api.put(`/business/add_claim/${_loginBusinessDetails?.id}`, {
                IdCardFrontImage: idCardFrontImg,
                IdCardBackImage: idCardBackImg,
                govCertificate: pdfFiles,
                claimDescription: description,
            })
                .then((res) => {
                    _dispatch(loginBusinessDetailsAction(res?.data))
                    toast.success('Your documents uploaded successfully.')
                    setIsLoading(false)
                    onCloseModal()
                }).catch(function (error: any) {
                    console.log(error)
                    setIsLoading(false)
                })
        }
        else {
            setIsLoading(false)
        }
    }

    const formValidation = () => {
        let isValid = true
        if (idCardFrontImg === '') {
            setIdCardFrontImgError('Fornt image is required.')
            isValid = false
        }
        if (idCardBackImg === '') {
            setIdCardBackImgError('Back image is required.')
            isValid = false
        }
        if (pdfFiles?.length === 0) {
            setPdfFileError('Documents are required.')
            isValid = false
        }

        return isValid
    }

    return (
        <>
            <Modal
                open={open}
                center
                onClose={() => {
                    onCloseModal()
                }}
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'blockModal'
                }}
                showCloseIcon={false}
                // @ts-ignore
                styles={{ zIndex: '2' }}>
                <ModalWrapper isDarkTheme={_isDarkTheme}>
                    <Head direction="row" align="center" justify="space-between" gap={1}>
                        <Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
                            Claim your business
                        </Text>
                        <CrossIcon
                            isDarkTheme={_isDarkTheme}
                            onClick={() => {
                                onCloseModal()
                            }}
                        />
                    </Head>
                    <Body>
                        <Spacer height={0.5} />
                        <Text type="normal" margin="0rem 0rem 0.5rem 0rem" fontWeight={500} isDarkTheme={_isDarkTheme}>Owner id card <Mandatory>*</Mandatory></Text>
                        <Flexed direction="row" flexWrap='wrap' gap={0.5}>
                            <div>
                                {idCardFrontImg ? <ImgWrapper>
                                    <ShowImage src={idCardFrontImg?.startsWith('data:') ? idCardFrontImg : process.env.REACT_APP_IMAGE_URL + idCardFrontImg} />
                                    <DeleteImage onClick={() => { setIdCardFrontImg('') }} isDarkTheme={_isDarkTheme} />
                                </ImgWrapper>
                                    :
                                    <InputWrapper>
                                        <ImageUpload>
                                            <MdOutlineCloudUpload color={_isDarkTheme ? palette.light_gray : palette.gray} fontSize={24} />
                                            <Text type='xsmall' isDarkTheme={_isDarkTheme}>Front Image</Text>
                                            <FileInput
                                                id="faceImage"
                                                accept="image/*"
                                                type="file"
                                                onChange={(e) => {
                                                    handleIdCardFrontImage(e)
                                                }}
                                            />
                                        </ImageUpload>
                                    </InputWrapper>
                                }
                                {idCardFrontImgError && <Text fontSize={0.7} type="small" color="danger">{idCardFrontImgError}</Text>}
                            </div>
                            <div>
                                {idCardBackImg ? <ImgWrapper>
                                    <ShowImage src={idCardBackImg?.startsWith('data:') ? idCardBackImg : process.env.REACT_APP_IMAGE_URL + idCardBackImg} />
                                    <DeleteImage onClick={() => { setIdCardBackImg('') }} isDarkTheme={_isDarkTheme} />
                                </ImgWrapper>
                                    :
                                    <InputWrapper>
                                        <ImageUpload>
                                            <MdOutlineCloudUpload color={_isDarkTheme ? palette.light_gray : palette.gray} fontSize={24} />
                                            <Text type='xsmall' isDarkTheme={_isDarkTheme}>Back Image</Text>
                                            <FileInput
                                                id="faceImage"
                                                accept="image/*"
                                                type="file"
                                                onChange={(e) => {
                                                    handleIdCardBackImage(e)
                                                }}
                                            />
                                        </ImageUpload>
                                    </InputWrapper>
                                }
                                {idCardBackImgError && <Text fontSize={0.7} type="small" color="danger">{idCardBackImgError}</Text>}
                            </div>
                        </Flexed>
                        <Spacer height={1} />

                        <Text type="normal" margin="0rem 0rem 0.5rem 0rem" fontWeight={500} isDarkTheme={_isDarkTheme}>Upload Govt issued PDF documents <Mandatory>*</Mandatory></Text>
                        <Flexed direction="row" flexWrap='wrap' gap={0.5}>
                            {pdfFiles?.map((file: any, index: any) => (
                                <div>
                                    <PDFWrapper key={index}>
                                        <Document
                                            file={file.startsWith('data:')? file : process.env.REACT_APP_IMAGE_URL + file}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                        >
                                            <Page pageNumber={1} width={160} height={80} />
                                        </Document>
                                        <DeleteImage onClick={() => { deleteSelectPDFFile(index) }} isDarkTheme={_isDarkTheme} />
                                    </PDFWrapper>
                                    {/* <Text type='xsmall' isDarkTheme={_isDarkTheme}>{file.name}</Text> */}
                                    {/* <Text type='xsmall' isDarkTheme={_isDarkTheme}>File Size: {(file.size / (1024 * 1024)).toFixed(2)} MB</Text> */}
                                </div>
                            ))}
                            {pdfFiles?.length < 2 && (
                                <PDFWrapper>
                                    <Upload>
                                        <MdOutlineCloudUpload color={_isDarkTheme ? palette.light_gray : palette.gray} fontSize={24} />
                                        <Text type='xsmall' isDarkTheme={_isDarkTheme} isCentered>Upload PDF File</Text>
                                        <FileInput type="file" accept=".pdf" multiple onChange={handleFileChange} />
                                    </Upload>
                                </PDFWrapper>
                            )}
                        </Flexed>
                        {pdfFileError && <Text fontSize={0.7} type="small" color="danger">{pdfFileError}</Text>}
                        <Spacer height={1} />

                        <InputField type='textarea' placeholder='Type here' label='Description' value={description} handleChange={(e: any) => {
                            if (e?.length <= 200) {
                                setDescription(e)
                            }
                        }} />
                        <Flexed direction='row' justify='flex-end'>
                            <Text isDarkTheme={_isDarkTheme} type='xsmall'>{description?.length}/500</Text>
                        </Flexed>

                        <Spacer height={1.5} />

                        <Flexed direction='row' align='center' justify='space-between' gap={1}>
                            <Button small width='100%' type='red' label='Save' ifClicked={() => sendClaimRequest()} />
                        </Flexed>
                    </Body>
                </ModalWrapper>
            </Modal>
            {isLoading && <Loading ><div className="dots-loading"></div></Loading>}
        </>
    )
}
const Mandatory = styled.span`
	color: ${palette.danger};
`

const ModalWrapper = styled.div<IProps>`
	background-color: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
    padding: 1rem;
`

const Head = styled(Flexed)`
	padding: 1rem;
    ${media.sm`
    width: 400px;
	`}
    ${media.md`
    width: 400px;
	`}
`

const Body = styled.div`
padding: 0rem 1rem 1rem;
`

const CrossIcon = styled(BsXLg) <IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

const ImgWrapper = styled.div`
	position: relative;
	width: 110px;
    // height: 60px;
	margin-bottom: 0.5rem;
    ${media.sm`
    width: 140px;
    // height: 60px;
	`}
    ${media.md`
    width: 160px;
    // height: 80px;
	`}
`

const ShowImage = styled.img`
    width: 110px;
    // height: 60px;
	object-fit: cover;
    aspect-ratio: 3/1.9;
	border: 0.063rem dashed ${palette.light_gray};
    ${media.sm`
    width: 140px;
    // height: 60px;
	`}
    ${media.md`
    width: 160px;
    // height: 80px;
	`}
`

const InputWrapper = styled.div`
	position: relative;
	width: 110px;
    // height: 60px;
	margin-bottom: 0.5rem;
    ${media.sm`
    width: 140px;
    // height: 60px;
	`}
    ${media.md`
    width: 160px;
    // height: 80px;
	`}
`

const ImageUpload = styled.label`
	padding: 0.5rem;
	width: 110px;
    aspect-ratio: 3/1.9;
    // height: 60px;
	border: 0.063rem dashed ${palette.light_gray};
	border-radius: 0.1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	flex-direction: column;
	cursor: pointer;
    ${media.sm`
    padding: 0.5rem;
    width: 140px;
    // height: 60px;
	`}
    ${media.md`
    padding: 1rem;
    width: 160px;
    // height: 80px;
	`}
`

const PDFWrapper = styled.div`
	position: relative;
	width: 110px;
    height: 140px;
	margin-bottom: 0.5rem;
    border: 0.063rem dashed ${palette.light_gray};
    border-radius: 0.1rem;
    overflow: hidden;
    ${media.sm`
    width: 140px;
    height: 160px;
	`}
    ${media.md`
    width: 160px;
    height: 180px;
	`}
`

const Upload = styled.label`
	padding: 0.5rem;
	width: 110px;
    height: 140px;
	// border: 0.063rem dashed ${palette.light_gray};
	// border-radius: 0.1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	flex-direction: column;
	cursor: pointer;
    ${media.sm`
    padding: 0.5rem;
    width: 140px;
    height: 160px;
	`}
    ${media.md`
    padding: 1rem;
    width: 160px;
    height: 180px;
	`}
`

const FileInput = styled.input`
	display: none !important;
`

const DeleteImage = styled(BsXLg) <IProps>`
    cursor: pointer;
    z-index: 2;
    padding: 0.2rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? palette.white : palette.white)};
    font-size: 1.3rem;
    background: ${({ isDarkTheme }) => (isDarkTheme ? palette.danger : palette.danger)};
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 2rem;
    padding: 0.3rem;
    position: absolute;
    top: 0.25rem;
    right:0.25rem;
`

export default BusinessClaimModal
