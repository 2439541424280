import React, {useEffect, useState, forwardRef, useImperativeHandle} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Spacer, Flexed, Loading, Text, Divider} from '../../styled/shared'
import ProductCard from '../../components/products/ProductCard'
import FilterSideBar from '../../components/common/FilterSideBar'
import {useSelector, useDispatch} from 'react-redux'
import Button from '../../components/common/Button'
import {useSearchParams} from 'react-router-dom'
import {api} from '../../components/api/callAxios'
import {FiGrid} from 'react-icons/fi'
import {PiListBold} from 'react-icons/pi'
import useWindowSize from '../../hooks/useWindowSize'
import {palette} from '../../styled/colors'
import {handleHomeBanerSearch, isImageSearch, saveSearchImage} from '../../actions/authActions'
import {doGenerateAiResponse} from '../../components/api/AI_Api'

const Business = forwardRef(({sideBarRefresh, clearFilter, searchKeyword, searchLocation, sideFilters, setSideFilters, page, setPage, pageSize, pageNoSearch, setPageNoSearch, copyAdsSearchResults, setCopyAdsSearchResults}: any, ref) => {
	const mainAppSearch = async (keyWord: any, location: any, sideFilters: any, pageNoSearch: any) => {
		let _topRated = sideFilters?.sort === 'topRated' ? 4 : ''
		let _freeDelivery = sideFilters?.sort === 'freeDelivery' ? true : ''
		setIsLoading(true)
		setAIResponse('')
		keyWord = keyWord.charAt(0).toUpperCase() + keyWord.slice(1)
		await api
			.post(`/business/search_data`, {
				keywords: keyWord,
				location: location,
				categories: sideFilters?.category,
				topRated: _topRated,
				freeDelivery: _freeDelivery,
				page: pageNoSearch,
				pageSize: pageSize,
				businessTypeId: _id
			})
			.then(async (response) => {
				setIsLoading(false)
				if (response?.data?.business?.length == 0 && keyWord?.length >= 2) {
					await generateImageAiResponse(_searchedImage && [_searchedImage], keyWord)
					setAllBusinesses([])
					setLoadMoreForSearch(false)
					setLoadMore(false)
				} else if (response?.data?.business?.length == 0 && keyWord.length == 0 && location.length == 0 && sideFilters?.category.length == 0 && (sideFilters?.sort.length == 0 || sideFilters?.sort.length == undefined)) {
					if (copyAllBusinesses?.length != 0) {
						setAllBusinesses(copyAllBusinesses)
						setLoadMoreForSearch(false)
						setLoadMore(copyAllBusinesses?.length >= pageSize)
					} else {
						if (_searchFromHome === '' || _searchFromHome === null) {
							setPage(1)
							getAllBusinesses(_id, 1)
						}
					}
				} else {
					let searchData = response?.data?.business
					if (pageNoSearch > 1) {
						searchData = copyAdsSearchResults.concat(searchData)
					}
					setAllBusinesses(searchData)
					setCopyAdsSearchResults(searchData)
					setLoadMore(false)
					setLoadMoreForSearch(response?.data?.business?.length >= pageSize)
				}
				if (response?.data?.businessId) {
					setFilterBusinessIds(response?.data?.businessId)
				}
			})
			.catch(function (error: any) {
				console.log(error)
				setIsLoading(false)
			})
	}
	useImperativeHandle(ref, () => ({
		mainAppSearch
	}))
	const windowSize = useWindowSize()
	let _dispatch = useDispatch()
	const [view, setView] = useState<any>('block')
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _searchFromHome: any = useSelector<any>((state: any) => state.auth.search)
	const _searchedImage: any = useSelector<any>((state: any) => state.auth.saveSearchImage)
	const [openFiterMenu, setOpenFilterMenu] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [allBusinesses, setAllBusinesses] = useState([])
	const [copyAllBusinesses, setCopyAllBusinesses] = useState([])
	const [searchParams] = useSearchParams()
	let _id: any = searchParams.get('id')
	const [loadMore, setLoadMore] = useState(false)
	const [loadMoreForSearch, setLoadMoreForSearch] = useState(false)
	const [filterBusinessIds, setFilterBusinessIds] = useState<any>('')
	const [aiResponse, setAIResponse] = useState<any>('')
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (_id) {
			setAllBusinesses([])
			setCopyAllBusinesses([])
			setCopyAdsSearchResults([])
			setLoadMoreForSearch(false)
			setLoadMore(false)
			if (_searchFromHome === '' || _searchFromHome === null) {
				setPage(1)
				getAllBusinesses(_id, 1)
			}
			_dispatch(handleHomeBanerSearch(null))
		}
	}, [_id])

	const getAllBusinesses = async (businessTypeId: number, page: number) => {
		setIsLoading(true)
		setAIResponse('')
		await api
			.post(`/business/all_businesses/${businessTypeId}?page=${page}&pageSize=${pageSize}`)
			.then(async (response) => {
				let data = response?.data
				if (page > 1) {
					data = allBusinesses.concat(data)
				}
				setAllBusinesses(data)
				setCopyAllBusinesses(data)
				setLoadMore(response?.data?.length >= pageSize)
				setLoadMoreForSearch(false)
				setIsLoading(false)
				setLoading(false)
			})
			.catch(function (error) {
				console.log(error)
				setIsLoading(false)
				setLoading(false)
			})
	}

	const loadMoreData = () => {
		setPage(page + 1)
		getAllBusinesses(_id, page + 1)
	}

	const loadMoreSearchData = () => {
		setPageNoSearch(pageNoSearch + 1)
		mainAppSearch(searchKeyword, searchLocation, sideFilters, pageNoSearch + 1)
	}

	useEffect(() => {
		if (windowSize?.width < 576) {
			setView('block')
		}
	}, [windowSize?.width])

	const getToBusinessName = (name: any) => {
		switch (name) {
			case '1':
				return 'Food'
			case '2':
				return 'Medicine'
			case '3':
				return 'Transport'
			case '4':
				return 'E-commerce'
			case '5':
				return 'Pickup and Delivery'
			case '6':
				return 'Logistics'
			default:
				return 'Food'
		}
	}

	const generateImageAiResponse = async (images?: any, text?: any) => {
		try {
			setIsLoading(true)
			const _text = `Generate relevant website links based on the provided ${
				text ? 'text' + text : 'image'
			}, excluding contact information in descriptions. If no results found in listings, provide alternative website links with a message indicating that. Ensure details are valid and accurate. Give Response in html tags and title and h1 always should be 'unfortunately, no results were found. However, you can find the same ad on these sites.'.`
			let res: any = await doGenerateAiResponse(_text, images)
			if (res) {
				console.log('ressss', res)

				setLoading(false)
				setIsLoading(false)
				_dispatch(isImageSearch(false))
				_dispatch(saveSearchImage(''))
				if (res?.match(/```html([\s\S]*?)```/)) {
					const stringWithEmbeddedJSON = res

					const jsonMatch = stringWithEmbeddedJSON.match(/```html([\s\S]*?)```/)

					if (jsonMatch && jsonMatch[1]) {
						const extractedJSON = jsonMatch[1].trim()

						try {
							setAIResponse(extractedJSON)
							console.log('parsedObject:', extractedJSON)
						} catch (error) {
							console.error('Error parsing JSON:', error)
						}
					} else {
						console.error('No JSON found in the string.')
					}
				} else {
					setAIResponse(res)
				}
			}
		} catch (error) {
			setLoading(false)
			setIsLoading(false)
			console.log('error', error)
		}
	}

	return (
		<>
			<Container>
				<Wrapper>
					<Filter open={openFiterMenu}>
						<FilterSideBar
							sideBarRefresh={sideBarRefresh}
							clearFilter={clearFilter}
							onClose={() => setOpenFilterMenu(false)}
							filterBusinessIds={filterBusinessIds}
							setSideFilters={(filter: any) => {
								setSideFilters(filter)
								mainAppSearch(searchKeyword, searchLocation, filter, 1)
							}}
						/>
					</Filter>
					<InnerWrapper fluid>
						<FilterContent>
							<Col>
								<Spacer height={0.3} />
							</Col>
							<Col>
								<Button
									small
									type="red"
									label="Open Filter"
									ifClicked={() => {
										setOpenFilterMenu(!openFiterMenu)
									}}
								/>
								<Spacer height={0.3} />
							</Col>
						</FilterContent>
						<Spacer height={0.3} />
						<Row>
							<Col>
								<Flexed direction="row" align="center" gap={0.5} margin={'0rem 0rem 0.5rem 0rem'}>
									<Text pointer type={windowSize?.width < 575 ? 'xsmall' : 'normal'} fontWeight={700} isDarkTheme={_isDarkTheme}>
										{getToBusinessName(_id)}
									</Text>
								</Flexed>
							</Col>
							{allBusinesses?.length > 0 && (
								<Col>
									<Flexed direction="row" justify="space-between" align="center" gap={1}>
										<PostCounterTag>{allBusinesses?.length > 9999 ? '10,000+' : allBusinesses?.length} ads</PostCounterTag>
										<ViewFlex direction="row" justify="space-between" align="center" gap={0.5}>
											<Text isDarkTheme={_isDarkTheme} fontWeight={700} textTransform="uppercase">
												View
											</Text>
											<ViewWrapper
												active={view === 'list'}
												onClick={() => {
													setView('list')
												}}
												isDarkTheme={_isDarkTheme}>
												<PiListBold />
											</ViewWrapper>
											<ViewWrapper
												active={view === 'block'}
												onClick={() => {
													setView('block')
												}}
												isDarkTheme={_isDarkTheme}>
												<FiGrid />
											</ViewWrapper>
										</ViewFlex>
									</Flexed>
									<Divider margin="0.92rem 0rem" />
								</Col>
							)}
							{allBusinesses?.map((business: any, key: number) => {
								return (
									<Col xxl={view === 'list' ? 6 : 4} xl={view === 'list' ? 6 : 4} lg={view === 'list' ? 12 : 6} md={view === 'list' ? 12 : 6} sm={view === 'list' ? 12 : 6} xs={12}>
										<ProductCard view={view === 'list' ? true : false} key={key} business={business} />
									</Col>
								)
							})}

							{allBusinesses?.length > 0 && !isLoading && loadMore && !loadMoreForSearch && (
								<Col>
									<Flexed direction="row" justify="center" margin="0rem 0rem 1rem 0rem">
										<Button medium label="Load More" type="red" ifClicked={() => loadMoreData()} />
									</Flexed>
								</Col>
							)}
							{allBusinesses?.length > 0 && !isLoading && !loadMore && loadMoreForSearch && (
								<Col>
									<Flexed direction="row" justify="center" margin="0rem 0rem 1rem 0rem">
										<Button medium label="Load More" type="red" ifClicked={() => loadMoreSearchData()} />
									</Flexed>
								</Col>
							)}
							{/* {allBusinesses?.length === 0 && <Col>
								<Spacer height={5} />
								{!isLoading ? (
									<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
										Sorry, the posts you're looking for could not be found.
									</Text>
								)
									:
									<Text type='small' isCentered isDarkTheme={_isDarkTheme}>The post is loading...</Text>
								}
							</Col>} */}

							{allBusinesses?.length === 0 && (
								<Col>
									<Spacer height={5} />
									{isLoading == false ? (
										aiResponse ? (
											<div style={{padding: '0 1rem'}} dangerouslySetInnerHTML={{__html: aiResponse}} />
										) : (
											<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
												No post available.
											</Text>
										)
									) : (
										<Text type="small" isCentered isDarkTheme={_isDarkTheme}>
											The post is loading...
										</Text>
									)}
								</Col>
							)}
						</Row>
					</InnerWrapper>
				</Wrapper>
			</Container>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
})

const Wrapper = styled.div`
	display: flex;
	padding-top: 0.5rem;
`

const InnerWrapper = styled(Container)`
	min-height: calc(100vh - 128px);
`

const Filter = styled.div<any>`
	display: ${({open}) => (open ? 'block' : 'none')};
	${media.lg`
    display:block;
`}
`

const FilterContent = styled(Row)`
	display: flex;
	${media.lg`display:none;`}
`

const PostCounterTag = styled.div`
	font-weight: 700;
	font-size: 14px;
	padding: 0.2rem 0.4rem;
	background: ${palette.red};
	color: ${palette.white};
	width: fit-content;
	border-radius: 0.3rem;
`

const ViewWrapper = styled.div<any>`
	padding: 0.35rem;
	background: ${({active}) => (active ? palette.red : '')};
	color: ${({active, isDarkTheme}) => (active ? palette.white : isDarkTheme ? palette.light_gray : palette.black)};
	border-radius: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 20px;
`

const ViewFlex = styled(Flexed)`
	opacity: 0;
	${media.sm`
		opacity:1;
	`}
`

export default Business
