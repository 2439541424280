import React, {useState} from 'react'
import styled from 'styled-components'
import axios from 'axios'
import {CommonEnum} from '../../utils'
import InputField from './InputField'
import useRouter from './UserRouterHook'
import {toast} from 'react-toastify'
import Button from './Button'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Text, Spacer} from '../../styled/shared'
import {useSelector} from 'react-redux'
import InputPhoneNo from './InputPhoneNo'
import {Loading} from '../MainCover'

const ContactUs = () => {
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _router = useRouter()
	const [name, setName] = useState<any>()
	const [email, setEmail] = useState<any>()
	const [message, setMessage] = useState<any>()
	const [contactInfo, setContactInfo] = useState<any>()

	const [nameError, setNameError] = useState<any>(false)
	const [emailError, setEmailError] = useState<any>(false)
	const [messagError, setMessageError] = useState<any>(false)
	const [phoneError, setPhoneError] = useState<any>(false)
	const [loading, setLoading] = useState(false)

	const contactUsEmail = async (data: any) => {
		let response: any = []
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: {authorization: `bearer ${_token}`}
		}
		await axios
			.post(`${CommonEnum.contactUsServerUrl}/users/contact-us`, data, config)
			.then((res) => {
				response = res
			})
			.catch((error) => {
				console.error(error)
				response = error?.response
			})
		return response
	}

	const sendEmail = async () => {
		if (Validate()) {
			setLoading(true)
			let res = await contactUsEmail({name, email, businessName: message, contactInfo: contactInfo})
			if (res?.data == true) {
				setLoading(false)
				toast.success(`Thank you! We have received your message. We will get back to you shortly.`)
				_router.navigate('/')
			} else {
				setLoading(false)
				toast.error('Failed to send message. Please try again later.')
			}
		}
	}
	const Validate = () => {
		let validate = true
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

		if (name?.trim()?.length == 0 || !name || name == undefined) {
			setNameError('Name is required')
			validate = false
		}
		if (email?.trim()?.length == 0 || !email || name == undefined) {
			setEmailError('Email is required')
			validate = false
		} else if (emailRegex.test(email) == false) {
			setEmailError('Please enter valid email')
			validate = false
		}
		if (message?.trim()?.length == 0 || !message || message == undefined) {
			setMessageError('Message is required')
			validate = false
		}

		if (contactInfo?.trim()?.length == 0 || !contactInfo || contactInfo == undefined) {
			setPhoneError('Contact Info is required')
			validate = false
		}
		return validate
	}
	return (
		<MainWrapper>
			<Spacer height={1} />
			{loading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
			<Row justifyContent="center">
				<Col lg={7} md={10} sm={12}>
					<ContactUsContainer>
						<Inner>
							<Text type="medium" margin="0rem 0rem 1rem 0rem" fontWeight={700} isDarkTheme={_isDarkTheme}>
								Contact Us
							</Text>
						</Inner>

						<InputField
							label="Name"
							error={nameError}
							required
							style={{width: '100%'}}
							placeholder="Your Name"
							type="text"
							id="name"
							value={name}
							handleChange={(e: any) => {
								setNameError('')
								if (e == '') {
									setNameError('Name is required')
								}
								setName(e)
							}}
						/>
						<Spacer height={1} />

						<InputField
							label="Email"
							error={emailError}
							required
							style={{width: '100%'}}
							placeholder="Email"
							type="email"
							id="email"
							value={email}
							handleChange={(e: any) => {
								setEmailError('')
								if (e == '') {
									setEmailError('Email is required')
								}
								setEmail(e)
							}}
						/>
						<Spacer height={1} />

						<InputField
							label="Message"
							error={messagError}
							required
							type="textarea"
							id="message"
							rows={5}
							placeholder="Your Message"
							value={message}
							handleChange={(e: any) => {
								setMessageError('')
								if (e == '') {
									setMessageError('Message is required')
								}
								setMessage(e)
							}}></InputField>
						<Spacer height={1} />

						<InputPhoneNo
							label="Contact Info"
							placeholder="Enter"
							required
							name={'phone'}
							handleChange={(e: any) => {
								if (e?.value == '') {
									setPhoneError('Contact Info is required')
								} else {
									setPhoneError('')
								}
								setContactInfo(e.value)
							}}
							value={contactInfo}
							error={phoneError}
						/>
						<Spacer height={1} />

						<Button
							label="	Submit"
							width="100%"
							medium
							type="red"
							ifClicked={() => {
								sendEmail()
							}}
						/>
					</ContactUsContainer>
				</Col>
			</Row>
		</MainWrapper>
	)
}

const MainWrapper = styled(Container)`
	min-height: calc(100vh - 70px);
	${media.md`min-height: calc(100vh - 136px);`}
`

const ContactUsContainer = styled.div`
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 8px;
	margin: 0px;
`

const Inner = styled.div`
	margin-bottom: 15px;
	border-bottom: 1px solid #ccc;
`

export default ContactUs
