import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Flexed, Heading, Spacer, Text, Divider, Loading} from '../../styled/shared'
import RestaurantDetailCard from '../../components/business/RestaurantDetailCard'
import {useSelector, useDispatch} from 'react-redux'
import MenuList from '../../components/business/MenuList'
import {GiShoppingCart} from 'react-icons/gi'
import {palette} from '../../styled/colors'
import Button from '../../components/common/Button'
import {useSearchParams, useNavigate, Link} from 'react-router-dom'
import {api} from '../../components/api/callAxios'
import CartItemList from '../../components/business/CartItemList'
import {toast} from 'react-toastify'
import SelectProductItemsModal from '../../components/modals/SelectProductItemsModal'
import 'react-multi-carousel/lib/styles.css'
import {FiEdit} from 'react-icons/fi'
import EditRestaurantDetails from './EditRestaurantDetails'
import {RiAddFill, RiDeleteBin6Line} from 'react-icons/ri'
import AddMenuLabelModal from '../../components/modals/AddMenuLabelModal'
import AddRestaurantMenuModal from '../../components/modals/AddRestaurantMenuModal'
import DeleteRestaurantMenuModal from '../../components/modals/DeleteRestaurantMenuModal'
import DeleteMenuLabelModal from '../../components/modals/DeleteMenuLabelModal'
import AddOffersLabelModal from '../../components/modals/AddOffersLabelModal'
import AddRestaurantOfferModal from '../../components/modals/AddRestaurantOfferModal'
import DeleteRestaurantOfferModal from '../../components/modals/DeleteRestaurantOfferModal'
import DeleteOfferLabelModal from '../../components/modals/DeleteOfferLabelModal'
import {IoIosArrowRoundBack} from 'react-icons/io'
import {checkOutDetailAction, loginBusinessDetailsAction} from '../../actions/authActions'
import moment from 'moment'
import InputField from '../../components/common/InputField'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {getDistanceFromLatLonInMiles, verifyJSON} from '../../constants/commonFunctions'
import InputAddress from '../../components/common/InputAddress'
import InputPhoneNo from '../../components/common/InputPhoneNo'
import AutocompleteSearch from '../../components/common/AutocompleteSearch'
import useWindowSize from '../../hooks/useWindowSize'
import BusinessFooter from '../../components/common/BussinessFooter'

const RestaurantDetails = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const MySwal = withReactContent(Swal)
	let _navigate = useNavigate()
	let _dispatch = useDispatch()
	const windowSize = useWindowSize()
	const [scrollPosition, setScrollPosition] = useState(0)
	const _loginBusinessDetails: any = useSelector<any>((state: any) => state.auth.loginBusinessDetails)
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginUserStatus: any = localStorage.getItem('userStatus')
	const [searchParams] = useSearchParams()
	const _id: any = searchParams.get('id')
	const [businessFullDetails, setBusinessFullDetails] = useState<any>()
	const [menuLabelId, setMenuLabelId] = useState('')
	const [editMenuData, setEditMenuData] = useState('')
	const [deleteMenuId, setDeleteMenuId] = useState('')
	const [deleteMenuLabelId, setDeleteMenuLabelId] = useState('')
	const [offerLabelId, setOfferLabelId] = useState('')
	const [editOfferData, setEditOfferData] = useState('')
	const [deleteOfferId, setDeleteOfferId] = useState('')
	const [deleteOfferLabelId, setDeleteOfferLabelId] = useState('')
	const [activeLableTag, setActiveLableTag] = useState<any>(0)
	const [selectedItem, setSelectedItem] = useState<any>()
	const [openCartModal, setOpenCartModal] = useState(false)
	const [cartItems, setCartItems] = useState<any>([])
	const [itemsTotalAmount, setItemsTotalAmount] = useState<any>(0)
	const [grandTotalAmount, setGrandTotalAmount] = useState<any>(0)
	const [editRestaurant, setEditRestaurant] = useState<any>('')
	const [activeEditTab, setActiveEditTab] = useState<any>('')
	const [isLoading, setIsLoading] = useState<any>(false)
	const [paymentMethod, setPaymentMethod] = useState('cashOnDelivery')
	const [instructions, setInstructions] = useState('')
	const [modalStatus, setModalStatus] = useState('menu')
	const [isEditCart, setIsEditCart] = useState(false)
	const [openMenuLabelModal, setOpenMenuLabelModal] = useState(false)
	const [openRestaurantMenuModal, setOpenRestaurantMenuModal] = useState(false)
	const [openRestaurantMenuDeleteModal, setOpenRestaurantMenuDeleteModal] = useState(false)
	const [openMenuLabelDeleteModal, setOpenMenuLabelDeleteModal] = useState(false)

	const [openOfferLabelModal, setOpenOfferLabelModal] = useState(false)
	const [openOfferMenuModal, setOpenOfferMenuModal] = useState(false)
	const [openRestaurantOfferDeleteModal, setOpenRestaurantOfferDeleteModal] = useState(false)
	const [openOfferLabelDeleteModal, setOpenOfferLabelDeleteModal] = useState(false)

	const [nonRegisterUserId, setNonRegisterUserId] = useState('')
	const [nonRegisterUserName, setNonRegisterUserName] = useState('')
	const [nonRegisterUserPhone, setNonRegisterUserPhone] = useState('')
	const [nonRegisterUserLocation, setNonRegisterUserLocation] = useState('')
	const [activeCheckOutButton, setActiveCheckOutButton] = useState(true)

	const [nonRegisterUserNameError, setNonRegisterUserNameError] = useState('')
	const [nonRegisterUserPhoneError, setNonRegisterUserPhoneError] = useState('')
	const [nonRegisterUserLocationError, setNonRegisterUserLocationError] = useState('')
	const [suggestions, setSuggestions] = useState([])
	const [clat, setCLat] = useState<any>('')
	const [clng, setCLng] = useState<any>('')
	const [showAddressInput, setShowAddressInput] = useState(true)

	let _shopping_cart: any = ''
	let _cart_btns: any = []

	const handleScroll = () => {
		const _position = window.pageYOffset
		setScrollPosition(_position)
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, {passive: true})
	}, [])

	useEffect(() => {
		fetchBusinessDetails(_id)
		nonRegisterClientGet(_id)
	}, [_id])

	useEffect(() => {
		_shopping_cart = document.querySelector('#shopping-cart')
		_cart_btns = document.querySelectorAll('#add-to-cart')
	})

	const addToCart = (e: any, itemsData: any) => {
		if (businessFullDetails?.businessSettings?.inHouseDelivery) {
			let _cartDataObj = {
				title: itemsData?.item?.title,
				id: itemsData?.item?.id,
				productId: itemsData?.item?.id,
				description: itemsData?.item?.description,
				photo: itemsData?.item?.photo,
				businessId: itemsData?.item?.businessId,
				totalPrice: itemsData?.item?.totalPrice,
				total: itemsData?.total,
				quantity: itemsData?.quantity,
				soldItemsAddOns: itemsData?.addedAddOnsList ? itemsData?.addedAddOnsList : []
			}
			if (cartItems?.find((cartItem: any) => cartItem?.id == _cartDataObj?.id && cartItem?.title == _cartDataObj?.title) == undefined || isEditCart) {
				_shopping_cart.classList.add('active')
				let _product_count = Number(_shopping_cart.getAttribute('data-product-count')) || 0
				_shopping_cart.setAttribute('data-product-count', _product_count + 1)
				// finding first grand parent of target button
				let _target_parent = e.target.parentNode.parentNode.parentNode
				_target_parent.style.zIndex = '100'
				// Creating separate Image
				let _img = _target_parent.querySelector('img')
				let _flying_img = _img.cloneNode()
				_flying_img.classList.add('flying-img')
				_target_parent.appendChild(_flying_img)

				// Finding position of flying image
				const _flying_img_pos = _flying_img.getBoundingClientRect()
				const _shopping_cart_pos = _shopping_cart.getBoundingClientRect()
				let data = {
					left: _shopping_cart_pos.left - (_shopping_cart_pos.width / 2 + _flying_img_pos.left + _flying_img_pos.width / 2),
					top: _shopping_cart_pos.bottom - _flying_img_pos.bottom + 100
				}

				_flying_img.style.cssText = `
                                --left : ${data.left.toFixed(2)}px;
                                --top : ${data.top.toFixed(2)}px;
                                `
				setTimeout(() => {
					_target_parent.style.zIndex = ''
					_target_parent.removeChild(_flying_img)
					_shopping_cart.classList.remove('active')

					setOpenCartModal(false)
					let _cartItems = cartItems
					if (cartItems?.find((cartItem: any) => cartItem?.id == _cartDataObj?.id && cartItem?.title == _cartDataObj?.title) == undefined) {
						_cartItems?.unshift(_cartDataObj)
					} else {
						_cartItems = _cartItems?.map((res: any) => {
							if (res?.id === _cartDataObj?.id && res?.title === _cartDataObj?.title) {
								return _cartDataObj // Replace with the new object
							} else {
								return res // Keep the original object
							}
						})
					}
					let _itemsTotalAmt: any = 0
					let _grandTotalAmt: any = 0
					_cartItems?.map((i: any) => (_itemsTotalAmt += parseFloat(i?.total)))
					setItemsTotalAmount(_itemsTotalAmt)
					if (businessFullDetails?.businessSettings?.freeDelivery && businessFullDetails?.businessSettings?.inHouseDelivery) {
						_grandTotalAmt = _itemsTotalAmt
					} else {
						_grandTotalAmt = _itemsTotalAmt + businessFullDetails?.businessSettings?.deliveryCharges
					}
					setGrandTotalAmount(_grandTotalAmt)
					setCartItems(_cartItems)
				}, 900)
			} else {
				toast.warning('Item already added to cart')
			}
		} else {
			toast.warning('Home delivery is not available.')
		}
	}

	const removeFromCart = (removeItemId: number, removeItemTitle: string) => {
		let _cartItems: any = []
		cartItems?.map((item: any) => {
			if (item?.id != removeItemId && item?.title != removeItemTitle) {
				_cartItems?.push(item)
			}
		})
		let _itemsTotalAmt = 0
		_cartItems?.map((i: any) => {
			_itemsTotalAmt = _itemsTotalAmt + parseFloat(i?.total)
		})
		setItemsTotalAmount(_itemsTotalAmt)
		let _grandTotalAmt = _itemsTotalAmt + businessFullDetails?.businessSettings?.deliveryCharges
		setGrandTotalAmount(_grandTotalAmt)
		setCartItems(_cartItems)
	}

	const fetchBusinessDetails = async (businessId: string) => {
		setIsLoading(true)
		await api
			.get(`business/${businessId}`)
			.then(async (response: any) => {
				setBusinessFullDetails(response?.data)
				if (_loginBusinessDetails?.id === response?.data?.id) {
					_dispatch(loginBusinessDetailsAction(response?.data))
				}
				setIsLoading(false)
			})
			.catch(function (error: any) {
				console.log(error)
				setIsLoading(false)
			})
	}

	const deleteRestaurantMenu = (deleteMenuId: any) => {
		let _apiPath = modalStatus === 'menu' ? '/business-menu' : '/addOns-menu'
		setIsLoading(true)
		api.delete(`${_apiPath}/${deleteMenuId}`)
			.then((res) => {
				setIsLoading(false)
				toast.success(modalStatus === 'menu' ? 'Menu item deleted successfully.' : 'Add-on item deleted successfully.')
				setDeleteMenuId('')
				setOpenRestaurantMenuDeleteModal(false)
				fetchBusinessDetails(_id)
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const deleteMenuLabel = (deleteMenuLabelId: any) => {
		let _apiPath = modalStatus === 'menu' ? '/menu-labels' : '/addOns-labels'
		setIsLoading(true)
		api.delete(`${_apiPath}/${deleteMenuLabelId}`)
			.then((res) => {
				setIsLoading(false)
				toast.success(modalStatus === 'menu' ? 'Menu label deleted successfully.' : 'Add-on label deleted successfully.')
				setDeleteMenuLabelId('')
				setOpenMenuLabelDeleteModal(false)
				fetchBusinessDetails(_id)
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const deleteRestaurantOffer = (deleteOfferId: any) => {
		setIsLoading(true)
		api.delete(`/active-offers/${deleteOfferId}`)
			.then((res) => {
				setIsLoading(false)
				toast.success('Offer/Deal item deleted successfully.')
				setDeleteOfferId('')
				setOpenRestaurantOfferDeleteModal(false)
				fetchBusinessDetails(_id)
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const deleteOfferLabel = (deleteOfferLabelId: any) => {
		setIsLoading(true)
		api.delete(`/offer-labels/${deleteOfferLabelId}`)
			.then((res) => {
				setIsLoading(false)
				toast.success('Offer/Deal label deleted successfully')
				setDeleteOfferLabelId('')
				setOpenOfferLabelDeleteModal(false)
				fetchBusinessDetails(_id)
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const cartChectOut = () => {
		let _location: any = {address: ''}
		let _phone: any = ''
		let _myBusinessOrderIdForBusinessToBusiness: any = ''
		let _myBusinessOrderNameForBusinessToBusiness: any = ''
		let _myBusinessOrderImageForBusinessToBusiness: any = ''
		setIsLoading(true)
		if (businessFullDetails?.id === _loginBusinessDetails?.id) {
			_location = {address: nonRegisterUserLocation}
			_phone = nonRegisterUserPhone
		} else {
			if (_loginUserStatus === 'owner') {
				_location = verifyJSON(_loginBusinessDetails?.location) ? JSON.parse(_loginBusinessDetails?.location) : _loginBusinessDetails?.location
				_phone = _loginBusinessDetails?.phone
				_myBusinessOrderIdForBusinessToBusiness = _loginBusinessDetails?.id
				_myBusinessOrderNameForBusinessToBusiness = _loginBusinessDetails?.name
				_myBusinessOrderImageForBusinessToBusiness = _loginBusinessDetails?.photo
			} else {
				_location = verifyJSON(_loginUserDetails?.address) ? JSON.parse(_loginUserDetails?.address) : _loginUserDetails?.address
				_phone = _loginUserDetails?.phone
			}
		}
		if (grandTotalAmount >= businessFullDetails?.businessSettings?.minOrderAmount) {
			api.post(`/check-out`, {
				businessId: businessFullDetails?.id,
				customerId: nonRegisterUserName ? null : _myBusinessOrderIdForBusinessToBusiness ? null : _loginUserDetails?.id,
				//for business to business order
				businessOrderId: _myBusinessOrderIdForBusinessToBusiness,
				businessOrderName: _myBusinessOrderNameForBusinessToBusiness,
				businessOrderImage: _myBusinessOrderImageForBusinessToBusiness,
				//for business to business order ^^^
				totalPrice: parseFloat(itemsTotalAmount).toFixed(2),
				deliveryCharges: businessFullDetails?.businessSettings?.deliveryCharges,
				GrandTotal: parseFloat(grandTotalAmount).toFixed(2),
				location: _location,
				paymentMethod: paymentMethod,
				instructions: instructions,
				soldItems: cartItems,
				nonRegisterCustomer: nonRegisterUserName,
				phone: _phone
			})
				.then((res) => {
					setIsLoading(false)
					_dispatch(checkOutDetailAction(res?.data?.data))
					setCartItems([])
					setNonRegisterUserId('')
					setNonRegisterUserName('')
					setNonRegisterUserPhone('')
					setNonRegisterUserLocation('')
					setInstructions('')
					setPaymentMethod('cashOnDelivery')
					placeOrderPopup()
				})
				.catch((e) => {
					if (e?.response) {
						toast.error(e?.response?.data?.message)
						setIsLoading(false)
					}
				})
		} else {
			setIsLoading(false)
			toast.warning(`Your order should exceed $${businessFullDetails?.businessSettings?.minOrderAmount}.`)
		}
	}

	const placeOrderPopup = () => {
		MySwal.fire({
			title: <p>Your order has been placed successfully</p>,
			icon: 'success',
			confirmButtonText: 'Thank You'
		}).then((result) => {
			if (result.isConfirmed) {
				_navigate('/history')
			}
		})
	}

	const addQty = (id: any, qty: any) => {
		cartItems?.map((item: any) => {
			if (item?.id === id) {
				item.quantity = qty
				item.totalPrice = item.price * qty
			}
		})
		let _itemsTotalAmt = 0
		cartItems?.map((i: any) => {
			_itemsTotalAmt = _itemsTotalAmt + parseFloat(i?.totalPrice)
		})
		setItemsTotalAmount(_itemsTotalAmt)
		let _grandTotalAmt = _itemsTotalAmt + businessFullDetails?.businessSettings?.deliveryCharges
		setGrandTotalAmount(_grandTotalAmt)
		setCartItems(cartItems)
	}

	const nonRegisterClientGet = async (id: any) => {
		setIsLoading(true)
		await api
			.post(`/nonRegister/search`, {
				businessId: id
			})
			.then(async (response: any) => {
				setSuggestions(response?.data?.data)
				setIsLoading(false)
			})
			.catch(function (error: any) {
				console.log(error)
				setIsLoading(false)
			})
	}

	const createCustomer = () => {
		setIsLoading(true)
		api.post(`/nonRegister/create`, {
			businessId: businessFullDetails?.id,
			customerId: nonRegisterUserId,
			name: nonRegisterUserName,
			phone: nonRegisterUserPhone,
			address: {address: nonRegisterUserLocation}
		})
			.then((res) => {
				setIsLoading(false)
				cartChectOut()
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
					setIsLoading(false)
				}
			})
	}

	const formValidation = () => {
		let isValid = true
		if (nonRegisterUserName === '') {
			setNonRegisterUserNameError('name is required')
			isValid = false
		}
		if (nonRegisterUserPhone === '') {
			setNonRegisterUserPhoneError('phone number is required')
			isValid = false
		}
		if (nonRegisterUserLocation === '') {
			setNonRegisterUserLocationError('location is required')
			isValid = false
		}
		return isValid
	}

	useEffect(() => {
		if (nonRegisterUserLocation?.includes('#')) {
			doGetDistanceFromLatLonInMiles()
		}
	}, [nonRegisterUserLocation])

	const doGetDistanceFromLatLonInMiles = async () => {
		if (businessFullDetails?.address) {
			let locationJson = businessFullDetails?.address
			let fullAddress = verifyJSON(locationJson) ? JSON.parse(locationJson) : locationJson
			var address = fullAddress?.split('#')
			if (address?.length && address[1]) {
				var businessCoords = address[1]?.split(' ')
			}
		}
		var _nonRegisterUserAddress = nonRegisterUserLocation?.split('#')
		if (_nonRegisterUserAddress[1]) {
			var _nonRegisterUserCoords = _nonRegisterUserAddress[1]?.split(' ')
			if (parseFloat(businessCoords[1]) && parseFloat(businessCoords[2]) && _nonRegisterUserCoords[1] && _nonRegisterUserCoords[2]) {
				const res = await getDistanceFromLatLonInMiles(parseFloat(businessCoords[1]), parseFloat(businessCoords[2]), _nonRegisterUserCoords[1], _nonRegisterUserCoords[2])
				if (res <= parseFloat(businessFullDetails?.deliveryRadius)) {
					setActiveCheckOutButton(true)
				} else {
					toast.error('This address is outside our service range.')
					setActiveCheckOutButton(false)
				}
			}
		}
	}

	return (
		<>
			{editRestaurant === '' ? (
				<>
					{windowSize?.width > 992 && (
						<StickyWrapper scroll={scrollPosition} isDarkTheme={_isDarkTheme}>
							<Container fluid>
								<Row>
									<Col>
										<RestaurantDetailCard
											isStickyHeader={true}
											business={businessFullDetails}
											onEdit={() => {
												setActiveEditTab('editRestaurant')
												setEditRestaurant(businessFullDetails)
											}}
										/>
									</Col>
								</Row>
							</Container>
						</StickyWrapper>
					)}
					<Container fluid>
						<Spacer height={0.5} />
						<GoBack pointer direction="row" align="center">
							<IoIosArrowRoundBack fontSize="1.8rem" />
							<Text
								type="normal"
								fontWeight={500}
								onClick={() => {
									_navigate(`/business?id=${businessFullDetails?.businessTypeId}`)
								}}>
								Back
							</Text>
						</GoBack>
						<Spacer height={0.5} />
						<Row>
							<Col>
								<RestaurantDetailCard
									isStickyHeader={false}
									business={businessFullDetails}
									onEdit={() => {
										setActiveEditTab('editRestaurant')
										setEditRestaurant(businessFullDetails)
									}}
								/>
							</Col>
						</Row>
					</Container>
					<Divider isDarkTheme={_isDarkTheme} />
					<FilterBar isDarkTheme={_isDarkTheme}>
						<FlexScroll>
							<div>
								<FilterContent>
									{businessFullDetails?.menuLabels?.map((label: any, key: number) => {
										return (
											<div
												key={`menuheading${key}`}
												onClick={() => {
													setActiveLableTag(key)
													var element = document.getElementById(`menu${label?.id}`)
													element?.scrollIntoView({block: 'start', behavior: 'smooth'})
												}}>
												<Tags isDarkTheme={_isDarkTheme} active={key === activeLableTag}>
													{label?.title}
												</Tags>
											</div>
										)
									})}
									{businessFullDetails?.id === _loginBusinessDetails?.id && (
										<div>
											<AddMenuLabel
												direction="row"
												align="center"
												margin="0rem 1rem 0rem 0rem"
												onClick={() => {
													setModalStatus('menu')
													setOpenMenuLabelModal(true)
												}}>
												<RiAddFill color={palette.red} />
												<Text type="small" color="red" line fontWeight={500}>
													{' '}
													Menu Label
												</Text>
											</AddMenuLabel>
										</div>
									)}
								</FilterContent>
							</div>
							<div>
								<FilterContent>
									{businessFullDetails?.addOnsLabels?.map((label: any, key: number) => {
										return (
											<div
												key={`menuheading${key}`}
												onClick={() => {
													setActiveLableTag(key + 'a')
													var element = document.getElementById(`addons${label?.id}`)
													element?.scrollIntoView({block: 'start', behavior: 'smooth'})
												}}>
												<Tags isDarkTheme={_isDarkTheme} active={key + 'a' === activeLableTag}>
													{label?.title}
												</Tags>
											</div>
										)
									})}
									{businessFullDetails?.id === _loginBusinessDetails?.id && (
										<div>
											<AddMenuLabel
												direction="row"
												align="center"
												margin="0rem 1rem 0rem 0rem"
												onClick={() => {
													setModalStatus('addons')
													setOpenMenuLabelModal(true)
												}}>
												<RiAddFill color={palette.red} />
												<Text type="small" color="red" line fontWeight={500}>
													Add-on Label
												</Text>
											</AddMenuLabel>
										</div>
									)}
								</FilterContent>
							</div>
						</FlexScroll>
					</FilterBar>

					<ContentWrapper fluid isDarkTheme={_isDarkTheme}>
						<Spacer height={2} />
						<Row>
							<Col xxl={8} xl={6.8} lg={6}>
								<Wrapper fluid>
									<Col>
										<Flexed direction="row" align="center" margin={'0rem 0rem 1rem 0rem'} gap={1}>
											<Heading level={3} fontWeight={700} isDarkTheme={_isDarkTheme}>
												Offers/Deals
											</Heading>
											<EditRestaurantLink direction="row" align="center" onClick={() => setOpenOfferLabelModal(true)}>
												{businessFullDetails?.id === _loginBusinessDetails?.id && (
													<>
														<EditRestaurant lineHeight={1.3} type="normal" color="blue" fontWeight={500} isDarkTheme={_isDarkTheme}>
															Add Offers/Deals Label
														</EditRestaurant>
														<FiEdit color={`${palette.blue}`} />
													</>
												)}
											</EditRestaurantLink>
										</Flexed>
									</Col>
									{businessFullDetails?.activeOfferLabels?.map((label: any, key: number) => {
										return (
											<>
												<Col id={label?.id}>
													<Flexed direction="row" align="center" gap={1} margin="0rem 0rem 0.35rem 0rem">
														<Heading level={5} fontWeight={700} isDarkTheme={_isDarkTheme}>
															{label?.title}
														</Heading>
														<EditRestaurantLink
															direction="row"
															align="center"
															onClick={() => {
																setOfferLabelId(label?.id)
																setEditOfferData('')
																setOpenOfferMenuModal(true)
															}}>
															{businessFullDetails?.id === _loginBusinessDetails?.id && (
																<>
																	<EditRestaurant lineHeight={1.3} type="normal" color="blue" fontWeight={500} isDarkTheme={_isDarkTheme}>
																		Add Offer/Deal
																	</EditRestaurant>
																	<FiEdit color={`${palette.blue}`} />
																</>
															)}
														</EditRestaurantLink>
														<DeleteRestaurantLink
															direction="row"
															align="center"
															onClick={() => {
																setDeleteOfferLabelId(label?.id)
																setOpenOfferLabelDeleteModal(true)
															}}>
															{businessFullDetails?.id === _loginBusinessDetails?.id && (
																<>
																	<DeleteRestaurant lineHeight={1.3} type="normal" color="danger" fontWeight={500} isDarkTheme={_isDarkTheme}>
																		Delete Offer/Deal Label
																	</DeleteRestaurant>
																	<RiDeleteBin6Line color={palette.danger} />
																</>
															)}
														</DeleteRestaurantLink>
													</Flexed>
													{label?.description && (
														<LabelDiscription type="small" isDarkTheme={_isDarkTheme}>
															{label?.description}
														</LabelDiscription>
													)}
													{label?.description && <Spacer height={1} />}
												</Col>
												<Row>
													{businessFullDetails?.activeOffers?.length > 0 ? (
														<>
															{businessFullDetails?.activeOffers?.map((offer: any, key: number) => {
																return (
																	<>
																		{offer?.activeOfferLabelsId === label?.id && (
																			<>
																				{offer?.displayItem && offer?.isUnlimited ? (
																					<Col xxl={6} xl={10} lg={12} md={10} key={`offerItems${key}`}>
																						<MenuList
																							menuListStatus={() => {
																								setModalStatus('menu')
																								setIsEditCart(false)
																							}}
																							business={businessFullDetails}
																							ownMenu={businessFullDetails?.id === _loginBusinessDetails?.id}
																							editMenu={() => {
																								setOfferLabelId(label?.id)
																								setEditOfferData(offer)
																								setOpenOfferMenuModal(true)
																							}}
																							deleteMenu={() => {
																								setDeleteOfferId(offer?.id)
																								setOpenRestaurantOfferDeleteModal(true)
																							}}
																							setOpenCartModal={setOpenCartModal}
																							setSelectedItem={setSelectedItem}
																							displayItemInfo={offer}
																						/>
																					</Col>
																				) : (
																					<>
																						{offer?.displayItem && !offer?.isUnlimited && moment().isBetween(moment(offer?.activeFrom, 'DD-MM-YYYY HH:mm'), moment(offer?.activeTo, 'DD-MM-YYYY HH:mm')) ? (
																							<Col xxl={6} xl={10} lg={12} md={10} key={`offerItems1${key}`}>
																								<MenuList
																									menuListStatus={() => {
																										setModalStatus('menu')
																										setIsEditCart(false)
																									}}
																									business={businessFullDetails}
																									ownMenu={businessFullDetails?.id === _loginBusinessDetails?.id}
																									editMenu={() => {
																										setOfferLabelId(label?.id)
																										setEditOfferData(offer)
																										setOpenOfferMenuModal(true)
																									}}
																									deleteMenu={() => {
																										setDeleteOfferId(offer?.id)
																										setOpenRestaurantOfferDeleteModal(true)
																									}}
																									setOpenCartModal={setOpenCartModal}
																									setSelectedItem={setSelectedItem}
																									displayItemInfo={offer}
																								/>
																							</Col>
																						) : businessFullDetails?.id === _loginBusinessDetails?.id ? (
																							<Col xxl={6} xl={10} lg={12} md={10} key={`offerItems2${key}`}>
																								<MenuList
																									menuListStatus={() => {
																										setModalStatus('menu')
																										setIsEditCart(false)
																									}}
																									business={businessFullDetails}
																									ownMenu={businessFullDetails?.id === _loginBusinessDetails?.id}
																									editMenu={() => {
																										setOfferLabelId(label?.id)
																										setEditOfferData(offer)
																										setOpenOfferMenuModal(true)
																									}}
																									deleteMenu={() => {
																										setDeleteOfferId(offer?.id)
																										setOpenRestaurantOfferDeleteModal(true)
																									}}
																									setOpenCartModal={setOpenCartModal}
																									setSelectedItem={setSelectedItem}
																									displayItemInfo={offer}
																								/>
																							</Col>
																						) : null}
																					</>
																				)}
																			</>
																		)}
																	</>
																)
															})}
														</>
													) : (
														<Col>
															<Text type="normal" isDarkTheme={_isDarkTheme} margin={'0rem 0rem 0rem 2rem'}>
																No offers/deals found.
															</Text>
														</Col>
													)}
												</Row>
											</>
										)
									})}
									{businessFullDetails?.activeOfferLabels?.length === 0 && (
										<Row>
											<Col>
												<Text type="normal" isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 2rem'}>
													No offers/deals found.
												</Text>
											</Col>
										</Row>
									)}
									<Spacer height={3} />
									<Col>
										<Flexed direction="row" align="center" margin={'0rem 0rem 1rem 0rem'} gap={1}>
											<Heading level={3} fontWeight={700} isDarkTheme={_isDarkTheme}>
												Menu
											</Heading>
											<EditRestaurantLink
												direction="row"
												align="center"
												onClick={() => {
													setModalStatus('menu')
													setOpenMenuLabelModal(true)
												}}>
												{businessFullDetails?.id === _loginBusinessDetails?.id && (
													<>
														<EditRestaurant lineHeight={1.3} type="normal" color="blue" fontWeight={500} isDarkTheme={_isDarkTheme}>
															Add Menu Label
														</EditRestaurant>
														<FiEdit color={`${palette.blue}`} />
													</>
												)}
											</EditRestaurantLink>
										</Flexed>
									</Col>
									{businessFullDetails?.menuLabels?.map((label: any, key: number) => {
										return (
											<>
												<Col id={'menu' + label?.id}>
													<Flexed direction="row" align="center" margin="0rem 0rem 0.35rem 0rem" gap={1}>
														<Heading level={5} fontWeight={700} isDarkTheme={_isDarkTheme}>
															{label?.title}
														</Heading>
														<EditRestaurantLink
															direction="row"
															align="center"
															onClick={() => {
																setMenuLabelId(label?.id)
																setEditMenuData('')
																setModalStatus('menu')
																setOpenRestaurantMenuModal(true)
															}}>
															{businessFullDetails?.id === _loginBusinessDetails?.id && (
																<>
																	<EditRestaurant lineHeight={1.3} type="normal" color="blue" fontWeight={500} isDarkTheme={_isDarkTheme}>
																		Add Menu
																	</EditRestaurant>
																	<FiEdit color={`${palette.blue}`} />
																</>
															)}
														</EditRestaurantLink>
														<DeleteRestaurantLink
															direction="row"
															align="center"
															onClick={() => {
																setDeleteMenuLabelId(label?.id)
																setModalStatus('menu')
																setOpenMenuLabelDeleteModal(true)
															}}>
															{businessFullDetails?.id === _loginBusinessDetails?.id && (
																<>
																	<DeleteRestaurant lineHeight={1.3} type="normal" color="danger" fontWeight={500} isDarkTheme={_isDarkTheme}>
																		Delete Menu Label
																	</DeleteRestaurant>
																	<RiDeleteBin6Line color={palette.danger} />
																</>
															)}
														</DeleteRestaurantLink>
													</Flexed>
													{label?.description && (
														<LabelDiscription type="small" isDarkTheme={_isDarkTheme}>
															{label?.description}
														</LabelDiscription>
													)}
													{label?.description && <Spacer height={1} />}
												</Col>
												<Row>
													{businessFullDetails?.businessMenu?.length > 0 ? (
														<>
															{businessFullDetails?.businessMenu?.map((menu: any, key: number) => {
																return (
																	<>
																		{menu?.menuLabelsId === label?.id && (
																			<>
																				{menu?.displayItem ? (
																					<Col xxl={6} xl={10} lg={12} md={10} key={`menuItems${key}`}>
																						<MenuList
																							menuListStatus={() => {
																								setModalStatus('menu')
																								setIsEditCart(false)
																							}}
																							business={businessFullDetails}
																							ownMenu={businessFullDetails?.id === _loginBusinessDetails?.id}
																							editMenu={() => {
																								setMenuLabelId(label?.id)
																								setEditMenuData(menu)
																								setModalStatus('menu')
																								setOpenRestaurantMenuModal(true)
																							}}
																							deleteMenu={() => {
																								setDeleteMenuId(menu?.id)
																								setModalStatus('menu')
																								setOpenRestaurantMenuDeleteModal(true)
																							}}
																							setOpenCartModal={setOpenCartModal}
																							setSelectedItem={setSelectedItem}
																							displayItemInfo={menu}
																						/>
																					</Col>
																				) : businessFullDetails?.id === _loginBusinessDetails?.id ? (
																					<Col xxl={6} xl={10} lg={12} md={10} key={`menuItems1${key}`}>
																						<MenuList
																							menuListStatus={() => {
																								setModalStatus('menu')
																								setIsEditCart(false)
																							}}
																							business={businessFullDetails}
																							ownMenu={businessFullDetails?.id === _loginBusinessDetails?.id}
																							editMenu={() => {
																								setMenuLabelId(label?.id)
																								setEditMenuData(menu)
																								setModalStatus('menu')
																								setOpenRestaurantMenuModal(true)
																							}}
																							deleteMenu={() => {
																								setDeleteMenuId(menu?.id)
																								setModalStatus('menu')
																								setOpenRestaurantMenuDeleteModal(true)
																							}}
																							setOpenCartModal={setOpenCartModal}
																							setSelectedItem={setSelectedItem}
																							displayItemInfo={menu}
																						/>
																					</Col>
																				) : null}
																			</>
																		)}
																	</>
																)
															})}
														</>
													) : (
														<Col>
															<Text type="normal" isDarkTheme={_isDarkTheme} margin={'0rem 0rem 0rem 2rem'}>
																No menu found.
															</Text>
														</Col>
													)}
												</Row>
											</>
										)
									})}
									{businessFullDetails?.menuLabels?.length === 0 && (
										<Row>
											<Col>
												<Text type="normal" isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 2rem'}>
													No menu found.
												</Text>
											</Col>
										</Row>
									)}

									<Spacer height={3} />
									<Col>
										<Flexed direction="row" align="center" margin={'0rem 0rem 1rem 0rem'} gap={1}>
											<Heading level={3} fontWeight={700} isDarkTheme={_isDarkTheme}>
												Add-on
											</Heading>
											<EditRestaurantLink
												direction="row"
												align="center"
												onClick={() => {
													setModalStatus('addons')
													setOpenMenuLabelModal(true)
												}}>
												{businessFullDetails?.id === _loginBusinessDetails?.id && (
													<>
														<EditRestaurant lineHeight={1.3} type="normal" color="blue" fontWeight={500} isDarkTheme={_isDarkTheme}>
															Add Add-on Label
														</EditRestaurant>
														<FiEdit color={`${palette.blue}`} />
													</>
												)}
											</EditRestaurantLink>
										</Flexed>
									</Col>
									{businessFullDetails?.addOnsLabels?.map((label: any, key: number) => {
										return (
											<>
												<Col id={'addons' + label?.id}>
													<Flexed direction="row" align="center" margin="0rem 0rem 0.35rem 0rem" gap={1}>
														<Heading level={5} fontWeight={700} isDarkTheme={_isDarkTheme}>
															{label?.title}
														</Heading>
														<EditRestaurantLink
															direction="row"
															align="center"
															onClick={() => {
																setMenuLabelId(label?.id)
																setEditMenuData('')
																setModalStatus('addons')
																setOpenRestaurantMenuModal(true)
															}}>
															{businessFullDetails?.id === _loginBusinessDetails?.id && (
																<>
																	<EditRestaurant lineHeight={1.3} type="normal" color="blue" fontWeight={500} isDarkTheme={_isDarkTheme}>
																		Add Add-on
																	</EditRestaurant>
																	<FiEdit color={`${palette.blue}`} />
																</>
															)}
														</EditRestaurantLink>
														<DeleteRestaurantLink
															direction="row"
															align="center"
															onClick={() => {
																setDeleteMenuLabelId(label?.id)
																setModalStatus('addons')
																setOpenMenuLabelDeleteModal(true)
															}}>
															{businessFullDetails?.id === _loginBusinessDetails?.id && (
																<>
																	<DeleteRestaurant lineHeight={1.3} type="normal" color="danger" fontWeight={500} isDarkTheme={_isDarkTheme}>
																		Delete Add-on Label
																	</DeleteRestaurant>
																	<RiDeleteBin6Line color={palette.danger} />
																</>
															)}
														</DeleteRestaurantLink>
													</Flexed>
													{label?.description && (
														<LabelDiscription type="small" isDarkTheme={_isDarkTheme}>
															{label?.description}
														</LabelDiscription>
													)}
													{label?.description && <Spacer height={1} />}
												</Col>
												<Row>
													{businessFullDetails?.addOnsMenu?.length > 0 ? (
														<>
															{businessFullDetails?.addOnsMenu?.map((menu: any, key: number) => {
																return (
																	<>
																		{menu?.addOnsLabelsId === label?.id && (
																			<>
																				{menu?.displayItem ? (
																					<Col xxl={6} xl={10} lg={12} md={10} key={`addOnsMenuItems${key}`}>
																						<MenuList
																							menuListStatus={() => {
																								setModalStatus('addons')
																								setIsEditCart(false)
																							}}
																							setModalStatus
																							business={businessFullDetails}
																							ownMenu={businessFullDetails?.id === _loginBusinessDetails?.id}
																							editMenu={() => {
																								setMenuLabelId(label?.id)
																								setEditMenuData(menu)
																								setModalStatus('addons')
																								setOpenRestaurantMenuModal(true)
																							}}
																							deleteMenu={() => {
																								setDeleteMenuId(menu?.id)
																								setModalStatus('addons')
																								setOpenRestaurantMenuDeleteModal(true)
																							}}
																							setOpenCartModal={setOpenCartModal}
																							setSelectedItem={setSelectedItem}
																							displayItemInfo={menu}
																						/>
																					</Col>
																				) : businessFullDetails?.id === _loginBusinessDetails?.id ? (
																					<Col xxl={6} xl={10} lg={12} md={10} key={`addOnsMenuItems1${key}`}>
																						<MenuList
																							menuListStatus={() => {
																								setModalStatus('addons')
																								setIsEditCart(false)
																							}}
																							business={businessFullDetails}
																							ownMenu={businessFullDetails?.id === _loginBusinessDetails?.id}
																							editMenu={() => {
																								setMenuLabelId(label?.id)
																								setEditMenuData(menu)
																								setModalStatus('addons')
																								setOpenRestaurantMenuModal(true)
																							}}
																							deleteMenu={() => {
																								setDeleteMenuId(menu?.id)
																								setModalStatus('addons')
																								setOpenRestaurantMenuDeleteModal(true)
																							}}
																							setOpenCartModal={setOpenCartModal}
																							setSelectedItem={setSelectedItem}
																							displayItemInfo={menu}
																						/>
																					</Col>
																				) : null}
																			</>
																		)}
																	</>
																)
															})}
														</>
													) : (
														<Col>
															<Text type="normal" isDarkTheme={_isDarkTheme} margin={'0rem 0rem 0rem 2rem'}>
																No Add-on found.
															</Text>
														</Col>
													)}
												</Row>
											</>
										)
									})}
									{businessFullDetails?.addOnsLabels?.length === 0 && (
										<Row>
											<Col>
												<Text type="normal" isDarkTheme={_isDarkTheme} margin={'0rem 0rem 1rem 2rem'}>
													No Add-on found.
												</Text>
											</Col>
										</Row>
									)}
								</Wrapper>
							</Col>
							<Col xxl={4} xl={5.2} lg={6}>
								<Cart isDarkTheme={_isDarkTheme}>
									<CartWrapper isDarkTheme={_isDarkTheme}>
										{cartItems?.length > 0 && (
											<Text type="normal" isCentered fontWeight={600} isDarkTheme={_isDarkTheme}>
												Order detail
											</Text>
										)}
										<CartEmpty visible={cartItems?.length == 0}>
											<CartIcon visible={cartItems?.length == 0} isDarkTheme={_isDarkTheme} id="shopping-cart" data-product-count="0" />
											{cartItems?.length == 0 &&
												(_loginUserStatus ? (
													<Text type="normal" isDarkTheme={_isDarkTheme} isCentered margin="1rem 0rem 0rem 0rem" fontWeight={500}>
														You haven’t added any items in the basket yet
													</Text>
												) : (
													<Text type="normal" isDarkTheme={_isDarkTheme} margin="1rem 0rem 0rem 0rem" fontWeight={500}>
														Please <SignIn to="/customer-login">sign in</SignIn> before checking out.
													</Text>
												))}
										</CartEmpty>
										<List>
											{cartItems?.length > 0 &&
												cartItems?.map((cartItem: any, key: number) => {
													return (
														<div key={`cartBasketItem${key}`}>
															<CartItemList
																item={cartItem}
																removeItemCb={removeFromCart}
																addQty={(id: any, qty: any) => {
																	addQty(id, qty)
																}}
																openCartModalHandler={() => {
																	setOpenCartModal(true)
																	setModalStatus('menu')
																	setSelectedItem(cartItem)
																	setIsEditCart(true)
																}}
															/>
														</div>
													)
												})}
										</List>
									</CartWrapper>

									{cartItems?.length > 0 && (
										<BillingContent isDarkTheme={_isDarkTheme}>
											<Flexed direction="row" justify="space-between">
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Total
												</Text>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													${itemsTotalAmount ? parseFloat(itemsTotalAmount).toFixed(2) : 0}
												</Text>
											</Flexed>
											<Flexed direction="row" justify="space-between">
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													Delivery Charges
												</Text>
												<Text type="normal" isDarkTheme={_isDarkTheme}>
													$ {businessFullDetails?.businessSettings?.freeDelivery && businessFullDetails?.businessSettings?.inHouseDelivery ? '0' : businessFullDetails?.businessSettings?.deliveryCharges?.toFixed(2)}
												</Text>
											</Flexed>
											<Flexed direction="row" justify="space-between">
												<Heading level={5} isDarkTheme={_isDarkTheme} fontWeight={800}>
													Grand Total
												</Heading>
												<Heading level={5} isDarkTheme={_isDarkTheme} fontWeight={800}>
													{grandTotalAmount ? '$' + parseFloat(grandTotalAmount).toFixed(2) : '$0'}
												</Heading>
											</Flexed>

											{businessFullDetails?.id === _loginBusinessDetails?.id && (
												<>
													<Spacer height={0.5} />
													<AutocompleteSearch
														label="Source Location"
														placeHolder="Search by phone number"
														suggestionsList={suggestions}
														nonRegisterClientSelected={(customer: any) => {
															setShowAddressInput(false)
															setNonRegisterUserId(customer?.id)
															setNonRegisterUserName(customer?.name)
															setNonRegisterUserPhone(customer?.phone)
															let locationJson = customer?.address
															let locationObject = verifyJSON(locationJson) ? JSON.parse(locationJson) : locationJson
															var fullAddress = locationObject?.address
															setNonRegisterUserLocation(fullAddress)
															setTimeout(() => {
																setNonRegisterUserLocationError('')
																setShowAddressInput(true)
															}, 200)
														}}
													/>
													<Spacer height={0.5} />
													<InputField
														error={nonRegisterUserNameError}
														label="Customer Name"
														placeholder="Customer Name"
														required
														value={nonRegisterUserName}
														handleChange={(e: any) => {
															setNonRegisterUserNameError('')
															if (e == '') {
																setNonRegisterUserNameError('name is required')
															}
															setNonRegisterUserName(e)
														}}
													/>
													<Spacer height={0.5} />
													<InputPhoneNo
														error={nonRegisterUserPhoneError}
														label="Phone"
														required
														value={nonRegisterUserPhone}
														handleChange={(e: any) => {
															setNonRegisterUserPhoneError('')
															if (e?.value == '') {
																setNonRegisterUserPhoneError('phone number is required')
															}
															setNonRegisterUserPhone(e?.value)
														}}
													/>
													<Spacer height={0.5} />
													{showAddressInput && (
														<InputAddress
															label="Address"
															required
															address={nonRegisterUserLocation}
															setCLat={setCLat}
															setCLng={setCLng}
															isEdit={true}
															setAddress={(address: any) => {
																setNonRegisterUserLocationError('')
																if (address == '') {
																	setNonRegisterUserLocationError('location is required')
																}
																setNonRegisterUserLocation(address)
															}}
															error={nonRegisterUserLocationError}
														/>
													)}
												</>
											)}

											<Heading fontWeight={700} isDarkTheme={_isDarkTheme} margin="1.1rem 0rem 0.5rem 0rem">
												Payment Method
											</Heading>
											<Flexed justify="space-between" gap={0.5} pointer>
												<Flexed direction="row" align="center" gap={0.5}>
													<input
														id="cashOnDelivery"
														name="paymentMethod"
														type="radio"
														value="cashOnDelivery"
														checked={paymentMethod === 'cashOnDelivery'}
														onClick={() => {
															setPaymentMethod('cashOnDelivery')
														}}
													/>
													<Label htmlFor="cashOnDelivery" isDarkTheme={_isDarkTheme}>
														Cash on Delivery
													</Label>
												</Flexed>

												<Flexed direction="row" align="center" gap={0.5}>
													<input
														id="debitOrCreditCard"
														name="paymentMethod"
														type="radio"
														value="debitOrCreditCard"
														checked={paymentMethod === 'debitOrCreditCard'}
														onClick={() => {
															setPaymentMethod('debitOrCreditCard')
														}}
													/>
													<Label htmlFor="debitOrCreditCard" isDarkTheme={_isDarkTheme}>
														Debit / Credit card
													</Label>
												</Flexed>
												<Spacer height={0.2} />
												<InputField
													label="Instructions"
													type="textarea"
													value={instructions}
													handleChange={(e: any) => {
														if (e?.length <= 250) {
															setInstructions(e)
														}
													}}
												/>
												<Flexed direction="row" justify="flex-end">
													<Text isDarkTheme={_isDarkTheme} type="xsmall">
														{instructions?.length}/250
													</Text>
												</Flexed>
											</Flexed>
											<Spacer height={1} />
											<Button
												label="Place Order"
												width="100%"
												type="red"
												ifClicked={() => {
													if (activeCheckOutButton) {
														if (businessFullDetails?.id === _loginBusinessDetails?.id) {
															if (formValidation()) {
																createCustomer()
															}
														} else {
															cartChectOut()
														}
													} else {
														toast.error('This address is outside our service range.')
													}
												}}
											/>
										</BillingContent>
									)}
								</Cart>
							</Col>
						</Row>
						<Spacer height={2} />
					</ContentWrapper>
					<BusinessFooter businessFullDetails={businessFullDetails} />

					{openCartModal && (
						<SelectProductItemsModal
							modalStatus={modalStatus}
							addToCart={addToCart}
							open={openCartModal}
							addOnsMenuList={businessFullDetails?.addOnsMenu}
							onCloseModal={() => {
								setOpenCartModal(false)
								setSelectedItem(null)
							}}
							item={selectedItem}
						/>
					)}

					<AddMenuLabelModal modalStatus={modalStatus} open={openMenuLabelModal} fetchBusinessDetails={() => fetchBusinessDetails(_id)} onCloseModal={() => setOpenMenuLabelModal(false)} />
					<AddRestaurantMenuModal
						modalStatus={modalStatus}
						setIsLoading={setIsLoading}
						fetchBusinessDetails={() => fetchBusinessDetails(_id)}
						businessDetails={businessFullDetails}
						menuLabelId={menuLabelId}
						editMenuData={editMenuData}
						open={openRestaurantMenuModal}
						onCloseModal={() => setOpenRestaurantMenuModal(false)}
					/>
					<DeleteRestaurantMenuModal
						modalStatus={modalStatus}
						open={openRestaurantMenuDeleteModal}
						onCloseModal={() => {
							setDeleteMenuId('')
							setOpenRestaurantMenuDeleteModal(false)
						}}
						deleteRestaurantMenu={() => deleteRestaurantMenu(deleteMenuId)}
					/>
					<DeleteMenuLabelModal
						modalStatus={modalStatus}
						open={openMenuLabelDeleteModal}
						onCloseModal={() => {
							setDeleteMenuLabelId('')
							setOpenMenuLabelDeleteModal(false)
						}}
						deleteMenuLabel={() => deleteMenuLabel(deleteMenuLabelId)}
					/>

					<AddOffersLabelModal open={openOfferLabelModal} fetchBusinessDetails={() => fetchBusinessDetails(_id)} onCloseModal={() => setOpenOfferLabelModal(false)} />
					<AddRestaurantOfferModal
						setIsLoading={setIsLoading}
						fetchBusinessDetails={() => fetchBusinessDetails(_id)}
						businessDetails={businessFullDetails}
						offerLabelId={offerLabelId}
						editOfferData={editOfferData}
						open={openOfferMenuModal}
						onCloseModal={() => setOpenOfferMenuModal(false)}
					/>
					<DeleteRestaurantOfferModal
						open={openRestaurantOfferDeleteModal}
						onCloseModal={() => {
							setDeleteOfferId('')
							setOpenRestaurantOfferDeleteModal(false)
						}}
						deleteRestaurantOffer={() => deleteRestaurantOffer(deleteOfferId)}
					/>
					<DeleteOfferLabelModal
						open={openOfferLabelDeleteModal}
						onCloseModal={() => {
							setDeleteOfferLabelId('')
							setOpenOfferLabelDeleteModal(false)
						}}
						deleteOfferLabel={() => deleteOfferLabel(deleteOfferLabelId)}
					/>
				</>
			) : (
				<EditRestaurantDetails
					activeTab={activeEditTab}
					setActiveEditTab={setActiveEditTab}
					editRestaurant={editRestaurant}
					onClose={() => {
						setEditRestaurant('')
						fetchBusinessDetails(_id)
					}}
				/>
			)}
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
		</>
	)
}

const StickyWrapper = styled.div<any>`
	position: -webkit-sticky; /* Safari */
	position: -webkit-sticky;
	position: sticky;
	top: 0rem;
	width: 100%;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : '#e8edf1')};
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
	z-index: 6;
	display: ${({scroll}) => (scroll > 250 ? '' : 'none')};
	padding: 1rem 0rem;
`

const Wrapper = styled(Container)`
	padding: 0rem;
`

const ContentWrapper = styled(Container)<any>`
	background-color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_black : '')};
`

const FilterBar = styled.div<any>`
	display: flex;
	justify-content: space-between;
	border-top: 1px solid ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.black}` : `#edf1f2`)};
	height: 4rem;
	width: 100%;
	position: -webkit-sticky; /* Safari */
	position: -webkit-sticky;
	position: sticky;
	top: 4.4rem;
	z-index: 4;
	box-shadow: ${palette.shadow};
	align-items: center;
	gap: 1rem;
	${media.lg`top:9.5rem`}
`

const FilterContent = styled.div`
	padding: 1rem;
	display: -webkit-box;
	gap: 1rem;
`

const Tags = styled.div<any>`
	background: ${({active}) => (active ? palette.green_light : ``)};
	font-size: 0.875rem;
	font-weight: ${({active}) => (active ? `700` : `normal`)};
	color: ${({active}) => (active ? palette.green : palette.green)};
	padding: 0.2rem 1rem;
	border-radius: 1rem;
	cursor: pointer;
	&:hover {
		background: ${palette.green_light_hover};
		// color: ${palette.white};
	}
`

const LabelDiscription = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow-wrap: anywhere;
`

const List = styled.div`
	max-height: 30rem;
	padding: 0rem 0.5rem;
	overflow-y: scroll;
	overflow-x: hidden;
`

const Cart = styled.div<any>`
	position: -webkit-sticky; /* Safari */
	position: -webkit-sticky;
	position: sticky;
	top: 10rem;
	border: 1px solid ${({error, disabled, isDarkTheme}) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	border-radius: 0.39rem;
	${media.lg`top:15.5rem`}
`

const CartWrapper = styled.div<any>`
	padding: 1rem 1rem 0rem 1rem;
	box-shadow: ${palette.shadow};
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.light_black}` : `${palette.white}`)};
	border-radius: 0.39rem;
	${media.xs`padding: 0.5rem 0rem 0rem 0rem;`}
`

const CartEmpty = styled.div<any>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: ${({visible}) => (visible ? `2rem` : `0rem`)};
`

const CartIcon = styled(GiShoppingCart)<any>`
	font-size: ${({visible}) => (visible ? `3.5rem` : `0.5rem`)};
	opacity: 0.3;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.silver : palette.gray)};
	visibility: ${({visible}) => (visible ? `visible` : `hidden`)};
`

const BillingContent = styled.div<any>`
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	padding: 1rem 1rem;
	border-radius: 0.39rem;
`

const AddMenuLabel = styled(Flexed)<any>`
	background: ${palette.red_light};
	cursor: pointer;
	border-radius: 1rem;
	padding: 0.3rem 0.7rem 0.3rem 0.5rem;
	&:hover {
		background: ${palette.red_light_hover};
	}
`

const EditRestaurantLink = styled(Flexed)<any>`
	gap: 0.6rem;
	cursor: pointer;
	width: fit-content;
	${media.xl`border-bottom: 1.2px solid ${palette.blue}; `};
`

const DeleteRestaurantLink = styled(EditRestaurantLink)<any>`
	${media.xl`border-bottom: 1.2px solid ${palette.red};`};
`

const DeleteRestaurant = styled(Text)<any>`
	&:hover {
		color: ${palette.danger_hover};
	}
	display: none;
	${media.xl`display:block`};
`

const GoBack = styled(Flexed)`
	width: fit-content;
	color: ${palette.red};
	&:hover {
		color: ${palette.red_hover};
	}
	& div {
		color: ${palette.red};
	}
	& div:hover {
		color: ${palette.red_hover};
	}
`

const FlexScroll = styled.div`
	overflow: auto;
	display: -webkit-box;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}
`

const Label = styled.label<any>`
	margin: 0;
	cursor: pointer;
	color: ${({isDarkTheme}) => (isDarkTheme ? palette.light_gray : palette.dark_gray)};
`

const SignIn = styled(Link)<any>`
	color: ${palette.red};
	cursor: pointer;
	&:hover {
		color: ${palette.red_hover_text};
	}
	text-decoration: underline;
	text-underline-offset: 4px;
`

const EditRestaurant = styled(Text)<any>`
	&:hover {
		color: ${palette.blue_hover};
	}
	display: none;
	${media.xl`display:block`};
`

export default RestaurantDetails
