import React, { useState, useEffect } from 'react'
import { Text } from '../../styled/shared'
import { useSelector } from 'react-redux'
import { weekday } from '../../constants/commonFunctions'
import moment from 'moment';
import styled from 'styled-components';
import { palette } from '../../styled/colors';

const CheckBusinessTime = ({ view, isFromBusinessCard, isStickyHeader, business }: any) => {
    const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
    const _day = new Date().getDay()
    const currentDay = weekday[_day]?.toLowerCase();

    if (business?.workingHours[currentDay + 'On']) {
        const currentTime = moment();
        const openingTime = moment(business?.workingHours[currentDay + 'From'], 'HH:mm');
        const closingTime = moment(business?.workingHours[currentDay + 'To'], 'HH:mm');

        if (currentTime.isBetween(openingTime, closingTime)) {
            const openTimeFormatted = openingTime.format('h:mm A');
            const closeTimeFormatted = closingTime.format('h:mm A');
            const closeTimeWithOutFormatted = closingTime.format('HH:mm A');

            return (
                isFromBusinessCard ?
                    null
                    :
                    <Text type={isStickyHeader ? 'small' : 'medium'} isDarkTheme={_isDarkTheme}>
                        {`Today opens at ${openTimeFormatted}, closes at ${closeTimeWithOutFormatted}`}
                    </Text>
            );
        } else {
            return (
                isFromBusinessCard ?
                    <DisplayItemBaner view={view} zIndex={2} top={view ? 0 : -0.7} right={0} left={view ? 0 : ''}>
                        Closed
                    </DisplayItemBaner>
                    :
                    <Text type={isStickyHeader ? 'small' : 'medium'} fontWeight={500} color={isFromBusinessCard ? 'white' : 'danger'}>
                        Closed
                    </Text>
            );
        }
    } else {
        // Business is closed for the day
        return (
            isFromBusinessCard ?
                <DisplayItemBaner view={view} zIndex={2} top={view ? 0 : -0.7} right={0} left={view ? 0 : ''}>
                    Closed
                </DisplayItemBaner>
                :
                <Text type={isStickyHeader ? 'small' : 'medium'} fontWeight={500} color={isFromBusinessCard ? 'white' : 'danger'}>
                    Closed
                </Text>
        );
    }
}

const DisplayItemBaner = styled(Text) <any>`
	position: ${({ view }) => view ? 'absolute' : 'absolute'};
	width: fit-content;
	border-radius: 0.2rem;
	background: ${palette.red}; 
	color: ${palette.white}; 
	font-size: 14px;
	font-weight: 500;
	padding: 0.2rem 0.3rem;
	display:flex;
	align-items:center;
	gap:0.2rem;
	top: ${({ top }) => `${top}rem`};
	right: ${({ right }) => `${right}rem`};
	left: ${({ left }) => `${left}rem`};
	bottom: ${({ bottom }) => `${bottom}rem`};
	margin: ${({ margin }) => `${margin}`};
	z-index: ${({ zIndex }) => `${zIndex}`};
`
export default CheckBusinessTime