import React from 'react'
import { useSelector } from 'react-redux';
import Select, { StylesConfig, ControlProps } from 'react-select';
import styled from 'styled-components';
import { palette } from '../../styled/colors';
import { Text } from '../../styled/shared'

const InputSelect = ({ defalutValue, value, isMulti, options, onChange, name, disabled, error, label, subLable, required, ref }: any) => {
  const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);
  let set = new Set();
  let values: any = [];
  value && value.forEach((item: any) => {
    if (!set.has(item?.label)) {
      set.add(item?.label);
      values.push(item);
    }
  });

  const customDarkStyles: any = {
    control: (base:any, state:any) => ({
      ...base,
      backgroundColor: 'hsl(0deg 0% 7.84%)',
      color: 'white',
      minHeight: '3.1rem',
      outline: 0,
      borderColor: state.isFocused ? '#fe5b60' : 'hsl(0deg 0% 26.27%)',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        borderColor: state.isFocused
          ? '#fe5b60'
          : 'hsl(0deg 0% 26.27%)',
      }
    }),
    menu: (base:any) => ({
      ...base,
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #434343',
    }),
    option: (base:any, state:any) => ({
      ...base,
      backgroundColor: '#141414',
      color: 'white',
      '&:hover': {
        backgroundColor: '#f94e53',
      },
    }),
    singleValue: (base:any) => ({
      ...base,
      color: 'white',
    }),
  };

  const customLightStyles = {
    control: (base:any, state:any) => ({
      ...base,
      backgroundColor: 'white',
      color: 'white',
      minHeight: '3.1rem',
      outline: 0, 
      borderColor: state.isFocused ? '#fe5b60' : base.borderColor,
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        borderColor: state.isFocused
          ? '#fe5b60'
          : base.borderColor,
      }
    }),
    menu: (base:any) => ({
      ...base,
      backgroundColor: 'white',
      color: 'white',
    }),
    option: (base:any, state:any) => ({
      ...base,
      backgroundColor: 'white',
      color: '#434343',
      '&:hover': {
        backgroundColor: '#f94e53',
        color: '#ffffff',
      },
    }),
    singleValue: (base:any) => ({
      ...base,
      color: '#434343',
    }),
  };

  return (
    <div className={_isDarkTheme ? 'darkForSelect' : 'light'}>
      {label && (
        <Label type="normal" margin="0rem 0rem 0.19rem 0rem" isDarkTheme={_isDarkTheme}>
          {label}{required ? <Mandatory>*</Mandatory> : ''}
          {subLable && <SubLable>{subLable}</SubLable>}
        </Label>
      )}
      <Select
      className='signUpSelete'
      // styles={customStyles}
      styles={_isDarkTheme ? customDarkStyles : customLightStyles}
        ref={ref}
        defaultValue={defalutValue}
        name={name}
        value={isMulti ? values : value}
        onChange={(e: any) => onChange(e)}
        options={options}
        isMulti={isMulti}
        isDisabled={disabled}
        required={required}
      />
      {error && !disabled && (
        <Text fontSize={0.7} type="small" color="danger">
          {error?.message}
        </Text>
      )}
    </div>
  )
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`
const SubLable = styled.span`
	font-size: 1rem;
	line-height: 2rem;
	color: ${palette.text_color};
	font-family: 'Roboto';
	text-transform: capitalize;
`
const Mandatory = styled.span`
	color: ${palette.danger};
`
export default InputSelect