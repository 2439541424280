import React from 'react';
import styled from 'styled-components'
import useWindowSize from '../../hooks/useWindowSize';

interface YouTubeProps {
    videoUrl: string;
}

const extractYouTubeId = (url: string): string | undefined => {
    const regularExpression = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const shortsExpression = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([^"&?/\s]{11})/;

    const regularMatch = url.match(regularExpression);
    const shortsMatch = url.match(shortsExpression);

    return regularMatch?.[1] || shortsMatch?.[1];
};

const YouTubeVideo: React.FC<YouTubeProps> = ({ videoUrl }) => {
    const videoId = extractYouTubeId(videoUrl);
    const _windowSize = useWindowSize()
    return (
        <Wrapper>
            <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                // control={0}
                width={_windowSize?.width > 620 ? '560' : _windowSize?.width > 520 ? '480' : _windowSize?.width > 434 ? '400' : _windowSize?.width > 400 ? '368' : _windowSize?.width > 355 ? '320' : '280'}
                height="315"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube" />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    @media screen and (max-width: 619px) {
        background : #040505;
    }
`;

export default YouTubeVideo;