import React, {useEffect} from 'react'

declare global {
	interface Window {
		adsbygoogle: {
			push: Function
		}
	}
}

const AdBanner = () => {
	useEffect(() => {
		// Trigger AdSense to load ads
		if (window != undefined) {
			;(window.adsbygoogle = window.adsbygoogle || []).push({})
		}
	}, [])

	return (
		<div>
			<ins className="adsbygoogle" style={{display: 'block'}} data-ad-client="ca-pub-2822975920383791" data-ad-slot="8786946613" data-ad-format="auto" data-full-width-responsive="true"></ins>
		</div>
	)
}

export default AdBanner
