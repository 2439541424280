import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { Flexed, Heading, Text } from '../../styled/shared'
import { useSelector } from 'react-redux'
import { BsXLg } from 'react-icons/bs'
import { verifyJSON } from '../../constants/commonFunctions'
import { MdOutlineAdd, MdRemove } from 'react-icons/md'

const AddOnsList = ({ item, addAddOnsItemQty, selectedAddOnsFromCartItemList }: any) => {
	let photo = verifyJSON(item?.photo)
		? JSON.parse(item?.photo)
		: item?.photo;
	const [quantity, setQuantity] = useState(0)
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)

	useEffect(() => {
		selectedAddOnsFromCartItemList?.map((seletedAddOns: any) => {
			if (item?.id === seletedAddOns?.id && item?.title === seletedAddOns?.title) {
				setQuantity(seletedAddOns?.quantity)
			}
		})
	}, [item])
	return (
		<>
			<Wrapper isDarkTheme={_isDarkTheme}>
				<Cover>
					<Image src={photo[0]?.startsWith('data:') ? photo[0] : process.env.REACT_APP_IMAGE_URL + photo[0]} />
				</Cover>
				<Content>
					<div>
						<Text type="small" fontWeight={700} isDarkTheme={_isDarkTheme}>
							{item?.title}
						</Text>
						<Price gap={0.5} direction='row' margin='0.2rem 0rem 0rem 0rem'>
							<Text type="small" isDarkTheme={_isDarkTheme}>
								{'$' + parseFloat(item?.totalPrice)?.toFixed(2)}
							</Text>
						</Price>
					</div>
					<Flexed gap={1} direction='row'>
						{quantity > 0 ?
							<QuantityWrapper direction="row" align="center" gap="0.3" justify="space-between">
								<Icons
								isDarkTheme={_isDarkTheme} 
									rotate={true}
									onClick={() => {
										if (quantity > 0) {
											setQuantity(quantity - 1)
											addAddOnsItemQty(item, quantity - 1)
										}
									}}>
									<MdRemove />
								</Icons>
								<div id="quantity">
									<Input isDarkTheme={_isDarkTheme}>{quantity}</Input>
								</div>
								<Icons
								isDarkTheme={_isDarkTheme} 
									add
									onClick={() => {
										setQuantity(quantity + 1)
										addAddOnsItemQty(item, quantity + 1)
									}}>
									<MdOutlineAdd />
								</Icons>
							</QuantityWrapper>
							:
							<Button onClick={() => { setQuantity(quantity + 1); addAddOnsItemQty(item, quantity + 1) }}>Add</Button>}
					</Flexed>
				</Content>
			</Wrapper>
		</>
	)
}

const Wrapper = styled.div<any>`
	display: flex;
	gap: 1rem;
	// margin: 0 0 0.5rem 0;
	width: 100%;
	background: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
	overflow: hidden;
	// box-shadow: ${palette.shadow};
	padding: 0.3rem 0rem;
	transition: all ease 0.25s;
	position: relative;
    border-bottom: 1px dashed ${({ isDarkTheme }) => (isDarkTheme ? palette.light_silver : palette.silver)};
`

const Cover = styled.div``

const Image = styled.img`
	width: 2.7rem;
	height: 2.7rem;
	object-fit: cover;
	border-radius: 0.39rem;
`

const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`

const QuantityWrapper = styled(Flexed) <any>`
	border: 1px solid ${palette.stroke};
	border-radius: 0.25rem;
	min-width: 4rem;
	height: 25px;
	overflow: hidden;
`

const Input = styled.div<any>`
	border: none;
	font-family: 'Lato-Regular', sans-serif;
	line-height: 1.3rem;
	outline: none;
	font-weight: 700;
	font-size: 0.875rem;
	padding: 0.2rem 0rem;
	// border-radius: 0.2rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.white : palette.black)};
	background: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
	// width: 1.5rem;
	width: 100%;
	&:focus {
		border: none;
	}
	&::placeholder {
		color: ${palette.black};
		opacity: 0.5; /* Firefox */
	}
`

const Icons = styled.div<any>`
	border-radius: 1px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.8rem;
	cursor: pointer;
	padding: 0rem 0.2rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.white : palette.black)};
	transform: ${({ rotate }) => (rotate ? 'rotate(180deg)' : 'rotate(0deg)')};
	height: 100%;

	&:hover{
		background-color: ${({ add }) => (add ? palette.green_light : palette.red_light)};
	}
`

const Price = styled(Flexed)`
	min-width:5rem;
`;

const Button = styled.div`
    text-transform: uppercase;
    border: none;
    border-radius: 0.25rem;
    font-size: 0.7rem;
    height: 25px;
    color: ${palette.green};
    background: ${palette.green_light};
    font-weight: 600;
    cursor: pointer;
    min-width: 3rem;
    align-items: center;
    position: relative;
    display: flex;
    justify-content: center;
    -webkit-transition: all ease 0.25s;
    transition: all ease 0.25s;
    font-family: 'Montserrat',sans-serif;
	&:hover{
		background: ${palette.green_light_hover};
	}
`;

export default AddOnsList
