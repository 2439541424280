import moment from 'moment'

export const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export function getRatingText(rating: number) {
	return rating >= 4.5 ? 'Excellent' : rating >= 4 ? 'Great' : rating >= 3 ? 'Average' : rating >= 2 ? 'Below Average' : 'Poor'
}

export const timeAfterMinutes = (m: any, format = false) => {
	return moment(new Date(new Date().getTime() + m * 60000)).format('DD/MM/YYYY HH:mm')
	// return new Date(new Date().getTime() + m * 60000)
}

const R = 6371 // Radius of the earth in miles
export const getDistanceFromLatLonInMiles = async (lat1: any, lon1 : any, lat2 : any, lon2 : any) => {
	let dLat = deg2rad(lat1 - lat2)
	let dLon = deg2rad(lon1 - lon2)
	let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
	let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
	let d = R * c //km
	let m = d * 0.621371 //miles
	return m
}

function deg2rad(deg: any) {
	return deg * (Math.PI / 180)
}


export const verifyJSON = (data: any) => {
    try {
        JSON.parse(data);
        return true;
    } catch (error) {
        return false;
    }
};
