import React from 'react'
import MainCover from '../components/MainCover'
import Testimonials from '../components/Testimonials'
import Category from '../components/Category'
import BusinessInterfaces from '../components/BusinessInterfaces'

const Home = () => {
	return (
		<>
			<MainCover/>
			<Category />
			<Testimonials />
			<BusinessInterfaces />
		</>
	)
}

export default Home
