import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { Flexed, Heading, Loading, Spacer, Text } from '../../styled/shared'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import InputFieldAuth from '../../components/common/InputFieldAuth'
import Button from '../../components/common/Button'
import LogInGoogle from '../../components/common/LogInGoogle'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import InputAddress from '../../components/common/InputAddress'
import InputPhoneNo from '../../components/common/InputPhoneNo'
import { GoogleMap, useJsApiLoader, MarkerF, Circle } from '@react-google-maps/api';
import { api } from '../../components/api/callAxios'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import InputSelect from '../../components/common/InputSelect'
import { MdOutlineCloudUpload } from 'react-icons/md';
import { loginBusinessDetailsAction, loginUserDetailsAction } from '../../actions/authActions'
import BusinessOTPVerification from './BusinessOTPVerification'
import useWindowSize from '../../hooks/useWindowSize'
import axios from 'axios'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

interface IProps {
    isDarkTheme?: boolean,
}

const SignUp = () => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);
    let _navigate = useNavigate();
    const _windowSize = useWindowSize()
    const _dispatch = useDispatch()
    const [storeType, setStoreStype] = useState([])
    const [allCategory, setAllCategory] = useState<any>([])
    const [category, setCategory] = useState<any>([])
    const [map, setMap] = useState(null)
    const [clat, setCLat] = useState<any>('')
    const [clng, setCLng] = useState<any>('')
    const [location, setLocation] = useState('')
    const [phone, setPhone] = useState('')
    const [image, setImage] = useState('');
    const [imageError, setImageError] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [showOtpInput, setShowOtpInput] = useState(false)
    const [dataForSignIn, setDataForSignIn] = useState('')
   

    const validationSchema = Yup.object().shape({
        businessType: Yup.string().required('This field is required'),
        typeCategory: Yup.array().required('This field is required'),
        businessName: Yup.string().required('This field is required'),
        branches: Yup.string().test(
            'Is positive?',
            'Branches must be greater than 0!',
            (value: any) => value > 0
        )
            .required('This field is required'),
        firstname: Yup.string().required('This field is required'),
        lastname: Yup.string().required('This field is required'),
        businessWebsite: Yup.string().matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter correct url!'
        ).required('This field is required'),
        email: Yup.string().email().required('This field is required'),
        location: Yup.string().required('This field is required'),
        deliveryRadius: Yup.string().test(
            'Is positive?',
            'Value must be greater than 0!',
            (value: any) => value > 0
        )
            .required('This field is required'),
        phone: Yup.string()
            .min(10, 'Phone must be a valid phone')
            .required('This field is required'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('This field is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match').required('This field is required')
    });

    const { handleSubmit, setValue, getValues, formState, trigger, resetField, register, watch } = useForm<any>({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;
    const deliveryRadius = watch('deliveryRadius');

    async function onChange({ name, value }: { name: string; value: any }) {
        if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        }
        else if (name === 'phone') {
            setValue(name as never, value as never);
            setPhone(value)
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const selectInputRef: any = useRef();

    let clearSelected = () => {
        selectInputRef?.current?.select?.select?.clearValue();
    }

    useEffect(() => {
        localStorage.removeItem('authorization')
        localStorage.removeItem('userStatus')
        _dispatch(loginUserDetailsAction({}))
        _dispatch(loginBusinessDetailsAction({}))
        getCurrentLatLng();
        getBusinessTypes()
    }, [])

    useEffect(() => {
        if (getValues('businessType')) {
            setValue('typeCategory', []);
            resetField('typeCategory')
            clearSelected()
            let filterCategories: any = []
            allCategory.filter(({ businessTypeId }: any) => businessTypeId == getValues('businessType')).map((res: any) => filterCategories.push({ label: res?.title, value: res }))
            setCategory(filterCategories)
        }
    }, [getValues('businessType')])

    useEffect(() => {
        setTimeout(() => {
            setValue('firstname', '');
            setValue('lastname', '');
            setValue('email', '');
            setValue('password', '');
            setValue('businessType', '');
            setValue('typeCategory', '');
        });
    }, [setValue]);

    const containerStyle = {
        width: '100%',
        height: '14.74rem',
    };

    const center = {
        lat: clat ? clat : 38.892708,
        lng: clng ? clng : -94.6426741,
        zoom: 12
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAiSEHvjCeth6Gx8YDltGHUhzBl5ongDRk"
    })

    const getCurrentLatLng = async () => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                setCLat(position.coords.latitude)
                setCLng(position.coords.longitude)
                await getCurrentAddress(position.coords.latitude, position.coords.longitude)
            },
            function (error) {
                if (error.code == error.PERMISSION_DENIED) {

                } else {

                }
            },
            { timeout: 5000, enableHighAccuracy: true }
        )
    }

    const getCurrentAddress = async (lat: any, lng: any) => {
        await axios
            .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(lat)},${parseFloat(lng)}&sensor=true&key=${process.env.REACT_APP_MAP_API_KEY}`)
            .then((response) => {
                setLocation(response.data.results[6].formatted_address)
            })
            .catch((error) => {
                setLocation('')
            })
    }

    const getBusinessTypes = () => {
        api.get(`/business-type`).then((res) => {
            if (res.data) {
                let storeType: any = []
                res?.data?.businesTypes?.map((e: any) => {
                    storeType.push({ label: e?.title, value: e.id })
                })
                setStoreStype(storeType)
                setAllCategory(res?.data?.typeCategories)
            }

        }).catch((e) => {
            if (e?.response) {
                toast.error(e?.response?.data?.message)
            }
        })
    }

    const onSubmit = (data: any) => {
        let typeCategory: any = []
        if (data?.typeCategory?.length) {
            data?.typeCategory?.map((e: any) => {
                typeCategory.push(e?.value)
            })
        }
        setDataForSignIn(data)
        if (formValidation()) {
            setIsLoading(true)
            api.post(`/owner/register`, { ...data, typeCategory: typeCategory, photo: image, location: { address: data?.location } }).then((res) => {
                if (res.data) {
                    setIsLoading(false)
                    toast.success('Business with owner registered successfully.')
                    setShowOtpInput(true)
                }

            }).catch((e) => {
                if (e?.response) {
                    setIsLoading(false)
                    toast.error(e?.response?.data?.message)
                }
            })
        }
        else {
            toast.error('Image is required')
        }
    }

    const formValidation = () => {
        let isValid = true
        if (image === '') {
            setImageError('image is required')
            isValid = false
        }
        return isValid
    }

    const handleCapture = ({ target }: any) => {
        const reader: any = new FileReader();
        reader.readAsDataURL(target.files[0]);
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImageError('')
                setImage(reader?.result);
            }
        };
    };

    return (<>
        <MainWrapper fluid>
            {!showOtpInput ?
                <Row justifyContent='center'>
                    <Col>
                        <Spacer height={2} />
                        <Heading level={_windowSize?.width < 600 ? 3 : 2} isCentered isDarkTheme={_isDarkTheme}>
                            Business Sign Up
                        </Heading>
                        <Spacer height={2} />
                    </Col>
                    <Col xxl={5} xl={5} lg={7} md={10} lgOrder={1} smOrder={2} xsOrder={2}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Container fluid>
                                <Row>
                                    {/* <Col>
                                    <LogInGoogle />
                                    <Spacer height={1} />
                                </Col>

                                <Col>
                                    <Border isDarkTheme={_isDarkTheme}>
                                        <CustomText fontSize="1" lineHeight={2} isDarkTheme={_isDarkTheme}>
                                            Or SignUp With
                                        </CustomText>
                                    </Border>
                                </Col>
                                <Col>
                                    <Spacer height={2} />
                                </Col> */}

                                    <Col sm={6} lgOrder={1} smOrder={2} xsOrder={2}>
                                        <InputSelect
                                            label='Store type'
                                            name="businessType"
                                            onChange={(e: any) => onChange({ name: 'businessType', value: e.value })}
                                            options={storeType}
                                            required
                                            defaultValue={getValues('businessType')}
                                            isMulti={false}
                                            error={errors.businessType as any}
                                        />
                                        <Spacer height={1} />
                                    </Col>

                                    <Col sm={6} lgOrder={2} smOrder={3} xsOrder={3}>
                                        <InputSelect
                                            label='Category'
                                            name="typeCategory"
                                            ref={selectInputRef}
                                            onChange={(e: any) => onChange({ name: 'typeCategory', value: e })}
                                            options={category}
                                            required
                                            value={getValues('typeCategory')}
                                            error={errors.typeCategory as any}
                                            isMulti={true}
                                        />
                                        <Spacer height={1} />
                                    </Col>

                                    <Col xxl={8} xl={7} lg={7} md={7} sm={7} lgOrder={3} smOrder={4} xsOrder={4}>
                                        <InputFieldAuth label="Store name"
                                            type="text"
                                            placeholder="Enter"
                                            name={'businessName'}
                                            required
                                            register={register}
                                            error={errors.businessName as any} />
                                        <Spacer height={1} />
                                    </Col>
                                    <Col xxl={4} xl={5} lg={5} md={5} sm={5} lgOrder={4} smOrder={5} xsOrder={5}>
                                        <InputFieldAuth
                                            label="No. of Branches"
                                            type="number"
                                            placeholder="Enter"
                                            name={'branches'}
                                            required
                                            register={register}
                                            error={errors.branches as any}
                                        />
                                        <Spacer height={1} />
                                    </Col>

                                    <Col lgOrder={5} smOrder={6} xsOrder={6}>
                                        <InputFieldAuth
                                            label="Website"
                                            type="text"
                                            placeholder="Enter"
                                            required
                                            name={'businessWebsite'}
                                            register={register}
                                            error={errors.businessWebsite as any} />
                                        <Spacer height={1} />
                                    </Col>

                                    <Col lgOrder={6} smOrder={1} xsOrder={1}>
                                        <InputAddress label="Business Address"
                                            required
                                            address={getValues('location')}
                                            setCLat={setCLat}
                                            setCLng={setCLng}
                                            isEdit={false}
                                            setAddress={(e: any) => onChange({ name: 'location', value: e })}
                                            error={errors.location?.message as any}
                                        />
                                        <Spacer height={1} />
                                    </Col>
                                    <Col sm={6} lgOrder={7} smOrder={7} xsOrder={7}>
                                        <InputPhoneNo
                                            label="Business Phone No"
                                            placeholder="Enter"
                                            required
                                            name={'phone'}
                                            handleChange={onChange}
                                            value={getValues('phone')}
                                            error={errors.phone as any} />
                                        <Spacer height={1} />
                                    </Col>

                                    <Col sm={6} lgOrder={8} smOrder={8} xsOrder={8}>
                                        <InputFieldAuth
                                            label="Business Email Address"
                                            type="email"
                                            placeholder="Enter"
                                            required
                                            name={'email'}
                                            register={register}
                                            error={errors.email as any} />
                                        <Spacer height={1} />
                                    </Col>
                                    

                                    <Col lg={6} md={6} sm={6} lgOrder={9} smOrder={9} xsOrder={9}>
                                        <InputFieldAuth
                                            label="Password"
                                            type="password"
                                            placeholder="Password"
                                            required
                                            name={'password'}
                                            register={register}
                                            error={errors.password as any}
                                        />
                                        <Spacer height={1} />
                                    </Col>


                                    <Col lg={6} md={6} sm={6} lgOrder={10} smOrder={10} xsOrder={10}>
                                        <InputFieldAuth
                                            label="Confirm Password"
                                            type="password"
                                            placeholder="Password"
                                            required
                                            name={'confirmPassword'}
                                            register={register}
                                            error={errors.confirmPassword as any} />
                                        <Spacer height={1} />
                                    </Col>
                                    <Col lg={6} md={6} sm={6} lgOrder={11} smOrder={11} xsOrder={11}>
                                        <InputFieldAuth
                                            label="Owner First Name"
                                            type="text"
                                            placeholder="Enter"
                                            required
                                            name={'firstname'}
                                            register={register}
                                            error={errors.firstname as any} />
                                        <Spacer height={1} />
                                    </Col>


                                    <Col lg={6} md={6} sm={6} lgOrder={12} smOrder={12} xsOrder={12}>
                                        <InputFieldAuth label="Owner Last Name"
                                            type="text"
                                            placeholder="Enter"
                                            required
                                            name={'lastname'}
                                            register={register}
                                            error={errors.lastname as any} />
                                        <Spacer height={1} />
                                    </Col>

                                    <Col lgOrder={13} smOrder={13} xsOrder={13}>
                                        <Spacer height={1} />
                                        <Button label="Sign Up" width="100%" type={'submit'} />

                                        <Spacer height={1} />

                                        <Flexed direction={_windowSize?.width < 400 ? 'column' : 'row'} justify="center" gap={0.5} align="center">
                                            <Text textTransform='capitalize' type={_windowSize?.width < 600 ? 'small' : 'normal'} isDarkTheme={_isDarkTheme}>
                                                Already have an account?
                                            </Text>
                                            <Heading level={_windowSize?.width < 600 ? 6 : 5} isDarkTheme={_isDarkTheme} pointer onClick={() => { _navigate('/business-login') }}>
                                                Business Sign In
                                            </Heading>
                                        </Flexed>
                                        <Spacer height={2} />
                                    </Col>
                                </Row>
                            </Container>
                        </form>
                    </Col>

                    <Col xxl={4} xl={4} lg={5} md={10} lgOrder={2} smOrder={1} xsOrder={1}>
                        <Container fluid>
                            <Row>
                                <Col lg={12} md={6} sm={6}>
                                    <Flexed direction='row' justify='space-between' align='center' margin="0rem 0rem 0.19rem 0rem" >
                                        <Text type="normal" fontWeight='500' isDarkTheme={_isDarkTheme}>
                                            Busniess Image <Mandatory>*</Mandatory>
                                        </Text>
                                        <Text fontSize={0.7} type="small" color="danger">
                                            {imageError}
                                        </Text>
                                    </Flexed>
                                    <Flexed direction="row" justify="center">
                                        <ProfileImgCover htmlFor="faceImage" >
                                            <ProfileImg isDarkTheme={_isDarkTheme} url={image} />
                                            {(image === '' || image === null || image === undefined) && <UploadImageText isDarkTheme={_isDarkTheme}><MdOutlineCloudUpload color={_isDarkTheme ? palette.light_gray : palette.gray} fontSize={24} />Upload Image</UploadImageText>}
                                        </ProfileImgCover>
                                    </Flexed>
                                    <Flexed direction="row" justify="center">
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="faceImage"
                                            type="file"
                                            onChange={handleCapture}
                                        />
                                    </Flexed>
                                    <Spacer height={1} />
                                </Col>
                                <Col lg={12} md={6} sm={6}>
                                    <Flexed>
                                        <Text type="normal" fontWeight='500' isDarkTheme={_isDarkTheme} margin="0rem 0rem 0.19rem 0rem">
                                            location <Mandatory>*</Mandatory>
                                        </Text>
                                        {isLoaded ? (
                                            <GoogleMap
                                                mapContainerStyle={containerStyle}
                                                zoom={center.zoom}
                                                center={center}
                                                onLoad={(map: any) => {
                                                    setMap(map)
                                                }}
                                                options={{
                                                    mapTypeControl: false,
                                                    fullscreenControl: false,
                                                    streetViewControl: false,
                                                }}
                                            >
                                                <MarkerF
                                                    position={{ lat: parseFloat(clat), lng: parseFloat(clng) }}
                                                    icon={{
                                                        // url: `/images/icons/marker.svg`,
                                                        scaledSize: new window.google.maps.Size(40, 40),
                                                        // @ts-ignore
                                                        shape: { coords: [17, 17, 18], type: 'circle' },
                                                        optimized: false,
                                                    }}
                                                />
                                                <Circle
                                                    center={{ lat: center.lat, lng: center.lng }}
                                                    radius={parseFloat(deliveryRadius ? deliveryRadius : 0 ) * 1609.34}
                                                    options={{
                                                        fillColor: '#FF0000',
                                                        fillOpacity: 0.2,
                                                        strokeColor: '#FF0000',
                                                        strokeOpacity: 1,
                                                        strokeWeight: 1,
                                                    }}
                                                />
                                            </GoogleMap>
                                        ) : <Text isDarkTheme={_isDarkTheme}>Loading</Text>}
                                    </Flexed>
                                    <Spacer height={1} />
                                </Col>
                                <Col>
                                        <InputFieldAuth
                                            label="Delivery radius for orders"
                                            type="number"
                                            placeholder="Values in miles."
                                            required
                                            name={'deliveryRadius'}
                                            register={register}
                                            error={errors.deliveryRadius as any}
                                        />
                                        <Spacer height={1} />
                                    </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                :
                <BusinessOTPVerification phone={phone} dataForSignIn={dataForSignIn} setIsLoading={setIsLoading} />
            }
        </MainWrapper >
        {isLoading && <Loading ><div className="dots-loading"></div></Loading>}
    </>
    )
}

const MainWrapper = styled(Container)`
	min-height: calc(100vh - 70px);
`

const Border = styled.div<IProps>`
	display: block;
	width: 100%;
	text-align: center;
	border-bottom: 0.063rem solid ${({ isDarkTheme }) => (isDarkTheme ? palette.light_silver : palette.silver)};
`

const CustomText = styled(Text)`
	text-align: center;
	position: relative;
	top: 0.938rem;
	padding: 0 0.938rem;
	display: inline-block;
	background: ${({ isDarkTheme }) => (isDarkTheme ? palette.light_black : palette.white)};
	color: ${palette.opacity.black};
	text-transform: capitalize;
`

const ProfileImgCover = styled.label<any>`
    width: 100%;
    position: relative;
    cursor: pointer;
    margin-bottom:0;
`;

const ProfileImg = styled.div<any>`
    background: ${({ url, defaultImage, isDarkTheme }) => (url ? `url(${url})` : defaultImage ? '' : isDarkTheme ? palette.light_silver : palette.silver)};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border:1px solid ${({ isDarkTheme }) => (isDarkTheme ? palette.light_silver : palette.silver)};
    width: 100%;
    height: 14.74rem;
    border-radius: 0.2rem;
    cursor: pointer;
`;

const UploadImageText = styled(Text) <any>`
    position: absolute;
    text-align: center;
    top:0;
    bottom:0;
    right: 0;
    left:0;
    display: flex;
    gap: 0.2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    color:  ${({ isDarkTheme }) => (isDarkTheme ? palette.light_gray : palette.gray)};
`;

const Mandatory = styled.span`
	color: ${palette.danger};
`

export default SignUp
