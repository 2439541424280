import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Flexed, Heading, Spacer, Text, DletelTag, DisplayItemBaner} from '../../styled/shared'
import {useSelector} from 'react-redux'
import moment from 'moment'
import {BiTimer} from 'react-icons/bi'
import {getDistanceFromLatLonInMiles, verifyJSON, weekday} from '../../constants/commonFunctions'
import {toast} from 'react-toastify'
import {GoDotFill} from 'react-icons/go'

const MenuList = ({menuListStatus, business, setOpenCartModal, setSelectedItem, displayItemInfo, ownMenu, editMenu, deleteMenu}: any) => {
	const _loginUserStatus: any = localStorage.getItem('userStatus')
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginBusinessDetails: any = useSelector<any>((state: any) => state.auth.loginBusinessDetails)
	const [distanceInMiles, setDistanceInMiles]: any = useState('')
	let photo = verifyJSON(displayItemInfo?.photo) ? JSON.parse(displayItemInfo?.photo) : displayItemInfo?.photo
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _day = new Date().getDay()
	const currentDay = weekday[_day]?.toLowerCase()
	const currentTime = moment()
	const openingTime = moment(business?.workingHours[currentDay + 'From'], 'HH:mm')
	const closingTime = moment(business?.workingHours[currentDay + 'To'], 'HH:mm')

	useEffect(() => {
		doGetDistanceFromLatLonInMiles()
	}, [business])

	const doGetDistanceFromLatLonInMiles = async () => {
		if (business?.location) {
			let locationJson = business?.location
			let locationObject = verifyJSON(locationJson) ? JSON.parse(locationJson) : locationJson
			var fullAddress = locationObject?.address
			var address = fullAddress?.split('#')
			if (address?.length && address[1]) {
				var businessCoords = address[1]?.split(' ')
			}
		}
		if (_loginUserStatus === 'owner') {
			let _loginBusinessLocationJson = _loginBusinessDetails?.location
			let _loginBusinessLocationObject = verifyJSON(_loginBusinessLocationJson) ? JSON.parse(_loginBusinessLocationJson) : _loginBusinessLocationJson
			var _loginBusinessFullAddress = _loginBusinessLocationObject?.address
			var _loginBusinessAddress = _loginBusinessFullAddress?.split('#')
			if (_loginBusinessAddress[1]) {
				var _loginBusinessCoords = _loginBusinessAddress[1]?.split(' ')
				if (parseFloat(businessCoords[1]) && parseFloat(businessCoords[2]) && _loginBusinessCoords[1] && _loginBusinessCoords[2]) {
					const res = await getDistanceFromLatLonInMiles(parseFloat(businessCoords[1]), parseFloat(businessCoords[2]), _loginBusinessCoords[1], _loginBusinessCoords[2])
					setDistanceInMiles(res)
				}
			}
		} else if (_loginUserStatus === 'customer') {
			let _loginUserLocationJson = _loginUserDetails?.address
			let _loginUserLocationObject = verifyJSON(_loginUserLocationJson) ? JSON.parse(_loginUserLocationJson) : _loginUserLocationJson
			var _loginUserFullAddress = _loginUserLocationObject?.address
			var _loginUserAddress = _loginUserFullAddress?.split('#')
			if (_loginUserAddress[1]) {
				var _loginUserCoords = _loginUserAddress[1]?.split(' ')
				if (parseFloat(businessCoords[1]) && parseFloat(businessCoords[2]) && _loginUserCoords[1] && _loginUserCoords[2]) {
					const res = await getDistanceFromLatLonInMiles(parseFloat(businessCoords[1]), parseFloat(businessCoords[2]), _loginUserCoords[1], _loginUserCoords[2])
					setDistanceInMiles(res)
				}
			}
		}
	}
	return (
		<>
			<Wrapper
				isDarkTheme={_isDarkTheme}
				onClick={() => {
					setSelectedItem(displayItemInfo)
					if (ownMenu) {
					} else if (parseFloat(distanceInMiles) <= parseFloat(business?.deliveryRadius)) {
						if (business?.workingHours[weekday[_day]?.toLowerCase() + 'On']) {
							if (currentTime.isBetween(openingTime, closingTime)) {
								setOpenCartModal(true)
								menuListStatus()
							} else {
								toast.error('Now the business is closed.')
							}
						} else {
							toast.error('Today the business is closed.')
						}
					} else {
						if (_loginUserStatus === null) {
							setOpenCartModal(true)
							menuListStatus()
						} else {
							toast.error('We are unable to deliver to your location as it is currently outside our service range.')
						}
					}
				}}>
				{!displayItemInfo?.displayItem && (
					<DisplayItemBaner margin="auto" bottom="0.5" right="1" zIndex="1">
						Not Listed
					</DisplayItemBaner>
				)}
				{!displayItemInfo?.isUnlimited && (
					<>
						{!moment().isBetween(moment(displayItemInfo?.activeFrom, 'DD-MM-YYYY HH:mm'), moment(displayItemInfo?.activeTo, 'DD-MM-YYYY HH:mm')) && (
							<DisplayItemBaner margin="auto" bottom="0.5" right="1" zIndex="1">
								<BiTimer fontSize="1rem" color="white" /> Not Active
							</DisplayItemBaner>
						)}
					</>
				)}
				<Content isDisplay={displayItemInfo?.displayItem}>
					<MainHeading level={6} fontWeight={700} isDarkTheme={_isDarkTheme}>
						{displayItemInfo?.title}
					</MainHeading>
					<Spacer height={0.5} />
					<Flexed gap={0.5}>
						<Description type="small" fontWeight={500} isDarkTheme={_isDarkTheme}>
							{displayItemInfo?.tagLine && (
								<Tag type="small" fontWeight={600} color={displayItemInfo?.tagLine === 'Vegan' ? 'vegan' : displayItemInfo?.tagLine === 'Popular' ? 'popular' : displayItemInfo?.tagLine === 'Recommended' ? 'recommended' : 'hot'}>
									{displayItemInfo?.tagLine}
									&nbsp;
									<GoDotFill fontSize={8} />
									&nbsp;
								</Tag>
							)}
							{displayItemInfo?.description}
						</Description>
						{/* <Flexed direction='row' gap={0.5} align='center' flexWrap='wrap'> */}
						<Flexed direction="row" gap={0.5} flexWrap="wrap" align="center">
							<Text fontSize={0.95} fontWeight={600} color={displayItemInfo?.discount && displayItemInfo?.discount != 0 ? 'danger' : ''} isDarkTheme={_isDarkTheme}>
								{'$' + displayItemInfo?.totalPrice}
							</Text>
							{displayItemInfo?.discount && displayItemInfo?.discount != 0 && (
								<DletelTag isDarkTheme={_isDarkTheme}>
									<Text type="small" fontWeight={500} isDarkTheme={_isDarkTheme}>
										{'$' + displayItemInfo?.price}
									</Text>
								</DletelTag>
							)}
							<Text type="small" fontWeight={500} color="danger">
								{displayItemInfo?.discount && displayItemInfo?.discount != 0
									? `${displayItemInfo?.discountType === 'percent' ? '' : '$'}${displayItemInfo?.discount + (displayItemInfo?.discountType === 'percent' ? '%' : '-flat') + ' ' + `off`}`
									: ''}
							</Text>
						</Flexed>
						{/* </Flexed> */}
						{displayItemInfo?.tax && displayItemInfo?.tax != 0 ? (
							<Text type="xsmall" fontWeight={500} isDarkTheme={_isDarkTheme}>
								{displayItemInfo?.tax + '% tax included in price'}
							</Text>
						) : (
							<Text type="xsmall" fontWeight={500} isDarkTheme={_isDarkTheme}>
								Excluding tax
							</Text>
						)}
						{ownMenu && (
							<Flexed direction="row" gap={1} align="center">
								<Edit type="normal" color="blue" lineHeight={1} fontWeight={600} isDarkTheme={_isDarkTheme} onClick={editMenu}>
									Edit
								</Edit>
								<Delete type="normal" color="danger" lineHeight={1} fontWeight={600} isDarkTheme={_isDarkTheme} onClick={deleteMenu}>
									Delete
								</Delete>
								{displayItemInfo?.displayItem && (displayItemInfo?.isUnlimited || moment().isBetween(moment(displayItemInfo?.activeFrom, 'DD-MM-YYYY HH:mm'), moment(displayItemInfo?.activeTo, 'DD-MM-YYYY HH:mm'))) && (
									<SelfOrder
										type="normal"
										color="green"
										lineHeight={1}
										fontWeight={600}
										isDarkTheme={_isDarkTheme}
										onClick={() => {
											if (business?.workingHours[weekday[_day]?.toLowerCase() + 'On']) {
												if (currentTime.isBetween(openingTime, closingTime)) {
													setOpenCartModal(true)
													menuListStatus()
												} else {
													toast.error('Now the business is closed.')
												}
											} else {
												toast.error('Today the business is closed.')
											}
										}}>
										Order
									</SelfOrder>
								)}
							</Flexed>
						)}
					</Flexed>
				</Content>
				<Cover isDisplay={displayItemInfo?.displayItem}>
					<Image isDarkTheme={_isDarkTheme} src={photo[0]?.startsWith('data:') ? photo[0] : process.env.REACT_APP_IMAGE_URL + photo[0]} />
				</Cover>
			</Wrapper>
		</>
	)
}

const Wrapper = styled.div<any>`
	display: flex;
	justify-content: space-between;
	position: relative;
	gap: 1rem;
	margin: 0 0 2rem;
	width: 100%;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	overflow: hidden;
	box-shadow: ${palette.shadow};
	padding: 1rem;
	cursor: pointer;
	border-radius: 0.39rem;
	transition: all ease 0.25s;
	&:hover {
		box-shadow: ${palette.shadowHover};
	}
`

const MainHeading = styled(Heading)`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
`

const Tag = styled.span<any>`
	font-size: 0.875rem;
	line-height: 1.125rem;
	color: ${({color, isDarkTheme}) => (color ? palette[color] : isDarkTheme ? palette.light_gray : palette.dark_gray)};
	font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '400')};
	letter-spacing: 0.02em;
`

const Description = styled(Text)`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow-wrap: anywhere;
	height: 36px;
`

const Cover = styled.div<any>`
	position: relative;
	opacity: ${({isDisplay}) => (isDisplay ? 1 : 0.5)};
`

const Image = styled.img<any>`
	width: 98px;
	height: 98px;
	object-fit: cover;
	border-radius: 0.39rem;
	border: 1px solid ${({isDarkTheme}) => (isDarkTheme ? palette.light_silver : palette.silver)};
`

const Content = styled.div<any>`
	padding: 0.5rem;
	opacity: ${({isDisplay}) => (isDisplay ? 1 : 0.5)};
`

const Edit = styled(Text)<any>`
	border-bottom: 1.2px solid ${palette.blue};
	width: fit-content;
	&:hover {
		color: ${palette.blue_hover};
	}
`
const Delete = styled(Text)<any>`
	border-bottom: 1.2px solid ${palette.danger};
	width: fit-content;
	&:hover {
		color: ${palette.danger_hover};
	}
`

const SelfOrder = styled(Text)<any>`
	border-bottom: 1.2px solid ${palette.green};
	width: fit-content;
	&:hover {
		color: ${palette.success};
	}
`

export default MenuList
