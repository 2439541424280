import React, { useState, useEffect } from 'react';
import { Spacer, Flexed, Text } from '../../styled/shared';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { palette } from '../../styled/colors';
import styled from 'styled-components';
import Button from '../../components/common/Button';
import { useSelector } from 'react-redux';
import { api } from '../../components/api/callAxios'
import { toast } from 'react-toastify';
import moment from 'moment';
import DropDown from '../../components/common/DropDown';
import { FiClock } from 'react-icons/fi';

const EditRestaurantTimings = ({ editRestaurant, onClose, setIsLoading }: any) => {
    const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
    const [restaurantId, setRestaurantId] = useState('');

    const [monday, setMonday] = useState(false);
    const [tuesday, setTuesday] = useState(false);
    const [wednesday, setWednesday] = useState(false);
    const [thursday, setThursday] = useState(false);
    const [friday, setFriday] = useState(false);
    const [saturday, setSaturday] = useState(false);
    const [sunday, setSunday] = useState(false);

    const [mondayFrom, setMondayFrom] = useState({ label: editRestaurant?.workingHours?.mondayFrom, value: editRestaurant?.workingHours?.mondayFrom });
    const [mondayTo, setMondayTo] = useState({ label: editRestaurant?.workingHours?.mondayTo, value: editRestaurant?.workingHours?.mondayTo });
    const [tuesdayFrom, setTuesdayFrom] = useState({ label: editRestaurant?.workingHours?.tuesdayFrom, value: editRestaurant?.workingHours?.tuesdayFrom });
    const [tuesdayTo, setTuesdayTo] = useState({ label: editRestaurant?.workingHours?.tuesdayTo, value: editRestaurant?.workingHours?.tuesdayTo });
    const [wednesdayFrom, setWednesdayFrom] = useState({ label: editRestaurant?.workingHours?.wednesdayFrom, value: editRestaurant?.workingHours?.wednesdayFrom });
    const [wednesdayTo, setWednesdayTo] = useState({ label: editRestaurant?.workingHours?.wednesdayTo, value: editRestaurant?.workingHours?.wednesdayTo });
    const [thursdayFrom, setThursdayFrom] = useState({ label: editRestaurant?.workingHours?.thursdayFrom, value: editRestaurant?.workingHours?.thursdayFrom });
    const [thursdayTo, setThursdayTo] = useState({ label: editRestaurant?.workingHours?.thursdayTo, value: editRestaurant?.workingHours?.thursdayTo });
    const [fridayFrom, setFridayFrom] = useState({ label: editRestaurant?.workingHours?.fridayFrom, value: editRestaurant?.workingHours?.fridayFrom });
    const [fridayTo, setFridayTo] = useState({ label: editRestaurant?.workingHours?.fridayTo, value: editRestaurant?.workingHours?.fridayTo });
    const [saturdayFrom, setSaturdayFrom] = useState({ label: editRestaurant?.workingHours?.saturdayFrom, value: editRestaurant?.workingHours?.saturdayFrom });
    const [saturdayTo, setSaturdayTo] = useState({ label: editRestaurant?.workingHours?.saturdayTo, value: editRestaurant?.workingHours?.saturdayTo });
    const [sundayFrom, setSundayFrom] = useState({ label: editRestaurant?.workingHours?.sundayFrom, value: editRestaurant?.workingHours?.sundayFrom });
    const [sundayTo, setSundayTo] = useState({ label: editRestaurant?.workingHours?.sundayTo, value: editRestaurant?.workingHours?.sundayTo });

    let businessHours: { label: string; value: any }[] = [];
    for (let i: any = 0; i < 24; i++) {
        for (let j = 0; j < 4; j++) {
            businessHours.push({
                label: moment({
                    hour: i,
                    minute: j * 15,
                }).format('HH:mm'),
                value: moment({ hour: i, minute: j * 15 }).format('HH:mm'),
            });
        }
    }

    useEffect(() => {
        setRestaurantId(editRestaurant?.id)

        setMonday(editRestaurant?.workingHours?.mondayOn)
        setTuesday(editRestaurant?.workingHours?.tuesdayOn)
        setWednesday(editRestaurant?.workingHours?.wednesdayOn)
        setThursday(editRestaurant?.workingHours?.thursdayOn)
        setFriday(editRestaurant?.workingHours?.fridayOn)
        setSaturday(editRestaurant?.workingHours?.saturdayOn)
        setSunday(editRestaurant?.workingHours?.sundayOn)

        setMondayFrom({ label: editRestaurant?.workingHours?.mondayFrom, value: editRestaurant?.workingHours?.mondayFrom })
        setMondayTo({ label: editRestaurant?.workingHours?.mondayTo, value: editRestaurant?.workingHours?.mondayTo })
        setTuesdayFrom({ label: editRestaurant?.workingHours?.tuesdayFrom, value: editRestaurant?.workingHours?.tuesdayFrom })
        setTuesdayTo({ label: editRestaurant?.workingHours?.tuesdayTo, value: editRestaurant?.workingHours?.tuesdayTo })
        setWednesdayFrom({ label: editRestaurant?.workingHours?.wednesdayFrom, value: editRestaurant?.workingHours?.wednesdayFrom })
        setWednesdayTo({ label: editRestaurant?.workingHours?.wednesdayTo, value: editRestaurant?.workingHours?.wednesdayTo })
        setThursdayFrom({ label: editRestaurant?.workingHours?.wednesdayFrom, value: editRestaurant?.workingHours?.wednesdayFrom })
        setThursdayTo({ label: editRestaurant?.workingHours?.wednesdayTo, value: editRestaurant?.workingHours?.wednesdayTo })
        setFridayFrom({ label: editRestaurant?.workingHours?.fridayFrom, value: editRestaurant?.workingHours?.fridayFrom })
        setFridayTo({ label: editRestaurant?.workingHours?.fridayTo, value: editRestaurant?.workingHours?.fridayTo })
        setSaturdayFrom({ label: editRestaurant?.workingHours?.saturdayFrom, value: editRestaurant?.workingHours?.saturdayFrom })
        setSaturdayTo({ label: editRestaurant?.workingHours?.saturdayTo, value: editRestaurant?.workingHours?.saturdayTo })
        setSundayFrom({ label: editRestaurant?.workingHours?.sundayFrom, value: editRestaurant?.workingHours?.sundayFrom })
        setSundayTo({ label: editRestaurant?.workingHours?.sundayTo, value: editRestaurant?.workingHours?.sundayTo })
    }, [editRestaurant])

    const editRestaurantDetails = () => {
        api.put(`/workinghours/${restaurantId}`, {
            userId: editRestaurant?.workingHours?.userId,
            mondayFrom: mondayFrom.value,
            mondayTo: mondayTo.value,
            tuesdayFrom: tuesdayFrom.value,
            tuesdayTo: tuesdayTo.value,
            wednesdayFrom: wednesdayFrom.value,
            wednesdayTo: wednesdayTo.value,
            thursdayFrom: thursdayFrom.value,
            thursdayTo: thursdayTo.value,
            fridayFrom: fridayFrom.value,
            fridayTo: fridayTo.value,
            saturdayFrom: saturdayFrom.value,
            saturdayTo: saturdayTo.value,
            sundayFrom: sundayFrom.value,
            sundayTo: sundayTo.value,
            mondayOn: monday,
            tuesdayOn: tuesday,
            wednesdayOn: wednesday,
            thursdayOn: thursday,
            fridayOn: friday,
            saturdayOn: saturday,
            sundayOn: sunday,
        }).then((res) => {
            if (res.data) {
                setIsLoading(false)
                toast.success('Details updated successfully.')
                onClose()
            }

        }).catch((e) => {
            if (e?.response) {
                toast.error(e?.response?.data?.message)
                setIsLoading(false)
            }
        })
    }

    const calculateHoursDifference = (from: any, to: any) => {
        let time = 0
        if (from && to) {
            let Start_Time = moment(`02-01-2021 ${from}`, 'DD-MM-YYYY hh:mm:ss');
            let End_Time = moment(`02-01-2021 ${to}`, 'DD-MM-YYYY hh:mm:ss');
            time = End_Time.diff(Start_Time, 'hours');
        } else {
            time = 0
        }
        return time
    }
    return (<Row justifyContent='center'>
        <Col xl={7} lg={10} mdOrder={1} smOrder={2} xsOrder={2}>
            <Wrapper>
                <Row>
                    <Col lg={12}>
                        <InnerWrapper gap={0.5} justify='space-between'>
                            <Flexed direction='row' gap={0.5} align='center'>
                                <div>
                                    <Switch
                                        onClick={() => {
                                            setMonday(!monday)
                                        }}
                                        toggle={monday}
                                        disabled={false}>
                                        <Dot toggle={monday} />
                                    </Switch>
                                </div>
                                <Label type="small" disabled={!monday} fontWeight={500} isDarkTheme={_isDarkTheme}>Monday</Label>
                            </Flexed>
                            <Flexed direction='row' gap={0.5}>
                                <DropWrapper direction='row' align='center' gap={0.5}>
                                    <DropDown timeDrop timeDropEnd={mondayTo.label} disabled={!monday} firstSelected={mondayFrom.label} options={businessHours} hasChanged={(value: any) => setMondayFrom({ label: value, value: value })} dropDownListHeight='150px' />
                                    <DropDown timeDrop timeDropStart={mondayFrom.label} disabled={!monday} firstSelected={mondayTo.label} options={businessHours} hasChanged={(value: any) => setMondayTo({ label: value, value: value })} dropDownListHeight='150px' />
                                </DropWrapper>
                                <Flexed direction='row' align='center' gap={0.2}>
                                    <Clock disabled={!monday} isDarkTheme={_isDarkTheme} />
                                    <HoursText disabled={!monday} type="small" fontWeight={500} isDarkTheme={_isDarkTheme}>{calculateHoursDifference(mondayFrom.label, mondayTo.label) + 'h'}</HoursText>
                                </Flexed>
                            </Flexed>
                        </InnerWrapper>

                        <InnerWrapper gap={0.5} justify='space-between'>
                            <Flexed direction='row' gap={0.5} align='center'>
                                <div>
                                    <Switch
                                        onClick={() => {
                                            setTuesday(!tuesday)
                                        }}
                                        toggle={tuesday}
                                        disabled={false}>
                                        <Dot toggle={tuesday} />
                                    </Switch>
                                </div>
                                <Label type="small" disabled={!tuesday} fontWeight={500} isDarkTheme={_isDarkTheme}>Tuesday</Label>
                            </Flexed>

                            <Flexed direction='row' gap={0.5}>
                                <DropWrapper direction='row' align='center' gap={0.5}>
                                    <DropDown timeDrop timeDropEnd={tuesdayTo.label} disabled={!tuesday} firstSelected={tuesdayFrom.label} options={businessHours} hasChanged={(value: any) => setTuesdayFrom({ label: value, value: value })} dropDownListHeight='150px' />
                                    <DropDown timeDrop timeDropStart={tuesdayFrom.label} disabled={!tuesday} firstSelected={tuesdayTo.label} options={businessHours} hasChanged={(value: any) => setTuesdayTo({ label: value, value: value })} dropDownListHeight='150px' />
                                </DropWrapper>
                                <Flexed direction='row' align='center' gap={0.2}>
                                    <Clock disabled={!tuesday} isDarkTheme={_isDarkTheme} />
                                    <HoursText disabled={!tuesday} type="small" fontWeight={500} isDarkTheme={_isDarkTheme}>{calculateHoursDifference(tuesdayFrom.label, tuesdayTo.label) + 'h'}</HoursText>
                                </Flexed>
                            </Flexed>
                        </InnerWrapper>

                        <InnerWrapper gap={0.5} justify='space-between'>
                            <Flexed direction='row' gap={0.5} align='center'>
                                <div>
                                    <Switch
                                        onClick={() => {
                                            setWednesday(!wednesday)
                                        }}
                                        toggle={wednesday}
                                        disabled={false}>
                                        <Dot toggle={wednesday} />
                                    </Switch>
                                </div>
                                <Label type="small" disabled={!wednesday} fontWeight={500} isDarkTheme={_isDarkTheme}>Wednesday</Label>
                            </Flexed>

                            <Flexed direction='row' gap={0.5}>
                                <DropWrapper direction='row' align='center' gap={0.5}>
                                    <DropDown timeDrop timeDropEnd={wednesdayTo.label} disabled={!wednesday} firstSelected={wednesdayFrom.label} options={businessHours} hasChanged={(value: any) => setWednesdayFrom({ label: value, value: value })} dropDownListHeight='150px' />
                                    <DropDown timeDrop timeDropStart={wednesdayFrom.label} disabled={!wednesday} firstSelected={wednesdayTo.label} options={businessHours} hasChanged={(value: any) => setWednesdayTo({ label: value, value: value })} dropDownListHeight='150px' />
                                </DropWrapper>
                                <Flexed direction='row' align='center' gap={0.2}>
                                    <Clock disabled={!wednesday} isDarkTheme={_isDarkTheme} />
                                    <HoursText disabled={!wednesday} type="samll" fontWeight={500} isDarkTheme={_isDarkTheme}>{calculateHoursDifference(wednesdayFrom.label, wednesdayTo.label) + 'h'}</HoursText>
                                </Flexed>
                            </Flexed>
                        </InnerWrapper>

                        <InnerWrapper gap={0.5} justify='space-between'>
                            <Flexed direction='row' gap={0.5} align='center'>
                                <div>
                                    <Switch
                                        onClick={() => {
                                            setThursday(!thursday)
                                        }}
                                        toggle={thursday}
                                        disabled={false}>
                                        <Dot toggle={thursday} />
                                    </Switch>
                                </div>
                                <Label type="small" disabled={!thursday} fontWeight={500} isDarkTheme={_isDarkTheme}>Thursday</Label>
                            </Flexed>

                            <Flexed direction='row' gap={0.5}>
                                <DropWrapper direction='row' align='center' gap={0.5}>
                                    <DropDown timeDrop timeDropEnd={thursdayTo.label} disabled={!thursday} firstSelected={thursdayFrom.label} options={businessHours} hasChanged={(value: any) => setThursdayFrom({ label: value, value: value })} dropDownListHeight='150px' />
                                    <DropDown timeDrop timeDropStart={thursdayFrom.label} disabled={!thursday} firstSelected={thursdayTo.label} options={businessHours} hasChanged={(value: any) => setThursdayTo({ label: value, value: value })} dropDownListHeight='150px' />
                                </DropWrapper>
                                <Flexed direction='row' align='center' gap={0.2}>
                                    <Clock disabled={!thursday} isDarkTheme={_isDarkTheme} />
                                    <HoursText disabled={!thursday} type="small" fontWeight={500} isDarkTheme={_isDarkTheme}>{calculateHoursDifference(thursdayFrom.label, thursdayTo.label) + 'h'}</HoursText>
                                </Flexed>
                            </Flexed>
                        </InnerWrapper>

                        <InnerWrapper gap={0.5} justify='space-between'>
                            <Flexed direction='row' gap={0.5} align='center'>
                                <div>
                                    <Switch
                                        onClick={() => {
                                            setFriday(!friday)
                                        }}
                                        toggle={friday}
                                        disabled={false}>
                                        <Dot toggle={friday} />
                                    </Switch>
                                </div>
                                <Label type="small" disabled={!friday} fontWeight={500} isDarkTheme={_isDarkTheme}>Friday</Label>
                            </Flexed>

                            <Flexed direction='row' gap={0.5}>
                                <DropWrapper direction='row' align='center' gap={0.5}>
                                    <DropDown timeDrop timeDropEnd={fridayTo.label} disabled={!friday} firstSelected={fridayFrom.label} options={businessHours} hasChanged={(value: any) => setFridayFrom({ label: value, value: value })} dropDownListHeight='150px' />
                                    <DropDown timeDrop timeDropStart={fridayFrom.label} disabled={!friday} firstSelected={fridayTo.label} options={businessHours} hasChanged={(value: any) => setFridayTo({ label: value, value: value })} dropDownListHeight='150px' />
                                </DropWrapper>
                                <Flexed direction='row' align='center' gap={0.2}>
                                    <Clock disabled={!friday} isDarkTheme={_isDarkTheme} />
                                    <HoursText disabled={!friday} type="small" fontWeight={500} isDarkTheme={_isDarkTheme}>{calculateHoursDifference(fridayFrom.label, fridayTo.label) + 'h'}</HoursText>
                                </Flexed>
                            </Flexed>
                        </InnerWrapper>

                        <InnerWrapper gap={0.5} justify='space-between'>
                            <Flexed direction='row' gap={0.5} align='center'>
                                <div>
                                    <Switch
                                        onClick={() => {
                                            setSaturday(!saturday)
                                        }}
                                        toggle={saturday}
                                        disabled={false}>
                                        <Dot toggle={saturday} />
                                    </Switch>
                                </div>
                                <Label type="small" disabled={!saturday} fontWeight={500} isDarkTheme={_isDarkTheme}>Saturday</Label>
                            </Flexed>

                            <Flexed direction='row' gap={0.5}>
                                <DropWrapper direction='row' align='center' gap={0.5}>
                                    <DropDown timeDrop timeDropEnd={saturdayTo.label} disabled={!saturday} firstSelected={saturdayFrom.label} options={businessHours} hasChanged={(value: any) => setSaturdayFrom({ label: value, value: value })} dropDownListHeight='150px' />
                                    <DropDown timeDrop timeDropStart={saturdayFrom.label} disabled={!saturday} firstSelected={saturdayTo.label} options={businessHours} hasChanged={(value: any) => setSaturdayTo({ label: value, value: value })} dropDownListHeight='150px' />
                                </DropWrapper>
                                <Flexed direction='row' align='center' gap={0.2}>
                                    <Clock disabled={!saturday} isDarkTheme={_isDarkTheme} />
                                    <HoursText disabled={!saturday} type="small" fontWeight={500} isDarkTheme={_isDarkTheme}>{calculateHoursDifference(saturdayFrom.label, saturdayTo.label) + 'h'}</HoursText>
                                </Flexed>
                            </Flexed>
                        </InnerWrapper>

                        <InnerWrapper gap={0.5} justify='space-between'>
                            <Flexed direction='row' gap={0.5} align='center'>
                                <div>
                                    <Switch
                                        onClick={() => {
                                            setSunday(!sunday)
                                        }}
                                        toggle={sunday}
                                        disabled={false}>
                                        <Dot toggle={sunday} />
                                    </Switch>
                                </div>
                                <Label type="small" disabled={!sunday} fontWeight={500} isDarkTheme={_isDarkTheme}>Sunday</Label>
                            </Flexed>

                            <Flexed direction='row' gap={0.5}>
                                <DropWrapper direction='row' align='center' gap={0.5}>
                                    <DropDown timeDrop timeDropEnd={sundayTo.label} disabled={!sunday} firstSelected={sundayFrom.label} options={businessHours} hasChanged={(value: any) => setSundayFrom({ label: value, value: value })} dropDownListHeight='150px' />
                                    <DropDown timeDrop timeDropStart={sundayFrom.label} disabled={!sunday} firstSelected={sundayTo.label} options={businessHours} hasChanged={(value: any) => setSundayTo({ label: value, value: value })} dropDownListHeight='150px' />
                                </DropWrapper>
                                <Flexed direction='row' align='center' gap={0.2}>
                                    <Clock disabled={!sunday} isDarkTheme={_isDarkTheme} />
                                    <HoursText disabled={!sunday} type="small" fontWeight={500} isDarkTheme={_isDarkTheme}>{calculateHoursDifference(sundayFrom.label, sundayTo.label) + 'h'}</HoursText>
                                </Flexed>
                            </Flexed>
                        </InnerWrapper>
                    </Col>
                </Row>
            </Wrapper>
        </Col>

        <Col xl={7} lg={10} order={3}>
            <Wrapper>
                <Row>
                    <Col>
                        <Spacer height={1.5} />
                        <Flexed direction="row" justify="center" gap={1}>
                            <Button
                                medium
                                width="100%"
                                label="Cancel"
                                type="cancel"
                                ifClicked={() => {
                                    onClose()
                                }}
                            />
                            <Button medium width="100%" type='red' label="Save" ifClicked={editRestaurantDetails} />
                        </Flexed>
                        <Spacer height={1} />
                    </Col>
                </Row>
            </Wrapper>
        </Col>
    </Row>
    );
};

const Wrapper = styled(Container)`
    padding: 0;
`;

const InnerWrapper = styled(Flexed) <any>`
margin-bottom: 1.5rem;
    ${media.sm`flex-direction: row;align-items: center;margin-bottom: 0rem;`};
    ${media.md`flex-direction: row;align-items: center;margin-bottom: 0.5rem;`};
`

const Switch = styled.div<any>`
	display: flex;
	align-items: center;
	justify-content: ${({ toggle }) => (toggle ? 'flex-end' : 'flex-start')};
	background-color: ${({ toggle }) => (toggle ? palette.red : palette.gray)};
	width: 40px;
	height: 26px;
	border-radius: 1rem;
	padding: 0.5rem 0.1rem;
	transition: justify-content 2s, transform 2s;
	border: 0.063rem solid ${({ toggle }) => (toggle ? palette.red : palette.gray)};
	cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
	opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`

const Dot = styled.div<any>`
	width: 20px;
	height: 20px;
	border-radius: 100%;
	background-color: ${palette.white};
    cursor: pointer;
`

const DropWrapper = styled(Flexed) <any>`
	width: 250px;
`

const Label = styled(Text) <any>`
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`

const Clock = styled(FiClock) <any>`
    color: ${({ isDarkTheme }) => isDarkTheme ? palette.light_gray : palette.dark_gray};
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`

const HoursText = styled(Text) <any>`
	width: 20px;
    opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`

export default EditRestaurantTimings;
