import React, { useState, useEffect } from 'react';
import { Spacer, Flexed, Text } from '../../styled/shared';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { palette } from '../../styled/colors';
import styled from 'styled-components';
import InputField from '../../components/common/InputField';
import Button from '../../components/common/Button';
import { useSelector } from 'react-redux';
import { api } from '../../components/api/callAxios'
import { toast } from 'react-toastify';
import { BsCheckSquareFill } from 'react-icons/bs'
import { ImCheckboxUnchecked } from 'react-icons/im'
import DropDown from '../../components/common/DropDown';
import useWindowSize from '../../hooks/useWindowSize';

const EditRestaurantSettings = ({ editRestaurant, onClose, setIsLoading }: any) => {
    const windowSize = useWindowSize();
    const [thresholdTime, setThresholdTime] = useState([
        { value: '5 min', label: '5 min' },
        { value: '10 min', label: '10 min' },
        { value: '15 min', label: '15 min' },
        { value: '20 min', label: '20 min' },
        { value: '25 min', label: '25 min' },
        { value: '30 min', label: '30 min' },
        { value: '35 min', label: '35 min' },
        { value: '40 min', label: '40 min' },
        { value: '45 min', label: '45 min' },
        { value: '50 min', label: '50 min' },
        { value: '60 min', label: '1 hour' },
    ])

    const [time, setTime] = useState([
        { value: `5 min`, label: `5 min` },
        { value: '10 min', label: '10 min' },
        { value: '15 min', label: '15 min' },
        { value: '20 min', label: '20 min' },
        { value: '25 min', label: '25 min' },
        { value: '30 min', label: '30 min' },
        { value: '35 min', label: '35 min' },
        { value: '40 min', label: '40 min' },
        { value: '45 min', label: '45 min' },
        { value: '50 min', label: '50 min' },
        { value: '60 min', label: '1 hour' },
    ])

    const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
    const [restaurantId, setRestaurantId] = useState('');
    const [deliveryCharges, setDeliveryCharges] = useState<any>(0);
    const [deliveryChargesError, setDeliveryChargesError] = useState('');
    const [avgDeliveryTime, setAvgDeliveryTime] = useState({ label: editRestaurant?.businessSettings?.avgDeliveryTime, value: editRestaurant?.businessSettings?.avgDeliveryTime })
    const [avgThresholdTime, setAvgThresholdTime] = useState({ label: editRestaurant?.businessSettings?.threshold, value: editRestaurant?.businessSettings?.threshold })
    const selectedAvgDeliveryTime = async (val: any) => {
        setAvgDeliveryTime({ label: val, value: val });
    }
    const selectedAvgThresholdTime = async (val: any) => {
        setAvgThresholdTime({ label: val, value: val });
    }
    const [minOrderAmount, setMinOrderAmount] = useState(0);
    const [inHouseDelivery, setInHouseDelivery] = useState(true);
    const [freeDelivery, setFreeDelivery] = useState(true);

    useEffect(() => {
        setRestaurantId(editRestaurant?.businessSettings?.id)
        setDeliveryCharges(editRestaurant?.businessSettings?.deliveryCharges)
        setMinOrderAmount(editRestaurant?.businessSettings?.minOrderAmount)
        setAvgDeliveryTime({ label: editRestaurant?.businessSettings?.avgDeliveryTime, value: editRestaurant?.businessSettings?.avgDeliveryTime })
        setAvgThresholdTime({ label: editRestaurant?.businessSettings?.threshold, value: editRestaurant?.businessSettings?.threshold })

        if (editRestaurant?.businessSettings?.inHouseDelivery != undefined) {
            setInHouseDelivery(editRestaurant?.businessSettings?.inHouseDelivery)
        }
        else {
            setInHouseDelivery(true)
        }
        if (editRestaurant?.businessSettings?.freeDelivery != undefined) {
            setFreeDelivery(editRestaurant?.businessSettings?.freeDelivery)
        }
        else {
            setFreeDelivery(true)
        }
    }, [editRestaurant])

    const editRestaurantDetails = () => {
        if (formValidation()) {
            setIsLoading(true)
            api.put(`/common-business-settings`, {
                businessId: restaurantId,
                deliveryCharges: deliveryCharges,
                freeDelivery: freeDelivery,
                avgDeliveryTime: avgDeliveryTime.value,
                threshold:avgThresholdTime.value,
                minOrderAmount: minOrderAmount,
                inHouseDelivery: inHouseDelivery,
                rating: editRestaurant?.businessSettings.rating,

            }).then((res) => {
                if (res?.data) {
                    setIsLoading(false)
                    toast.success('Details updated successfully.')
                    onClose()
                }

            }).catch((e) => {
                if (e?.response) {
                    toast.error(e?.response?.data?.message)
                    setIsLoading(false)
                }
            })
        }
    }

    const formValidation = () => {
        let isValid = true
        if (inHouseDelivery && !freeDelivery && (deliveryCharges == 0)) {
            isValid = false
            setDeliveryChargesError('Delivery charges is required')
        }
        return isValid
    }
    return (
        <Row justifyContent='center'>
            <Col xl={7} lg={10} mdOrder={1} smOrder={2} xsOrder={2}>
                <Wrapper>
                    <Row>
                        <Col lg={12}>
                            <Flexed margin="0.5rem 0rem 0rem 0rem" direction="row" align="center" style={{ height: '100%' }} >
                                <Flexed direction="row" align="flex-start" >
                                    <CheckIconWrapper>{inHouseDelivery ? <Checked onClick={() => { setInHouseDelivery(!inHouseDelivery); setDeliveryCharges(0) }} /> : <UnChecked isDarkTheme={_isDarkTheme} onClick={() => { setInHouseDelivery(!inHouseDelivery); setDeliveryCharges(0) }} />}</CheckIconWrapper>
                                    <Text pointer type="normal" lineHeight={1} fontWeight={500} isDarkTheme={_isDarkTheme} onClick={() => { setInHouseDelivery(!inHouseDelivery); setDeliveryCharges(0) }}>Allow home delivery</Text>
                                </Flexed>
                            </Flexed>
                            <Spacer height={2} />
                        </Col>
                        <Col lg={6} md={6}>
                            <InputField error={deliveryChargesError} required={inHouseDelivery && !freeDelivery} disabled={!inHouseDelivery || freeDelivery} label="Delivery Charges" value={deliveryCharges} allowOnlyNumbersAndDecimal={true} handleChange={(e: any) => {
                                setDeliveryChargesError('');
                                if (e == '') {
                                    setDeliveryChargesError('Delivery charges is required')
                                }
                                setDeliveryCharges(e)
                            }} />
                            <Spacer height={1} />
                        </Col>
                        <Col lg={6} md={6}>
                            <Flexed direction="row" margin={windowSize?.width < 768 ? '0.5rem 0rem 1rem 0rem' : '2.6rem 0rem 1rem 0rem'} style={{ height: '100%' }} >
                                <Flexed direction="row" align="flex-start" >
                                    <CheckIconWrapper>{freeDelivery ? <Checked disabled={!inHouseDelivery} onClick={() => { setFreeDelivery(!freeDelivery); setDeliveryCharges(0) }} /> : <UnChecked disabled={!inHouseDelivery} isDarkTheme={_isDarkTheme} onClick={() => { setFreeDelivery(!freeDelivery); setDeliveryCharges(0) }} />}</CheckIconWrapper>
                                    <Label disabled={!inHouseDelivery} pointer type="normal" lineHeight={1} fontWeight={500} isDarkTheme={_isDarkTheme} onClick={() => { setFreeDelivery(!freeDelivery); setDeliveryCharges(0) }}>Free Delivery</Label>
                                </Flexed>
                            </Flexed>

                        </Col>
                        <Col lg={6}>
                            <DropDown disabled={!inHouseDelivery} label='Avg Delivery Time' subLable={`(${avgDeliveryTime?.label?.split(' ')[0]} - ${parseInt(avgDeliveryTime?.label?.split(' ')[0]) + parseInt(avgThresholdTime?.label?.split(' ')[0])} min)`} firstSelected={avgDeliveryTime.label} name="collection" hasChanged={(value: any) => selectedAvgDeliveryTime(value)} options={time} />
                            <Spacer height={1} />
                        </Col>
                        <Col lg={6}>
                            <DropDown disabled={!inHouseDelivery} label='Threshold Time' firstSelected={avgThresholdTime.label} name="collection" hasChanged={(value: any) => selectedAvgThresholdTime(value)} options={thresholdTime} />
                            <Spacer height={1} />
                        </Col>
                        <Col lg={6}>
                            <InputField disabled={!inHouseDelivery} label="Min Order Amount" value={minOrderAmount} allowOnlyNumbersAndDecimal={true} handleChange={(e: any) => {
                                setMinOrderAmount(e)
                            }} />
                            <Spacer height={1} />
                        </Col>
                    </Row>
                </Wrapper>
            </Col>
            <Col xl={7} lg={10} order={3}>
                <Wrapper>
                    <Row>
                        <Col>
                            <Spacer height={1.5} />
                            <Flexed direction="row" justify="center" gap={1}>
                                <Button
                                    medium
                                    width="100%"
                                    label="Cancel"
                                    type="cancel"
                                    ifClicked={() => {
                                        onClose()
                                    }}
                                />
                                <Button medium width="100%" type='red' label="Save" ifClicked={editRestaurantDetails} />
                            </Flexed>
                            <Spacer height={1} />
                        </Col>
                    </Row>
                </Wrapper>
            </Col>
        </Row>
    );
};

const Wrapper = styled(Container)`
    padding: 0;
`;

const CheckIconWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	cursor: pointer;
	height: 1.25rem;
	/* border-radius: 1rem; */
	width: 1.25rem;
`

const Checked = styled(BsCheckSquareFill) <any>`
	color: ${({ disabled }) => disabled ? palette.silver : palette.red};
    pointer-events: ${({ disabled }) => disabled ? 'none' : ''};
`
const UnChecked = styled(ImCheckboxUnchecked) <any>`
    color: ${({ isDarkTheme }) => isDarkTheme ? palette.light_silver : palette.silver};
    pointer-events: ${({ disabled }) => disabled ? 'none' : ''};
`

const Label = styled(Text)`
    pointer-events: ${({ disabled }) => disabled ? 'none' : ''};
`;



export default EditRestaurantSettings;
