import React, { useState, useEffect } from 'react';
import { Spacer, Flexed, Text } from '../../styled/shared';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { palette } from '../../styled/colors';
import styled from 'styled-components';
import InputField from '../../components/common/InputField';
import Button from '../../components/common/Button';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { api } from '../../components/api/callAxios'
import { toast } from 'react-toastify';
import InputAddress from '../../components/common/InputAddress';
import { GoogleMap, useJsApiLoader, MarkerF,Circle } from '@react-google-maps/api';
import axios from 'axios'
import { verifyJSON } from '../../constants/commonFunctions';
import InputPhoneNo from '../../components/common/InputPhoneNo';
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const EditRestaurantInfo = ({ editRestaurant, onClose, setIsLoading }: any) => {
    const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
    const [restaurantId, setRestaurantId] = useState('');
    const [ownerId, setOwnerId] = useState('');
    const [name, setName] = useState('');
    const [webSite, setWebSite] = useState('');
    const [image, setImage] = useState('');
    const [newImage, setNewImage] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [deliveryRadius, setDeliveryRadius] = useState<any>('');
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isCurrentPassword, setIsCurrentPassword] = useState(false)
    const [showAddressInput, setShowAddressInput] = useState(false)

    const [map, setMap] = useState(null)
    const [clat, setCLat] = useState<any>('')
    const [clng, setCLng] = useState<any>('')

    const [nameError, setNameError] = useState('')
    const [deliveryRadiusError, setDeliveryRadiusError] = useState('')
    const [webSiteError, setWebSiteError] = useState('')
    const [imageError, setImageError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [locationError, setLocationError] = useState('')
    const [newPasswordError, setNewPasswordError] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState('')

    useEffect(() => {
        setRestaurantId(editRestaurant?.id)
        setOwnerId(editRestaurant?.ownerId)
        setName(editRestaurant?.name)
        setWebSite(editRestaurant?.businessWebsite)
        setImage(editRestaurant?.photo ? process.env.REACT_APP_IMAGE_URL + editRestaurant?.photo : '')
        setEmail(editRestaurant?.email)
        setPhone(editRestaurant?.phone)
        if (editRestaurant?.location) {
            let locationJson = editRestaurant?.location;
            let locationObject = verifyJSON(locationJson) ? JSON.parse(locationJson) : locationJson;
            setLocation(locationObject?.address)
            var fullAddress = locationObject?.address;
            var address = fullAddress?.split('#');
            if (address[1]) {
                var coords = address[1]?.split(' ');
                getAddress(parseFloat(coords[1]), parseFloat(coords[2]));
            }
        }
        setDeliveryRadius(editRestaurant?.deliveryRadius)
        setDescription(editRestaurant?.description)
        setShowAddressInput(true)
    }, [editRestaurant])

    const handleCapture = ({ target }: any) => {
        const reader: any = new FileReader();
        reader.readAsDataURL(target.files[0]);
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage(reader?.result);
                setNewImage(reader?.result)
            }
        };
    };

    const editRestaurantDetails = () => {
        if (formValidation()) {
            setIsLoading(true)
            api.put(`/business/${restaurantId}`, {
                name: name,
                businessWebsite: webSite,
                newPhoto: newImage ? newImage : editRestaurant?.photo,
                email: email,
                phone: phone,
                location: { address: location },
                description: description,
                deliveryRadius: deliveryRadius,
            }).then(async (res) => {
                if (res.data) {
                    setIsLoading(false)
                    if (isCurrentPassword) {
                        await UpdatePassword()
                    }
                    else {
                        toast.success('Details updated successfully.')
                        onClose()
                    }

                }

            }).catch((e) => {
                if (e?.response) {
                    toast.error(e?.response?.data?.message)
                    setIsLoading(false)
                }
            })
        }
    }


    const formValidation = () => {
        let isValid = true
        if (name === '') {
            setNameError('name is required')
            isValid = false
        }
        if (deliveryRadius === '' || deliveryRadius == 0) {
            setDeliveryRadiusError('miles are required')
            isValid = false
        }

        if (webSite === '') {
            setWebSiteError('webSite is required')
            isValid = false
        }
        if (email === '') {
            setEmailError('email is required')
            isValid = false
        }
        if (phone === '') {
            setPhoneError('phone number is required')
            isValid = false
        }
        if (description === '') {
            setDescriptionError('description is required')
            isValid = false
        }
        if (location === '') {
            setLocationError('location is required')
            isValid = false
        }
        if (image === '') {
            setImageError('image is required')
            isValid = false
        }
        if (isCurrentPassword) {
            if (newPassword === '') {
                setNewPasswordError('Password must be at least 8 characters.')
                isValid = false
            }
            if (confirmPassword === '') {
                setConfirmPasswordError('Password must match')
                isValid = false
            }
            if (newPassword?.trim().length < 7) {
                setNewPasswordError('Password must be at least 8 characters.')
                isValid = false
            }
            if (newPassword != confirmPassword) {
                setConfirmPasswordError('Password must match')
                isValid = false
            } else {
                setConfirmPasswordError('')
            }
        }
        return isValid
    }

    const containerStyle = {
        width: '100%',
        height: '14.74rem',
    };

    const center = {
        lat: clat ? clat : 38.892708,
        lng: clng ? clng : -94.6426741,
        zoom: 12
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAiSEHvjCeth6Gx8YDltGHUhzBl5ongDRk"
    })

    const getAddress = async (lat: any, lng: any) => {
        await getCurrentLatLng(lat, lng);
    };
    const getCurrentLatLng = async (lat: any, lng: any) => {
        navigator.geolocation.getCurrentPosition(
            async position => {
                setCLat(lat);
                setCLng(lng);
                await getCurrentAddress(lat, lng);
            },
            function (error) {
                // eslint-disable-next-line eqeqeq
                if (error.code == error.PERMISSION_DENIED) {
                } else {
                }
            },
            { timeout: 5000, enableHighAccuracy: true }
        );
    };

    const getCurrentAddress = async (lat: any, lng: any) => {
        setCLat(lat)
        setCLng(lng)
        await axios
            .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(lat)},${parseFloat(lng)}&sensor=true&key=${process.env.REACT_APP_MAP_API_KEY}`)
            .then((response) => {
            })
            .catch((error) => {
            })
    }

    const matchCurrentPassword = () => {
        if (currentPassword.trim()?.length > 0) {
            setIsLoading(true)
            api.put(`/owner/match/password/${ownerId}`, {
                password: currentPassword
            })
                .then((res) => {
                    if (res?.data) {
                        setIsCurrentPassword(true)
                        toast.success('Your current password has been matched successfully.')
                    }
                    else {
                        setIsCurrentPassword(false)
                        toast.error('Your current password does not match.')
                    }
                    setIsLoading(false)
                })
                .catch((e) => {
                    if (e?.response) {
                        toast.error(e?.response?.data?.message)
                        setIsLoading(false)
                    }
                })
        }
    }

    const UpdatePassword = () => {
        if (currentPassword.trim()?.length > 0) {
            setIsLoading(true)
            api.put(`/owner/update_password/${ownerId}`, {
                password: newPassword
            })
                .then((res) => {
                    setIsLoading(false)
                    if (res?.data) {
                        toast.success('Details updated successfully.')
                        onClose()
                    }
                })
                .catch((e) => {
                    if (e?.response) {
                        toast.error(e?.response?.data?.message)
                        setIsLoading(false)
                    }
                })
        }
    }
    return (
        <Row justifyContent="center">
            <Col xl={7} lg={10}>
                <Wrapper>
                    <Row>
                        <Col lg={6} md={6}>
                            <Flexed direction='row' justify='space-between' align='center' margin="0rem 0rem 0.19rem 0rem" >
                                <Text type="normal" fontWeight='500' isDarkTheme={_isDarkTheme}>
                                    Busniess Image <Mandatory>*</Mandatory>
                                </Text>
                                <Text fontSize={0.7} type="small" color="danger">
                                    {imageError}
                                </Text>
                            </Flexed>
                            <Flexed direction="row" justify="center">
                                <ProfileImgCover htmlFor="faceImage" >
                                    <ProfileImg isDarkTheme={_isDarkTheme} url={image} />
                                    {(image === '' || image === null || image === undefined) && <UploadImageText isDarkTheme={_isDarkTheme}><MdOutlineCloudUpload color={_isDarkTheme ? palette.light_gray : palette.gray} fontSize={24} />Upload Image</UploadImageText>}
                                </ProfileImgCover>
                            </Flexed>
                            <Flexed direction="row" justify="center">
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="faceImage"
                                    type="file"
                                    onChange={handleCapture}
                                />
                            </Flexed>
                            <Spacer height={1} />
                        </Col>
                        <Col lg={6} md={6}>
                            <Text type="normal" fontWeight='500' isDarkTheme={_isDarkTheme} margin="0rem 0rem 0.19rem 0rem">
                                location <Mandatory>*</Mandatory>
                            </Text>
                            {isLoaded ? (
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    zoom={center.zoom}
                                    center={center}
                                    onLoad={(map: any) => {
                                        setMap(map);
                                    }}
                                    options={{
                                        mapTypeControl: false,
                                        fullscreenControl: false,
                                        streetViewControl: false,
                                    }}>
                                    <MarkerF
                                        position={{
                                            lat: parseFloat(clat),
                                            lng: parseFloat(clng),
                                        }}
                                        icon={{
                                            // url: `/images/icons/marker.svg`,
                                            scaledSize: new window.google.maps.Size(40, 40),
                                            // @ts-ignore
                                            shape: { coords: [17, 17, 18], type: 'circle' },
                                            optimized: false,
                                        }}
                                    />
                                    <Circle
                                                    center={{ lat: center.lat, lng: center.lng }}
                                                    radius={parseFloat(deliveryRadius ? deliveryRadius : 0 ) * 1609.34} // Convert miles to meters (1 mile = 1609.34 meters)
                                                    options={{
                                                        fillColor: '#FF0000',
                                                        fillOpacity: 0.2,
                                                        strokeColor: '#FF0000',
                                                        strokeOpacity: 1,
                                                        strokeWeight: 1,
                                                    }}
                                                />
                                </GoogleMap>
                            ) : (
                                <Text isDarkTheme={_isDarkTheme}>Loading</Text>
                            )}
                            <Spacer height={1} />
                        </Col>
                        {showAddressInput && <Col lg={12}>
                            <InputAddress label="Address"
                                required
                                address={location}
                                setCLat={setCLat}
                                setCLng={setCLng}
                                isEdit={true}
                                setAddress={(address: any) => {
                                    setLocationError('');
                                    if (address == '') {
                                        setLocationError('location is required')
                                    } setLocation(address)
                                }}
                                error={locationError}
                            />
                            <Spacer height={1} />
                        </Col>
                        }
                        <Col lg={6} md={6}>
                            <InputField error={nameError} label="Business Name" required value={name} handleChange={(e: any) => {
                                setNameError('');
                                if (e == '') {
                                    setNameError('name is required')
                                }
                                setName(e)
                            }} />
                            <Spacer height={1} />
                        </Col>
                        <Col lg={6} md={6}>
                            <InputField
                                error={deliveryRadiusError}
                                required
                                label="Delivery radius in miles"
                                placeholder='Values in miles.'
                                value={deliveryRadius}
                                allowOnlyNumbersAndDecimal={true}
                                onFocus={() => {
                                    if (parseFloat(deliveryRadius) == 0) {
                                        setDeliveryRadius('')
                                        setDeliveryRadiusError('')
                                    }
                                }}
                                onBlur={() => {
                                    if (deliveryRadius?.trim() == '') {
                                        setDeliveryRadius(0)
                                        setDeliveryRadiusError('')
                                    }
                                }}
                                handleChange={(e: any) => {
                                    setDeliveryRadiusError('');
                                    if (e == '') {
                                        setDeliveryRadiusError('miles are required')
                                    }
                                    setDeliveryRadius(e)
                                }}
                            />
                            <Spacer height={1} />
                        </Col>
                        <Col lg={6} md={6}>
                            <InputField error={webSiteError} label="Web Site" value={webSite} required handleChange={(e: any) => {
                                setWebSiteError('');
                                if (e == '') {
                                    setWebSiteError('web site is required')
                                }
                                setWebSite(e)
                            }} />
                            <Spacer height={1} />
                        </Col>
                        <Col lg={6} md={6}>
                            <InputField disabled error={emailError} label="Email" value={email} required handleChange={(e: any) => {
                                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
                                setEmailError('')
                                if (e === '') {
                                    setEmailError('email is required')
                                } else if (!regex.test(e.value)) {
                                    setEmailError('invalid email')
                                }
                                setEmail(e)
                            }} />
                            <Spacer height={1} />
                        </Col>
                        <Col lg={6} md={6}>
                            <InputPhoneNo error={phoneError} label="Phone" required value={phone} handleChange={(e: any) => {
                                setPhoneError('');
                                if (e?.value == '') {
                                    setPhoneError('phone number is required')
                                }
                                setPhone(e?.value)
                            }} />
                            <Spacer height={1} />
                        </Col>

                        <Col lg={6} md={6}>
                            <InputField
                                disabled={isCurrentPassword}
                                type="password"
                                required={isCurrentPassword}
                                label="Current Password"
                                placeholder='Current Password'
                                value={currentPassword}
                                handleChange={(e: any) => {
                                    setCurrentPassword(e)
                                }}
                                onBlur={() => matchCurrentPassword()}
                            />
                            <Spacer height={1} />
                        </Col>

                        <Col lg={6} md={6}>
                            <InputField
                                disabled={!isCurrentPassword}
                                type="password"
                                required={isCurrentPassword}
                                label="New Password"
                                placeholder='New Password'
                                error={newPasswordError}
                                value={newPassword}
                                handleChange={(e: any) => {
                                    setNewPasswordError('');
                                    if (newPassword == '') {
                                        setNewPasswordError('Password must be at least 8 characters.')
                                    }
                                    if (newPassword?.trim().length < 7) {
                                        setNewPasswordError('Password must be at least 8 characters.')
                                    }
                                    setNewPassword(e)
                                }}
                            />
                            <Spacer height={1} />
                        </Col>
                        <Col lg={6} md={6}>
                            <InputField
                                disabled={!isCurrentPassword}
                                type="password"
                                required={isCurrentPassword}
                                error={confirmPasswordError}
                                label="Confirm Password"
                                placeholder='Confirm Password'
                                value={confirmPassword}
                                handleChange={(e: any) => {
                                    if (e?.length < 7) {
                                        setConfirmPasswordError('password must be at least 8 characters.')
                                    } else if (e !== newPassword) {
                                        setConfirmPasswordError('password must match')
                                    } else {
                                        setConfirmPasswordError('')
                                    }
                                    setConfirmPassword(e)
                                }}
                            />
                            <Spacer height={1} />
                        </Col>

                        <Col lg={12}>
                            <InputField error={descriptionError} type='textarea' required placeholder='Type here' label="Business Info" value={description} handleChange={(e: any) => {
                                setDescriptionError('');
                                if (e == '') {
                                    setDescriptionError('description is required')
                                }
                                if (e?.length <= 250) {
                                    setDescription(e)
                                }
                            }} />
                            <Flexed direction='row' justify='flex-end'>
                                <Text isDarkTheme={_isDarkTheme} type='xsmall'>{description?.length}/250</Text>
                            </Flexed>
                            <Spacer height={1} />
                        </Col>
                    </Row>
                </Wrapper>
            </Col>
            <Col xl={7} lg={10} order={3}>
                <Wrapper>
                    <Row>
                        <Col>
                            <Spacer height={1.5} />
                            <Flexed direction="row" justify="center" gap={1}>
                                <Button
                                    medium
                                    width="100%"
                                    label="Cancel"
                                    type="cancel"
                                    ifClicked={() => {
                                        onClose()
                                    }}
                                />
                                <Button medium width="100%" type='red' label="Save" ifClicked={editRestaurantDetails} />
                            </Flexed>
                            <Spacer height={1} />
                        </Col>
                    </Row>
                </Wrapper>
            </Col>
        </Row>
    );
};

const Wrapper = styled(Container)`
    padding: 0;
`;

const ProfileImgCover = styled.label<any>`
    width: 100%;
    position: relative;
    cursor: pointer;
`;

const ProfileImg = styled.div<any>`
    background: ${({ url, defaultImage }) => (url ? `url(${url})` : defaultImage ? '' : palette.silver)};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border:1px solid ${({ isDarkTheme }) => (isDarkTheme ? palette.light_silver : palette.silver)};
    width: 100%;
    height: 14.74rem;
    border-radius: 0.2rem;
    cursor: pointer;
`;

const UploadImageText = styled(Text) <any>`
    position: absolute;
    text-align: center;
    top:0;
    bottom:0;
    right: 0;
    left:0;
    display: flex;
    gap: 0.2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    color:  ${({ isDarkTheme }) => (isDarkTheme ? palette.light_gray : palette.gray)};
`;

const Mandatory = styled.span`
	color: ${palette.danger};
`

export default EditRestaurantInfo;
