import React, { useState } from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { Flexed, Text } from '../../styled/shared'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'
import { useSelector } from 'react-redux'

const InputFieldAuth = ({ label, value, required, type, placeholder, handleChange, disabled, error, errorMsg, bgTransparent, name, register }: any) => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);

	const [visible, setVisible] = useState(false)
	return (
		<>
			{label && (
				<Label type="normal" margin="0rem 0rem 0.19rem 0rem" isDarkTheme={_isDarkTheme}>
					{label} {required ? <Mandatory>*</Mandatory> : ''}
				</Label>
			)}
			{type !== 'textarea' ? (
				<>
					<InputWrapper>
						<TextInput
							value={value}
							type={`${type && !visible ? (type === 'search' ? 'text' : type) : 'text'}`}
							disabled={disabled}
							placeholder={placeholder}
							error={error}
							name={name}
							onChange={(event: any) => handleChange(event.target)}
							bgTransparent={bgTransparent}
							isDarkTheme={_isDarkTheme}
							{...(register && {...register(name, required)})}
						/>
						{type === 'password' && (
							<Icon direction="row" align="center" justify="center" isDarkTheme={_isDarkTheme}>
								{visible ? (
									<Eye
										onClick={() => {
											setVisible(false)
										}}
										isDarkTheme={_isDarkTheme}
									/>
								) : (
									<CloseEye
										onClick={() => {
											setVisible(true)
										}}
										isDarkTheme={_isDarkTheme}
									/>
								)}
							</Icon>
						)}
						{type === 'search' && (
							<Icon direction="row" align="center" justify="center">
								<Search src="/images/icons/search.svg" />
							</Icon>
						)}
					</InputWrapper>
					{required && error && !disabled && (
						<Text fontSize={0.7} type="small" color="danger">
							{error?.message ? error?.message : error}
						</Text>
					)}
				</>
			) : (<>
				<TextArea isDarkTheme={_isDarkTheme} bgTransparent={bgTransparent} row={4} value={value} placeholder={placeholder} onChange={(event: any) => handleChange(event.target)} />
				{required && error && !disabled && (
					<Text fontSize={0.7} type="small" color="danger">
						{error}
					</Text>
				)}
			</>
			)}
		</>
	)
}

const Label = styled(Text)`
	font-weight: 500;
	text-transform: capitalize;
`

const Mandatory = styled.span`
	color: ${palette.danger};
`

const InputWrapper = styled.div`
	position: relative;
`

const TextInput = styled.input<any>`
	font-family: 'Roboto';
	width: 100%;
	line-height: 2rem;
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem;
	border-radius: 0.375rem;
	padding: 0.5rem 0.8rem;
	border: 1px solid ${({ error, disabled, isDarkTheme }) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	color: ${({ disabled, isDarkTheme }) => (disabled ? isDarkTheme ? palette.light_gray : palette.gray_400 : isDarkTheme ? `${palette.light_gray}` : `${palette.dark_gray}`)};
	width: 100%;
	// cursor: ${({ disabled }) => (disabled ? `no-drop` : `pointer`)};
	background: ${({ disabled, bgTransparent, isDarkTheme }) => (bgTransparent ? 'transparent' : disabled ? `` : isDarkTheme ? `${palette.black}` : `${palette.white}`)};

	// &:hover {
	// 	box-shadow: 0 0 0.31rem ${({ error, disabled }) => (disabled ? 'none' : error ? `${palette.danger}` : 'rgba(0, 0, 0, 0.25)')};
	// }
	&:focus {
		border: 1px solid ${({ error, disabled }) => (disabled ? 'none' : error ? `${palette.danger}` : `${palette.red}`)};
	}
	&::placeholder {
		color: ${({ disabled, isDarkTheme }) => (disabled || isDarkTheme ? `${palette.silver}` : `${palette.black}`)};
		opacity: 0.5; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({ disabled, isDarkTheme }) => (disabled || isDarkTheme ? `${palette.silver}` : `${palette.black}`)};
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		// color: ${palette.black};
	}
`

const Icon = styled(Flexed) <any>`
	position: absolute;
	top: 0.063rem;
	bottom: 0.063rem;
	margin: auto;
	right: 0.063rem;
	width: 2.5rem;
	background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	border-bottom-right-radius: 0.375rem;
	border-top-right-radius: 0.375rem;
`

const Eye = styled(BsFillEyeFill) <any>`
	font-size: 1.25rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	opacity: 0.5;
	cursor: pointer;
`
const CloseEye = styled(BsFillEyeSlashFill) <any>`
	font-size: 1.25rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	opacity: 0.5;
	cursor: pointer;
`

const TextArea = styled.textarea<any>`
	font-family: 'Roboto';
	width: 100%;
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem;
	border-radius: 0.375rem;
	padding: 0.5rem 0.8rem;
	border: 1px solid ${({ error, disabled, isDarkTheme }) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	color: ${({ disabled, isDarkTheme }) => (disabled ? isDarkTheme ? palette.light_gray : palette.gray_400 : isDarkTheme ? `${palette.light_gray}` : `${palette.dark_gray}`)};
	width: 100%;
	// cursor: ${({ disabled }) => (disabled ? `no-drop` : `pointer`)};
	background: ${({ disabled, bgTransparent, isDarkTheme }) => (bgTransparent ? 'transparent' : disabled ? `${palette.silver}` : isDarkTheme ? `${palette.black}` : `${palette.white}`)};

	// &:hover {
	// 	box-shadow: 0 0 0.31rem ${({ error, disabled }) => (disabled ? 'none' : error ? `${palette.danger}` : 'rgba(0, 0, 0, 0.25)')};
	// }
	&:focus {
		border: 1px solid ${({ error, disabled }) => (disabled ? 'none' : error ? `${palette.danger}` : `${palette.red}`)};
	}
	&::placeholder {
		color: ${({ disabled, isDarkTheme }) => (disabled || isDarkTheme ? `${palette.silver}` : `${palette.black}`)};
		opacity: 0.5; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: ${({ disabled, isDarkTheme }) => (disabled || isDarkTheme ? `${palette.silver}` : `${palette.black}`)};
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		// color: ${palette.black};
	}
`

const Search = styled.img`
	width: 1rem;
`

export default InputFieldAuth
