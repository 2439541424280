import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { Heading, Text, Spacer, Flexed, Divider } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { BsXLg } from 'react-icons/bs'
import { FaPlus, FaMinus } from "react-icons/fa";
import { useSelector } from 'react-redux'
import { GoogleMap, useJsApiLoader, MarkerF, Circle } from '@react-google-maps/api';
import axios from 'axios'
import { verifyJSON } from '../../constants/commonFunctions'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

interface IProps {
    isDarkTheme: boolean,
}

const RestaurantInfoModal = ({ open, onCloseModal, business }: any) => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme);
    const [map, setMap] = useState(null)
    const [clat, setCLat] = useState<any>('')
    const [clng, setCLng] = useState<any>('')
    const [location, setLocation] = useState('')

    useEffect(() => {
        if (business?.location?.address) {
            let locationJson = business?.location;
            let locationObject = verifyJSON(locationJson) ? JSON.parse(locationJson) : locationJson;
            var fullAddress = locationObject?.address;
            var address = fullAddress?.split('#');
            setLocation(address[0]);
            if (address[1]) {
                var coords = address[1]?.split(' ');
                getAddress(parseFloat(coords[1]), parseFloat(coords[2]));
            }
        }
    }, [open])


    const containerStyle = {
        width: '100%',
        height: '330px'
    };

    const center = {
        lat: clat ? clat : 38.892708,
        lng: clng ? clng : -94.6426741,
        zoom: 12
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAiSEHvjCeth6Gx8YDltGHUhzBl5ongDRk"
    })

    const getAddress = async (lat: any, lng: any) => {
        await getCurrentLatLng(lat, lng);
    };

    const getCurrentLatLng = async (lat: any, lng: any) => {
        navigator.geolocation.getCurrentPosition(
            async position => {
                setCLat(lat);
                setCLng(lng);
                await getCurrentAddress(lat, lng);
            },
            function (error) {
                // eslint-disable-next-line eqeqeq
                if (error.code == error.PERMISSION_DENIED) {
                } else {
                }
            },
            { timeout: 5000, enableHighAccuracy: true }
        );
    };

    const getCurrentAddress = async (lat: any, lng: any) => {
        setCLat(lat)
        setCLng(lng)
        await axios
            .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(lat)},${parseFloat(lng)}&sensor=true&key=${process.env.REACT_APP_MAP_API_KEY}`)
            .then((response) => {
            })
            .catch((error) => {
            })
    }
    return (
        <>
            <Modal
                open={open}
                center
                onClose={() => {
                    onCloseModal()
                }}
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'selectItemModal'
                }}
                showCloseIcon={false}
                // @ts-ignore
                styles={{ zIndex: '2' }}>
                <ModalWrapper isDarkTheme={_isDarkTheme}>
                    <Head direction="row" align="center" justify="space-between">
                        <Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
                            Business Info
                        </Text>
                        <CrossIcon
                            isDarkTheme={_isDarkTheme}
                            onClick={() => {
                                onCloseModal()
                            }}
                        />
                    </Head>
                    <Body>
                        {business?.description && <> <HeadSection isDarkTheme={_isDarkTheme}>
                            <Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
                                About {business?.name}
                            </Text>
                        </HeadSection>
                            <Content>
                                <Text isDarkTheme={_isDarkTheme}>{business?.description}</Text>
                            </Content>
                            <Spacer height={0.5} />
                        </>
                        }
                        <HeadSection isDarkTheme={_isDarkTheme}>
                            <Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
                                Visit Site
                            </Text>
                        </HeadSection>
                        <Content>
                            <CustomText type='normal' isDarkTheme={_isDarkTheme} pointer onClick={() => { window.open(business?.businessWebsite.startsWith('https://') || business?.businessWebsite.startsWith('http://') ? business?.businessWebsite : `http://${business?.businessWebsite}`, '_blank') }}>
                                {business?.businessWebsite}
                            </CustomText>
                        </Content>
                        <HeadSection isDarkTheme={_isDarkTheme}>
                            <Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
                                Contact at
                            </Text>
                        </HeadSection>
                        <Content>
                            <CustomText type='normal' isDarkTheme={_isDarkTheme} pointer onClick={() => {
                                const mailtoLink = `mailto:${business?.email}`
                                window.location.href = mailtoLink
                            }}>
                                {business?.email}
                            </CustomText>
                            <Spacer height={0.3} />
                            <CustomText type='normal' isDarkTheme={_isDarkTheme} pointer onClick={() => {
                                const telLink = `tel:${business?.phone}`
                                window.location.href = telLink
                            }}>
                                +{business?.phone}
                            </CustomText>
                        </Content>

                        <HeadSection isDarkTheme={_isDarkTheme}>
                            <Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
                                Delivery Radius
                            </Text>
                        </HeadSection>
                        <Content>
                            <Text type='normal' isDarkTheme={_isDarkTheme}>
                                {business?.deliveryRadius} miles
                            </Text>
                        </Content>

                        <HeadSection isDarkTheme={_isDarkTheme}>
                            <Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
                                Location
                            </Text>
                        </HeadSection>

                        <Content>
                            <CustomText isDarkTheme={_isDarkTheme} pointer
                                onClick={() => {
                                    const address = encodeURIComponent(location);
                                    const mapLink = `https://www.google.com/maps?q=${address}`;
                                    window.open(mapLink, '_blank');
                                }}>{location}</CustomText>
                            <CustomText pointer
                                onClick={() => {
                                    const mapLink = `https://www.google.com/maps?q=${clat},${clng}`;
                                    window.open(mapLink, '_blank');
                                }}>
                                {'latitude:' +
                                    ' ' +
                                    `${clat}` +
                                    ' ' +
                                    'longitude:' +
                                    ' ' +
                                    `${clng}`}
                            </CustomText>
                            <Flexed direction="row" align="center" margin="1rem 0rem 0rem 0rem">
                                {isLoaded ? (
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        zoom={center.zoom}
                                        center={center}
                                        onLoad={(map: any) => {
                                            setMap(map);
                                        }}
                                        options={{
                                            mapTypeControl: false,
                                            fullscreenControl: false,
                                            streetViewControl: false,
                                        }}>
                                        <MarkerF
                                            position={{
                                                lat: parseFloat(clat),
                                                lng: parseFloat(clng),
                                            }}
                                            icon={{
                                                // url: `/images/icons/marker.svg`,
                                                scaledSize: new window.google.maps.Size(40, 40),
                                                // @ts-ignore
                                                shape: { coords: [17, 17, 18], type: 'circle' },
                                                optimized: false,
                                            }}
                                        />
                                        <Circle
                                            center={{ lat: center.lat, lng: center.lng }}
                                            radius={parseFloat(business?.deliveryRadius ? business?.deliveryRadius : 0) * 1609.34} // Convert miles to meters (1 mile = 1609.34 meters)
                                            options={{
                                                fillColor: '#FF0000', // Replace with your preferred color
                                                fillOpacity: 0.2, // Adjust the opacity as needed
                                                strokeColor: '#FF0000', // Replace with your preferred color
                                                strokeOpacity: 1,
                                                strokeWeight: 1,
                                            }}
                                        />
                                    </GoogleMap>
                                ) : (
                                    <Text isDarkTheme={_isDarkTheme}>Loading</Text>
                                )}
                            </Flexed>
                        </Content>
                        <Divider isDarkTheme={_isDarkTheme} />
                        <Divider isDarkTheme={_isDarkTheme} />
                    </Body>
                </ModalWrapper>
            </Modal>
        </>
    )
}

const ModalWrapper = styled.div<IProps>`
position:relative;
background-color: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};`

const Head = styled(Flexed)`
	padding: 1rem;
`

const Body = styled.div`
    max-height:35rem;
    overflow-y: scroll;
    overflow-x: hidden;
`

const Content = styled.div`
    padding:1rem;
`

const CrossIcon = styled(BsXLg) <IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
    color: ${({ isDarkTheme }) => (isDarkTheme ? palette.silver : palette.gray)}; 
    font-size:1.3rem;
    background: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 2rem;
    padding: 0.8rem;
`

const HeadSection = styled.div<any>`
    background: ${({ isDarkTheme }) => (isDarkTheme ? palette.light_black : palette.gray_300)};
    border-top: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? palette.dark_gray : palette.light_gray)};
    border-bottom: 1px solid ${({ isDarkTheme }) => (isDarkTheme ? palette.dark_gray : palette.light_gray)};
    padding: 1rem;
`;

const CustomText = styled(Text) <any>`
   &:hover{
    color: ${palette.red_hover_text};
   }
`;




export default RestaurantInfoModal
