import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import PlacesAutocomplete, {
	geocodeByAddress,
	geocodeByPlaceId,
	getLatLng
	// @ts-ignore
} from 'react-places-autocomplete'
import { BsXLg } from 'react-icons/bs'
import { BiCurrentLocation } from 'react-icons/bi'
import { useSelector } from 'react-redux'

const LocationSearch = ({sideBarRefresh, setSearchLocation, appSearch }: any) => {
	const [address, setAddress] = useState('')
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const handleSelect = (address: any) => {
		if (address !== '') {
			setAddress(address)
			setSearchLocation(address)
			appSearch(address)
		}
	}
	useEffect(()=>{
		if(address === ''){
			setAddress('')
			setSearchLocation('')
			appSearch('')
		}
	},[address])

	useEffect(()=>{
			setAddress('')
			setSearchLocation('')
			appSearch('')
	},[sideBarRefresh])

	return (
		<>
			<PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
			{/* searchOptions={{
				types: ["(cities)"], // Restrict the results to city names
			}} */}
				{/* searchOptions={{
				componentRestrictions: { country: "PK" }, // Specify the country code here
			}} */}
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
					<div>
						<InnerWrapper isDarkTheme={_isDarkTheme}>
							<LocationIcon isDarkTheme={_isDarkTheme} src="/images/icons/location_icon.svg" />
							<Input width={'100%'} borderRadius={'0.2rem'} margin={'0'} padding={'0.8rem 0.8rem 0.8rem 2.5rem'} value={address} {...getInputProps()} isDarkTheme={_isDarkTheme} placeholder="Search location" />
							{address ? (
								<CrossIcon isDarkTheme={_isDarkTheme}
									onClick={() => {
										setAddress('')
										setSearchLocation('')
										appSearch('')
									}}
								/>
							) : (null
							)}
						</InnerWrapper>
						<ListItemsWrappeer isDarkTheme={_isDarkTheme}>
							{loading ? (
								<ListItems>
									<LocationList isDarkTheme={_isDarkTheme}>Fetching locations</LocationList>{' '}
								</ListItems>
							) : null}
							{suggestions.map((suggestion: any, i: any) => {
								const style = {
									backgroundColor: suggestion.active ? '#F5F3ED' : `${_isDarkTheme ? `${palette.black}` : `${palette.white}`}`
								}
								return (
									<LocationList
									isDarkTheme={_isDarkTheme}
										key={i + 'mapkey'}
										{...getSuggestionItemProps(suggestion, {
											style
										})}>
										{suggestion?.description}
									</LocationList>
								)
							})}
						</ListItemsWrappeer>
					</div>
				)}
			</PlacesAutocomplete>
		</>
	)
}

const InnerWrapper = styled.div<any>`
	width: 100%;
	position: relative;
	// z-index: 5;
	// border: 1px solid ${({ error, disabled, isDarkTheme }) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	border-radius: 0.375rem;
	
`

const Input = styled.input<any>`
font-size: 0.875rem;
	width: 100%;
	border: 0;
	outline: none;
	padding: 0.5rem 2.3rem;
	line-height: 2rem;
	background: ${({ disabled, bgTransparent, isDarkTheme }) => (bgTransparent ? 'transparent' : disabled ? `${palette.silver}` : isDarkTheme ? `${palette.black}` : `${palette.white}`)};
	color: ${({ disabled, isDarkTheme }) => (disabled ? isDarkTheme ? palette.light_gray : palette.gray_400 : isDarkTheme ? `${palette.light_gray}` : `${palette.dark_gray}`)};
	border-radius: 0.375rem;
	border: 1px solid ${({error, disabled, isDarkTheme}) => (disabled ? `${palette.silver}` : error ? `${palette.danger}` : isDarkTheme ? `${palette.light_silver}` : `${palette.silver}`)};
	&:focus {
		border: 1px solid ${({ error, disabled }) => (disabled ? 'none' : error ? `${palette.danger}` : `${palette.red}`)};
	}
`

const LocationIcon = styled.img<any>`
	position: absolute;
	height: 1rem;
	top: 0;
	left: 1rem;
	bottom: 0;
	margin: auto;
	// z-index: 1;
	filter: ${({ isDarkTheme }) => (isDarkTheme ? 'invert(100%) sepia(100%) saturate(7%) hue-rotate(137deg) brightness(102%) contrast(102%);' : '')};
`

const CrossIcon = styled(BsXLg)<any>`
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 1rem;
	bottom: 0;
	margin: auto;
	z-index: 1;
	padding: 0.2rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	font-size: 1.3rem;
`

const CurrentLocation = styled(BiCurrentLocation)`
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 1rem;
	bottom: 0;
	margin: auto;
	// z-index: 1;
	padding: 0.2rem;
	color: ${palette.gray};
	font-size: 1.6rem;
`
const ListItemsWrappeer = styled.div<any>`
	position: absolute;
	background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	z-index: 4;
	width: calc(100% - 1.875rem);
	border-radius: 0.5rem;
	box-shadow:0px 4px 10px rgba(0, 0, 0, 0.10);
	max-height: 10rem;
	overflow: auto;
`

const ListItems = styled.div<any>`
	// position: absolute;
	background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
    color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	z-index: 4;
	// width: calc(100% - 1.875rem);
	border-radius: 0.5rem;
	box-shadow:0px 4px 10px rgba(0, 0, 0, 0.10);
`

const LocationList = styled.div<any>`
background: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.black}` : `${palette.white}`)};
color: ${({ isDarkTheme }) => (isDarkTheme ? `${palette.silver}` : `${palette.gray}`)};
	text-align: left;
	padding: 0.5rem;
	cursor: pointer;
	font-weight: 300;
	font-size: 1rem;
	border-radius: 0.5rem;
	overflow: hidden;
    overflow-wrap: anywhere;
	&:hover {
		background-color: ${({ isDarkTheme }) => (isDarkTheme ? palette.red : palette.red)} !important;
		color: ${palette.white};
	}
`

export default LocationSearch
