import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {palette} from '../../styled/colors'
import {Flexed, Heading, Spacer, Text} from '../../styled/shared'
import {AiFillStar, AiOutlineHeart, AiFillHeart} from 'react-icons/ai'
import {useSelector} from 'react-redux'
import {createSearchParams, useNavigate} from 'react-router-dom'
import {getRatingText, weekday, getDistanceFromLatLonInMiles, verifyJSON} from '../../constants/commonFunctions'
import CheckBusinessTime from '../common/CheckBusinessTime'

interface IBusinessCardProps {
	business: any
	view?: boolean
}

const RestaurantCardContent = ({view, business}: IBusinessCardProps) => {
	const [addFav, setAddFav] = useState(false)
	const _isDarkTheme = useSelector<any>((state: any) => state.auth.isDarkTheme)
	let _navigate = useNavigate()
	const _day = new Date().getDay()
	const _loginUserStatus: any = localStorage.getItem('userStatus')
	const _loginUserDetails: any = useSelector<any>((state: any) => state.auth.loginUserDetails)
	const _loginBusinessDetails: any = useSelector<any>((state: any) => state.auth.loginBusinessDetails)
	const [distanceInMiles, setDistanceInMiles]: any = useState('')

	useEffect(() => {
		doGetDistanceFromLatLonInMiles()
	}, [business])

	const doGetDistanceFromLatLonInMiles = async () => {
		if (business?.location) {
			let locationJson = business?.location
			let locationObject = verifyJSON(locationJson) ? JSON.parse(locationJson) : locationJson
			var fullAddress = locationObject?.address
			var address = fullAddress?.split('#')
			if (address?.length && address[1]) {
				var businessCoords = address[1]?.split(' ')
			}
		}
		if (_loginUserStatus === 'owner') {
			let _loginBusinessLocationJson = _loginBusinessDetails?.location
			let _loginBusinessLocationObject = verifyJSON(_loginBusinessLocationJson) ? JSON.parse(_loginBusinessLocationJson) : _loginBusinessLocationJson
			var _loginBusinessFullAddress = _loginBusinessLocationObject?.address
			var _loginBusinessAddress = _loginBusinessFullAddress?.split('#')
			if (_loginBusinessAddress[1]) {
				var _loginBusinessCoords = _loginBusinessAddress[1]?.split(' ')
				if (parseFloat(businessCoords[1]) && parseFloat(businessCoords[2]) && _loginBusinessCoords[1] && _loginBusinessCoords[2]) {
					const res = await getDistanceFromLatLonInMiles(parseFloat(businessCoords[1]), parseFloat(businessCoords[2]), _loginBusinessCoords[1], _loginBusinessCoords[2])
					setDistanceInMiles(res)
				}
			}
		} else if (_loginUserStatus === 'customer') {
			let _loginUserLocationJson = _loginUserDetails?.address
			let _loginUserLocationObject = verifyJSON(_loginUserLocationJson) ? JSON.parse(_loginUserLocationJson) : _loginUserLocationJson
			var _loginUserFullAddress = _loginUserLocationObject?.address
			var _loginUserAddress = _loginUserFullAddress?.split('#')
			if (_loginUserAddress[1]) {
				var _loginUserCoords = _loginUserAddress[1]?.split(' ')
				if (parseFloat(businessCoords[1]) && parseFloat(businessCoords[2]) && _loginUserCoords[1] && _loginUserCoords[2]) {
					const res = await getDistanceFromLatLonInMiles(parseFloat(businessCoords[1]), parseFloat(businessCoords[2]), _loginUserCoords[1], _loginUserCoords[2])
					setDistanceInMiles(res)
				}
			}
		}
	}
	return (
		<Wrapper
			isDarkTheme={_isDarkTheme}
			onClick={() => {
				_navigate({
					pathname: '/business-details',
					search: createSearchParams({
						id: business.id
					}).toString()
				})
			}}
			view={view}>
			<Cover view={view}>
				<Image view={view} src={process.env.REACT_APP_IMAGE_URL + business?.photo} />
			</Cover>
			<Content view={view}>
				<Heading level={5} fontWeight={700} isDarkTheme={_isDarkTheme}>
					{business?.name ? business?.name : '(NO NAME)'}
				</Heading>
				<RatingAndTime justify="space-between" view={view}>
					<Flexed direction="row" align="center" gap={0.2}>
						<AiFillStar fill={`${palette.golden}`} />
						<Text type="small" isDarkTheme={_isDarkTheme}>
							{business?.businessSettings?.rating?.toFixed(1) + ' ' + getRatingText(business?.businessSettings?.rating)}
						</Text>
					</Flexed>
					<Text type="small" fontWeight="bold" isDarkTheme={_isDarkTheme}>
						{business?.businessSettings?.avgDeliveryTime.split(' ')[0]} - {parseInt(business?.businessSettings?.avgDeliveryTime.split(' ')[0]) + parseInt(business?.businessSettings?.threshold.split(' ')[0])} min
					</Text>
				</RatingAndTime>
				<InfoContent view={view}>
					<Text type="small" isDarkTheme={_isDarkTheme}>
						{_loginUserStatus ? (distanceInMiles ? `${distanceInMiles?.toFixed(2)} miles away` : '0 miles away') : ''}
					</Text>
					{business?.businessSettings?.inHouseDelivery ? (
						<>
							{business?.businessSettings?.freeDelivery && business?.businessSettings?.inHouseDelivery ? (
								<Text type="small" fontWeight={500} color="blue">
									Free delivery
								</Text>
							) : (
								<Text type="small" fontWeight={500} color="danger" isDarkTheme={_isDarkTheme}>
									${business?.businessSettings?.deliveryCharges} delivery
								</Text>
							)}
						</>
					) : null}
				</InfoContent>
				<InfoContent view={view}>
					<Text type="xsmall" color="popular" fontWeight={500}>
						Delivery within {business?.deliveryRadius} miles
					</Text>
				</InfoContent>
				<Spacer height={0.5} />
				<CheckBusinessTime view={view} isFromBusinessCard={true} isStickyHeader={true} business={business} />
			</Content>
		</Wrapper>
	)
}

const Image = styled.img<any>`
	width: 100%;
	height: ${({view}) => (view ? '12rem' : '10rem')};
	border-radius: ${({view}) => (view ? '0.39rem' : '0')};
	overflow: hidden;
	transition: all 0.2s ease-in-out;
	object-fit: cover;
`

const Wrapper = styled.div<any>`
	position: relative;
	margin: 0 0 2rem;
	display: ${({view}) => (view ? 'flex' : '')};
	width: 100%;
	border-radius: 0.39rem;
	transition: all 0.3s ease-in-out;
	background: ${({isDarkTheme}) => (isDarkTheme ? palette.black : palette.white)};
	box-shadow: ${palette.shadow};
	overflow: hidden;
	cursor: pointer;
	&:hover ${Image} {
		transform: scale(1.04);
	}
`

const Cover = styled.div<any>`
	width: ${({view}) => (view ? '30rem' : '100%')};
	height: ${({view}) => (view ? '12rem' : '10rem')};
	overflow: hidden;
	cursor: pointer;
	pointer-event: ${({isSold}) => (isSold ? 'none' : '')};
`

const Content = styled.div<any>`
	position: ${({view}) => (view ? '' : 'relative')};
	padding: 0.5rem;
	padding: ${({view}) => (view ? '0.5rem 0.5rem 0.5rem 1rem' : '0.5rem')};
	width: 100%;
	display: ${({view}) => (view ? 'flex' : '')};
	flex-direction: ${({view}) => (view ? 'column' : '')};
	gap: ${({view}) => (view ? '0.5rem' : '')};
	justify-content: ${({view}) => (view ? 'space-between' : '')};
`

const InfoContent = styled(Flexed)<any>`
	margin-top: ${({view}) => (view ? '0rem' : '0.3rem')};
	flex-direction: ${({view}) => (view ? 'column' : 'row')};
	justify-content: ${({view}) => (view ? 'space-between' : 'space-between')};
	align-items: ${({view}) => (view ? '' : 'center')};
	gap: 0.5rem;
`

const DisplayItemBaner = styled(Text)<any>`
	position: ${({view}) => (view ? '' : 'absolute')};
	width: fit-content;
	border-radius: 0.2rem;
	background: ${palette.danger};
	color: ${palette.white};
	font-size: 14px;
	font-weight: 500;
	padding: 0.2rem 0.3rem;
	display: flex;
	align-items: center;
	gap: 0.2rem;
	top: ${({top}) => `${top}rem`};
	right: ${({right}) => `${right}rem`};
	left: ${({left}) => `${left}rem`};
	bottom: ${({bottom}) => `${bottom}rem`};
	margin: ${({margin}) => `${margin}`};
	z-index: ${({zIndex}) => `${zIndex}`};
`

const RatingAndTime = styled(Flexed)`
	flex-direction: ${({view}) => (view ? 'column' : 'row')};
	align-items: ${({view}) => (view ? 'start' : 'center')};
	gap: 0.5rem;
`

export default RestaurantCardContent
