import React, {useEffect, useState, useRef} from 'react'
import styled from 'styled-components'
import {palette} from '../styled/colors'
import {useNavigate} from 'react-router-dom'
import {Container, Row, Col, media} from 'styled-bootstrap-grid'
import {Flexed, Spacer, Text} from '../styled/shared'
import InputField from './common/InputField'
import Button from './common/Button'
import {useSelector, useDispatch} from 'react-redux'
import {createSearchParams} from 'react-router-dom'
import DropDown from './common/DropDown'
import {api} from './api/callAxios'
import {toast} from 'react-toastify'
import {handleHomeBanerSearch, isImageSearch, saveSearchImage} from '../actions/authActions'
import {doGenerateAiResponse} from './api/AI_Api'
import {verifyJSON} from '../constants/commonFunctions'
import VoiceRecognition from './common/VoiceRecognition'

interface IProps {
	isDarkTheme: boolean
}

const MainCover = () => {
	const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
	const _navigate = useNavigate()
	let _dispatch = useDispatch()
	const [search, setSearch] = useState('')
	const [tagLine, setTagLine] = useState({label: '', value: ''})
	const [tagOption, setTagOption] = useState<any>([])
	const [isLoading, setIsLoading] = useState(false)

	const fileInputRef: any = useRef(null)

	useEffect(() => {
		getBusinessTypes()
	}, [])

	const getBusinessTypes = () => {
		api.get(`/business-type`)
			.then((res) => {
				if (res.data) {
					let storeType: any = []
					res?.data?.businesTypes?.map((e: any) => {
						storeType.push({label: e.title, value: e?.id})
					})
					setTagOption(storeType)
				}
			})
			.catch((e) => {
				if (e?.response) {
					toast.error(e?.response?.data?.message)
				}
			})
	}

	const handleLinkClick = () => {
		// Trigger the file input when the link is clicked
		if (fileInputRef?.current) {
			fileInputRef.current.click()
		}
	}

	const handleFileChange = async (event: any) => {
		if (event.target.files?.length) {
			let res = await fileToGenerativePart(event.target.files[0])
			console.log('res:', res)
			if (res) {
				generateImageAiResponse([res])
			}
		}
	}

	const fileToGenerativePart = (file: any) => {
		return new Promise((resolve: any, reject: any) => {
			const reader: any = new FileReader()

			reader.onloadend = () => {
				const data = reader.result.split(',')[1] // Extract base64 data
				const mimeType = file.type

				resolve({
					inlineData: {
						data,
						mimeType
					}
				})
			}

			reader.onerror = reject

			reader.readAsDataURL(file)
		})
	}

	const generateImageAiResponse = async (images?: any) => {
		try {
			setIsLoading(true)
			const _text = `Parse the image and generate a relevant response. Use title to store image name. Do not use contact information in the description. Provide a description with a maximum of 4029 words, and always use numerical values for the price. Verify and respond with all possible details, returning responses in all relevant fields. If fields are not applicable to the business type and category, return null or an empty string. The result should be a single object without any extra text.`

			let res: any = await doGenerateAiResponse(_text, images)
			if (res) {
				console.log('======res', res)
				_dispatch(isImageSearch(true))
				_dispatch(saveSearchImage(images))

				let data = verifyJSON(res) ? JSON.parse(res) : {}
				if (!data?.title || !data?.name) {
					const stringWithEmbeddedJSON = res

					const jsonMatch = stringWithEmbeddedJSON.match(/```json([\s\S]*?)```/)

					if (jsonMatch && jsonMatch[1]) {
						const extractedJSON = jsonMatch[1].trim()

						try {
							const parsedObject = JSON.parse(extractedJSON)
							data = parsedObject
						} catch (error) {
							console.error('Error parsing JSON:', error)
						}
					} else {
						console.error('No JSON found in the string.')
					}
				}

				if (data?.title || data?.name) {
					setSearch(data?.name ? data?.name : data?.title)
					_dispatch(handleHomeBanerSearch(data?.name ? data?.name : data?.title))
					_navigate({
						pathname: '/business',
						search: createSearchParams({
							id: tagLine?.value ? tagLine?.value : tagOption[0]?.value
						}).toString()
					})
				}
				setIsLoading(false)
			}
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			console.log('error', error)
		}
	}
	return (
		<>
			{isLoading && (
				<Loading>
					<div className="dots-loading"></div>
				</Loading>
			)}
			<Container>
				<Row>
					<Col>
						<MainContent>
							<Overlay />
							<Wrapper>
								<CustomText fontSize={1.4} lineHeight={2} isCentered fontWeight="bold" color="white" style={{textShadow: '0 2px 4px rgb(0 0 0 / 35%)'}}>
									Restaurant food, takeaway and groceries. Delivered.
								</CustomText>
								<Spacer height={1.5} />
								<InputWrapper gap={1} align="center">
									<Main direction="row" gap={1} align="center">
										<InputField
											type="search"
											placeholder="Search for anything"
											value={search}
											handleChange={(e: any) => {
												setSearch(e)
											}}
										/>

										{tagOption?.length > 0 && (
											<DropDown
												firstSelected={tagOption[0]?.label}
												options={tagOption}
												hasChanged={(value: any) => {
													setTagLine({label: value, value: value})
												}}
												dropDownListHeight="150px"
											/>
										)}
									</Main>
									<VoiceRecognition iconsColor={true} setText={(e: any) => setSearch(e)} />
									<Button
										id="searchId"
										label="Search"
										type="red"
										ifClicked={() => {
											if (search?.trim().length > 0) {
												_dispatch(handleHomeBanerSearch(search))
											}
											_navigate({
												pathname: '/business',
												search: createSearchParams({
													id: tagLine?.value ? tagLine?.value : tagOption[0]?.value
												}).toString()
											})
										}}
									/>
								</InputWrapper>
								<span style={{display: 'flex', justifyContent: 'flex-end', marginTop: '0.2rem', position: 'relative', right: '2rem'}}>
									<CustomLink onClick={handleLinkClick}>Image Search</CustomLink>
									<input type="file" accept=".jpg, .png, .jpeg, .webp" ref={fileInputRef} style={{display: 'none'}} onChange={handleFileChange} />
								</span>
								<Spacer height={0.5} />
							</Wrapper>
						</MainContent>
					</Col>
				</Row>
			</Container>
		</>
	)
}

const CustomLink = styled.span`
	color: ${palette.white};
	font-weight: 500;
	cursor: pointer;
	font-size: 0.9rem;
	&:hover {
		color: blue;
	}
`

export const Loading = styled.div<any>`
	position: ${({position}) => (position ? position : 'fixed')};
	background: rgb(0 0 0 / 36%);
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: ${({left}) => left};
`

const MainContent = styled.div`
	position: relative;
	background-image: url('/images/delivery_app_cover.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	min-height: 16.063rem;
	border-radius: 0.5rem;
	// overflow:hidden;
	margin: 1rem 0rem 0rem 0rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
	justify-content: space-around;
	padding: 1rem 1rem;
	${media.sm` padding: 2rem 2rem 2rem 2rem`};
	${media.xl` padding: 2rem 12rem 2rem 12rem`};
`

const Overlay = styled.div<any>`
	width: 100%;
	height: 100%;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.main_cover_overlay_dark}` : `${palette.main_cover_overlay_light}`)};
	position: absolute;
	border-radius: 0.5rem;
`

const CustomText = styled(Text)`
	position: relative;
`

const Wrapper = styled(Flexed)`
	width: 100%;
	background: ${({isDarkTheme}) => (isDarkTheme ? `${palette.main_cover_overlay_dark}` : `${palette.main_cover_overlay_light}`)};
	padding: 1rem;
	border-radius: 0.5rem;
`

const InputWrapper = styled(Flexed)`
	width: 100%;
	flex-direction: column;
	${media.lg`flex-direction:row`};
	& #inputWrapper,
	#main {
		width: 100%;
	}
	& #ourDropDown {
		width: 100% !important;
		${media.sm`width:19rem !important;`};
		${media.md`width:19rem !important;`};
	}
	& #searchId {
		width: 100% !important;
		height: 45px;
		${media.sm`width:50% !important`};
		${media.lg`width:12rem !important; height:50px`};
	}
	& #dropList {
		z-index: 4 !important;
	}
`

const Main = styled(Flexed)`
	width: 100%;
	flex-direction: column;
	${media.sm`flex-direction:row`};
`

export default MainCover
