import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import { Text, Spacer, Flexed, Loading } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { BsXLg } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { media } from 'styled-bootstrap-grid'
import axios from 'axios'
import InputField from '../common/InputField'
import { api } from '../api/callAxios'
import Button from '../common/Button'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

interface ICategoriesModalProps {
    modalStatus:string
    open: boolean
    onCloseModal: any
    fetchBusinessDetails: any
}

interface IProps {
    isDarkTheme: boolean
}

const AddMenuLabelModal = ({modalStatus, open, onCloseModal, fetchBusinessDetails }: ICategoriesModalProps) => {
    const _isDarkTheme: any = useSelector<any>((state: any) => state.auth.isDarkTheme)
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [nameError, setNameError] = useState('')
    const [isLoading, setIsLoading] = useState<any>(false)

    useEffect(() => {
        if (open) {
            setName('')
            setDescription('')
            setNameError('')
        }
    }, [open])

    const AddMenuLabel = () => {
        let _apiPath = modalStatus === 'menu' ? '/menu-labels' : '/addOns-labels';
        if (formValidation()) {
            setIsLoading(true)
            api.post(_apiPath, {
                title: name,
                description: description
            })
                .then((res) => {
                    setIsLoading(false)
                    fetchBusinessDetails()
                    onCloseModal()
                }).catch(function (error: any) {
                    console.log(error)
                    setIsLoading(false)
                })
        }
    }

    const formValidation = () => {
        let isValid = true
        if (name === '') {
            setNameError('label is required')
            isValid = false
        }
        return isValid
    }
    return (
        <>
            <Modal
                open={open}
                center
                onClose={() => {
                    onCloseModal()
                }}
                classNames={{
                    overlay: 'customOverlay',
                    modal: 'blockModal'
                }}
                showCloseIcon={false}
                // @ts-ignore
                styles={{ zIndex: '2' }}>
                <ModalWrapper isDarkTheme={_isDarkTheme}>
                    <Head direction="row" align="center" justify="space-between" gap={1}>
                        <Text type="large" lineHeight="1.438" fontWeight="700" isDarkTheme={_isDarkTheme}>
                            {modalStatus === 'menu' ? 'Add Menu Label' : 'Add Add-on Label' }
                        </Text>
                        <CrossIcon
                            isDarkTheme={_isDarkTheme}
                            onClick={() => {
                                onCloseModal()
                            }}
                        />
                    </Head>
                    <Body>
                        <Spacer height={0.5} />
                        <InputField error={nameError} label={modalStatus === 'menu' ? 'Menu Label' : 'Add-on Label'} placeholder={modalStatus === 'menu' ? 'Menu label here' : 'Add-on label here'} required value={name} handleChange={(e: any) => {
                            setNameError('');
                            if (e == '') {
                                setNameError('label is required')
                            }
                            setName(e)
                        }} />

                        <Spacer height={1} />

                        <InputField type='textarea' placeholder='Type here' label="Description" value={description} handleChange={(e: any) => {
                            if (e?.length <= 200) {
                                setDescription(e)
                            }
                        }} />
                        <Flexed direction='row' justify='flex-end'>
                            <Text isDarkTheme={_isDarkTheme} type='xsmall'>{description?.length}/200</Text>
                        </Flexed>

                        <Spacer height={1.5} />

                        <Flexed direction='row' align='center' justify='space-between' gap={1}>
                            <Button small width='100%' type='red' label='Save' ifClicked={AddMenuLabel} />
                        </Flexed>
                    </Body>
                </ModalWrapper>
            </Modal>
            {isLoading && <Loading ><div className="dots-loading"></div></Loading>}
        </>
    )
}

const ModalWrapper = styled.div<IProps>`
	background-color: ${({ isDarkTheme }) => (isDarkTheme ? palette.black : palette.white)};
    padding: 1rem;
`

const Head = styled(Flexed)`
	padding: 1rem;
    ${media.sm`
    width: 400px;
	`}
    ${media.md`
    width: 400px;
	`}
`

const Body = styled.div`
padding: 0rem 1rem 1rem;
`

const CrossIcon = styled(BsXLg) <IProps>`
	cursor: pointer;
	z-index: 1;
	padding: 0.2rem;
	color: ${({ isDarkTheme }) => (isDarkTheme ? palette.silver : palette.gray)};
	font-size: 1.3rem;
`

export default AddMenuLabelModal
