export const CommonEnum = Object.freeze({
    AppName: 'ListingApp',
    logo: '/images/Logo.png',
    fbUrl: 'https://www.facebook.com/SmartSoftStudios',
    linkedinUrl: 'https://www.linkedin.com/company/smart-soft-studios',
    websiteUrl: 'https://www.smartsoftstudios.com/',
    appStoreUrl: 'https://itunes.apple.com/app/',
    googleStoreUrl: 'https://play.google.com/store/apps/details?id={YOUR_PACKAGE_NAME}',
    contactUsServerUrl: `https://api.economicsale.finance/booking`

});